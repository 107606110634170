import actionTypes from 'actiontypes/';

const initialState = {
  requiredLevel: 3,
  authMethods: [],
  selectedAuthMethod: undefined,
  requiredUserAction: {},
  code: '',
};

function mfaReducer(state = initialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.MFA.UPDATE_STATE:
      return {
        ...state,
        ...actionPayload,
      };
    case actionTypes.MFA.LOAD_AUTH_METHODS:
      return {
        ...state,
        authMethods: actionPayload,
      };
    default:
      return state;
  }
}

export { mfaReducer as default };
