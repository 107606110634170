import HttpClient from 'utils/http/HttpClient';
import getConstants from 'utils/constants';

const constants = getConstants(process.env.BASE_URL || window.location.origin);
const { uiWebService } = constants.serviceBaseUrls;

const httpClient = HttpClient.createInstance({ baseURL: uiWebService });

const prolongSession = (headers) => httpClient.get('/prolong-session', { headers });

export default prolongSession;
