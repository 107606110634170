import styles from './style.module.scss';
import actionCreator from '../../../actioncreators/forgotPassword/request';
import applicationActionCreator from '../../../actioncreators/application';
import { staticConstants } from '../../../utils/constants';
import Translator from '../../../utils/translation/Translator';
import { EmailInput, ButtonContainer, PrimaryButton, SecondaryButton } from '../../../components';
import { withNamespace } from '../../../components/elements/textDisplays/TranslatedText';

import { StandardParagraph, Header2 } from '@idm/ui-components';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const { LYRIC_PRODUCT_NAME } = staticConstants;

const TranslatedText = withNamespace('FORGOT_PASSWORD.REQUEST');
const requestDictionary = new Translator('FORGOT_PASSWORD.REQUEST');

class Request extends Component {
  static propTypes = {
    formEmail: PropTypes.string.isRequired,
    isValidating: PropTypes.bool.isRequired,
    isAllValid: PropTypes.bool.isRequired,
    isRebrandEnabled: PropTypes.bool,
    dictionary: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    dispatchResetState: PropTypes.func.isRequired,
    dispatchSetFormField: PropTypes.func.isRequired,
    dispatchSetFormFieldValidationResult: PropTypes.func.isRequired,
    dispatchSetFormIsValidating: PropTypes.func.isRequired,
    dispatchRequestResetPassword: PropTypes.func.isRequired,
    onChangeUrl: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isRebrandEnabled: false,
  };

  componentDidUpdate(prevProps) {
    const { isValidating, isAllValid, dispatchRequestResetPassword, formEmail } = this.props;

    if (this.shouldRequestResetPassword(prevProps.isValidating, isValidating, isAllValid)) {
      dispatchRequestResetPassword(formEmail);
    }
  }

  componentWillUnmount() {
    const { dispatchResetState } = this.props;

    dispatchResetState();
  }

  shouldRequestResetPassword = (isFormSubmitted, isStillValidating, isAllValid) =>
    isFormSubmitted && !isStillValidating && isAllValid;

  goToLogin = () => {
    const { onChangeUrl } = this.props;

    onChangeUrl('/auth/login');
  };

  handleValidationChange(field, validationResult) {
    const { dispatchSetFormFieldValidationResult } = this.props;

    dispatchSetFormFieldValidationResult(field, validationResult);
  }

  handleEmailValidationChange = this.handleValidationChange.bind(this, 'email');

  handleKeyPress = (event) => {
    const { dispatchSetFormIsValidating } = this.props;

    if (event.key === 'Enter') {
      dispatchSetFormIsValidating();
    }
  };

  handleChange(field, event) {
    const { dispatchSetFormField } = this.props;

    dispatchSetFormField(field, event.target.value);
  }

  handleEmailChange = this.handleChange.bind(this, 'email');

  render() {
    const { dispatchSetFormIsValidating, formEmail, isValidating, dictionary, isRebrandEnabled } =
      this.props;
    const regex = new RegExp('^.+@.+\\..+$');
    const isValidEmail = regex.test(formEmail);

    return (
      <section>
        <Header2 className={styles.header2}>
          <TranslatedText i18nKey="HEADER" />
        </Header2>
        <StandardParagraph className={styles.displayMessage}>
          <TranslatedText i18nKey="BODY" />
        </StandardParagraph>
        <EmailInput
          forceValidationCheck={isValidating}
          handleValidationChange={this.handleEmailValidationChange}
          onChange={this.handleEmailChange}
          onKeyPress={this.handleKeyPress}
          value={formEmail}
          label={requestDictionary.get(
            'EMAIL_LABEL',
            dictionary,
            { productName: LYRIC_PRODUCT_NAME },
            isRebrandEnabled
          )}
          className="input-large"
          autoFocus={true}
          id="email-input"
          dataMetaId="email-input"
        />
        <ButtonContainer>
          <SecondaryButton
            onClick={this.goToLogin}
            value={requestDictionary.get('CANCEL_BUTTON', dictionary)}
            size="large"
            data-meta-id="cancel-button"
          >
            <TranslatedText i18nKey="CANCEL_BUTTON" />
          </SecondaryButton>
          <PrimaryButton
            onClick={dispatchSetFormIsValidating}
            size="large"
            data-meta-id="submit-button"
            disabled={!isValidEmail}
          >
            <TranslatedText i18nKey="SUBMIT_BUTTON" />
          </PrimaryButton>
        </ButtonContainer>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    formEmail: state.forgotPasswordRequest.form.email.value,
    isAllValid: state.forgotPasswordRequest.form.email.isValid,
    isValidating: state.forgotPasswordRequest.form.email.isValidating,
    dictionary: state.translation.main.dictionary,
    isRebrandEnabled: state.me.main.isRebrandEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeUrl(url) {
      applicationActionCreator.changeUrl(url);
    },
    dispatchResetState() {
      dispatch(actionCreator.resetState());
    },

    dispatchSetFormField(field, value) {
      dispatch(actionCreator.setFormField(field, value));
    },

    dispatchSetFormIsValidating() {
      dispatch(actionCreator.setFormIsValidating());
    },

    dispatchSetFormFieldValidationResult(field, validationResult) {
      dispatch(actionCreator.setFormFieldValidationResult(field, validationResult));
    },

    dispatchRequestResetPassword(email) {
      dispatch(actionCreator.requestResetPassword(email));
    },
  };
}

export { Request as UnwrappedResetPasswordRequest };
export default connect(mapStateToProps, mapDispatchToProps)(Request);
