import { noop } from '../../../utils/constants';
import { PageHeader, List, ListElement } from '@idm/ui-components';
import combineClasses from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const arrayOfProducts = PropTypes.arrayOf(
  PropTypes.shape({
    productName: PropTypes.node,
    productDescription: PropTypes.node,
  })
);

class ProductSelection extends React.Component {
  static propTypes = {
    products: arrayOfProducts,
    isLoading: PropTypes.bool,

    onLoadProducts: PropTypes.func.isRequired,
    onProductSelected: PropTypes.func,
  };

  static defaultProps = {
    products: null,
    isLoading: false,

    onProductSelected: noop,
  };

  componentDidMount() {
    const { products, isLoading, onLoadProducts } = this.props;

    if (!isLoading && !products) {
      onLoadProducts();
    }
  }

  get isLoading() {
    const { products, isLoading } = this.props;

    return isLoading || !products;
  }

  renderListElement(product) {
    const { onProductSelected } = this.props;

    return (
      <ListElement
        data-meta-id={product.productId}
        title={product.productName}
        subtitle={product.description}
        onClick={() => onProductSelected(product)}
      />
    );
  }

  renderList() {
    const { products } = this.props;

    if (!products) {
      return null;
    }

    const listElements = products.map((product) => this.renderListElement(product));

    return <List>{listElements}</List>;
  }

  renderPageTitle() {
    let title = 'Which product are you using?';

    if (this.isLoading) {
      title = 'Loading';
    }

    const cssClasses = combineClasses('page-title', { loading: this.isLoading });

    return <PageHeader className={cssClasses}>{title}</PageHeader>;
  }

  render() {
    return (
      <section className="ui-product-selection">
        {this.renderPageTitle()}
        {this.renderList()}
      </section>
    );
  }
}

export default ProductSelection;
