import CreateCredentialObligation from './CreateCredentialObligation/CreateCredentialObligation';
import SamlRedirectObligation from './SamlRedirectObligation';
import { staticConstants } from '../../../utils/constants';
import LoginForm from '../../Login/LoginForm';
import { withNamespace } from '../../../components/elements/textDisplays/TranslatedText';

import React from 'react';
import PropTypes from 'prop-types';

const TranslatedText = withNamespace('OBLIGATION');
const translateKey = (key, props) => <TranslatedText i18nKey={key} {...props} />;

const { obligationCommandKinds } = staticConstants;

const Obligations = (props) => {
  const { obligationData, onAfterObligation } = props;

  if (obligationData && obligationData.command) {
    switch (obligationData.command) {
      case obligationCommandKinds.CREATE_BASIC_CREDENTIALS:
        return <CreateCredentialObligation onAfterObligation={onAfterObligation} />;
      case obligationCommandKinds.REQUIRE_SAML:
        return <SamlRedirectObligation args={obligationData.args} />;
      case obligationCommandKinds.REQUIRE_BASIC_CREDENTIALS:
        return (
          <LoginForm
            title={translateKey('LOGIN.TITLE')}
            subtitle={translateKey('LOGIN.SUBTITLE')}
            submitTitle={translateKey('LOGIN.BUTTON_TEXT')}
            onAfterLogin={onAfterObligation}
          />
        );
      default:
        break;
    }
  }
  return null;
};

Obligations.propTypes = {
  obligationData: PropTypes.shape({
    kind: PropTypes.string,
    command: PropTypes.string,
    isLoginObligation: PropTypes.bool,
    args: PropTypes.shape({}),
  }).isRequired,
  onAfterObligation: PropTypes.func.isRequired,
};

export default Obligations;
