import {
  LengthRule,
  DigitRule,
  UpperCaseRule,
  LowerCaseRule,
  SpecialCharRule,
} from '../../../utils/validator/rules';
import { ValidatedInput } from '../..';
import NotValidatedPasswordInput from '../NotValidatedPasswordInput';
import UtilityHelper from '../../../utils/helpers';
import { noop } from '../../../utils/constants';
import { CheckIcon } from '../../elements/icons';
import React from 'react';
import PropTypes from 'prop-types';

class PasswordInput extends React.Component {
  static propTypes = {
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    dataMetaId: PropTypes.string,
    forceValidationCheck: PropTypes.bool,
    handleValidationChange: PropTypes.func,
    hidePasswordToggle: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    overrideRules: PropTypes.arrayOf(PropTypes.object.isRequired),
    neverShowError: PropTypes.bool,
    neverShowRequirements: PropTypes.bool,
    requirementsTitle: PropTypes.string,
    validateOnChange: PropTypes.bool,
    value: PropTypes.string,
    lengthRuleViolationMsg: PropTypes.string,
    digitRuleViolationMsg: PropTypes.string,
    upperCaseRuleViolationMsg: PropTypes.string,
    lowerCaseRuleViolationMsg: PropTypes.string,
    specialCharRuleViolationMsg: PropTypes.string,
    additionalValidationText: PropTypes.string,
    showPasswordText: PropTypes.string,
    hidePasswordText: PropTypes.string,
  };

  static defaultProps = {
    autoComplete: '',
    autoFocus: false,
    className: '',
    dataMetaId: '',
    forceValidationCheck: false,
    handleValidationChange: noop,
    hidePasswordToggle: false,
    id: UtilityHelper.generateUniqueId(),
    label: '',
    neverShowError: true,
    neverShowRequirements: true,
    onChange: noop,
    onKeyPress: noop,
    overrideRules: null,
    requirementsTitle: '',
    validateOnChange: false,
    value: '',
    lengthRuleViolationMsg: 'At least 8 characters',
    digitRuleViolationMsg: 'At least 1 number',
    upperCaseRuleViolationMsg: 'At least 1 uppercase letter',
    lowerCaseRuleViolationMsg: 'At least 1 lowercase letter',
    specialCharRuleViolationMsg: 'At least 1 special character',
    additionalValidationText: '* Additional validation rules will apply',
    showPasswordText: 'Show',
    hidePasswordText: 'Hide',
  };

  constructor(props) {
    super(props);

    //this.validatedInput = React.createRef(); //react 16

    this.state = {
      validationResult: {},
    };
  }

  handleValidationChangeForRequirements = (valid, validationResult) => {
    const { handleValidationChange } = this.props;

    this.setState({ validationResult });
    handleValidationChange(valid, validationResult);
  };

  validate() {
    return this.validatedInput.assertValid();
  }

  renderRequirement = ({ message }, i) => {
    const { validationResult } = this.state;

    return (
      <div key={i} className="cell">
        <div>{validationResult[message] && <CheckIcon width={14} height={14} />}</div>
        <span>{message}</span>
      </div>
    );
  };

  renderRequirements() {
    const { neverShowRequirements, requirementsTitle, additionalValidationText } = this.props;

    if (neverShowRequirements) {
      return null;
    }

    const requirements = this.validationRules.map(this.renderRequirement);

    return (
      <div className="validation-box">
        <span className="title">{requirementsTitle}</span>
        <div className="table">{requirements}</div>
        <span className="additional-validation">{additionalValidationText}</span>
      </div>
    );
  }

  render() {
    const {
      lengthRuleViolationMsg,
      digitRuleViolationMsg,
      upperCaseRuleViolationMsg,
      lowerCaseRuleViolationMsg,
      specialCharRuleViolationMsg,
      overrideRules,
      autoComplete,
      autoFocus,
      className,
      dataMetaId,
      forceValidationCheck,
      hidePasswordToggle,
      id,
      label,
      neverShowError,
      onChange,
      onKeyPress,
      validateOnChange,
      showPasswordText,
      hidePasswordText,
      value,
    } = this.props;
    const rules = [
      new LengthRule(lengthRuleViolationMsg, 8, 72),
      new DigitRule(digitRuleViolationMsg),
      new UpperCaseRule(upperCaseRuleViolationMsg),
      new LowerCaseRule(lowerCaseRuleViolationMsg),
      new SpecialCharRule(specialCharRuleViolationMsg),
    ];

    this.validationRules = overrideRules && overrideRules.length ? overrideRules : rules;

    return (
      <ValidatedInput
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={className}
        dataMetaId={dataMetaId}
        forceValidationCheck={forceValidationCheck}
        handleValidationChange={this.handleValidationChangeForRequirements}
        hidePasswordToggle={hidePasswordToggle}
        id={id}
        InputComponent={NotValidatedPasswordInput}
        label={label}
        neverShowError={neverShowError}
        onChange={onChange}
        onKeyPress={onKeyPress}
        rules={this.validationRules}
        validateOnChange={validateOnChange}
        value={value}
        showPasswordText={showPasswordText}
        hidePasswordText={hidePasswordText}
        ref={(obj) => {
          this.validatedInput = obj;
        }}
      >
        {this.renderRequirements()}
      </ValidatedInput>
    );
  }
}

export { PasswordInput as default };
