import { LengthRule, EmailRule, NoNonAdpEmailRule } from '../../../utils/validator/rules';
import UtilityHelper from '../../../utils/helpers';
import { noop } from '../../../utils/constants';
import { ValidatedInput, NotValidatedInput } from '@idm/ui-components';
import React from 'react';
import PropTypes from 'prop-types';

/*eslint-disable react/destructuring-assignment */
function EmailInput(props) {
  const rules = [
    new LengthRule(props.lengthRuleViolationMsg, 1, 255),
    new EmailRule(props.emailRuleViolationMsg),
    new NoNonAdpEmailRule(props.noNonAdpViolationMsg, props.clientId),
  ];

  return (
    <ValidatedInput
      autoComplete="email"
      autoFocus={props.autoFocus}
      className={props.className}
      dataMetaId={props.dataMetaId}
      forceValidationCheck={props.forceValidationCheck}
      handleValidationChange={props.handleValidationChange}
      id={props.id}
      InputComponent={NotValidatedInput}
      label={props.label}
      neverShowError={props.neverShowError}
      onBlur={props.onBlur}
      onChange={props.onChange}
      onKeyPress={props.onKeyPress}
      onPaste={props.onPaste}
      rules={[...rules, ...props.additionalRules]}
      type="email"
      value={props.value}
    />
  );
}

EmailInput.propTypes = {
  additionalRules: PropTypes.arrayOf(PropTypes.object.isRequired),
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  dataMetaId: PropTypes.string,
  forceValidationCheck: PropTypes.bool,
  handleValidationChange: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string,
  neverShowError: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onPaste: PropTypes.func,
  value: PropTypes.string,
  lengthRuleViolationMsg: PropTypes.string,
  emailRuleViolationMsg: PropTypes.string,
  noNonAdpViolationMsg: PropTypes.string,
  clientId: PropTypes.string,
};

EmailInput.defaultProps = {
  additionalRules: [],
  autoFocus: false,
  className: '',
  dataMetaId: '',
  forceValidationCheck: false,
  handleValidationChange: noop,
  id: UtilityHelper.generateUniqueId(),
  label: '',
  neverShowError: false,
  onBlur: noop,
  onChange: noop,
  onKeyPress: noop,
  onPaste: noop,
  value: '',
  lengthRuleViolationMsg: 'This field is required',
  emailRuleViolationMsg: 'A valid email address is required',
  noNonAdpViolationMsg: 'An @adp.com email address is required',
  clientId: '',
};

export { EmailInput as default };
