import WindowUtils from './window';
import DeviceUtilities from '../device';
import urls from '../urls';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import daytz from 'dayjs/plugin/timezone';
import queryString from 'query-string';
import { generateUniqueId } from '@idm/ui-components';
import { Buffer } from 'buffer';
import 'url-polyfill';

dayjs.extend(utc);
dayjs.extend(daytz);

class UtilityHelper {
  static setLangAttribute = (lang) => {
    document.querySelector('html').setAttribute('lang', lang || 'en-US');
  };

  static generateUniqueId() {
    return generateUniqueId();
  }

  static invoke(evt, ...args) {
    if (typeof evt === 'function') {
      evt(...args);
    }
  }

  static getHostnameForCookies() {
    const {
      location: { hostname },
    } = window;
    const validHostname = hostname === 'localhost' ? null : hostname;

    return validHostname;
  }

  static getWindowSettings() {
    return window.__environment_settings__ || {};
  }

  static openOrFallback(config) {
    UtilityHelper.redirectTo(config.APP_URL);
    setTimeout(() => {
      UtilityHelper.redirectTo(config.APP_STORE_URL);
    }, 500);
  }

  static openAppWithFallback() {
    if (DeviceUtilities.isAndroid()) {
      UtilityHelper.openOrFallback(urls.mobileApp.android);
    } else if (DeviceUtilities.isIOS()) {
      UtilityHelper.openOrFallback(urls.mobileApp.ios);
    } else {
      UtilityHelper.navigateToLifionHome();
    }
  }

  static navigateToLifionHome() {
    UtilityHelper.redirectTo(urls.LIFION_HOME);
  }

  static navigateToLogin() {
    UtilityHelper.redirectTo(urls.redirectToCurrentAfterLogin());
  }

  static requiresRedirect(path) {
    return UtilityHelper.getCurrentPath() !== path;
  }

  static isAbsoluteUrl(url) {
    try {
      // eslint-disable-next-line no-new
      new URL(url);

      return true;
    } catch (err) {
      return false;
    }
  }

  static redirectTo(url, history, state) {
    if (UtilityHelper.isIDMOwnedUrl(url) && Boolean(history)) {
      const urlObj = new URL(url, window.location.origin);
      const relativeUrl = urlObj.pathname + urlObj.search;

      history.push(relativeUrl, state);
    } else {
      window.location.href = url;
    }
  }

  static getWindowLocation() {
    return WindowUtils.getWindowLocation();
  }

  static getCurrentPath() {
    return WindowUtils.getCurrentPath();
  }

  static getWindwQueryParams() {
    const location = UtilityHelper.getWindowLocation();

    return UtilityHelper.parseQueryString(location.search);
  }

  static parseQueryString(queryStr) {
    if (!queryStr || queryStr.length === 0) {
      return {};
    }

    return queryString.parse(queryStr);
  }

  static concatQueryString(params, existingQueryString) {
    const qs = UtilityHelper.parseQueryString(existingQueryString);
    const qsParams = { ...qs, ...params };

    return queryString.stringify(qsParams);
  }

  static isIDMOwnedUrl(url) {
    const urlObj = new URL(url, window.location.origin);

    return urlObj.origin === window.location.origin && urlObj.pathname.startsWith('/auth');
  }

  static appendQueryStringToUrl(url, queryParamsToAppend) {
    const urlObj = new URL(url, window.location.origin);
    const params = UtilityHelper.parseQueryString(queryParamsToAppend);
    const newQueryString = UtilityHelper.concatQueryString(params, urlObj.search);

    urlObj.search = newQueryString;

    return urlObj.toString();
  }

  /**
   * Checks whether a given object inherits or construct from a given (parent) class
   */
  static isA(object, parentClass) {
    let current = object.constructor;
    const isString = typeof parentClass === 'string';

    while (current) {
      const currentObject = isString ? current && current.name : current;

      if (currentObject === parentClass) {
        return true;
      }

      current = Object.getPrototypeOf(current);
    }

    return false;
  }

  static parseDateForDisplay(
    date,
    outFormat,
    inFormat = 'YYYY-MM-DDThh:mm:ss.SSSZ',
    timezone = 'America/New_York'
  ) {
    return dayjs.tz(date, inFormat, 'UTC').tz(timezone).format(outFormat);
  }

  static encodeBase64(data) {
    return Buffer.from(data).toString('base64');
  }

  static getAccessibilityPropsForClickableElement(onClick) {
    return {
      onClick,
      onKeyPress: (event) => {
        if (event.key === 'Enter') {
          onClick();
        }
      },
      role: 'button',
      tabIndex: '0',
    };
  }

  /* istanbul ignore next */
  static isMobile() {
    let check;

    (function mobileCheck(s) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          s
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
          s.substr(0, 4)
        )
      ) {
        check = true;
      } else {
        check = false;
      }
    })(window.navigator.userAgent || window.navigator.vendor || window.opera);

    return check;
  }

  static convertToRelativePath(url) {
    return url.replace(/^[/\\]*/, '/');
  }

  static isReactNativeWebView() {
    return Boolean(window.ReactNativeWebView);
  }

  static triggerMobileEvent(key) {
    if (this.isReactNativeWebView()) {
      window.ReactNativeWebView.postMessage(key);
    }
  }

  static getRedirectUrl(baseUrl) {
    const queryParams = UtilityHelper.parseQueryString(window.location.search);
    const returnUrl = queryParams.redirectUrl || queryParams.returnUrl || '';
    const relativePath = UtilityHelper.convertToRelativePath(returnUrl);

    return baseUrl.concat(relativePath);
  }
}

export { UtilityHelper as default };
