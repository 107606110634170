import styles from './index.module.scss';
import TitledIcon from '../TitledIcon';
import { Header2, StandardParagraph } from '@idm/ui-components';
import React from 'react';
import PropTypes from 'prop-types';

const Notification = (props) => {
  const { status, title, message, className, children, ...otherProps } = props;

  return (
    <div className={className} data-meta-id={`${status}-page`} {...otherProps}>
      <TitledIcon type={status}>
        <Header2 className={styles.title}>{title}</Header2>
      </TitledIcon>
      <div className={styles.messageContainer}>
        <StandardParagraph className={styles.message}>{message}</StandardParagraph>

        <section>{children}</section>
      </div>
    </div>
  );
};

Notification.propTypes = {
  status: PropTypes.oneOf(['success', 'error', 'info']).isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  children: PropTypes.node,
  title: PropTypes.node.isRequired,
};

Notification.defaultProps = {
  message: '',
  className: '',
  children: null,
};
export default Notification;
