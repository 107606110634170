import CreateCredentialForm from '../../../CreateBasicCredentialsPage/CreateCredentialForm';
import TranslatedText from '../../../../components/elements/textDisplays/TranslatedText';
import basicCredentialsActionCreator from '../../../../actioncreators/createCredential/basicCredentials';
import utilHelper from '../../../../utils/helpers';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const translateKey = (key, props) => <TranslatedText i18nKey={key} {...props} />;

class CreateCredentialObligationForm extends Component {
  static propTypes = {
    onFormSubmit: PropTypes.func.isRequired,
  };

  onSubmit = async (data) => {
    const { onFormSubmit } = this.props;

    await onFormSubmit(data);
  };

  render() {
    return (
      <CreateCredentialForm
        submitTitle={translateKey('CREATE_BASIC_CREDENTIALS.SUBMIT')}
        title={translateKey('OBLIGATION.LOGIN.TITLE')}
        subtitle={translateKey('OBLIGATION.CREATE_CREDENTIAL.SUBTITLE')}
        requireTermsAcceptance={true}
        onSubmit={this.onSubmit}
      />
    );
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onClearObligation() {
      return basicCredentialsActionCreator.clearObligations();
    },

    async onFormSubmit(data) {
      const { onAfterObligation } = ownProps;

      await basicCredentialsActionCreator.createBasicCredentials(data);

      utilHelper.invoke(onAfterObligation);
    },
  };
}
export { CreateCredentialObligationForm };
export default connect(null, mapDispatchToProps)(CreateCredentialObligationForm);
