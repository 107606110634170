import actionCreator from '../../actioncreators/messages';
import { withNamespace } from '../../components/elements/textDisplays/TranslatedText';
import { ToastMessage } from '../../components';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './style.scss';

const TranslatedText = withNamespace('TOAST_MESSAGE');

function Messages({ messages, dispatchRemoveToastMessage }) {
  const toastMessages = messages.map(({ message, type, id, translationNamespace }) => {
    const messageComponent = translationNamespace ? (
      <TranslatedText namespace={translationNamespace} i18nKey={message} />
    ) : (
      message
    );

    return (
      <ToastMessage
        key={id}
        id={id}
        message={messageComponent}
        type={type}
        removeSelf={dispatchRemoveToastMessage}
      />
    );
  });

  return <div className="idm-messages">{toastMessages}</div>;
}

Messages.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.node.isRequired,
      type: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      translationNamespace: PropTypes.string,
    }).isRequired
  ).isRequired,

  dispatchRemoveToastMessage: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchRemoveToastMessage(id) {
      dispatch(actionCreator.removeToastMessage({ id }));
    },
  };
}

function mapStateToProps(state) {
  return {
    messages: state.messages.toastMessages,
  };
}

export { Messages as UnwrappedMessages };
export default connect(mapStateToProps, mapDispatchToProps)(Messages);
