import serializer from './serializer';
import HttpClient from '../../utils/http/HttpClient';
import getConstants from '../../utils/constants';

const constants = getConstants(process.env.BASE_URL);
const baseURL = constants.serviceBaseUrls.authenticationService;

class LoginDatasource {
  constructor() {
    this.httpClient = HttpClient.createInstance({
      baseURL,
    });
  }

  async ssoLogin(email) {
    const payload = serializer.toLoginRequest({
      email,
    });

    const response = await this.httpClient.post(
      '/saml/as-sp/clients/identity-providers/search',
      payload
    );

    return serializer.fromLoginResponse(response.data);
  }
}

export default new LoginDatasource();
