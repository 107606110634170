import { staticConstants } from 'utils/constants';

const { tokenKinds } = staticConstants;

const getPersonDataPayload = (personData) => ({
  code: personData.code,
  isSSO: false,
  personData: {
    firstName: personData.firstName,
    lastName: personData.lastName,
    username: personData.username,
    password: personData.password,
  },
});

const getPersonDataPayloadForSSO = (personData) => ({
  code: personData.code,
  isSSO: true,
  personData: {
    firstName: personData.firstName,
    lastName: personData.lastName,
  },
});

const toCreateAccountRequest = (token, personData, isSSO) => ({
  token,
  kind: tokenKinds.REGISTRATION_EMAIL_VERIFICATION,
  data: isSSO ? getPersonDataPayloadForSSO(personData) : getPersonDataPayload(personData),
});

export default {
  toCreateAccountRequest,
};
