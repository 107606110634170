import ChangePasswordForm from './ChangePasswordForm';
import actionCreator from '../../actioncreators/accountSettings/changePassword';
import applicationActionCreator from '../../actioncreators/application';

import { staticConstants } from '../../utils/constants';
import { ButtonContainer, PrimaryButton } from '../../components';
import namespaceTranslatedText from 'containers/TranslatedText/';
import { Header2, StandardParagraph, Modal } from '@idm/ui-components';
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const TranslatedText = namespaceTranslatedText('ACCOUNT_SETTINGS.CHANGE_PW');
const isChangePasswordModalDisplaying =
  staticConstants.localStorage.IS_CHANGE_PASSWORD_MODAL_DISPLAYING;

class ChangePassword extends React.Component {
  static propTypes = {
    dispatchUpdatePassword: PropTypes.func.isRequired,
    title: PropTypes.node,
    subtitle: PropTypes.node,
    isModalVisible: PropTypes.bool.isRequired,
    isPasswordChanged: PropTypes.bool.isRequired,
    dispatchHideModal: PropTypes.func.isRequired,
    onChangeUrl: PropTypes.func.isRequired,
  };

  static defaultProps = {
    title: null,
    subtitle: null,
  };

  constructor() {
    super();

    this.intervalId = null;
    this.refreshHandler = null;

    this.state = {
      redirectTimeLeft: 10000,
      startShowingModal: true,
    };
  }

  onCountdownEnd = () => {
    const { dispatchHideModal, onChangeUrl } = this.props;

    localStorage.removeItem(isChangePasswordModalDisplaying);
    clearInterval(this.intervalId);
    dispatchHideModal();
    onChangeUrl('/auth/logout-all-sessions');
  };

  componentDidMount() {
    if (localStorage.getItem(isChangePasswordModalDisplaying) === 'true') {
      localStorage.removeItem(isChangePasswordModalDisplaying);
      window.location.href = '/auth/logout-all-sessions';
    }
  }

  componentWillUnmount() {
    const { dispatchHideModal } = this.props;

    dispatchHideModal();
  }

  componentDidUpdate() {
    const { isModalVisible } = this.props;
    const { startShowingModal } = this.state;

    if (isModalVisible && startShowingModal) {
      this.setState(() => {
        return { startShowingModal: false };
      });

      this.intervalId = setInterval(() => {
        this.setState((state) => {
          return { redirectTimeLeft: state.redirectTimeLeft - 500 };
        });
      }, 500);
    }

    const { redirectTimeLeft } = this.state;

    if (redirectTimeLeft <= 0) {
      this.onCountdownEnd();
    }
  }

  onClickModalOkButton = () => {
    this.onCountdownEnd();
  };

  renderTitle() {
    const { title } = this.props;

    if (!title) {
      return null;
    }
    return <Header2 className="header2">{title}</Header2>;
  }

  renderSubtitle() {
    const { subtitle } = this.props;

    if (!subtitle) {
      return null;
    }
    return <StandardParagraph>{subtitle}</StandardParagraph>;
  }

  render() {
    const { dispatchUpdatePassword, isModalVisible, isPasswordChanged } = this.props;
    const { redirectTimeLeft } = this.state;
    const secondsLeft = Math.ceil(redirectTimeLeft / 1000);
    const modalTitle = isPasswordChanged ? (
      <TranslatedText textKey="SUCCESS_LOGOUT_MODAL_TITLE" />
    ) : (
      <TranslatedText textKey="FAIL_LOGOUT_MODAL_TITLE" />
    );
    const modalBody = isPasswordChanged ? (
      <TranslatedText
        textKey="SUCCESS_LOGOUT_MODAL_BODY"
        containHtml={true}
        params={{ secondsLeft }}
      />
    ) : (
      <TranslatedText
        textKey="FAIL_LOGOUT_MODAL_BODY"
        containHtml={true}
        params={{ secondsLeft }}
      />
    );

    return (
      <div className="change-pw-container">
        {this.renderTitle()}
        {this.renderSubtitle()}
        <ChangePasswordForm onSubmit={dispatchUpdatePassword} />
        <Modal
          isVisible={isModalVisible}
          closable={false}
          title={modalTitle}
          onOk={this.onClickModalOkButton}
          footer={[
            <ButtonContainer key="buttons">
              <PrimaryButton onClick={this.onClickModalOkButton} data-meta-id="modal-ok-button">
                <TranslatedText textKey="LOGOUT_MODAL_OK_BUTTON_TEXT" />
              </PrimaryButton>
            </ButtonContainer>,
          ]}
        >
          {modalBody}
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isModalVisible: state.accountSettingsChangePassword.main.modal.isVisible,
    isPasswordChanged: state.accountSettingsChangePassword.main.modal.isPasswordChanged,
  };
}

function mapDispatchToProps() {
  return {
    async dispatchUpdatePassword(data) {
      await actionCreator.updatePassword(data);
    },

    dispatchHideModal() {
      actionCreator.resetState();
    },

    onChangeUrl(redirectUrl) {
      applicationActionCreator.changeUrl(redirectUrl);
    },
  };
}

export { ChangePassword as UnwrappedChangePassword };
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
