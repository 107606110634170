import logoPath from '../../../static/assets/images/unauthenticated/ADP_logo.png';
import chairPath from '../../../static/assets/images/chair-with-spill.png';
import namespaceTranslatedText from 'containers/TranslatedText/';
import UtilityHelper from 'utils/helpers/';
import { Flexbox } from '@idm/ui-components';
import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const TranslatedText = namespaceTranslatedText('RESOURCE_NOT_FOUND_ERROR');

function AccountNoAccessErrorPage(props) {
  const { location } = props;

  const queryParams = UtilityHelper.parseQueryString(location.search);
  const traceId = queryParams.traceId || '';

  return (
    <div className="archived-page-container">
      <Flexbox className="archived-page-layout">
        <Flexbox className="message-container">
          <img alt="Logo" className="lifion-logo" src={logoPath} />
          <h1 className="xlarge-page-header">
            <TranslatedText textKey="DONT_HAVE_ACCESS_TO_LIFION" />
          </h1>
          <span className="message">
            <TranslatedText textKey="ACCOUNT_NO_ACCESS" containHtml={true} />
          </span>
          <span className="message">
            <TranslatedText
              textKey="IMMEDIATE_ASSISTANCE"
              containHtml={true}
              params={{ traceId }}
            />
          </span>
          <span className="logout">
            <a href="/auth/logout">
              <TranslatedText textKey="LOGOUT" containHtml={true} />
            </a>
          </span>
        </Flexbox>
        <Flexbox className="chair-with-spill">
          <img alt="Chair with spill" src={chairPath} />
        </Flexbox>
      </Flexbox>
    </div>
  );
}

AccountNoAccessErrorPage.propTypes = {
  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export { AccountNoAccessErrorPage as default };
