const changePasswordActionTypes = {
  RESET_STATE: 'RESET_STATE',
  UPDATE_DATA_REQUEST: 'UPDATE_DATA_REQUEST',
  UPDATE_DATA_SUCCESS: 'UPDATE_DATA_SUCCESS',
  UPDATE_DATA_FAILURE: 'UPDATE_DATA_FAILURE',
  UPDATE_PASSWORD_INCORRECT_PASSWORD_OR_INVALID_PASSWORD_FAILURE:
    'UPDATE_PASSWORD_INCORRECT_PASSWORD_OR_INVALID_PASSWORD_FAILURE',
  UPDATE_PASSWORD_FAIL_TOO_MANY_ATTEMPTS: 'UPDATE_PASSWORD_FAIL_TOO_MANY_ATTEMPTS',
};

export { changePasswordActionTypes as default };
