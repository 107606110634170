import meActionCreator from '../../actioncreators/me';
import messagesActionCreator from '../../actioncreators/messages';
import applicationActionCreator from '../../actioncreators/application';
import UtilityHelper from '../../utils/helpers';
import { staticConstants } from '../../utils/constants';
import { AddPhoneAtLoginObligationFlow, ErrorCodes } from '@idm/authentication-flows';
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const { toastMessage, errorCodes, errorNotificationEvents } = staticConstants;
const authFlowsErrorCodes = ErrorCodes;

class AddPhoneAtLoginPageContainer extends React.Component {
  static propTypes = {
    dispatchInitialize: PropTypes.func.isRequired,
    dispatchCodeSentToastMessage: PropTypes.func.isRequired,
    dispatchErrorToastMessage: PropTypes.func.isRequired,
    dispatchAddPhoneComplete: PropTypes.func.isRequired,
    dispatchCancel: PropTypes.func.isRequired,
    language: PropTypes.string,
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    language: null,
  };

  componentDidMount() {
    const { dispatchInitialize, location } = this.props;
    const redirectUrl = encodeURIComponent(`${location.pathname}${location.search}`);
    const redirectLocation = {
      pathname: '/auth/login',
      search: `?redirectUrl=${redirectUrl}`,
    };

    dispatchInitialize(redirectLocation);
  }

  render() {
    const {
      dispatchErrorToastMessage,
      dispatchCodeSentToastMessage,
      dispatchCancel,
      dispatchAddPhoneComplete,
      language,
    } = this.props;

    return (
      <div className="add-phone">
        <AddPhoneAtLoginObligationFlow
          onError={dispatchErrorToastMessage}
          onCodeSent={dispatchCodeSentToastMessage}
          onCancel={dispatchCancel}
          onSuccess={dispatchAddPhoneComplete}
          language={language}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { accountSettingsLocalization } = state;

  return {
    language: accountSettingsLocalization.main.language,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchInitialize(redirectLocation) {
    dispatch(meActionCreator.checkAuthentication(redirectLocation));
  },

  dispatchCodeSentToastMessage() {
    const toastMessageAction = messagesActionCreator.addToastMessage({
      message: 'CODE_SENT',
      type: toastMessage.SUCCESS_TOAST_TYPE,
      id: UtilityHelper.generateUniqueId(),
      translationNamespace: toastMessage.TOAST_I18N_NAMESPACE,
    });

    dispatch(toastMessageAction);
  },

  dispatchErrorToastMessage(code, error) {
    let errorMessage = errorNotificationEvents.ADD_PHONE_ERROR;

    if (error.code === errorCodes.PHONE_NUMBER_ALREADY_EXISTS) {
      errorMessage = errorNotificationEvents.DUPE_PHONE_NUMBER_ERROR;
    } else if (code === authFlowsErrorCodes.INVALID_PHONE_NUMBER) {
      errorMessage = errorNotificationEvents.INVALID_PHONE_NUMBER;
    }

    const toastMessageAction = messagesActionCreator.addToastMessage({
      message: errorMessage,
      type: toastMessage.FAILURE_TOAST_TYPE,
      id: UtilityHelper.generateUniqueId(),
      translationNamespace: toastMessage.TOAST_I18N_NAMESPACE,
    });

    dispatch(toastMessageAction);
  },

  async dispatchAddPhoneComplete() {
    const queryString = ownProps.location.search;
    const { redirectUrl } = UtilityHelper.parseQueryString(queryString);
    const defaultSuccessUrl = '/';
    const successLocation = redirectUrl || defaultSuccessUrl;

    await applicationActionCreator.clearObligations();
    applicationActionCreator.changeUrl(successLocation);
  },

  dispatchCancel() {
    const queryString = ownProps.location.search;
    const { cancelUrl } = UtilityHelper.parseQueryString(queryString);
    const defaultCancelUrl = '/auth/logout';
    const cancelLocation = cancelUrl || defaultCancelUrl;

    applicationActionCreator.changeUrl(cancelLocation);
  },
});

const AddPhoneAtLoginPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPhoneAtLoginPageContainer);

export default AddPhoneAtLoginPage;
