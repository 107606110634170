import datasource from './datasource';
import applicationActionCreator from '../application';
import messagesActionCreator from '../messages';
import buildFormActionCreator from '../builders/form';
import actionTypes, { actionTypeSections } from '../../actiontypes';
import UtilityHelper from '../../utils/helpers';
import getConstants from '../../utils/constants';

const { requestResult } = getConstants();

const emailVerifyNotificationActionCreator = {
  ...buildFormActionCreator(actionTypeSections.EMAIL_VERIFY_NOTIFICATION),

  resetMainState() {
    return {
      type: actionTypes.EMAIL_VERIFY_NOTIFICATION.RESET_STATE,
    };
  },

  resetState() {
    return (dispatch) => {
      dispatch(this.resetMainState());

      dispatch(this.resetFormState());
    };
  },

  updateDataRequest() {
    return {
      type: actionTypes.EMAIL_VERIFY_NOTIFICATION.UPDATE_DATA_REQUEST,
    };
  },

  updateDataSuccess(token) {
    return {
      type: actionTypes.EMAIL_VERIFY_NOTIFICATION.UPDATE_DATA_SUCCESS,
      payload: { token },
    };
  },

  updateDataFailure() {
    return {
      type: actionTypes.EMAIL_VERIFY_NOTIFICATION.UPDATE_DATA_FAILURE,
    };
  },

  codeVerificationSuccess(token) {
    return {
      type: actionTypes.EMAIL_VERIFY_NOTIFICATION.VERIFICATION_SUCCESS,
      payload: { token },
    };
  },

  codeVerificationFailure() {
    return {
      type: actionTypes.EMAIL_VERIFY_NOTIFICATION.VERIFICATION_FAILURE,
    };
  },

  verifyEmailWithCode(token, code) {
    return async (dispatch) => {
      try {
        await datasource.verifyEmail(token, code);

        dispatch(
          messagesActionCreator.addToastMessage({
            message: 'VERIFICATION_COMPLETE',
            type: 'success',
            id: UtilityHelper.generateUniqueId(),
            translationNamespace: 'TOAST_MESSAGE',
          })
        );

        dispatch(this.codeVerificationSuccess(token));
      } catch (e) {
        dispatch(this.codeVerificationFailure(e));
        throw e;
      }
    };
  },

  resendVerificationEmail(email, emailToUpdate) {
    return async (dispatch) => {
      try {
        dispatch(this.updateDataRequest());

        const { token } = await datasource.resendEmailVerification(email, emailToUpdate);

        dispatch(
          messagesActionCreator.addToastMessage({
            message: 'VERIFICATION_EMAIL_SENT',
            type: 'success',
            id: UtilityHelper.generateUniqueId(),
            translationNamespace: 'TOAST_MESSAGE',
          })
        );

        dispatch(this.updateDataSuccess(token));
        return token;
      } catch (e) {
        applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);

        dispatch(this.updateDataFailure());
        return null;
      }
    };
  },
};

export { emailVerifyNotificationActionCreator as default };
