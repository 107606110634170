import Request from './Request';
import Reset from './Reset';
import { Route, Redirect, Switch } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';

const ForgotPassword = (props) => {
  const { match } = props;

  return (
    <Switch>
      <Route exact={true} path={`${match.path}/request`} component={Request} />
      <Route exact={true} path={`${match.path}/reset`} component={Reset} />

      <Redirect from={`${match.path}`} to={`${match.path}/request`} />
    </Switch>
  );
};

ForgotPassword.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};

export default ForgotPassword;
