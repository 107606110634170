import HttpClient from './HttpClient';
import getConstants from '../constants';

const constants = getConstants(process.env.BASE_URL || window.location.origin);
const identityOrchestratorUrl = constants.serviceBaseUrls.identityOrchestrator;
const httpClient = HttpClient.createInstance({ baseURL: identityOrchestratorUrl });

export function loadAndVerify(kind, token, isExtended = false) {
  return httpClient.get(`/tokens/${kind}/${token}?extended=${isExtended}`);
}

export function execute(kind, token, data) {
  const payload = {
    kind,
    token,
    data,
  };

  return httpClient.post('/tokens/execute', payload);
}

export function validate(kind, token, data) {
  const payload = {
    kind,
    token,
    data,
  };

  return httpClient.post('/tokens/validate', payload);
}

export function destroy(kind, token) {
  return httpClient.delete(`/tokens/${kind}/${token}`);
}

export function prepareCodeEmailVerification(request) {
  return httpClient.post('/tokens/codeEmailVerification', request);
}

export function createAccount(token, data, isSSO = false) {
  const payload = {
    token,
    ...data,
  };

  return httpClient.post(`/persons${isSSO ? '/sso' : ''}`, payload);
}

export function requestPasswordReset(identifier) {
  return httpClient.post('/credentials/forgot', { identifier });
}

export function requestForgotUsername(identifier) {
  return httpClient.post('/credentials/forgotUsername', { identifier });
}

export function requestSelfRegistration(emailAddress) {
  return httpClient.post('/self-registration/send-email', { emailAddress });
}
