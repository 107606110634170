import windowUtils from './helpers/window';

const AUTHENTICATED_ROOT = '/auth/user';

const UNAUTHENTICATED_ROOT = '/auth/a';
const OBLIGATIONS = '/auth/obligations';
const LOGIN = '/auth/login';

export default {
  AUTHENTICATED_ROOT,
  UNAUTHENTICATED_ROOT,
  LIFION_HOME: '/',
  LANDING_PAGE_ALIAS: '/authn/landing',
  LANDING_PAGE: '/api/authentication-service/v0/landing',
  LOGOUT: '/auth/logout',
  LOGOUT_ALL_SESSIONS: '/auth/logout-all-sessions',
  LOGIN,
  LOGIN_STEP_UP: '/auth/login-step-up',
  redirectToCurrentAfterLogin: () => `${LOGIN}?redirectUrl=${windowUtils.getCurrentPath()}`,
  getForObligation: (kind) => `${OBLIGATIONS}/${kind}`,
  getSamlPageForClient: (clientId) => `/api/authentication-service/v0/clients/${clientId}`,
  ERROR: `${UNAUTHENTICATED_ROOT}/error`,
  CHANGE_PASSWORD_OBLIGATION: '/auth/change-password',
  REVIEW_CONTACT_INFO_OBLIGATION: '/auth/review-contact-info',
  CHANGE_USERNAME_OBLIGATION: '/auth/change-username',
  SWITCH_CLIENT_EDUCATIONAL_MESSAGE: '/auth/switch-clients-message',
  FORGOT_PASSWORD: '/auth/forgot-password',
  FORGOT_USERNAME: '/auth/forgot-username',
  SSO_LOGIN: '/auth/sso-login',
  SELF_REGISTRATION: '/auth/self-registration',
  VERIFY_CONTACT_INFO: '/auth/verify-contact-info',
  authenticated: {
    OBLIGATIONS,
    CREATE_BASIC_CREDENTIALS: `${AUTHENTICATED_ROOT}/create-credential`,
    LEGAL_AGREEMENTS: `${AUTHENTICATED_ROOT}/legal-agreements`,
    FORCE_SAML_LOGIN: `${AUTHENTICATED_ROOT}/saml-redirect/:clientId`,
  },
  registration: {
    SUCCESS: `${UNAUTHENTICATED_ROOT}/registration/success`,
  },
  accountSettings: {
    CLIENT_PICKER: '/auth/account-settings/switch-clients',
    CONTACT_INFO: '/auth/account-settings/contact-info',
  },

  mobileApp: {
    ios: {
      APP_URL: 'lifion://',
      APP_STORE_URL:
        'https://apps.apple.com/us/app/lifion-by-adp/id1259949679?ign-itsct=apps_box&ign-itscg=30200',
    },
    android: {
      APP_URL: 'lifion://mobile.lifion.com/',
      APP_STORE_URL: 'market://details?id=com.oneadp.lifion.mobile',
    },
  },

  RECAPTCHA_SCRIPT_URL: 'https://www.google.com/recaptcha/enterprise.js?render=explicit',
};
