import React from 'react';
import PropTypes from 'prop-types';

const LiteralText = (props) => {
  const { children, ...otherProps } = props;

  if (process.env.NODE_ENV === 'test') {
    return <span {...otherProps}>**{children} **</span>;
  }
  return <span {...otherProps}>{children}</span>;
};

LiteralText.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LiteralText;
