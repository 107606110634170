import Icon, { IconTypes, IconSizes } from './Icon';
import React from 'react';

export const DeleteIcon = (props) => <Icon {...props} type={IconTypes.DELETE} />;
export const EmailIcon = (props) => <Icon {...props} type={IconTypes.EMAIL} />;
export const MobileIcon = (props) => <Icon {...props} type={IconTypes.MOBILE} />;
export const PhoneIcon = (props) => <Icon {...props} type={IconTypes.PHONE} />;

export {
  FaCheck as CheckIcon,
  FaCheck as SuccessIcon,
  FaExclamationTriangle as ErrorIcon,
  FaInfoCircle as InfoIcon,
  FaWindowClose as CloseIcon,
  FaChevronRight as RightArrow,
  FaCaretLeft as LeftCaret,
  FaCaretRight as RightCaret,
  FaPlus as AddIcon,
} from 'react-icons/fa';

export { FiInfo as InfoIconAlt } from 'react-icons/fi';

export { IconTypes, IconSizes };

export default Icon;
