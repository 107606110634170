import urls from '../../../utils/urls';
import ErrorMessage from '../ErrorMessage';
import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

class CaptchaInput extends React.Component {
  static propTypes = {
    captchaRequired: PropTypes.bool.isRequired,
    recaptchaToken: PropTypes.string,
    recaptchaSiteKey: PropTypes.string,
    forceValidationCheck: PropTypes.bool.isRequired,
    isLoggingIn: PropTypes.bool.isRequired,

    handleRecaptchaTokenChange: PropTypes.func.isRequired,
    handleValidationChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    recaptchaToken: undefined,
    recaptchaSiteKey: undefined,
  };

  constructor(props) {
    super(props);
    this.recaptchaId = 'recaptchaComponent';
  }

  isCaptchaHandled() {
    const { captchaRequired, recaptchaToken } = this.props;

    return !captchaRequired || (captchaRequired && !!recaptchaToken);
  }

  renderRecaptcha() {
    const { recaptchaSiteKey } = this.props;

    window.grecaptcha.enterprise.render(this.recaptchaId, {
      sitekey: recaptchaSiteKey,
      callback: 'handleRecaptchaTokenSubmit',
      'expired-callback': 'handleRecaptchaTokenExpired',
    });
  }

  resetRecaptcha() {
    this.handleRecaptchaTokenExpired();
    window.grecaptcha.enterprise.reset();
  }

  handleRecaptchaTokenSubmit(t) {
    const { handleRecaptchaTokenChange, handleValidationChange } = this.props;

    handleRecaptchaTokenChange(t);
    handleValidationChange(true);
  }

  handleRecaptchaTokenExpired() {
    const { handleRecaptchaTokenChange, handleValidationChange } = this.props;

    handleRecaptchaTokenChange(undefined);
    handleValidationChange(false);
  }

  handleValidationChange() {
    const { handleValidationChange } = this.props;

    handleValidationChange(this.isCaptchaHandled());
  }

  componentDidMount() {
    const script = document.createElement('script');

    script.async = true;
    script.defer = true;
    script.src = urls.RECAPTCHA_SCRIPT_URL;
    script.id = 'captcha-script';
    document.head.appendChild(script);
    window.handleRecaptchaTokenSubmit = this.handleRecaptchaTokenSubmit.bind(this);
    window.handleRecaptchaTokenExpired = this.handleRecaptchaTokenExpired.bind(this);
  }

  componentWillUnmount() {
    const script = document.getElementById('captcha-script');

    document.head.removeChild(script);
    window.handleRecaptchaTokenSubmit = undefined;
    window.handleRecaptchaTokenExpired = undefined;
  }

  componentDidUpdate(prevProps) {
    const { captchaRequired, forceValidationCheck, isLoggingIn } = this.props;

    if (forceValidationCheck && !prevProps.forceValidationCheck) this.handleValidationChange();

    if (captchaRequired && !prevProps.captchaRequired) this.renderRecaptcha();

    if (!isLoggingIn && prevProps.isLoggingIn && captchaRequired) this.resetRecaptcha();
  }

  render() {
    const { captchaRequired } = this.props;

    return (
      captchaRequired && (
        <div className="recaptcha-container">
          <div id={this.recaptchaId} />
          <div className="recaptcha-error-message">
            <ErrorMessage message="This field is required" showError={!this.isCaptchaHandled()} />
          </div>
        </div>
      )
    );
  }
}

export { CaptchaInput as default };
