import { BadLinkError, UnsupportedError } from './Errors';
import { errorTypes } from '../../utils/constants';
import React from 'react';
import PropTypes from 'prop-types';

const ErrorPage = (props) => {
  const { type } = props;

  switch (type) {
    case errorTypes.BAD_REGISTRATION_TOKEN:
      return <BadLinkError />;
    default:
      return <UnsupportedError />;
  }
};

ErrorPage.propTypes = {
  type: PropTypes.oneOf(Object.values(errorTypes)).isRequired,
};

export default ErrorPage;
