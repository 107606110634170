import buildLoadDataReducer from './builders/loadData';
import buildFormReducer from './builders/form';
import actionTypes, { actionTypeSections } from 'actiontypes/';
import { combineReducers } from 'redux';

const mainInitialState = {
  agreements: [],
};

const loadDataInitialState = {
  isLoadingData: false,
};

const formInitialState = {
  isAgreementsAccepted: {
    value: false,
  },
};

function mainReducer(state = mainInitialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.LEGAL_AGREEMENTS.RESET_STATE:
      return { ...mainInitialState };
    case actionTypes.LEGAL_AGREEMENTS.SET_DATA:
      return {
        ...state,
        ...actionPayload,
      };
    default:
      return state;
  }
}

const legalAgreementsReducer = combineReducers({
  main: mainReducer,
  form: buildFormReducer(formInitialState, actionTypeSections.LEGAL_AGREEMENTS),
  loadData: buildLoadDataReducer(loadDataInitialState, actionTypeSections.LEGAL_AGREEMENTS),
});

export { legalAgreementsReducer as default };
