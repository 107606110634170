import styles from './styles.module.scss';
import translationUtils from '../../../utils/translation/utility';
import TranslatedText from '../../../components/elements/textDisplays/TranslatedText';
import UnauthenticatedNotification from '../../UnauthenticatedNotification';
import ChangePasswordObligationPage from '../../ChangePasswordObligationPage/ReduxChangePasswordObligationPage';
import ReviewContactInfoObligationPage from '../../ReviewContactInfoObligationPage/ReviewContactInfoObligationPage';
import Login from '../../Login';
import ForgotPassword from '../../ForgotPassword';
import ForgotUsername from '../../ForgotUsername';
import SSOLogin from '../../SSOLogin';
import MfaStepUpAtLogin from '../../MfaStepUpAtLogin';
import AccountRegistration from '../../AccountRegistration';
import CreateAccount from '../../CreateAccount';
import SelfRegistration from '../../SelfRegistration';
import CreateCredential from '../../CreateCredential';
import CreateBasicCredentialsPage from '../../CreateBasicCredentialsPage';
import NotificationPage from '../../NotificationPage';
import EmailVerifyNotification from '../../EmailVerifyNotification';
import UnauthorizedNotification from '../../UnauthorizedNotification';
import AddPhoneAtLogin from '../../AddPhoneAtLogin';
import AddEmailAtLogin from '../../AddEmailAtLogin';
import Logout from '../../Logout';
import LogoutAllSessions from '../../LogoutAllSessions';
import SsoServiceProvider from '../../SsoServiceProvider';
import LegalDocument from '../../LegalDocument';
import LegalAgreements from '../../LegalAgreements';
import urls from '../../../utils/urls';
import ErrorPage from '../../ErrorPage';
import RegistrationComplete from '../../CreateAccount/RegistrationComplete';
import ObligationsPage from '../../ObligationHandler/ObligationsPage';
import VerifyContactInfo from '../../VerifyContactInfo';

import SamlRedirectObligation from '../../ObligationHandler/Obligations/SamlRedirectObligation';

import ChangeUsernameObligationPage from '../../ChangeUsernameObligationPage/ChangeUsernameObligationPage';
import SwitchClientRedirectPage from '../../SwitchClientRedirectPage/SwitchClientRedirectPage';

import { Route, Redirect, Switch } from 'react-router-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const LinkSvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1792 1896.083"
    height="1em"
    width="0.9451em"
    fill="currentColor"
    focusable="false"
  >
    <g>
      <path d="M1408 928v320q0 119-84.5 203.5T1120 1536H288q-119 0-203.5-84.5T0 1248V416q0-119 84.5-203.5T288 128h704q14 0 23 9t9 23v64q0 14-9 23t-23 9H288q-66 0-113 47t-47 113v832q0 66 47 113t113 47h832q66 0 113-47t47-113V928q0-14 9-23t23-9h64q14 0 23 9t9 23zm384-864v512q0 26-19 45t-45 19-45-19l-176-176-652 652q-10 10-23 10t-23-10L695 983q-10-10-10-23t10-23l652-652-176-176q-19-19-19-45t19-45 45-19h512q26 0 45 19t19 45z" />
    </g>
  </svg>
);

// eslint-disable-next-line react/prefer-stateless-function
export default class UnauthenticatedLayout extends Component {
  static propTypes = {
    className: PropTypes.string,

    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    className: '',
  };

  constructor(props) {
    super(props);
    this.state = { loaded: false };
  }

  componentDidMount() {
    this.setState({ loaded: true });
  }

  render() {
    const { location, className } = this.props;
    const { loaded } = this.state;

    const cssClass = cx(styles.idmBackgroundPage, className);

    const linksOrLabels = {
      privacy: {
        label: <TranslatedText i18nKey="FOOTER.PRIVACY" />,
        url: translationUtils.getPrivacyPolicy(),
      },
      legal: {
        label: <TranslatedText i18nKey="FOOTER.LEGAL" />,
        url: translationUtils.getLegalTerms(),
      },
      browserRequirements: {
        label: <TranslatedText i18nKey="FOOTER.BROWSER_REQUIREMENTS" />,
        url: translationUtils.getBrowserRequirements(),
      },
      lifionByAdp: {
        url: translationUtils.getLifionByAdp(),
      },
      copyRight: {
        label: <TranslatedText i18nKey="FOOTER.COPY_RIGHTS_LABEL" />,
      },
    };

    return (
      <main>
        <div className={styles.background} />
        <div className={cssClass}>
          <section className={cx(styles.mainContent, { [styles.loaded]: loaded })}>
            <div className={styles.body}>
              {location.state && location.state.REDIRECT_RESULT ? (
                <UnauthenticatedNotification />
              ) : (
                <Switch>
                  <Route exact={true} path={urls.LOGIN} component={Login} />
                  <Route exact={true} path={urls.LOGOUT} component={Logout} />
                  <Route
                    exact={true}
                    path={urls.LOGOUT_ALL_SESSIONS}
                    component={LogoutAllSessions}
                  />
                  <Route exact={true} path="/auth/add-phone" component={AddPhoneAtLogin} />
                  <Route exact={true} path="/auth/add-email" component={AddEmailAtLogin} />
                  <Route exact={true} path={urls.LOGIN_STEP_UP} component={MfaStepUpAtLogin} />
                  <Route
                    exact={true}
                    path={urls.authenticated.CREATE_BASIC_CREDENTIALS}
                    component={CreateBasicCredentialsPage}
                  />
                  <Route
                    exact={true}
                    path={urls.authenticated.FORCE_SAML_LOGIN}
                    component={(props) => (
                      <SamlRedirectObligation args={{ clientId: props.match.params.clientId }} />
                    )}
                  />
                  <Route
                    exact={true}
                    path={urls.CHANGE_PASSWORD_OBLIGATION}
                    component={ChangePasswordObligationPage}
                  />
                  <Route
                    exact={true}
                    path={urls.CHANGE_USERNAME_OBLIGATION}
                    component={ChangeUsernameObligationPage}
                  />
                  <Route
                    exact={true}
                    path={urls.SWITCH_CLIENT_EDUCATIONAL_MESSAGE}
                    component={SwitchClientRedirectPage}
                  />
                  <Route
                    exact={true}
                    path={urls.REVIEW_CONTACT_INFO_OBLIGATION}
                    component={ReviewContactInfoObligationPage}
                  />
                  <Route exact={true} path="/auth/notification" component={NotificationPage} />
                  <Route
                    exact={true}
                    path="/auth/email-verify-notification"
                    component={EmailVerifyNotification}
                  />
                  <Route
                    exact={true}
                    path="/auth/unauthorized-notification"
                    component={UnauthorizedNotification}
                  />
                  <Route exact={true} path="/auth/legal" component={LegalDocument} />
                  <Route
                    exact={true}
                    path={urls.authenticated.LEGAL_AGREEMENTS}
                    component={LegalAgreements}
                  />
                  <Route exact={true} path={urls.ERROR} component={ErrorPage} />
                  <Route
                    exact={true}
                    path="/auth/account-registration"
                    component={AccountRegistration}
                  />
                  <Route
                    exact={true}
                    path="/auth/create-account/:token/:accountType(sso)?"
                    component={CreateAccount}
                  />
                  <Route
                    exact={true}
                    path={urls.getForObligation(':kind')}
                    component={ObligationsPage}
                  />
                  <Route
                    exact={true}
                    path={urls.registration.SUCCESS}
                    component={RegistrationComplete}
                  />
                  <Route
                    exact={true}
                    path={urls.VERIFY_CONTACT_INFO}
                    component={VerifyContactInfo}
                  />
                  <Route path="/auth/create-credential" component={CreateCredential} />
                  <Route path={urls.FORGOT_PASSWORD} component={ForgotPassword} />
                  <Route path={urls.FORGOT_USERNAME} component={ForgotUsername} />
                  <Route path={urls.SSO_LOGIN} component={SSOLogin} />
                  <Route path={urls.SELF_REGISTRATION} component={SelfRegistration} />
                  <Route path="/auth/sso-service-provider" component={SsoServiceProvider} />
                  <Redirect from="/auth/email-verify" to="/auth/email-verify-notification" />
                  <Redirect
                    from="/auth/r/:token/:accountType(sso)?"
                    to="/auth/create-account/:token/:accountType(sso)?"
                  />
                  <Redirect from="*" to={urls.LOGIN} />
                </Switch>
              )}
            </div>
          </section>
        </div>
        <footer className={styles.vdlfooter}>
          <ul className={styles.ulc}>
            <li className={styles.lileft}>
              <a
                href={linksOrLabels.privacy.url}
                className={`${styles.a} ${styles.center_a}`}
                target="_blank"
                rel="noreferrer"
              >
                <button
                  aria-label="Go to ADP Privacy Statement"
                  id="privacy-statement-button"
                  type="button"
                  aria-disabled="false"
                  className={`${styles.vdlbutton} ${styles.vdlbuttonlink}`}
                >
                  <span className={styles.vdlbuttoncontainer}>
                    <span className={styles.svgcontainer}>
                      <LinkSvgIcon />
                    </span>
                    <div className={styles.linktext}>{linksOrLabels.privacy.label}</div>
                  </span>
                </button>
              </a>
            </li>
            <li className={styles.lileft}>
              <a
                href={linksOrLabels.legal.url}
                className={`${styles.a} ${styles.center_a}`}
                target="_blank"
                rel="noreferrer"
              >
                <button
                  aria-label="Go to ADP Terms and Conditions"
                  id="terms-and-conditions-button"
                  type="button"
                  aria-disabled="false"
                  className={`${styles.vdlbutton} ${styles.vdlbuttonlink}`}
                >
                  <span className={styles.vdlbuttoncontainer}>
                    <span className={styles.svgcontainer}>
                      <LinkSvgIcon />
                    </span>
                    <div className={styles.linktext}>{linksOrLabels.legal.label}</div>
                  </span>
                </button>
              </a>
            </li>
            <li className={styles.lileft}>
              <a
                href={linksOrLabels.browserRequirements.url}
                className={`${styles.a} ${styles.center_a}`}
                target="_blank"
                rel="noreferrer"
              >
                <button
                  aria-label="Go to ADP supported Internet browsers"
                  id="supported-browsers-button"
                  type="button"
                  aria-disabled="false"
                  className={`${styles.vdlbutton} ${styles.vdlbuttonlink}`}
                >
                  <span className={styles.vdlbuttoncontainer}>
                    <span className={styles.svgcontainer}>
                      <LinkSvgIcon />
                    </span>
                    <div className={styles.linktext}>{linksOrLabels.browserRequirements.label}</div>
                  </span>
                </button>
              </a>
            </li>
            <li className={styles.copyright}>
              <div>{linksOrLabels.copyRight.label}</div>
            </li>
          </ul>
        </footer>
      </main>
    );
  }
}
