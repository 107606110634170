import datasource from './datasource';
import translationActionCreator from '../../translations';
import getConstants from '../../../utils/constants';
import actionTypes from '../../../actiontypes';
import messagesActionCreator from '../../messages';
import UtilityHelper from '../../../utils/helpers';
import cookieUtils from '../../../utils/cookie';
import BaseActionCreator from '../../BaseActionCreator';

const { requestResult } = getConstants();

const {
  UPDATE_DATA_REQUEST,
  UPDATE_DATA_SUCCESS,
  UPDATE_DATA_FAILURE,
  SET_LANGUAGES,
  SET_REGIONS,
  SET_TIMEZONES,
  ERROR_LOADING_OPTIONS,
  LOAD_DATA_SUCCESS,
  LOAD_DATA_FAILURE,
  LOAD_DATA_REQUEST,
} = actionTypes.ACCOUNT_SETTINGS_LOCALIZATION;

class LocalizationActionCreator extends BaseActionCreator {
  async updateRegionalSettings(payload) {
    this.dispatch(UPDATE_DATA_REQUEST);

    try {
      await datasource.setMyLocaleSettings(payload);
      const regionalSettings = {
        language: payload.language,
        region: payload.region,
        timezone: payload.timezone,
      };

      cookieUtils.setRegionalSettingsCookies(regionalSettings);

      await translationActionCreator.loadPackageResource();

      await this.loadData();

      this.dispatch(
        messagesActionCreator.addToastMessage({
          message: 'CHANGED_REGIONAL_SETTINGS',
          type: 'success',
          id: UtilityHelper.generateUniqueId(),
          translationNamespace: 'TOAST_MESSAGE',
        })
      );
      this.dispatch(UPDATE_DATA_SUCCESS, regionalSettings);
    } catch (err) {
      this.dispatch(UPDATE_DATA_FAILURE);

      this.changeState(requestResult.FAILURE_UNSUPPORTED);
    }
  }

  async loadRegionalSettings() {
    try {
      const loadSettingsPromises = [
        datasource.getLanguages(),
        datasource.getRegions(),
        datasource.getTimezones(),
      ];
      const [languages, regions, timezones] = await Promise.all(loadSettingsPromises);

      this.dispatch(SET_LANGUAGES, languages);
      this.dispatch(SET_REGIONS, regions);
      this.dispatch(SET_TIMEZONES, timezones);
    } catch (err) {
      this.dispatch(ERROR_LOADING_OPTIONS, err);
    }
  }

  async loadData() {
    this.dispatch(LOAD_DATA_REQUEST);

    try {
      const settings = await datasource.getMyLocaleSettings();

      await this.loadRegionalSettings();
      this.dispatch(LOAD_DATA_SUCCESS, settings);
    } catch (err) {
      this.dispatch(LOAD_DATA_FAILURE, err);

      this.changeState(requestResult.FAILURE_UNSUPPORTED);
    }
  }
}

export default new LocalizationActionCreator();
