import Rule from './Rule';
import ValidationError from '../../exceptions/ValidationError';

const upperCaseRegex = /[A-Z]/;

export default class UpperCaseRule extends Rule {
  apply(value) {
    if (upperCaseRegex.test(value)) {
      return Promise.resolve(this.message);
    }

    return Promise.reject(new ValidationError(this.message));
  }
}
