import serializer from './serializer';
import HttpClient from '../../../utils/http/HttpClient';
import getConstants from '../../../utils/constants';

const constants = getConstants(process.env.BASE_URL);
const identityOrchestratorUrl = constants.serviceBaseUrls.identityOrchestrator;

class CreateAccountDatasource {
  constructor() {
    this.httpClient = HttpClient.createInstance({
      skipErrorHandling: true,
      baseURL: identityOrchestratorUrl,
    });
  }

  async createAccount(token, createAccountData, isSSO) {
    try {
      const createAccountRequest = serializer.toCreateAccountRequest(
        token,
        createAccountData,
        isSSO
      );
      const url = '/tokens/createPerson';

      await this.httpClient.post(url, createAccountRequest);
    } catch (error) {
      const { errors } = error.response.data;

      throw errors[0];
    }
  }
}

export default new CreateAccountDatasource();
