import UtilityHelper from 'utils/helpers/';
import namespaceTranslatedText from 'containers/TranslatedText/';
import { ErrorPage } from '@idm/ui-components';
import React from 'react';
import PropTypes from 'prop-types';

const TranslatedText = namespaceTranslatedText('UNAUTHORIZED_ERROR');

const errorMessage = (traceId) => {
  return (
    <div className="idm-error-message forbidden-error">
      <div className="idm-error-header-text">
        <TranslatedText textKey="DONT_HAVE_PERMISSION" />
      </div>
      <p className="idm-error-desc-text">
        <TranslatedText textKey="NOT_INTENDED_FOR_YOU" />
      </p>
      <p className="idm-error-desc-text">
        <TranslatedText textKey="IMMEDIATE_ASSISTANCE" containHtml={true} />
        {traceId && (
          <TranslatedText textKey="PROVIDE_TRACE_ID" containHtml={true} params={{ traceId }} />
        )}
      </p>

      {traceId && (
        <a href="/auth/login">
          <TranslatedText textKey="BACK_BUTTON" containHtml={true} />
        </a>
      )}
    </div>
  );
};

function UnauthorizedErrorPage(props) {
  const { location } = props;

  const queryParams = UtilityHelper.parseQueryString(location.search);
  const { traceId } = queryParams;

  return <ErrorPage code="403" message={errorMessage(traceId)} />;
}

UnauthorizedErrorPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export { UnauthorizedErrorPage as default };
