const legalAgreementsActionTypes = {
  RESET_STATE: 'RESET_STATE',
  SET_DATA: 'SET_DATA',
  UPDATE_DATA_REQUEST: 'UPDATE_DATA_REQUEST',
  UPDATE_DATA_SUCCESS: 'UPDATE_DATA_SUCCESS',
  UPDATE_DATA_FAILURE: 'UPDATE_DATA_FAILURE',
  LOAD_DATA_REQUEST: 'LOAD_DATA_REQUEST',
  LOAD_DATA_SUCCESS: 'LOAD_DATA_SUCCESS',
  LOAD_DATA_FAILURE: 'LOAD_DATA_FAILURE',
};

export { legalAgreementsActionTypes as default };
