import serializer from './serializer';
import HttpClient from '../../utils/http/HttpClient';
import { serviceBaseUrls, tokenKinds } from '../../utils/constants';

const identityOrchestratorUrl = serviceBaseUrls.identityOrchestrator;

class TokensDatasource {
  constructor() {
    this.httpClient = HttpClient.createInstance({
      baseURL: identityOrchestratorUrl,
    });
  }

  verifyValidUsername(token, username, tokenKind) {
    const kind = tokenKind || tokenKinds.ASSOCIATE;

    return this.validateToken(kind, token, { username });
  }

  async validateToken(kind, token, data) {
    const url = '/tokens/validate';

    const request = serializer.toValidateRequest(kind, token, data);

    const response = await this.httpClient.post(url, request);

    return serializer.fromValidateResponse(kind, response);
  }
}

export default new TokensDatasource();
