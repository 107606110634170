import datasource from './datasource';
import http from '../../../utils/http';
import getConstants from '../../../utils/constants';
import utils from '../../../utils/helpers';
import actionTypes from '../../../actiontypes';
import BaseActionCreator from '../../BaseActionCreator';
import urls from '../../../utils/urls';

const { requestResult } = getConstants();

const isRedirectingToMyAdp = (queryString) => {
  const { redirectUrl } = utils.parseQueryString(queryString);

  return redirectUrl && redirectUrl.toLowerCase().indexOf('redbox') >= 0;
};

class AccountInfoActionCreator extends BaseActionCreator {
  invalidPasswordFailure(errorCode) {
    this.dispatch(
      actionTypes.CREATE_ACCOUNT_ACCOUNT_INFO.CREATE_ACCOUNT_PASSWORD_VALIDATION_FAILURE,
      {
        errorCode,
      }
    );
  }

  invalidUsernameFailure(errorCode) {
    this.dispatch(
      actionTypes.CREATE_ACCOUNT_ACCOUNT_INFO.CREATE_ACCOUNT_USERNAME_OR_EMAIL_VALIDATION_FAILURE,
      {
        errorCode,
      }
    );
  }

  async createAccount(token, data, isSSO) {
    try {
      this.dispatch(actionTypes.CREATE_ACCOUNT_ACCOUNT_INFO.CREATE_ACCOUNT_REQUEST);

      await datasource.createAccount(token, data, isSSO);

      this.dispatch(actionTypes.CREATE_ACCOUNT_ACCOUNT_INFO.CREATE_ACCOUNT_SUCCESS);
      const windowLocation = utils.getWindowLocation();
      const isMyADPRedirect = isRedirectingToMyAdp(windowLocation.search);

      if (isMyADPRedirect) {
        this.changeUrl(`/auth/create-credential/success-notification${windowLocation.search}`);
      } else {
        this.changeUrl(urls.registration.SUCCESS);
      }
    } catch (error) {
      this.dispatch(actionTypes.CREATE_ACCOUNT_ACCOUNT_INFO.CREATE_ACCOUNT_FAILURE);

      const isBadLink =
        error.code === http.supportedErrorCodes.TOKEN_LOAD_VERIFY_FAILURE ||
        error.code === http.supportedErrorCodes.TOKEN_EXECUTE_FAILURE ||
        error.code === http.supportedErrorCodes.INVALID_TOKEN;

      const isBadUsername =
        error.code === http.supportedErrorCodes.DUPLICATE_USERNAME ||
        error.code === http.supportedErrorCodes.DUPLICATE_EMAIL;

      const isBadPassword = Boolean(http.supportedErrorCodes[error.code]);

      if (isBadLink) {
        this.changeState(requestResult.FAILURE_LINK);
      } else if (isBadUsername) {
        this.invalidUsernameFailure(error.code);
      } else if (isBadPassword) {
        this.invalidPasswordFailure(error.code);
      } else {
        this.changeState(requestResult.FAILURE_UNSUPPORTED);
      }
      throw error;
    }
  }
}

export default new AccountInfoActionCreator();
