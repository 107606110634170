import actionTypes from 'actiontypes/';

const { FORM } = actionTypes;

export default function (name) {
  return {
    resetFormState() {
      return {
        type: FORM.RESET_STATE,
        name,
      };
    },

    setFormField(field, value) {
      return {
        type: FORM.SET_FIELD,
        payload: { field, value },
        name,
      };
    },

    setFormFieldClean(field, value) {
      return {
        type: FORM.SET_FIELD_CLEAN,
        payload: { field, value },
        name,
      };
    },

    setFormFieldValidationResult(field, validationResult) {
      return {
        type: FORM.SET_VALIDATION_RESULT,
        payload: { field, validationResult },
        name,
      };
    },

    setFormIsValidating() {
      return {
        type: FORM.SET_IS_VALIDATING,
        name,
      };
    },

    markAllClean() {
      return {
        type: FORM.MARK_ALL_CLEAN,
        name,
      };
    },
  };
}
