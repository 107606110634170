import buildLoadDataActionCreator from './builders/loadData';
import applicationActionCreator from './application';
import actionTypes, { actionTypeSections } from '../actiontypes';
import http from '../utils/http';
import getConstants from '../utils/constants';

const { requestResult } = getConstants();

const accountLinkingActionCreator = {
  ...buildLoadDataActionCreator(actionTypeSections.ACCOUNT_LINKING),

  resetMainState() {
    return {
      type: actionTypes.ACCOUNT_LINKING.RESET_STATE,
    };
  },

  resetState() {
    return (dispatch) => {
      dispatch(this.resetMainState());

      dispatch(this.resetLoadDataState());
    };
  },

  setCompanyName(payload) {
    return {
      type: actionTypes.ACCOUNT_LINKING.SET_COMPANY_NAME,
      payload,
    };
  },

  loadAndVerifyToken(token) {
    return async (dispatch) => {
      dispatch(this.loadDataRequest());

      try {
        const res = await http.loadAndVerify('associate', token, true);

        dispatch(this.loadDataSuccess());

        dispatch(
          this.setCompanyName(
            (res.data.extra_information && res.data.extra_information.client_name) || ''
          )
        );
      } catch (e) {
        dispatch(this.loadDataFailure());

        if (e.code === http.supportedErrorCodes.TOKEN_LOAD_VERIFY_FAILURE) {
          applicationActionCreator.changeState(requestResult.FAILURE_LINK);

          return;
        }

        applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);
      }
    };
  },

  connectRequest() {
    return {
      type: actionTypes.ACCOUNT_LINKING.CONNECT_REQUEST,
    };
  },

  connectSuccess() {
    return {
      type: actionTypes.ACCOUNT_LINKING.CONNECT_SUCCESS,
    };
  },

  connectFailure() {
    return {
      type: actionTypes.ACCOUNT_LINKING.CONNECT_FAILURE,
    };
  },

  connect(personId, token) {
    return async (dispatch) => {
      try {
        dispatch(this.connectRequest());

        await http.execute('associate', token, { personId });

        dispatch(this.connectSuccess());

        applicationActionCreator.changeUrl('/');
      } catch (e) {
        dispatch(this.connectFailure());

        applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);
      }
    };
  },

  showModal() {
    return {
      type: actionTypes.ACCOUNT_LINKING.SHOW_MODAL,
    };
  },

  hideModal() {
    return {
      type: actionTypes.ACCOUNT_LINKING.HIDE_MODAL,
    };
  },

  declineInvitationRequest() {
    return {
      type: actionTypes.ACCOUNT_LINKING.DECLINE_INVITATION_REQUEST,
    };
  },

  declineInvitationSuccess() {
    return {
      type: actionTypes.ACCOUNT_LINKING.DECLINE_INVITATION_SUCCESS,
    };
  },

  declineInvitationFailure() {
    return {
      type: actionTypes.ACCOUNT_LINKING.DECLINE_INVITATION_FAILURE,
    };
  },

  declineInvitation(companyName, token) {
    return async (dispatch) => {
      try {
        dispatch(this.declineInvitationRequest());

        await http.destroy('associate', token);

        dispatch(this.declineInvitationSuccess());

        dispatch(this.hideModal());

        applicationActionCreator.changeUrl('/auth/decline-confirmation', { companyName });
      } catch (e) {
        dispatch(this.declineInvitationFailure());

        applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);
      }
    };
  },
};

export { accountLinkingActionCreator as default };
