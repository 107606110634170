import http from '../../utils/http';
import UtilityHelper from '../../utils/helpers';
import messagesActionCreator from '../messages';
import actionTypes from '../../actiontypes';
import getConstants from '../../utils/constants';
import applicationActionCreator from '../application';

const { requestResult } = getConstants();

const devicesActionCreator = {
  resetState() {
    return {
      type: actionTypes.ACCOUNT_SETTINGS_DEVICES.RESET_STATE,
    };
  },

  updateDataRequest() {
    return {
      type: actionTypes.ACCOUNT_SETTINGS_DEVICES.UPDATE_DATA_REQUEST,
    };
  },

  updateDataSuccess() {
    return {
      type: actionTypes.ACCOUNT_SETTINGS_DEVICES.UPDATE_DATA_SUCCESS,
    };
  },

  updateDataFailure() {
    return {
      type: actionTypes.ACCOUNT_SETTINGS_DEVICES.UPDATE_DATA_FAILURE,
    };
  },

  addRevokedDevice(deviceId) {
    return {
      type: actionTypes.ACCOUNT_SETTINGS_DEVICES.ADD_REVOKED_DEVICE_ID,
      payload: deviceId,
    };
  },

  revokeDeviceAccess(deviceId) {
    return (dispatch) => {
      dispatch(this.updateDataRequest());

      return http
        .revokeDeviceAccess(deviceId)
        .then(() => {
          dispatch(this.updateDataSuccess());
          dispatch(this.addRevokedDevice(deviceId));

          dispatch(
            messagesActionCreator.addToastMessage({
              message: 'DEVICE_REVOKED',
              type: 'success',
              id: UtilityHelper.generateUniqueId(),
              translationNamespace: 'TOAST_MESSAGE',
            })
          );
        })
        .catch(() => {
          dispatch(this.updateDataFailure());

          applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);
        });
    };
  },

  loadDataRequest() {
    return {
      type: actionTypes.ACCOUNT_SETTINGS_DEVICES.LOAD_DATA_REQUEST,
    };
  },

  loadDataSuccess() {
    return {
      type: actionTypes.ACCOUNT_SETTINGS_DEVICES.LOAD_DATA_SUCCESS,
    };
  },

  loadDataFailure() {
    return {
      type: actionTypes.ACCOUNT_SETTINGS_DEVICES.LOAD_DATA_FAILURE,
    };
  },

  loadData() {
    return (dispatch) => {
      dispatch(this.loadDataRequest());

      return http
        .getMyDevices()
        .then((res) => {
          dispatch(this.loadDataSuccess());

          const devices = res.data;

          if (devices && devices.length) {
            dispatch(this.setDevices(devices));
          }
        })
        .catch(() => {
          dispatch(this.loadDataFailure());
        });
    };
  },

  setDevices(payload) {
    return {
      type: actionTypes.ACCOUNT_SETTINGS_DEVICES.SET_DEVICES,
      payload,
    };
  },
};

export { devicesActionCreator as default };
