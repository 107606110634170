import PasswordCreationInput from '../../../components/elements/formInputs/PasswordCreationInput';
import TranslatedText from '../../../components/elements/textDisplays/TranslatedText';
import { noop } from '../../../utils/constants';
import ServerPasswordError from '../../../components/ServerError';
import { PrimaryButton, ButtonContainer } from '../../../components';
import { Form } from '@tbiegner99/react-forms';
import combineClasses from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const translateKey = (key) => <TranslatedText i18nKey={key} />;

class ChangePasswordForm extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onSubmitFailure: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    onSubmitFailure: noop,
    className: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isErrorVisible: false,
      errorCode: null,
    };
  }

  clearForm() {
    this.setState({
      oldPassword: '',
      newPassword: '',
      valid: false,
    });
  }

  async submit(formData) {
    const { onSubmit, onSubmitFailure } = this.props;

    try {
      await onSubmit(formData);
      this.clearForm();
    } catch (err) {
      this.setState({ errorCode: err.code, isErrorVisible: true });
      onSubmitFailure(err);
    }
  }

  renderErrorLabel() {
    const { isErrorVisible, errorCode } = this.state;

    if (isErrorVisible) {
      return (
        <div data-meta-id="form-error">
          <ServerPasswordError code={errorCode} />
        </div>
      );
    }
    return null;
  }

  render() {
    const { className } = this.props;
    const { oldPassword, newPassword, valid } = this.state;

    return (
      <Form
        onSubmit={(data) => this.submit(data)}
        className={combineClasses('change-password-form', className)}
      >
        <PasswordCreationInput
          label={translateKey('ACCOUNT_SETTINGS.CHANGE_PW.CURRENT_PW')}
          data-rule-required={true}
          onChange={(value) => this.setState({ oldPassword: value })}
          data-msg-required={translateKey('ACCOUNT_SETTINGS.CHANGE_PW.REQUIRED_RULE')}
          className="input-large"
          name="oldPassword"
          value={oldPassword}
          data-meta-id="current-password-input"
        />
        <PasswordCreationInput
          aria-describedby="change-password-requirements"
          showRequirements={true}
          label={translateKey('ACCOUNT_SETTINGS.CHANGE_PW.NEW_PW')}
          validateOnChange={true}
          onChange={(value) => this.setState({ newPassword: value })}
          onValidate={(value) => this.setState({ valid: value })}
          name="newPassword"
          value={newPassword}
          className="input-large"
          data-meta-id="new-password-input"
        />
        {this.renderErrorLabel()}

        <ButtonContainer>
          <PrimaryButton
            size="large"
            submittable={true}
            data-meta-id="update-password-button"
            disabled={!oldPassword || !newPassword || !valid}
          >
            <TranslatedText i18nKey="ACCOUNT_SETTINGS.CHANGE_PW.UPDATE_PW_BUTTON" />
          </PrimaryButton>
        </ButtonContainer>
      </Form>
    );
  }
}

export default ChangePasswordForm;
