import { version } from '../../../../../package.json';
import HttpClient from 'utils/http/HttpClient';
import DeviceUtilities from 'utils/device';
import getConstants from 'utils/constants';
import debounce from 'lodash.debounce';

const constants = getConstants(process.env.BASE_URL || window.location.origin);
const { uiWebService } = constants.serviceBaseUrls;

const CLIENT_TYPE = 'Identity UI Client';
const DEBOUNCE_TIME = 500;
const maxBatchedLogsSize = 10;
const batchLogCache = [];
const httpClient = HttpClient.createInstance({ baseURL: uiWebService });

const clearBatchCache = () => {
  batchLogCache.length = 0;
};

const postBatchLogs = debounce(async () => {
  const cache = [...batchLogCache];

  batchLogCache.length = 0;

  if (!cache.length) {
    return;
  }
  const batchLogs = cache.slice(0, maxBatchedLogsSize);

  try {
    await httpClient.post('/logging/batch-log', batchLogs);
  } catch (err) {} // eslint-disable-line no-empty
}, DEBOUNCE_TIME);

const postLog = async (log) => {
  try {
    await httpClient.post('/logging/log', log);
  } catch (err) {} // eslint-disable-line no-empty
};

const createLogBody = (payload) => {
  const { message, level, meta } = payload;

  const userAgent = DeviceUtilities.getUserAgent();
  const body = {
    level,
    message,
    meta: {
      appVersion: version,
      clientType: CLIENT_TYPE,
      userAgent,
      ...meta,
    },
  };

  return body;
};

const log = (payload) => {
  batchLogCache.push(createLogBody(payload));
  postBatchLogs();
};

const logWithoutBatching = (payload) => {
  postLog(createLogBody(payload));
};

const forTestsOnly = {
  postBatchLogs,
  clearBatchCache,
  DEBOUNCE_TIME,
};

export default { log, logWithoutBatching, forTestsOnly };
