import RouteManager from './RouteManager';

import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
    url: state.application.url,
    locationState: state.application.state,
  };
}

const ReduxRouteManager = connect(mapStateToProps)(RouteManager);

export default ReduxRouteManager;
