import styles from './style.module.scss';
import { BorderedCard, PrimaryButton, ButtonContainer } from '../index';
import TranslatedText from '../elements/textDisplays/TranslatedText';
import { InfoIconAlt } from '../elements/icons';
import React from 'react';
import PropTypes from 'prop-types';

import { PageHeader, StandardParagraph } from '@idm/ui-components';

const renderHeader = (header) => {
  if (!header) {
    return null;
  }
  return <PageHeader>{header}</PageHeader>;
};

function SuccessNotificationPage(props) {
  const { onContinueAction, header, children, ...otherProps } = props;

  return (
    <section {...otherProps}>
      {renderHeader(header)}
      <BorderedCard className={styles.message}>
        <InfoIconAlt className={styles.infoIcon} />

        <StandardParagraph>{children}</StandardParagraph>
      </BorderedCard>
      <ButtonContainer>
        <PrimaryButton onClick={onContinueAction} size="large" data-meta-id="log-in-button">
          <TranslatedText i18nKey="SUCCESS_NOTIFICATION_PAGE.CONTINUE_BUTTON" />
        </PrimaryButton>
      </ButtonContainer>
    </section>
  );
}
SuccessNotificationPage.propTypes = {
  onContinueAction: PropTypes.func.isRequired,
  header: PropTypes.node,
  children: PropTypes.node,
};

SuccessNotificationPage.defaultProps = {
  header: null,
  children: null,
};

export default SuccessNotificationPage;
