import { Notification } from '../../components';
import TranslatedText from '../../components/elements/textDisplays/TranslatedText';
import React from 'react';

function UnauthorizedNotification() {
  const notificationProps = {
    status: 'error',
    title: <TranslatedText i18nKey="UNAUTHORIZED_NOTIFICATION.TITLE" />,
    message: (
      <p>
        <TranslatedText i18nKey="UNAUTHORIZED_NOTIFICATION.MESSAGE" containHtml={true} />
      </p>
    ),
  };

  return <Notification {...notificationProps} />;
}

export { UnauthorizedNotification as default };
