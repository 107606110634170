const formActionTypes = {
  RESET_STATE: 'RESET_STATE',
  SET_FIELD: 'SET_FIELD',
  SET_FIELD_CLEAN: 'SET_FIELD_CLEAN',
  SET_VALIDATION_RESULT: 'SET_VALIDATION_RESULT',
  SET_IS_VALIDATING: 'SET_IS_VALIDATING',
  MARK_ALL_CLEAN: 'MARK_ALL_CLEAN',
};

export { formActionTypes as default };
