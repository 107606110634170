import Rule from './Rule';
import ValidationError from 'utils/exceptions/ValidationError';

/*
 * Only allow the following characters: (A-Z), (a-z), (0-9), dashes( - ), underscores(_),
 * and periods(.), but no consecutive periods(.)
 */
const usernameRule = /^([a-zA-Z0-9-_@]|[.](?=[a-zA-Z0-9-_@]|$)){5,40}$/;

export default class UsernameRule extends Rule {
  apply(value) {
    if (usernameRule.test(value)) {
      return Promise.resolve(this.message);
    }

    return Promise.reject(new ValidationError(this.message));
  }
}
