import Rule from './Rule';
import ValidationError from '../../exceptions/ValidationError';

const specialCharRegex = /[^0-9a-zA-Z]/;

export default class SpecialCharRule extends Rule {
  apply(value) {
    if (specialCharRegex.test(value)) {
      return Promise.resolve(this.message);
    }

    return Promise.reject(new ValidationError(this.message));
  }
}
