import obligationsActionCreator from '../obligations';
import buildLoadDataActionCreator from 'actioncreators/builders/loadData';
import actionTypes, { actionTypeSections } from 'actiontypes/';
import meActionCreator from 'actioncreators/me';
import switchClientsRedirectPageActionCreator from 'actioncreators/accountSettings/switchClientsRedirectPage';
import http from 'utils/http/';
import urls from 'utils/urls';
import getConstants, { staticConstants } from 'utils/constants';

const { authenticationMethod } = getConstants();

const switchClientsActionCreator = {
  ...buildLoadDataActionCreator(actionTypeSections.ACCOUNT_SETTINGS_SWITCH_CLIENTS),

  resetMainState() {
    return {
      type: actionTypes.ACCOUNT_SETTINGS_SWITCH_CLIENTS.RESET_STATE,
    };
  },

  resetState() {
    return (dispatch) => {
      dispatch(this.resetMainState());
      dispatch(this.resetLoadDataState());
    };
  },

  setAssociates(payload) {
    return {
      type: actionTypes.ACCOUNT_SETTINGS_SWITCH_CLIENTS.SET_ASSOCIATES,
      payload,
    };
  },

  setActiveAssociateRequest() {
    return {
      type: actionTypes.ACCOUNT_SETTINGS_SWITCH_CLIENTS.SET_ACTIVE_ASSOCIATE_REQUEST,
    };
  },

  setActiveAssociateSuccess() {
    return {
      type: actionTypes.ACCOUNT_SETTINGS_SWITCH_CLIENTS.SET_ACTIVE_ASSOCIATE_SUCCESS,
    };
  },

  setActiveAssociateFailure() {
    return {
      type: actionTypes.ACCOUNT_SETTINGS_SWITCH_CLIENTS.SET_ACTIVE_ASSOCIATE_FAILURE,
    };
  },

  setActiveAssociate(associateId, clientId, isNexoClient) {
    return async (dispatch) => {
      dispatch(this.setActiveAssociateRequest());

      try {
        const {
          data: { authentication_method: authenticationMode },
        } = await http.checkAuthentication();
        const isAuthenticatedByNexo = authenticationMode === authenticationMethod.NEXO;
        const response = await http.setMyActiveAssociate(
          associateId,
          clientId,
          isNexoClient,
          isAuthenticatedByNexo
        );

        if (response.status === staticConstants.statusCodes.OK) {
          const { username, redirectLoginUrl } = response.data;

          dispatch(this.setActiveAssociateSuccess());
          await dispatch(meActionCreator.logout('/auth/switch-clients-message'));
          await dispatch(
            switchClientsRedirectPageActionCreator.setSwitchClientsDetails(
              username,
              isNexoClient,
              redirectLoginUrl
            )
          );
        } else if (response.status === staticConstants.statusCodes.NO_CONTENT) {
          dispatch(this.setActiveAssociateSuccess());
          try {
            await obligationsActionCreator.checkObligation(urls.accountSettings.CLIENT_PICKER);
          } catch (err) {
            return;
          }
          dispatch(meActionCreator.loadData());
          dispatch(this.loadData());
        }
      } catch (e) {
        dispatch(this.setActiveAssociateFailure());
      }
    };
  },

  loadData() {
    return async (dispatch) => {
      dispatch(this.loadDataRequest());

      try {
        const { data } = await http.getMyAssociates();
        const validAssociates = data.filter((assoc) => assoc.client !== undefined);

        dispatch(this.loadDataSuccess());
        dispatch(this.setAssociates(validAssociates));
      } catch (e) {
        dispatch(this.loadDataFailure());
      }
    };
  },
};

export { switchClientsActionCreator as default };
