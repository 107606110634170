import styles from './style.module.scss';
import { BorderedCard, PrimaryButton, ButtonContainer } from '../../../components';
import { withNamespace } from '../../../components/elements/textDisplays/TranslatedText';
import utils from '../../../utils/helpers';
import { InfoIconAlt } from '../../../components/elements/icons';
import urls from '../../../utils/urls';
import React from 'react';

import { PageHeader, StandardParagraph } from '@idm/ui-components';

const TranslatedText = withNamespace('SUCCESS_NOTIFICATION');

function SuccessNotification() {
  const { redirectUrl } = utils.parseQueryString(utils.getWindowLocation().search);

  return (
    <section>
      <PageHeader>
        <TranslatedText i18nKey="HEADER" />
      </PageHeader>
      <BorderedCard className={styles.message}>
        <InfoIconAlt className={styles.infoIcon} />

        <StandardParagraph>
          <TranslatedText i18nKey="INFORMATION" />
        </StandardParagraph>
      </BorderedCard>
      <ButtonContainer>
        <PrimaryButton
          onClick={() => utils.redirectTo(redirectUrl || urls.LANDING_PAGE_ALIAS)}
          size="large"
          data-meta-id="log-in-button"
        >
          Continue
        </PrimaryButton>
      </ButtonContainer>
    </section>
  );
}

export { SuccessNotification as default };
