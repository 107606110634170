import styles from './index.module.scss';
import { Notification, LinkButton } from '../../../../components';
import TranslationText from '../../../../components/elements/textDisplays/TranslatedText';

import Utilities from '../../../../utils/helpers';
import React from 'react';

const DesktopRegistrationComplete = () => {
  const status = 'success';
  const title = <TranslationText i18nKey="REGISTRATION.SUCCESS.TITLE" />;
  const message = <TranslationText i18nKey="REGISTRATION.SUCCESS.MESSAGE" />;

  return (
    <section>
      <div data-meta-id={`${status}-page`}>
        <Notification title={title} message={message} status={status}>
          <LinkButton
            className={styles.continueButton}
            data-meta-id="continue-link"
            onClick={() => Utilities.navigateToLifionHome()}
          >
            <TranslationText i18nKey="REGISTRATION.SUCCESS.DESKTOP_LINK" />
          </LinkButton>
        </Notification>
      </div>
    </section>
  );
};

export default DesktopRegistrationComplete;
