import datasource from './datasource';
import getConstants from '../../utils/constants';
import actionTypes from '../../actiontypes';
import messagesActionCreator from '../messages';
import UtilityHelper from '../../utils/helpers';
import BaseActionCreator from '../BaseActionCreator';

const { requestResult } = getConstants();

const { REVIEW_CONTACT_INFO, REVIEW_CONTACT_INFO_FAILURE, REVIEW_CONTACT_INFO_SUCCESS } =
  actionTypes.REVIEW_CONTACT_INFO;

class ReviewContactInfoActionCreator extends BaseActionCreator {
  async confirmContactInfo() {
    this.dispatch(REVIEW_CONTACT_INFO);

    try {
      await datasource.confirmContactInfo();

      this.dispatch(
        messagesActionCreator.addToastMessage({
          message: 'CONFIRMED_CONTACT_INFO',
          type: 'success',
          id: UtilityHelper.generateUniqueId(),
          translationNamespace: 'TOAST_MESSAGE',
        })
      );
      this.dispatch(REVIEW_CONTACT_INFO_SUCCESS);
    } catch (err) {
      this.dispatch(REVIEW_CONTACT_INFO_FAILURE);
      this.changeState(requestResult.FAILURE_UNSUPPORTED);
      throw err;
    }
  }
}

export default new ReviewContactInfoActionCreator();
