import Utils from '../../utils/helpers';
import React from 'react';
import PropTypes from 'prop-types';

class RouteManager extends React.Component {
  static propTypes = {
    history: PropTypes.shape({}),
    url: PropTypes.string.isRequired,
    locationState: PropTypes.object,
  };

  static defaultProps = {
    history: null,
    locationState: null,
  };

  shouldComponentUpdate(nextProps) {
    const { url: currentUrl, locationState: prevState } = this.props;
    const { url: nextUrl, locationState } = nextProps;

    return currentUrl !== nextUrl || prevState !== locationState;
  }

  componentDidUpdate() {
    const { history, url, locationState } = this.props;

    Utils.redirectTo(url, history, locationState);
  }

  render() {
    return null;
  }
}

export default RouteManager;
