import styles from './style.module.scss';
import { Notification, LinkButton } from '../../components';
import { withNamespace } from '../../components/elements/textDisplays/TranslatedText';
import getConstants from '../../utils/constants';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const TranslatedText = withNamespace('AUTHENTICATED_NOTIFICATION');
const { requestResult } = getConstants();

class AuthenticatedNotification extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  renderBackLink() {
    const { history } = this.props;

    return (
      <LinkButton
        onClick={history.goBack}
        tabIndex="0"
        className={styles.backLink}
        data-meta-id="back-link"
      >
        <TranslatedText i18nKey="UNKNOWN_ERROR_BACK" />
      </LinkButton>
    );
  }

  notificationProps = {
    [requestResult.FAILURE_LINK]: {
      status: 'error',
      title: <TranslatedText i18nKey="LINK_EXPIRED" />,
      message: <TranslatedText i18nKey="LINK_EXPIRED" />,
      children: this.renderBackLink(),
    },
    [requestResult.FAILURE_SAML_INCORRECT_APP_ID]: {
      status: 'error',
      title: <TranslatedText i18nKey="SAML_ERROR_TITLE" />,
      message: <TranslatedText i18nKey="SAML_ERROR_MESSAGE" dangerouslySetInnerHtml={true} />,
      children: this.renderBackLink(),
    },
    [requestResult.FAILURE_UNSUPPORTED]: {
      status: 'error',
      title: <TranslatedText i18nKey="UNKNOWN_ERROR_TITLE" />,
      message: <TranslatedText i18nKey="UNKNOWN_ERROR_MESSAGE" dangerouslySetInnerHtml={true} />,
      children: this.renderBackLink(),
    },
  };

  render() {
    const { location } = this.props;
    const notificationProps =
      this.notificationProps[location.state.REDIRECT_RESULT] ||
      this.notificationProps.FAILURE_UNSUPPORTED;

    return <Notification {...notificationProps} />;
  }
}

export { AuthenticatedNotification as UnwrappedAuthenticatedNotification };
export default withRouter(AuthenticatedNotification);
