import styles from './style.module.scss';
import Translator from '../../../utils/translation/Translator';
import { ButtonContainer, PrimaryButton, SecondaryButton, ErrorMessage } from '../../../components';
import { withNamespace } from '../../../components/elements/textDisplays/TranslatedText';
import EmailFormInput from '../../../components/elements/formInputs/EmailFormInput';
import actionCreator from '../../../actioncreators/ssoLogin/index';

import { Form } from '@tbiegner99/react-forms';
import { StandardParagraph, Header2 } from '@idm/ui-components';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const TranslatedText = withNamespace('SSO_LOGIN.REQUEST');
const requestDictionary = new Translator('SSO_LOGIN.REQUEST');

class UnwrappedSSOLoginRequest extends Component {
  static propTypes = {
    dictionary: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    showError: PropTypes.bool.isRequired,
    onChangeUrl: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isValidEmail: false,
    };
  }

  clearForm() {
    this.setState({
      email: '',
      isValidEmail: false,
    });
  }

  submit = async (formData) => {
    await actionCreator.ssoLogin(formData.email);
    this.clearForm();
  };

  goToLogin = () => {
    const { onChangeUrl } = this.props;

    onChangeUrl('/auth/login');
  };

  onChangeEmail = (email) => {
    const regex = new RegExp('^.+@.+\\..+$');
    const isValidEmail = regex.test(email);

    this.setState({ email, isValidEmail });
  };

  render() {
    const { dictionary, showError } = this.props;
    const { email, isValidEmail } = this.state;

    return (
      <section>
        <Header2 className={styles.header2}>
          <TranslatedText i18nKey="HEADER" />
        </Header2>
        <StandardParagraph className={styles.displayMessage}>
          <TranslatedText i18nKey="BODY" />
        </StandardParagraph>
        <Form onSubmit={(data) => this.submit(data)}>
          <EmailFormInput
            forceValidationCheck={false}
            onChange={this.onChangeEmail}
            value={email}
            name="email"
            label={requestDictionary.get('EMAIL_LABEL', dictionary)}
            className="input-large"
            autoFocus={true}
            id="email-input"
            data-meta-id="email-input"
          />
          <ErrorMessage
            message={requestDictionary.get('NOT_FOUND_ERROR', dictionary)}
            showError={showError}
            dataMetaId="error-message"
            width={140}
            height={140}
            id="error-message"
          />
          <ButtonContainer>
            <SecondaryButton
              onClick={this.goToLogin}
              value={requestDictionary.get('CANCEL_BUTTON', dictionary)}
              size="large"
              data-meta-id="cancel-button"
            >
              <TranslatedText i18nKey="CANCEL_BUTTON" />
            </SecondaryButton>
            <PrimaryButton
              size="large"
              submittable={true}
              data-meta-id="submit-button"
              disabled={!isValidEmail}
            >
              <TranslatedText i18nKey="SUBMIT_BUTTON" />
            </PrimaryButton>
          </ButtonContainer>
        </Form>
      </section>
    );
  }
}

export default UnwrappedSSOLoginRequest;
