import UtilityHelper from '../helpers';
import { staticConstants } from '../constants';
import BaseActionCreator from '../../actioncreators/BaseActionCreator';
import obligationSerializer from '../../actioncreators/obligations/serializer';
import tokenClient from 'ohcm-identity-csrf-token-client';
import axios from 'axios';

const supportedErrorCodes = {
  INCORRECT_CREDS: 'INCORRECT_CREDS',
  TOO_MANY_ATTEMPTS: 'TOO_MANY_ATTEMPTS',
  CAPTCHA_MISSING: 'RECAPTCHA_RESPONSE_TOKEN_MISSING',

  INSUFFICIENT_PERMISSIONS: 'INSUFFICIENT_PERMISSIONS',
  MISSING_SESSION_COOKIE: 'MISSING_SESSION_COOKIE',

  TOKEN_LOAD_VERIFY_FAILURE: 'TOKEN_LOAD_VERIFY_FAILURE',
  TOKEN_EXECUTE_FAILURE: 'TOKEN_EXECUTE_FAILURE',
  INVALID_TOKEN: 'INVALID_TOKEN',
  REDIRECT_TO_AIM_REGISTRATION_ERROR: 'REDIRECT_TO_AIM_REGISTRATION_ERROR',

  DUPLICATE_EMAIL: 'DUPLICATE_EMAIL',
  DUPLICATE_USERNAME: 'DUPLICATE_USERNAME',
  TOO_WEAK_PASSWORD: 'TOO_WEAK_PASSWORD',
  PASSWORD_BLACKLISTED: 'PASSWORD_BLACKLISTED',
  PASSWORD_3_SEQUENTIAL_CHARS: 'PASSWORD_3_SEQUENTIAL_CHARS',
  PASSWORD_3_REPEATED_CHARS: 'PASSWORD_3_REPEATED_CHARS',
  PASSWORD_PRECONDITION_UNMATCHED: 'PASSWORD_PRECONDITION_UNMATCHED',
  LENGTH_RULE: 'LENGTH_RULE',
  ACCOUNT_INACTIVE: 'ACCOUNT_INACTIVE',

  INCORRECT_PASSWORD: 'INCORRECT_PASSWORD',

  FAILED_TO_GET_SETTINGS: 'FAILED_TO_GET_SETTINGS',
  BLOCK_BY_OBLIGATION: 'BLOCK_BY_OBLIGATION',
  BLOCKED_BY_OBLIGATION: 'BLOCKED_BY_OBLIGATION',

  EMAIL_ADDRESS_ALREADY_EXISTS: 'EMAIL_ADDRESS_ALREADY_EXISTS',
  EMAIL_IS_ALREADY_VERIFIED: 'EMAIL_IS_ALREADY_VERIFIED',

  ASSOCIATE_SUSPENDED: 'ASSOCIATE_SUSPENDED',

  ERROR_VERIFYING_ASSOCIATE: 'ERROR_VERIFYING_ASSOCIATE',

  CLIENT_NOT_FOUND: 'CLIENT_NOT_FOUND',
  CLIENT_MISSING_SAML_CONFIGURATION: 'CLIENT_MISSING_SAML_CONFIGURATION',
};

class HttpClient {
  static actionCreator = new BaseActionCreator();

  static createInstance(instanceConfig) {
    const { skipErrorHandling, ...axiosConfig } = instanceConfig;
    const axiosInstance = axios.create(axiosConfig);

    axiosInstance.interceptors.request.use((config) => {
      const reqHeader = {
        Accept: 'application/json',
        'x-trace-id': UtilityHelper.generateUniqueId(),
      };
      const csrfHeader = { 'X-Csrf-Token': tokenClient.fetchCsrfToken() || '' };

      if (config.method === 'get') {
        return {
          ...config,
          headers: {
            ...config.headers,
            ...reqHeader,
          },
        };
      }
      return {
        ...config,
        headers: {
          ...config.headers,
          ...reqHeader,
          ...csrfHeader,
        },
      };
    }, null);
    if (!skipErrorHandling) {
      // eslint-disable-next-line no-use-before-define
      axiosInstance.interceptors.response.use(null, handleError);
    }
    return axiosInstance;
  }
}

const HANDLED_FORBIDDEN_CODES = {
  TOO_MANY_ATTEMPTS: 'TOO_MANY_ATTEMPTS',
};

const instantiateErrorWithCodeAndMsg = ({ code, message, errors }) => {
  const error = new Error(message);

  error.code = code;
  error.errors = errors;

  return error;
};

const getErrorCodeAndMsg = (errors) => ({
  code:
    (Array.isArray(errors) && errors[0] && supportedErrorCodes[errors[0].code]) || 'UNKNOWN_ERROR',
  message:
    (Array.isArray(errors) && errors[0] && errors[0].message) ||
    'Something went wrong while making a HTTP request.',
  errors,
});

const populateCurrentErrorStack = (error) => {
  if (Array.isArray(window.currentErrorStack)) {
    window.currentErrorStack.unshift(error);
  } else {
    window.currentErrorStack = [error];
  }
};

const throwIfForbiddenErrorIsHandled = (response) => {
  const errors = getErrorCodeAndMsg(response.data.errors);

  if (HANDLED_FORBIDDEN_CODES[errors.code]) {
    throw instantiateErrorWithCodeAndMsg(errors);
  }
};

const getRequiredAction = (obligation) => {
  if (obligation.requiredActions) {
    return obligation.requiredActions[0];
  }
  return obligation.requiredAction;
};

const reconstructUrl = (url) => {
  const split = url.split('?');
  const existingRedirect = split.length > 1 ? '&' : '?';
  const updatedURL = `${url}${existingRedirect}redirectUrl=${UtilityHelper.getCurrentPath()}`;

  return updatedURL;
};

const handleError = async (error) => {
  let errors;
  let requireSomeAction;

  populateCurrentErrorStack(error);

  if (error.response && error.response.data) {
    const responseData = error.response.data;

    requireSomeAction = responseData.obligation && getRequiredAction(responseData.obligation);

    if (error.response.status === staticConstants.statusCodes.FORBIDDEN) {
      throwIfForbiddenErrorIsHandled(error.response);
      let unauthorizedErrorPage = staticConstants.UNAUTHORIZED_ERROR_PAGE;
      const errResponse = responseData.errors && responseData.errors[0];

      if (errResponse && errResponse.details && errResponse.details.traceId) {
        unauthorizedErrorPage = unauthorizedErrorPage.concat(
          `?traceId=${encodeURIComponent(errResponse.details.traceId)}`
        );
      }
      UtilityHelper.redirectTo(unauthorizedErrorPage);
      return null;
    }

    if (requireSomeAction) {
      const { kind, url } = requireSomeAction;

      if (kind === 'redirect') {
        if (UtilityHelper.requiresRedirect(url)) {
          UtilityHelper.redirectTo(reconstructUrl(url));
        }
        return null;
      }
      if (kind === staticConstants.obligationActionTypes.COMMAND) {
        const action = obligationSerializer.fromActionRequiredResponse(requireSomeAction);

        HttpClient.actionCreator.handleObligations(action);
      }
    }

    errors = responseData.errors || [error];
  } else {
    errors = [error];
  }
  throw instantiateErrorWithCodeAndMsg(getErrorCodeAndMsg(errors));
};

export { HttpClient as default, supportedErrorCodes };
