import { ErrorIcon } from '../elements/icons';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './style.scss';

const InlineMessage = (props) => {
  const { type, children, className } = props;
  const isErrorMessage = type === 'error';

  return (
    <div
      className={cx('inline-message', className, { 'error-message': isErrorMessage })}
      data-meta-id={isErrorMessage ? 'error-notification' : 'inline-notification'}
    >
      {isErrorMessage && (
        <div>
          <ErrorIcon width={20} height={20} />
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};

InlineMessage.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
};

InlineMessage.defaultProps = {
  type: '',
  className: '',
};

export { InlineMessage as default };
