import buildFormActionCreator from '../builders/form';
import applicationActionCreator from '../application';
import actionTypes, { actionTypeSections } from '../../actiontypes';
import http from '../../utils/http';
import getConstants from '../../utils/constants';

const { requestResult } = getConstants();

const requestActionCreator = {
  ...buildFormActionCreator(actionTypeSections.SELF_REGISTRATION),

  resetMainState() {
    return {
      type: actionTypes.SELF_REGISTRATION.RESET_STATE,
    };
  },

  resetState() {
    return (dispatch) => {
      dispatch(this.resetMainState());

      dispatch(this.resetFormState());
    };
  },

  requestSelfRegistrationRequest() {
    return {
      type: actionTypes.SELF_REGISTRATION.SELF_REGISTRATION_REQUEST,
    };
  },

  requestSelfRegistrationRequestSuccess() {
    return {
      type: actionTypes.SELF_REGISTRATION.REQUEST_STATUS_SUCCESS,
    };
  },

  requestSelfRegistrationRequestFailure() {
    return {
      type: actionTypes.SELF_REGISTRATION.REQUEST_STATUS_FAILURE,
    };
  },

  selfRegistrationRequest(email) {
    return async (dispatch) => {
      try {
        dispatch(this.requestSelfRegistrationRequest());

        await http.requestSelfRegistration(email);

        applicationActionCreator.changeState(requestResult.SUCCESS_SELF_REGISTRATION_REQUEST);

        dispatch(this.requestSelfRegistrationRequestSuccess());
      } catch (error) {
        const isBadAttempt = error.code === http.supportedErrorCodes.TOO_MANY_ATTEMPTS;

        if (isBadAttempt) {
          applicationActionCreator.changeState(requestResult.FAILURE_RECOVER_ATTEMPT);
        } else {
          applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);
        }

        dispatch(this.requestSelfRegistrationRequestFailure());
      }
    };
  },
};

export { requestActionCreator as default };
