const fromProduct = (product) => {
  const { targetUrl, productName, description, productId } = product;

  return { targetUrl, productName, description, productId };
};

const fromLoadLandingProductsResponse = (responseData) => {
  return responseData.map(fromProduct);
};

export default {
  fromLoadLandingProductsResponse,
};
