import meActionCreator from '../../actioncreators/me';
import cookieUtils from '../../utils/cookie';
import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class UnwrappedLogout extends Component {
  static propTypes = {
    dispatchLogout: PropTypes.func.isRequired,

    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  componentDidMount() {
    const { dispatchLogout, location } = this.props;

    if (cookieUtils.getCookie('lifionSsoSession')) {
      dispatchLogout('/auth/sso-login/request');
    } else {
      dispatchLogout(`/auth/login${location.search}`);
    }
  }

  render() {
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchLogout(redirectLocation) {
      dispatch(meActionCreator.logout(redirectLocation));
    },
  };
}

const Logout = connect(null, mapDispatchToProps)(UnwrappedLogout);

export { UnwrappedLogout, Logout as default };
