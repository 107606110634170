import buildLoadDataActionCreator from './builders/loadData';
import applicationActionCreator from './application';
import datasource from './accountSettings/contactInfo/datasource';
import http from '../utils/http';
import getConstants, { adpAdmin, tokenKinds, staticConstants } from '../utils/constants';
import translationUtils from '../utils/translation/utility';
import actionTypes, { actionTypeSections } from '../actiontypes';

const { requestResult } = getConstants();

const accountRegistrationActionCreator = {
  ...buildLoadDataActionCreator(actionTypeSections.ACCOUNT_REGISTRATION),

  resetMainState() {
    return {
      type: actionTypes.ACCOUNT_REGISTRATION.RESET_STATE,
    };
  },

  resetState() {
    return (dispatch) => {
      dispatch(this.resetMainState());

      dispatch(this.resetLoadDataState());
    };
  },

  skipToCreateAccount(payload) {
    return {
      type: actionTypes.ACCOUNT_REGISTRATION.SKIP_TO_CREATE_ACCOUNT,
      payload,
    };
  },

  setCompanyName(payload) {
    return {
      type: actionTypes.ACCOUNT_REGISTRATION.SET_COMPANY_NAME,
      payload,
    };
  },

  setAssociateFirstName(payload) {
    return {
      type: actionTypes.ACCOUNT_REGISTRATION.SET_ASSOCIATE_FIRST_NAME,
      payload,
    };
  },

  setAssociateIdentifier(payload) {
    return {
      type: actionTypes.ACCOUNT_REGISTRATION.SET_ASSOCIATE_IDENTIFIER,
      payload,
    };
  },

  setClientIdentifier(payload) {
    return {
      type: actionTypes.ACCOUNT_REGISTRATION.SET_CLIENT_IDENTIFIER,
      payload,
    };
  },

  toggleShowDobObligation() {
    return {
      type: actionTypes.ACCOUNT_REGISTRATION.TOGGLE_SHOW_DOB_OBLIGATION,
    };
  },

  setDobLocale(payload) {
    return {
      type: actionTypes.ACCOUNT_REGISTRATION.SET_DOB_LOCALE,
      payload,
    };
  },

  getLocale() {
    return async (dispatch) => {
      const locale = translationUtils.getLocale();

      dispatch(this.setDobLocale(locale));
    };
  },

  resetDobCheck() {
    return {
      type: actionTypes.ACCOUNT_REGISTRATION.RESET_DOB_CHECK,
    };
  },

  setRegistrationCode(registrationCode) {
    return {
      type: actionTypes.ACCOUNT_REGISTRATION.FAILURE_REDIRECT_TO_AIM,
      payload: registrationCode,
    };
  },

  loadAndVerifyToken(token) {
    return async (dispatch) => {
      try {
        dispatch(this.loadDataRequest());

        const res = await http.loadAndVerify(tokenKinds.ASSOCIATE, token, true);

        const companyName = res.data.extra_information && res.data.extra_information.client_name;

        if (companyName) {
          dispatch(this.setCompanyName(companyName));
        }

        const associateFirstName =
          res.data.extra_information && res.data.extra_information.first_name;

        if (associateFirstName) {
          dispatch(this.setAssociateFirstName(associateFirstName));
        }

        // const associateId = res.data && res.data.identifier;
        const associateId =
          res.data &&
          ((res.data.token_blob && res.data.token_blob.associateId) || res.data.identifier);

        if (associateId) {
          dispatch(this.setAssociateIdentifier(associateId));
        }

        const clientId = res.data.token_blob && res.data.token_blob.client_id;

        if (clientId) {
          dispatch(this.setClientIdentifier(clientId));
        }

        const email = res.data && res.data.token_blob && res.data.token_blob.email;

        const featureEnabledStatus = await datasource.getFeatureEnabledStatus(
          staticConstants.featureFlags.ENABLE_REGISTRATION_LINK_EXISTING_ACCOUNT
        );

        if (clientId && associateId) {
          dispatch(this.loadAndCheckDobObligation(clientId, associateId, email));
        } else {
          dispatch(this.loadDataSuccess());
        }
        if (!featureEnabledStatus) dispatch(this.checkIfAdpAssociate(email));
      } catch (err) {
        if (err.code === http.supportedErrorCodes.TOKEN_LOAD_VERIFY_FAILURE) {
          applicationActionCreator.changeState(requestResult.FAILURE_LINK);
        } else if (err.code === 'REDIRECT_TO_AIM_REGISTRATION_ERROR') {
          const { pic } = err.errors[0].details;

          dispatch(this.setRegistrationCode(pic));
          applicationActionCreator.changeState(requestResult.FAILURE_REDIRECT_TO_AIM);
        } else {
          applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);
        }

        dispatch(this.loadDataFailure());
      }
    };
  },

  checkIfAdpAssociate(email) {
    return async (dispatch) => {
      if (!adpAdmin.emailPattern.test(email)) {
        dispatch(this.skipToCreateAccount(true));
      }
    };
  },

  checkAssociateDobAttribute(clientId, associateId) {
    return async (dispatch) => {
      try {
        const res = await http.checkIfDobExists(clientId, associateId);
        const { identityVerifiers } = res.data;

        if (identityVerifiers.isDobPresent) {
          dispatch(this.toggleShowDobObligation());
        } else {
          applicationActionCreator.changeState(requestResult.FAILURE_INCOMPLETE_PROFILE);
        }

        dispatch(this.loadDataSuccess());
      } catch (e) {
        applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);
      }
    };
  },

  setAssociateDob(payload) {
    return {
      type: actionTypes.ACCOUNT_REGISTRATION.SET_ASSOCIATE_DOB,
      payload,
    };
  },

  checkAssociateDobRequest() {
    return {
      type: actionTypes.ACCOUNT_REGISTRATION.CHECK_ASSOCIATE_DOB_REQUEST,
    };
  },

  checkAssociateDobSuccess() {
    return {
      type: actionTypes.ACCOUNT_REGISTRATION.CHECK_ASSOCIATE_DOB_SUCCESS,
    };
  },

  checkAssociateDobFailure(payload) {
    return {
      type: actionTypes.ACCOUNT_REGISTRATION.CHECK_ASSOCIATE_DOB_FAILURE,
      payload,
    };
  },

  loadAndCheckDobObligation(clientId, associateId) {
    return async (dispatch) => {
      try {
        const res = await http.checkForDobObligation(clientId, associateId);
        const { verificationFields } = res.data;
        const dobKind = verificationFields[0].kind === 'dob';

        if (dobKind) {
          dispatch(this.checkAssociateDobAttribute(clientId, associateId));
        } else {
          dispatch(this.loadDataSuccess());
        }
      } catch (e) {
        dispatch(this.loadDataFailure());
        dispatch(this.checkAssociateDobFailure());
      }
    };
  },

  checkAssociateDob(clientId, associateId, dob) {
    return async (dispatch) => {
      dispatch(this.checkAssociateDobRequest());

      try {
        await dispatch(this.setAssociateDob(dob));

        await http.checkDob(clientId, associateId, dob.isoDate);
        await dispatch(this.checkAssociateDobSuccess());
      } catch (error) {
        const { errors } = error;
        const { code } = errors[0];

        dispatch(this.checkAssociateDobFailure(code));
      }
    };
  },
};

export { accountRegistrationActionCreator as default };
