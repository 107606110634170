import actionTypes from 'actiontypes/';
import { noop } from 'utils/constants';

const initialState = {
  isVisible: false,
  title: '',
  body: '',
  okCallback: noop,
  cancelCallback: noop,
  okButtonText: '',
  cancelButtonText: '',
};

function modalReducer(state = initialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.MODAL.SHOW:
      return {
        ...state,
        isVisible: true,
        title: actionPayload.title,
        body: actionPayload.body,
        okButtonText: actionPayload.okButtonText,
        cancelButtonText: actionPayload.cancelButtonText,
        okCallback: actionPayload.okCallback,
        cancelCallback: actionPayload.cancelCallback,
      };
    case actionTypes.MODAL.HIDE:
      return { ...state, isVisible: false };
    case actionTypes.MODAL.RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
}

export { modalReducer as default };
