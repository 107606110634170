const contactInfoActionTypes = {
  LOAD: {
    REQUEST: 'CONTACT_INFO.LOAD.REQUEST',
    FAILURE: 'CONTACT_INFO.LOAD.FAILURE',
    SUCCESS: 'CONTACT_INFO.LOAD.SUCCESS',
  },
  DELETE: {
    REQUEST: 'CONTACT_INFO.DELETE.REQUEST',
    FAILURE: 'CONTACT_INFO.DELETE.FAILURE',
    SUCCESS: 'CONTACT_INFO.DELETE.SUCCESS',
  },
  GET_ADP_EMAIL_REQUIRED_FLAG_STATUS: {
    SUCCESS: 'CONTACT_INFO.GET_ADP_EMAIL_REQUIRED_FLAG_STATUS.SUCCESS',
    FAILURE: 'CONTACT_INFO.GET_ADP_EMAIL_REQUIRED_FLAG_STATUS.FAILURE',
  },
  GET_ONLY_ALLOW_ADP_EMAIL_FLAG_STATUS: {
    SUCCESS: 'CONTACT_INFO.GET_ONLY_ALLOW_ADP_EMAIL_FLAG_STATUS.SUCCESS',
    FAILURE: 'CONTACT_INFO.GET_ONLY_ALLOW_ADP_EMAIL_FLAG_STATUS.FAILURE',
  },
};

export default contactInfoActionTypes;
