class WindowUtils {
  static getWindowLocation() {
    return window.location;
  }

  static getCurrentPath() {
    return WindowUtils.getWindowLocation().pathname;
  }

  static refresh() {
    WindowUtils.getWindowLocation().reload();
  }

  static getNexoBaseUrl(isRebrandEnabled) {
    return isRebrandEnabled && window.__new_nexo_base_url__
      ? window.__new_nexo_base_url__
      : window.__nexo_base_url__;
  }

  static getWindowOriginUrl() {
    return window.location.origin;
  }

  static getNexoBaseUrlRebrand() {
    const originUrl = this.getWindowOriginUrl();

    return window.__new_nexo_base_url__ && originUrl.toLowerCase().includes('lyric')
      ? window.__new_nexo_base_url__
      : window.__nexo_base_url__;
  }
}

export default WindowUtils;
