import HttpClient from './HttpClient';
import getConstants from '../constants';
import queryString from 'query-string';

const constants = getConstants(process.env.BASE_URL || window.location.origin);
const authenticationServiceUrl = constants.serviceBaseUrls.authenticationService;
const httpClient = HttpClient.createInstance({ baseURL: authenticationServiceUrl });

const reqHeader = {
  'Content-Type': 'application/x-www-form-urlencoded',
};

export function login(username, password, recaptchaToken) {
  const payload = recaptchaToken
    ? queryString.stringify({
        username,
        password,
        recaptchaToken,
      })
    : queryString.stringify({
        username,
        password,
      });

  return httpClient.post('/login', payload, { headers: reqHeader });
}

export function logout() {
  return httpClient.post('/logout');
}

export function logoutAllSessions() {
  return httpClient.post('/logout-all-sessions');
}

export function checkAuthentication() {
  return httpClient.get('/check');
}

export async function getAuthenticationMethod() {
  try {
    const {
      data: { authentication_method: authenticationMethod },
    } = await checkAuthentication();

    return authenticationMethod || constants.authenticationMethod.LIFION;
  } catch (err) {
    return constants.authenticationMethod.LIFION;
  }
}

export async function checkAuthPath(username) {
  return httpClient.get(`/username/${username}/check-auth-path`);
}
