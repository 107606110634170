import { ErrorIcon } from '../../elements/icons';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

const ErrorMessage = (props) => {
  const { message, showError, className, height, width, dataMetaId } = props;
  const classname = classNames('idm-input-error', { hide: !showError }, className);

  return (
    <div className={classname} data-meta-id={dataMetaId}>
      <ErrorIcon width={width} height={height} />
      {message}
    </div>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  showError: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  dataMetaId: PropTypes.string,
};

ErrorMessage.defaultProps = {
  message: '',
  className: '',
  showError: false,
  width: 12,
  height: 12,
  dataMetaId: '',
};

export default ErrorMessage;
