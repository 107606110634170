import datasource from './datasource';
import BaseActionCreator from '../BaseActionCreator';

class ObligationActionCreator extends BaseActionCreator {
  async checkObligation(redirectUrl, isLogin) {
    const { obligationRequired, action } = await datasource.checkObligation();

    await this.clearObligations();
    if (obligationRequired) {
      const dispatchAction = Object.assign(action, { isLoginObligation: isLogin });

      this.handleObligations(dispatchAction);

      throw action;
    }
  }
}

export default new ObligationActionCreator();
