import Translator from '../../../utils/translation/Translator';
import { LeftCaret, RightCaret } from '../../../components/elements/icons';
import { LinkButton } from '../../../components';
import namespaceTranslatedText from '../../TranslatedText';
import actionCreator from '../../../actioncreators/accountSettings/devices';

import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { connect } from 'react-redux';

import './style.scss';
import { Header2, StandardParagraph } from '@idm/ui-components';

const TranslatedText = namespaceTranslatedText('ACCOUNT_SETTINGS.DEVICES');
const devicesDictionary = new Translator('ACCOUNT_SETTINGS.DEVICES');

class Devices extends React.Component {
  static propTypes = {
    devices: PropTypes.arrayOf(
      PropTypes.shape({
        deviceId: PropTypes.string,
        deviceManufacturer: PropTypes.string,
        deviceName: PropTypes.string,
        deviceOs: PropTypes.string,
        deviceOsVersion: PropTypes.string,
        deviceType: PropTypes.string,
        isDeviceBasedAuthenticationEnabled: PropTypes.bool,
        lifionMobileAppVersion: PropTypes.string,
      }).isRequired
    ).isRequired,
    isLoadingData: PropTypes.bool.isRequired,
    revokedDeviceIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    dictionary: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    dispatchRevokeDeviceAccess: PropTypes.func.isRequired,
    dispatchResetState: PropTypes.func.isRequired,
    dispatchLoadData: PropTypes.func.isRequired,

    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  renderOperatingSystemColumn(record) {
    return `${record.deviceOs} ${record.deviceOsVersion}`;
  }

  static renderPaginationItem(current, type) {
    if (type === 'prev') {
      return <LeftCaret />;
    }
    if (type === 'next') {
      return <RightCaret />;
    }
    if (type === 'jump-prev' || type === 'jump-next') {
      return '...';
    }
    return current;
  }

  componentDidMount() {
    const { dispatchLoadData } = this.props;

    dispatchLoadData();
  }

  componentWillUnmount() {
    const { dispatchResetState } = this.props;

    dispatchResetState();
  }

  getPaginationProps() {
    const { dictionary } = this.props;

    return {
      defaultPageSize: 5,
      hideOnSinglePage: true,
      itemRender: Devices.renderPaginationItem,
      locale: {
        prev_page: devicesDictionary.get('PREVIOUS', dictionary),
        next_page: devicesDictionary.get('NEXT', dictionary),
        prev_5: devicesDictionary.get('JUMP_BACK_FIVE', dictionary),
        next_5: devicesDictionary.get('JUMP_FORWARD_FIVE', dictionary),
      },
    };
  }

  getRowClassName = (record) =>
    this.props.revokedDeviceIds.includes(record.deviceId)
      ? 'device-row-authentication-revoked'
      : '';

  getColumns() {
    const { dictionary } = this.props;

    return [
      {
        title: devicesDictionary.get('DEVICE_NAME', dictionary),
        dataIndex: 'deviceName',
      },
      {
        title: devicesDictionary.get('MANUFACTURER', dictionary),
        dataIndex: 'deviceManufacturer',
      },
      {
        title: devicesDictionary.get('OS', dictionary),
        key: 'deviceOs',
        render: (record) => this.renderOperatingSystemColumn(record),
      },
      {
        title: devicesDictionary.get('TOUCH_ID', dictionary),
        key: 'isDeviceBasedAuthenticationEnabled',
        render: (record) => this.renderStatusColumn(record),
      },
    ];
  }

  revokeAccess = (deviceId) => {
    const { history, dispatchRevokeDeviceAccess } = this.props;

    dispatchRevokeDeviceAccess(deviceId, history);
  };

  renderStatusColumn = (record) => {
    const { revokedDeviceIds } = this.props;

    if (revokedDeviceIds.includes(record.deviceId)) {
      return <span>Revoked</span>;
    }
    if (record.isDeviceBasedAuthenticationEnabled) {
      return (
        <LinkButton
          data-meta-id={record.deviceId}
          onClick={() => this.revokeAccess(record.deviceId)}
        >
          <TranslatedText textKey="REVOKE_BUTTON" />
        </LinkButton>
      );
    }

    return (
      <span className="not-configured">
        <TranslatedText textKey="NOT_CONFIGURED" />
      </span>
    );
  };

  render() {
    const { isLoadingData, devices } = this.props;

    if (isLoadingData) {
      return null;
    }

    const data = devices.map((device) => ({
      ...device,
      key: device.deviceId,
    }));

    return (
      <div className="idm-devices-tab">
        <Header2>
          <TranslatedText textKey="TITLE" />
        </Header2>
        <StandardParagraph>
          <TranslatedText textKey="MESSAGE" />
        </StandardParagraph>
        <Table
          className="devices-table"
          locale={{
            emptyText: <TranslatedText textKey="EMPTY_LIST" />,
            nextPage: <TranslatedText textKey="NEXT" />,
          }}
          columns={this.getColumns()}
          dataSource={data}
          pagination={this.getPaginationProps()}
          rowClassName={this.getRowClassName}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    devices: state.accountSettingsDevices.devices,
    revokedDeviceIds: state.accountSettingsDevices.revokedDeviceIds,
    isLoadingData: state.accountSettingsDevices.isLoadingData,
    dictionary: state.translation.main.dictionary,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchResetState() {
      dispatch(actionCreator.resetState());
    },

    dispatchLoadData() {
      dispatch(actionCreator.loadData());
    },

    dispatchRevokeDeviceAccess(deviceId, history) {
      dispatch(actionCreator.revokeDeviceAccess(deviceId, history));
    },
  };
}

export { Devices as UnwrappedDevices };
export default connect(mapStateToProps, mapDispatchToProps)(Devices);
