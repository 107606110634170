import { RegexRule } from '@tbiegner99/react-forms';

const REGEX = /[^0-9a-zA-Z]/;

class SpecialCharRule extends RegexRule {
  constructor() {
    super(REGEX);
  }

  static get ruleName() {
    return 'special-char-required';
  }

  getDefaultMessage() {
    return 'Must contain a special character';
  }
}

export default SpecialCharRule;
