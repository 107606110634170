const devicesActionTypes = {
  RESET_STATE: 'RESET_STATE',
  SET_DEVICES: 'SET_DEVICES',
  LOAD_DATA_REQUEST: 'LOAD_DATA_REQUEST',
  LOAD_DATA_SUCCESS: 'LOAD_DATA_SUCCESS',
  LOAD_DATA_FAILURE: 'LOAD_DATA_FAILURE',
  UPDATE_DATA_REQUEST: 'UPDATE_DATA_REQUEST',
  UPDATE_DATA_SUCCESS: 'UPDATE_DATA_SUCCESS',
  UPDATE_DATA_FAILURE: 'UPDATE_DATA_FAILURE',
  ADD_REVOKED_DEVICE_ID: 'ADD_REVOKED_DEVICE_ID',
};

export { devicesActionTypes as default };
