const toCreateCredentialsRequest = (data) => ({
  username: data.username,
  password: data.password,
});

export default {
  createCredentials: {
    toRequest: toCreateCredentialsRequest,
  },
};
