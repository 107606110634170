/* eslint-disable react/prop-types */
import ErrorPage from './ErrorPage';
import React from 'react';

const ReduxErrorPage = (props) => {
  const {
    location: { state },
  } = props;

  return <ErrorPage type={state} />;
};

export default ReduxErrorPage;
