import actionCreator from '../../actioncreators/userConfirmation';
import meActionCreator from '../../actioncreators/me';
import { RightArrow } from '../../components/elements/icons';

import namespaceTranslatedText from '../TranslatedText';
import UtilityHelper from '../../utils/helpers';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const TranslatedText = namespaceTranslatedText('USER_CONFIRMATION');

class UnwrappedUserConfirmation extends Component {
  static propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    isLoadingData: PropTypes.bool.isRequired,

    dispatchResetState: PropTypes.func.isRequired,
    dispatchLoadAndVerifyToken: PropTypes.func.isRequired,
    dispatchLogout: PropTypes.func.isRequired,

    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  constructor(props) {
    super(props);

    const queryParamMap = UtilityHelper.parseQueryString(props.location.search);

    this.token = queryParamMap.token;
  }

  componentDidMount() {
    const { history, dispatchLoadAndVerifyToken } = this.props;

    dispatchLoadAndVerifyToken(this.token, history);
  }

  componentWillUnmount() {
    const { dispatchResetState } = this.props;

    dispatchResetState();
  }

  onNotYouButtonClick = () => {
    const { location, dispatchLogout } = this.props;
    const { token } = UtilityHelper.parseQueryString(location.search);

    dispatchLogout(`/auth/create-account/${token}`);
  };

  render() {
    const { firstName, lastName, email, isLoadingData } = this.props;

    if (isLoadingData) {
      return null;
    }

    return (
      <section className="user-confirmation">
        <div className="confirmation-header">
          <TranslatedText textKey="HEADER" />
        </div>
        <div className="identities">
          <Link
            className="identity"
            to={`/auth/account-linking?token=${this.token}`}
            data-meta-id="is-you-button"
          >
            <div className="avatar initials">
              {firstName.charAt(0)}
              {lastName.charAt(0)}
            </div>
            <div className="details">
              <div className="primary-text">
                <TranslatedText
                  textKey="ARE_YOU"
                  containHtml={true}
                  params={{ firstName, lastName }}
                />
              </div>
              <div className="secondary-text">
                <TranslatedText textKey="CONTINUE_AS" params={{ email }} />
              </div>
            </div>
            <RightArrow />
          </Link>

          <div
            className="identity"
            role="button"
            onClick={this.onNotYouButtonClick}
            tabIndex="0"
            data-meta-id="not-you-button"
          >
            <div className="avatar unknown" />
            <div className="details">
              <div className="primary-text">
                <TranslatedText textKey="NOT_YOU" />
              </div>
              <div className="secondary-text">
                <TranslatedText textKey="SIGN_IN_DIFF" />
              </div>
            </div>
            <RightArrow />
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    firstName: state.me.main.firstName,
    lastName: state.me.main.lastName,
    email: state.me.main.email,
    isLoadingData: state.userConfirmation.loadData.isLoadingData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchResetState() {
      dispatch(actionCreator.resetState());
    },

    dispatchLoadAndVerifyToken(token, history) {
      dispatch(actionCreator.loadAndVerifyToken(token, history));
    },

    dispatchLogout(redirectLocation) {
      dispatch(meActionCreator.logout(redirectLocation));
    },
  };
}

const UserConfirmation = connect(mapStateToProps, mapDispatchToProps)(UnwrappedUserConfirmation);

export { UnwrappedUserConfirmation, UserConfirmation as default };
