import modalActionCreator from 'actioncreators/modal';

const buildGetUserConfirmation = (store) => (stringifiedModalProps, callback) => {
  const okCallback = callback.bind(null, true);
  const cancelCallback = callback.bind(null, false);

  store.dispatch(modalActionCreator.show(stringifiedModalProps, okCallback, cancelCallback));
};

export { buildGetUserConfirmation as default };
