import styles from './index.module.scss';
import { SuccessIcon, ErrorIcon, CloseIcon, InfoIcon } from '../elements/icons';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import combineClasses from 'classnames';

class TitledIcon extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['success', 'error', 'info', 'decline']),
    className: PropTypes.string,
    children: PropTypes.node,
  };

  static defaultProps = {
    type: 'success',
    children: null,
    className: '',
  };

  static icons = {
    success: SuccessIcon,
    error: ErrorIcon,
    info: InfoIcon,
    decline: CloseIcon,
  };

  renderIcon() {
    const { type } = this.props;

    const ActualIcon = TitledIcon.icons[type];

    return <ActualIcon className={styles.icon} />;
  }

  render() {
    const { type, children, className } = this.props;

    return (
      <div className={combineClasses(styles.titledIconContainer, className)}>
        <div className={styles.iconWrapper}>
          <div data-icon-type={type} className={combineClasses(styles.iconCircle, styles[type])}>
            {this.renderIcon()}
          </div>
        </div>
        <div className={styles.titledIconText}>{children}</div>
      </div>
    );
  }
}

export { TitledIcon as default };
