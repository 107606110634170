import datasource from './datasource';
import BaseActionCreator from '../BaseActionCreator';
import obligationsActionCreator from '../obligations';
import cookieUtils from '../../utils/cookie';
import actionTypes from '../../actiontypes';

class LoginActionCreator extends BaseActionCreator {
  async login(username, password, redirectUrl) {
    this.dispatch(actionTypes.LOGIN.BASIC_LOGIN_REQUEST);

    const { personalSettings, redirectUrl: serverRedirectUrl } = await datasource.login(
      username,
      password
    );

    cookieUtils.setRegionalSettingsCookies(personalSettings);

    try {
      const FOR_LOGIN = true;

      await obligationsActionCreator.checkObligation(redirectUrl, FOR_LOGIN);
    } catch (err) {
      return undefined;
    }

    this.dispatch(actionTypes.LOGIN.BASIC_LOGIN_SUCCESS);

    const url =
      serverRedirectUrl && redirectUrl && redirectUrl.startsWith('/')
        ? `${serverRedirectUrl}${redirectUrl}`
        : redirectUrl;

    return url;
  }
}

export default new LoginActionCreator();
