import meActionCreator from '../../actioncreators/me';
import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class UnwrappedLogoutAllSessions extends Component {
  static propTypes = {
    dispatchLogoutAllSessions: PropTypes.func.isRequired,

    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  componentDidMount() {
    const { dispatchLogoutAllSessions, location } = this.props;

    dispatchLogoutAllSessions(`/auth/login${location.search}`);
  }

  render() {
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchLogoutAllSessions(redirectLocation) {
      dispatch(meActionCreator.logoutAllSessions(redirectLocation));
    },
  };
}

const LogoutAllSessions = connect(null, mapDispatchToProps)(UnwrappedLogoutAllSessions);

export { UnwrappedLogoutAllSessions, LogoutAllSessions as default };
