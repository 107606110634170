import defaultResources from '../utils/translation/defaultResourcePackage.json';
import translationUtility from '../utils/translation/utility';
import React from 'react';

const defaultDictionary = translationUtility.generateDictionaryFromResourcePackge(defaultResources);

const TranslationsContext = React.createContext({
  dictionary: defaultDictionary,
  language: translationUtility.getCurrentLanguage(),
});

export default TranslationsContext;
