import buildFormReducer from 'reducers/builders/form';
import buildLoadDataReducer from 'reducers/builders/loadData';
import actionTypes, { actionTypeSections } from 'actiontypes/';
import { combineReducers } from 'redux';

const mainInitialState = {
  isCheckingAuth: false,
  companyName: 'Your company',
  associateFirstName: '',
  associateLastName: '',
  isPreparingToken: false,
  codeEmailVerificationToken: undefined,
};

const formInitialState = {
  firstName: {
    value: '',
    isValid: false,
    isValidating: false,
  },
  lastName: {
    value: '',
    isValid: false,
    isValidating: false,
  },
  email: {
    value: '',
    isValid: false,
    isValidating: false,
  },
  confirmEmail: {
    value: '',
    isValid: false,
    isValidating: false,
  },
  phoneCountryCode: {
    value: undefined,
  },
  phoneNumber: {
    value: '',
    isValid: true,
    isValidating: false,
  },
  termsAccepted: {
    value: false,
  },
};

const loadDataInitialState = {
  isLoadingData: false,
};

function mainReducer(state = mainInitialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.RESET_STATE:
      return { ...mainInitialState };
    case actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.SET_CLIENT_ID:
      return { ...state, clientId: actionPayload };
    case actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.SET_COMPANY_NAME:
      return { ...state, companyName: actionPayload };
    case actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.SET_ASSOCIATE_FIRST_NAME:
      return { ...state, associateFirstName: actionPayload };
    case actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.SET_ASSOCIATE_LAST_NAME:
      return { ...state, associateLastName: actionPayload };
    case actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.CHECK_AUTH_REQUEST:
      return { ...state, isCheckingAuth: true };
    case actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.CHECK_AUTH_SUCCESS:
    case actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.CHECK_AUTH_FAILURE:
      return { ...state, isCheckingAuth: false };
    case actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.SET_CODE_EMAIL_VERIFICATION_TOKEN:
      return { ...state, codeEmailVerificationToken: actionPayload };
    case actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.PREPARE_CODE_EMAIL_VERIFICATION_REQUEST:
      return { ...state, isPreparingToken: true };
    case actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.PREPARE_CODE_EMAIL_VERIFICATION_SUCCESS:
    case actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.PREPARE_CODE_EMAIL_VERIFICATION_FAILURE:
      return { ...state, isPreparingToken: false };
    default:
      return state;
  }
}

const personalInfoReducer = combineReducers({
  main: mainReducer,
  form: buildFormReducer(formInitialState, actionTypeSections.CREATE_ACCOUNT_PERSONAL_INFO),
  loadData: buildLoadDataReducer(
    loadDataInitialState,
    actionTypeSections.CREATE_ACCOUNT_PERSONAL_INFO
  ),
});

export { personalInfoReducer as default };
