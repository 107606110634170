import { parsePhoneNumberWithError } from 'libphonenumber-js';

const phoneNumberUtils = {
  getDisplayFormat(phoneNumber) {
    try {
      const parsed = parsePhoneNumberWithError(phoneNumber);

      return parsed.format('INTERNATIONAL');
    } catch (err) {
      return phoneNumber;
    }
  },

  getFormattedPhoneNumber: (phoneNumber, countryCode) => {
    const parsedPhoneNumber = parsePhoneNumberWithError(phoneNumber, countryCode);

    if (!parsedPhoneNumber.isValid()) {
      throw new Error();
    }
    return parsedPhoneNumber.format('E.164');
  },

  formatPhoneNumbersForBackEnd(phoneNumber, countryCode) {
    try {
      const formattedPhoneNumber = phoneNumberUtils.getFormattedPhoneNumber(
        phoneNumber,
        countryCode
      );

      return [{ number: formattedPhoneNumber }];
    } catch (e) {
      return [];
    }
  },

  getCountryCodeAndPhoneNumber(formattedPhoneNumber) {
    try {
      const parsedPhoneNumber = parsePhoneNumberWithError(formattedPhoneNumber);

      const { nationalNumber: number, country: countryCode } = parsedPhoneNumber;

      return { countryCode, number };
    } catch (e) {
      return {};
    }
  },

  formatPhoneNumberForIDM(phoneNumber) {
    const countryCodeAndPhoneNumber = this.getCountryCodeAndPhoneNumber(phoneNumber);

    return this.getFormattedPhoneNumber(
      countryCodeAndPhoneNumber.number,
      countryCodeAndPhoneNumber.countryCode
    );
  },
};

export default phoneNumberUtils;
