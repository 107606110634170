import buildFormReducer from './builders/form';
import actionTypes from '../actiontypes/startImpersonation';
import { actionTypeSections } from '../actiontypes';
import { combineReducers } from 'redux';

const initialState = {
  associateId: null,
  clientId: null,
  associateDisplayName: null,
  clientName: null,
  isLoadingData: false,
  isError: false,
  isSubmitting: false,
};

const impersonateFormInitialState = {
  reason: {
    value: undefined,
    isValid: false,
    isValidating: false,
  },
};

function mainReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.IMPERSONATION_LOAD_REQUEST:
      return {
        ...state,
        isLoadingData: true,
        isError: false,
      };
    case actionTypes.IMPERSONATION_LOAD_SUCCESS:
      return {
        ...state,
        associateId: payload.associateId,
        clientId: payload.clientId,
        associateDisplayName: payload.associateDisplayName,
        clientName: payload.clientName,
        isLoadingData: false,
        isError: false,
      };
    case actionTypes.IMPERSONATION_LOAD_FAILURE:
      return {
        ...state,
        isLoadingData: false,
        isError: true,
      };
    case actionTypes.IMPERSONATION_START_REQUEST:
      return {
        ...state,
        isSubmitting: true,
      };
    case actionTypes.IMPERSONATION_START_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case actionTypes.IMPERSONATION_START_FAILURE:
      return {
        ...state,
        isSubmitting: false,
      };
    default:
      return state;
  }
}

const startImpersonationReducer = combineReducers({
  main: mainReducer,
  form: buildFormReducer(
    impersonateFormInitialState,
    actionTypeSections.START_IMPERSONATION_REQUEST
  ),
});

export default startImpersonationReducer;
