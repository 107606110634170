import buildFormActionCreator from '../builders/form';
import applicationActionCreator from '../application';
import actionTypes, { actionTypeSections } from '../../actiontypes';
import http from '../../utils/http';
import getConstants from '../../utils/constants';

const { requestResult } = getConstants();

const requestActionCreator = {
  ...buildFormActionCreator(actionTypeSections.FORGOT_PASSWORD_REQUEST),

  resetMainState() {
    return {
      type: actionTypes.FORGOT_PASSWORD_REQUEST.RESET_STATE,
    };
  },

  resetState() {
    return (dispatch) => {
      dispatch(this.resetMainState());

      dispatch(this.resetFormState());
    };
  },

  requestResetPasswordRequest() {
    return {
      type: actionTypes.FORGOT_PASSWORD_REQUEST.REQUEST_RESET_PASSWORD_REQUEST,
    };
  },

  requestResetPasswordSuccess() {
    return {
      type: actionTypes.FORGOT_PASSWORD_REQUEST.REQUEST_RESET_PASSWORD_SUCCESS,
    };
  },

  requestResetPasswordFailure() {
    return {
      type: actionTypes.FORGOT_PASSWORD_REQUEST.REQUEST_RESET_PASSWORD_FAILURE,
    };
  },

  requestResetPassword(email) {
    return async (dispatch) => {
      try {
        dispatch(this.requestResetPasswordRequest());

        await http.requestPasswordReset(email);

        applicationActionCreator.changeState(requestResult.SUCCESS_FORGOT_PASS_REQUEST);

        dispatch(this.requestResetPasswordSuccess());
      } catch (error) {
        const isBadAttempt = error.code === http.supportedErrorCodes.TOO_MANY_ATTEMPTS;

        if (isBadAttempt) {
          applicationActionCreator.changeState(requestResult.FAILURE_RECOVER_ATTEMPT);
        } else {
          applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);
        }

        dispatch(this.requestResetPasswordFailure());
      }
    };
  },
};

export { requestActionCreator as default };
