import BaseError from './BaseError';
import { withNamespace } from '../../../components/elements/textDisplays/TranslatedText';
import React from 'react';

const TranslatedText = withNamespace('ERRORS.UNSUPPORTED');

const UnsupportedError = () => {
  const title = <TranslatedText i18nKey="TITLE" />;

  return (
    <BaseError title={title}>
      <TranslatedText i18nKey="MESSAGE" />
    </BaseError>
  );
};

export default UnsupportedError;
