import { noop } from '../../../../utils/constants';
import svgStore from '@lifion/ds-icons/dist/svg-defs.svg';
import iconsMap from '@lifion/ds-icons/dist/svg-icon-map';
import cx from 'classnames';
import { string, number, func } from 'prop-types';
import React from 'react';

const ICON_ID_MAP_VALUES = Object.values(iconsMap);
const FA_ICON_REGEX = /(fa-([^ ]*))|(adp-fa-([^ ]*))/;

const mapToSvgId = (icon) => {
  if (!icon || icon === 'no-icon') {
    return '';
  }

  const faIcon = icon.match(FA_ICON_REGEX);
  const isSvgIcon = icon.startsWith('icon-');

  let iconId;

  if (faIcon) {
    iconId = iconsMap[faIcon[0]];
  } else if (isSvgIcon && ICON_ID_MAP_VALUES.includes(icon)) {
    iconId = icon;
  }
  return iconId;
};

const DSIcon = ({
  color,
  className,
  'data-automation-id': dataAutomationId,
  iconId,
  ...otherProps
}) => {
  const iconStyle = {
    color,
  };
  const mappedIconId = mapToSvgId(iconId);

  if (!mappedIconId || !iconId) {
    const faIconProps = {
      className,
      icon: iconId,
    };

    return <GenericIcon {...faIconProps} />;
  }

  const svgClasses = cx('svg-icon', className, mappedIconId, {
    hidden: !mappedIconId,
  });

  const iconProps = {
    className: svgClasses,
    'data-automation-id': dataAutomationId || mappedIconId,
    'icon-in-metadata': iconId,
    style: iconStyle,
    ...otherProps,
  };

  return (
    <svg {...iconProps}>
      <use href={`${svgStore}#${mappedIconId}`} />
    </svg>
  );
};

DSIcon.propTypes = {
  className: string,
  color: string,
  'data-automation-id': string,
  height: number,
  iconId: string,
  onClick: func,
  width: number,
};

DSIcon.defaultProps = {
  className: '',
  color: '',
  'data-automation-id': '',
  height: 0,
  iconId: '',
  onClick: noop,
  width: 0,
};

DSIcon.mapToSvgId = mapToSvgId;

export default DSIcon;

class GenericIcon extends React.PureComponent {
  static propTypes = {
    icon: string,
    className: string,
  };

  static defaultProps = {
    icon: '',
    className: '',
  };

  render() {
    const { className, ...iconProps } = this.props;
    let { icon } = this.props;

    if (!icon || icon === 'no-icon') {
      return null;
    }
    if (!icon.startsWith('fa-')) {
      icon = `fa-${icon}`;
    }

    const iconClassName = cx(`fa ${icon}`, className || '');

    return <i {...iconProps} className={iconClassName} key={icon} />;
  }
}

/*
  Logic sourced from ui-web-blocks: 
  https://stash.es.ad.adp.com/projects/UI/repos/ui-web-blocks/browse/lib/components/private/svg-icon/svg-icon.jsx
*/
