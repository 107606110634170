import TranslationsContext from './context/TranslationsContext';
import DeviceUtilities from './utils/device';
import DeviceContext from './context/Device';
import UtilityHelper from './utils/helpers';
import React from 'react';
import PropTypes from 'prop-types';

import '../static/assets/css/index.scss';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      i18n: {
        dictionary: props.dictionary,
      },
      deviceInfo: {
        device: DeviceUtilities.getDeviceInfo(),
      },
    };
    window.addEventListener('resize', this.windowResized.bind(this));
  }

  componentDidMount() {
    const { onAppLoad } = this.props;

    onAppLoad();
  }

  windowResized() {
    this.setState({
      deviceInfo: {
        device: DeviceUtilities.getDeviceInfo(),
      },
    });
  }

  componentDidUpdate(prevProps) {
    const { dictionary, language } = this.props;

    if (prevProps.dictionary !== dictionary) {
      this.setState({
        i18n: {
          dictionary,
          language,
        },
      });

      UtilityHelper.setLangAttribute(language);
    }
  }

  renderApp() {
    const { children, dictionary } = this.props;

    if (!dictionary) {
      return null;
    }

    return children;
  }

  render() {
    const { i18n, deviceInfo } = this.state;

    return (
      <DeviceContext.Provider value={deviceInfo}>
        <TranslationsContext.Provider value={i18n}>{this.renderApp()}</TranslationsContext.Provider>
      </DeviceContext.Provider>
    );
  }
}

App.propTypes = {
  children: PropTypes.node.isRequired,
  dictionary: PropTypes.object,
  language: PropTypes.string.isRequired,
  onAppLoad: PropTypes.func.isRequired,
};

App.defaultProps = {
  dictionary: null,
};

export default App;
