import LocalizationPage from './LocalizationPage';
import actionCreator from '../../../actioncreators/accountSettings/localization';
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

const objectArray = PropTypes.arrayOf(PropTypes.object.isRequired);

const Localization = (props) => {
  const {
    languages,
    regions,
    timezones,
    defaultLanguage,
    defaultRegion,
    defaultTimezone,
    isLoadingData,
    dictionary,
    dispatchLoadData,
    dispatchUpdateRegionalSettings,
  } = props;

  return (
    <LocalizationPage
      languages={languages}
      regions={regions}
      timezones={timezones}
      isLoadingData={isLoadingData}
      dictionary={dictionary}
      defaultLanguage={defaultLanguage}
      defaultRegion={defaultRegion}
      defaultTimezone={defaultTimezone}
      onLoadData={dispatchLoadData}
      onUpdateRegionalSettings={dispatchUpdateRegionalSettings}
    />
  );
};

Localization.propTypes = {
  languages: objectArray.isRequired,
  regions: objectArray.isRequired,
  timezones: objectArray.isRequired,
  isLoadingData: PropTypes.bool.isRequired,

  dispatchLoadData: PropTypes.func.isRequired,
  dispatchUpdateRegionalSettings: PropTypes.func.isRequired,

  dictionary: PropTypes.object.isRequired,

  defaultRegion: PropTypes.string,
  defaultLanguage: PropTypes.string,
  defaultTimezone: PropTypes.string,
};

Localization.defaultProps = {
  defaultRegion: null,
  defaultLanguage: null,
  defaultTimezone: null,
};

function mapStateToProps(state) {
  return {
    languages: state.accountSettingsLocalization.main.languages,
    regions: state.accountSettingsLocalization.main.regions,
    timezones: state.accountSettingsLocalization.main.timezones,
    defaultLanguage: state.accountSettingsLocalization.main.currentLanguage,
    defaultRegion: state.accountSettingsLocalization.main.currentRegion,
    defaultTimezone: state.accountSettingsLocalization.main.currentTimezone,
    isLoadingData: state.accountSettingsLocalization.main.isLoadingData,
    dictionary: state.translation.main.dictionary,
  };
}
function mapDispatchToProps() {
  return {
    dispatchLoadData() {
      return actionCreator.loadData();
    },

    dispatchUpdateRegionalSettings(data) {
      return actionCreator.updateRegionalSettings(data);
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Localization);
