import namespaceTranslatedText from '../TranslatedText';
import { LeftCaret } from '../../components/elements/icons';
import { Link } from 'react-router-dom';
import React from 'react';

import './styles.scss';

const TranslatedText = namespaceTranslatedText('LEGAL_DOCUMENT');

function LegalDocument() {
  return (
    <section className="legal-doc">
      <Link to="/auth/login">
        <LeftCaret />
        <TranslatedText textKey="BACK_TO_LIFION" />
      </Link>
      <h1 className="header">TERMS AND CONDITIONS</h1>
      <p>
        <span className="font-bold">
          BY CLICKING OR TAPPING ON I AGREE BELOW AND/OR BY ACCESSING OR USING THE SITE IN ANY
          MANNER,
        </span>
        YOU ACKNOWLEDGE THAT YOU HAVE READ AND AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS.*If
        you do not agree to all of the Terms, you are not authorized to access or otherwise use the
        Site (as defined below).
      </p>
      <p>
        <span className="font-bold">1. SITE OVERVIEW.</span> The Site is available only to users who
        have a registered User ID and Password and are employees or plan participants of the
        employer indicated on the Site (the &quot;Employer&quot;). If you do not have a valid
        registered User Name and Password or are no longer an employee or plan participant of the
        Employer, you are not authorized to use or access the Site; however, in certain limited
        instances, the Employer may permit certain of its terminated employees to access the Site
        for very limited purposes. The Employer, through its service agreement(s) with ADP, LLC
        and/or ADP Canada Co, as applicable, (&quot; ADP&quot; ), is providing you with access to
        the information and services on the Site under these terms and conditions (&quot;Terms&quot;
        ). Please note that not all terms and conditions set out below may be applicable to you.
        Only the terms applicable to the products and services purchased by your Employer under its
        service agreement(s) with ADP apply. The &quot;Site&quot; shall refer to the site which
        makes available certain ADP Services (the &quot;Services&quot; ) to employee users, plan
        participant users and administrator users, as applicable, as authorized by the Employer.
      </p>
      <p>
        <span className="font-bold">A. EMPLOYEE/PLAN PARTICIPANT USERS.</span> On the Site, you will
        be able to access certain employment-related or plan-related information and documents made
        available by Employer and to participate in the &quot;Services&quot; . These Services may
        permit you to update certain personal information and to certain elections through the Site,
        in each case through the use of certain software proprietary to ADP and its licensors (the
        &quot;Software&quot;). You acknowledge and agree that it is your responsibility to submit
        information relating to the Services and to verify the accuracy and completeness of all such
        information. ADP does not review the accuracy or completeness of the information you submit.
      </p>
      <p>
        Calculations, estimates or modeling of events or circumstances, including projected or
        expected annual expenses, provided on this site are estimates only and are being provided by
        ADP for informational purposes only. Your actual costs and expenses may vary.
      </p>
      <p>
        You acknowledge and understand that ADP is merely making the Site available on behalf of the
        Employer and is not acting as an investment advisor, broker-dealer, insurance agent or
        intermediary or a financial or benefit planner or tax advisor. ADP is not providing any
        benefits or any information related thereto. The Employer is responsible for making
        available all benefits and information related thereto referenced or included on the Site.
      </p>
      <p>
        You acknowledge that ADP is not rendering legal advice or other professional services. While
        every effort is made to provide current information, the law changes regularly and laws may
        vary depending upon the country, state, province or municipality. The materials in the Site
        are made available for informational purposes only and are not a substitute for legal advice
        or your professional judgment. You should review applicable law in your jurisdiction and
        consult counsel for legal advice. You assume all responsibility for and risk arising from
        your use of and reliance upon the contents of the Site.
      </p>
      <p>
        A summary of the benefits provided under a workplace plan is usually contained in the
        plan&apos;s &quot;summary plan description,&quot; or &quot;SPD.&quot; Full details are
        provided in the actual governing plan documents, which govern the operation of the plan. In
        the event that the content of the Site is inconsistent with an SPD, the terms of the SPD
        will control (and if the terms of the SPD are inconsistent with the governing plan
        documents, the governing plan documents control). The information presented on the Site is
        not intended to create, nor is it to be construed to create, a contract between (1) the
        Employer or ADP, or their respective affiliates and (2) any one of the Employer&apos;s
        employees or other plan participants.
      </p>
      <p>
        You may from time to time provide suggestions, comments or other feedback
        (&quot;Feedback&quot;) to ADP with respect to the Software, Services and/or related
        documentation. All Feedback shall be provided voluntarily and shall not, absent a separate
        written agreement, create any confidentiality obligation for ADP. ADP may use, disclose,
        reproduce, license or otherwise distribute any Feedback it receives at its discretion.
      </p>
      <p>
        <span className="font-bold">B. ADMINISTRATOR USERS.</span> On behalf of the Employer, you
        will be able to access and input certain information relating to the Employer, the
        Employer&apos;s employees and/or the Employer&apos;s plan participants. The information that
        you input may be used and relied upon by ADP and by the Employer&apos;s employees and plan
        participants who participate in the Services. The Services may permit employees/plan
        participants of the Employer to make certain elections through the Site and to update
        certain personal information, in each case through the use of the Software. As an authorized
        administrator for the Employer, you acknowledge and agree, on behalf of the Employer, that
        it is the Employer&apos;s responsibility to submit instructions and information relating to
        the Services and to verify the accuracy and completeness of all such instructions and
        information submitted by the Employer and the Employer&apos;s employees and plan
        participants.
      </p>
      <p>
        As an authorized administrator for the Employer, you acknowledge and understand, on behalf
        of the Employer, that ADP is merely making the Site available and is not acting as an
        investment advisor, broker-dealer, insurance agent or intermediary or a financial or benefit
        planner. ADP is not providing any benefits or any information related thereto. As an
        authorized administrator for the Employer, you acknowledge, on behalf of the Employer, that
        the Employer is responsible for making available all benefits and information related
        thereto referenced or included on the Site.
      </p>
      <p>
        <span className="font-bold">* Note on Binding Effect:</span> As an authorized administrator
        for the Employer, your acceptance of these Terms will serve to bind BOTH you and the
        Employer and in accepting such terms, you represent that you are authorized to so bind the
        Employer. The portion of the Terms specifically applicable to &quot;Administrator
        Users&quot; will be binding upon you in your capacity as an authorized administrator and on
        the EMPLOYER, and the terms specifically applicable to &quot;Employee/Plan Participant
        Users&quot; or &quot;Employees&quot; or &quot;Participants&quot; will be binding upon you as
        an employee or a plan participant, and not upon your Employer. All other terms will be
        binding upon both you and the Employer.
      </p>
      <p>
        <span className="font-bold">2. REGISTRATION AND PASSWORD.</span> In order to access and use
        the Site, you must provide your current User Name and Password. You agree to take any and
        all actions necessary to maintain the privacy of your User ID and Password, including,
        without limitation, safeguarding your User ID and Password in a private and secure place,
        adopting special precautions when accessing the Site from a computer that is available for
        use by other users and exiting from the Site at the end of each session. In the event that
        you have reason to believe that a third party has access to your account, you should
        immediately change your Password and notify your security administrator or your HR
        Department. You may also notify us directly by using the ‘feedback’ feature within the
        application. You agree not to adopt any obscene, vulgar or profane User ID or password.
      </p>
      <p>
        <span className="font-bold">3. LAWS AND REGULATIONS.</span> Access to and use of the Site
        (including the Software and Services) is subject to all applicable international, federal,
        state, provincial and local laws and regulations. You agree to abide by these laws and not
        use the Site (including the Software and Services) in any way that violates such laws or
        regulations. You also agree that you will not use these products for the development,
        design, manufacture or production of nuclear missiles, or chemical or biological weapons.
        The provided Software is licensed for use in Canada and the United States only. Any export
        or use out of Canada or the U.S. is subject to you obtaining all required export licenses
        from the United States and Canada.
      </p>
      <p>
        <span className="font-bold">4. PRIVACY.</span> Any personal information provided to ADP
        through the Site is subject to the ADP privacy statement located at:
        <a href="https://privacy.adp.com/privacy.html">click or tap here</a>
      </p>
      <p>
        <span className="font-bold">5. SECURITY.</span> Data transmitted to and from the Site is
        encrypted using HTTPS and SSL for the user&apos;s protection. However, the security of
        information transmitted through the Internet can never be guaranteed. ADP is not responsible
        for any interception or interruption of any communications through the internet or for
        changes to or losses of data. User is responsible for maintaining the security of any
        password, user ID or other form of authentication involved in obtaining access to password
        protected or secure areas of ADP sites. In order to protect you and your data, ADP may
        suspend your use of the Site, without notice, pending an investigation, if any breach of
        security is suspected.
      </p>
      <p>
        <span className="font-bold">6. COPYRIGHTS AND TRADEMARKS.</span> The Software available on
        or through the Site is the sole and exclusive property of ADP, LLC or its licensors, and is
        protected by copyright, trademark, and other intellectual property laws. Users may not use
        the trademarks, logos and service marks (&quot;Marks&quot;) found on the Site for any
        purpose, including, but not limited to, use as &quot;hot links&quot; or meta tags in other
        pages or sites on the World Wide Web, without the written permission of ADP or such third
        party that may own the Mark. Please click or tap here for a list of ADP Marks.
      </p>
      <p>
        <span className="font-bold">7. RIGHTS TO USE SOFTWARE; NO TAMPERING.</span> The Software
        includes certain computer software that is subject to copyright protection by ADP and/or
        ADP&apos;s licensors. You agree and acknowledge that ADP and/or its licensors retain title
        to the Software and any copies thereof. You are not purchasing title to the Software or
        copies thereof. ADP reserves all rights not expressly granted to you. You may not copy,
        distribute, transmit, display, publish, sell, rent, license, create derivative works or
        otherwise use any Software available on or through the Site for commercial or public
        purposes. You are granted access to use the Software on a limited, revocable, non-exclusive,
        and non-transferable basis, subject to these Terms. You may not decompile, reverse engineer,
        disassemble, attempt to derive the source code of, modify, or create derivative works of the
        Software, any updates, or any part thereof (except as and only to the extent any foregoing
        restriction is prohibited by applicable law or to the extent as may be permitted by the
        licensing terms governing use of any open sourced components included with the Software).
        You agree not to modify, move, add to, delete or otherwise tamper with the Software
        contained in the Site. Any attempt to do so is a violation of the rights of ADP and its
        licensors.
      </p>
      <p>
        <span className="font-bold">8. USE OF SITE NOT TRANSFERABLE/ASSIGNABLE.</span> The right to
        use the Site (including the Software and Services) is personal to each user that is
        authorized to use the Site and is not transferable by such user by assignment, sublicense,
        or any other method to any other person or entity. Any attempt by a user to transfer such
        user&apos;s rights shall be void and shall constitute a breach of these Terms.
      </p>
      <p>
        <span className="font-bold">9. OFFENSIVE, DESTRUCTIVE OR INAPPROPRIATE USE.</span> You shall
        not exploit the Site or the Services in any unauthorized way whatsoever, including but not
        limited to, by trespass or burdening network capacity. You acknowledge and agree you are not
        permitted to use any personally identifiable information for personal use and you agree not
        to copy, transfer or distribute any personally identifiable information other than in
        compliance with all laws and as permitted in connection with the Services. You further agree
        not to use the Services in any manner to harass, abuse, stalk, threaten, defame or otherwise
        infringe or violate the rights of any other party, and that ADP is not in any way
        responsible for any such use by you, nor for any harassing, threatening, defamatory,
        offensive or illegal messages or transmissions that you may receive as a result of using any
        of the Services.
      </p>
      <p>
        <span className="font-bold">10. YOUR REPRESENTATIONS.</span> You represent, warrant and
        covenant that: (a) you are authorized to access and use the Site; (b) all instructions and
        information you enter into the Site related to the Services will be accurate and complete;
        (c) you will verify and remain solely responsible for all instructions and information
        entered into the Site related to the Services; (d) you will not upload, transmit, distribute
        or otherwise publish through the Site any materials that contain a virus, worm or other
        harmful component or that would cause the Site to malfunction;(e) you will not decompile,
        reverse engineer, disassemble or unlawfully use or reproduce any of the Software or other
        copyrighted or trademarked material, trade secrets or other proprietary information
        contained in the Site; and (f) if you are accessing ADP&apos;s background screening
        services, you shall not attempt to obtain any background report on yourself, associates, or
        any other person, except in the exercise of your official duties.
      </p>
      <p>
        <span className="font-bold">11. LINKS FROM THIRD PARTY SITES.</span> ADP prohibits
        unauthorized links to the Site and the framing of any information contained on the Site or
        any portion of the Site. ADP reserves the right to disable any unauthorized links or frames.
        ADP has no responsibility or liability for any material on other Web sites that may contain
        links to the Site.
      </p>
      <p>
        <span className="font-bold">
          12. LINKS TO THIRD PARTY SITES; OTHER CONTENT; THIRD PARTY SERVICES.
        </span>
        The Services may display, include or make available, content, data, information,
        applications or materials from third parties or provide links to certain third party web
        sites. Third party materials and links to other web sites are provided solely as a
        convenience to you. You acknowledge and agree that ADP is not responsible for examining or
        evaluating the content, accuracy, completeness, timeliness, validity, copyright compliance,
        legality, decency, quality or any other aspect of such third party materials or web sites.
        ADP does not warrant or endorse and does not assume and will not have any liability or
        responsibility to you or any other person for any third party services, third party
        materials or web sites, or for any other materials, products, or services of third parties.
        You also agree that any third party charges that are necessary to use the Site, such as
        internet charges and service provider charges, are your responsibility and not that of ADP.
        You understand that by using any of the third party services, you may encounter content that
        may be deemed offensive, indecent, or objectionable, which content may or may not be
        identified as having explicit language, and that the results of any search or entering of a
        particular URL may automatically and unintentionally generate links or references to
        objectionable material. Nevertheless, you agree to use such services at your sole risk and
        ADP shall not have any liability to you for content that may be found to be offensive,
        indecent, or objectionable. ADP DOES NOT ENDORSE, WARRANT, OR GUARANTEE ANY PRODUCT,
        INFORMATION OR SERVICE OFFERED BY A THIRD PARTY THROUGH THE SITE, AND WILL NOT BE A PARTY TO
        OR IN ANY WAY MONITOR ANY TRANSACTION BETWEEN ANY USER OF THE SITE AND ANY SUCH THIRD-PARTY
        PROVIDER.
      </p>
      <p>
        <span className="font-bold">
          13. NO WARRANTIES. ADP AND ITS LICENSORS MAKE NO WARRANTIES WHATSOEVER WITH RESPECT TO THE
          SITE AND THE LICENSED APPLICATION (INCLUDING THE SOFTWARE AND SERVICES). YOU ACKNOWLEDGE
          THAT YOUR USE OF THE SITE AND THE LICENSED APPLICATION (INCLUDING THE SOFTWARE AND
          SERVICES) IS AT YOUR OWN RISK. THE SITE AND LICENSED APPLICATION (INCLUDING THE SOFTWARE
          AND SERVICES) IS PROVIDED SOLELY ON AN &quot;AS-IS&quot; AND &quot; AS-AVAILABLE &quot;
          BASIS. ADP AND ITS LICENSORS MAKE, AND YOU RECEIVE, NO WARRANTIES, EXPRESS OR IMPLIED, IN
          FACT OR BY OPERATION OF LAW, STATUTORY OR OTHERWISE. ADP AND ITS LICENSORS EXPRESSLY
          DISCLAIM ANY IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A
          PARTICULAR PURPOSE, AND/OR NONINFRINGEMENT. NEITHER ADP NOR ITS LICENSORS WARRANTS THAT
          THE OPERATION OF THE SITE AND THE LICENSED APPLICATION (INCLUDING THE SOFTWARE AND
          SERVICES) SHALL BE UNINTERRUPTED, FREE OF VIRUSES, WORMS, TROJAN HORSES OR ANY OTHER
          MALICIOUS CODE, ERROR FREE OR THAT IT WILL FUNCTION OR OPERATE IN CONJUNCTION WITH ANY
          OTHER PRODUCT. ADP AND ITS LICENSORS FURTHER DISCLAIM ANY WARRANTY THAT THE RESULTS
          OBTAINED THROUGH THE USE OF THE SITE AND LICENSED APPLICATION (INCLUDING THE SOFTWARE OR
          SERVICES) WILL MEET YOUR NEEDS.
        </span>
      </p>
      <p>
        <span className="font-bold">
          14. LIMITATION OF LIABILITY. TO THE EXTENT PERMITTED BY LAW, UNDER NO CIRCUMSTANCES SHALL
          ADP OR ITS LICENSORS BE LIABLE FOR ANY DAMAGES SUFFERED BY YOU, INCLUDING, WITHOUT
          LIMITATION, ANY DIRECT, INCIDENTAL, INDIRECT, SPECIAL, EXEMPLERY OR CONSEQUENTIAL DAMAGES
          (INCLUDING, WITHOUT LIMITATION, (I) LOSSES ARISING FROM THE FAILURE TO RECEIVE ANY
          BENEFITS, (II) ANY TRADING LOSSES, (III) LOSSES ARISING FROM THE USE OR THE INABILITY TO
          USE THE SITE AND LICENSED APPLICATION (INCLUDING THE SOFTWARE AND/OR THE SERVICES), (IV)
          LOSSES ARISING FROM UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, OR
          (V) LOSS OF INFORMATION, PROGRAMS OR OTHER DATA THAT RESULT FROM ACCESS TO, USE OF, OR
          INABILITY TO USE THE SITE AND LICENSED APPLICATION (INCLUDING THE SOFTWARE AND/OR
          SERVICES) OR DUE TO ANY BREACH OF SECURITY ASSOCIATED WITH THE TRANSMISSION OF INFORMATION
          THROUGH THE INTERNET. THIS LIMITATION OF LIABILITY SHALL BE IN FULL FORCE REGARDLESS OF
          WHETHER SUCH LIABILITY IS BASED ON BREACH OF CONTRACT, TORT, STRICT LIABILITY OR
          OTHERWISE, AND EVEN IF ADP OR ITS LICENSORS HAD PREVIOUSLY BEEN ADVISED OF THE POSSIBILITY
          OF SUCH DAMAGES.
        </span>
      </p>
      <p>
        <span className="font-bold">15. ACCESS TO PASSWORD PROTECTED/SECURE AREAS.</span> Access to
        and use of password protected and/or secure areas of the Site is restricted to authorized
        users only. Unauthorized access to such areas is prohibited and may lead to criminal
        prosecution.
      </p>
      <p>
        <span className="font-bold">16. TERMINATION.</span> The Site and/or access to the Software
        and Services may be discontinued, temporarily or permanently, by ADP at any time without
        notice to users. ADP shall not be liable to any employee or plan participant of Employer or
        to any third party for any suspension or discontinuance of the Site, the Software or the
        Services. Your rights to access the Site and the Software shall terminate if you cease to be
        an employee or plan participant of the Employer, except as contemplated under Section 1 of
        these Terms. Upon termination of your access to the Site or Software, you shall cease use of
        the Site or Software.
      </p>
      <p>
        <span className="font-bold">17. CHANGES TO SERVICE AND TERMS.</span> ADP is not responsible
        for any technical inaccuracies or typographical errors that may be contained in these Terms.
        ADP reserves the right to changes these Terms in its sole discretion at any time. Any such
        changes to the Terms will become effective immediately upon being posted on the Site. You
        will be able to view the changed Terms by clicking or tapping on the link for Terms and
        Conditions after you login and your continued use of the Site shall constitute acceptance of
        such changed terms. The Site and/or the Software and any Services may be modified,
        temporarily or permanently, by ADP at any time without notice to you. You agree that ADP
        shall not be liable to you or to any third party for any modification of the Site and/or the
        Software or the Services.
      </p>
      <p>
        <span className="font-bold">18. TRANSMISSIONS OF DATA.</span> You acknowledge that
        transmissions to and from the Site could be read or intercepted by others. You acknowledge
        that by submitting communications through the Site, no confidential, fiduciary,
        contractually implied or other relationship is created between you and ADP other than
        pursuant to these Terms. The Site is currently hosted in the U.S. however, ADP reserves the
        right to utilize additional regional hosting centers outside the U.S. Data that you transmit
        through the Site may be accessed and/or processed by ADP, its affiliates or its trusted
        third party vendors within the U.S. or outside the U.S. in connection with the provision of
        Services by ADP to the Employer. As a result, your data may be subject to the laws of such
        jurisdictions, and may be accessible to the courts and law enforcement authorities in the
        U.S. or such other jurisdictions. By your use of the Site and your transmitting your
        personal information and other data, you consent to the use of your personal information and
        other data as required by ADP to provide these Services in accordance with the terms of ADP
        &apos;s applicable service agreement with the Employer.
      </p>
      <p>
        <span className="font-bold">19. CONSENT TO USE OF DATA.</span> You agree that ADP may
        collect and use technical data and related information, including but not limited to
        technical information about your Device, system and application software, and peripherals,
        that is gathered periodically to facilitate the provision of software updates, product
        support and other services to you (if any) related to the Software. ADP may use this
        information, as long as it is in a form that does not personally identify you, to improve
        its products or to provide services or technologies to you or the Employer. In some
        circumstances, ADP may share information you voluntarily provide us, such as your email
        address, with trustworthy service providers.
      </p>
      <p>
        <span className="font-bold">
          20. PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT.
        </span>{' '}
        Pursuant to the Digital Millennium Copyright Act, ADP has registered an agent with the U.S.
        Copyright Office. Notices of claimed copyright infringement on the Site should be directed
        to: Automatic Data Processing, Inc., One ADP Boulevard, MS 325, Roseland, NJ 07068-1728,
        Attn: IP Counsel. NOTE: THIS ADDRESS IS FOR COPYRIGHT INFRINGEMENT ISSUES ONLY. ALL OTHER
        COMMUNICATIONS REGARDING YOUR PLAN OR YOUR EMPLOYMENT SHOULD BE DIRECTED TO YOUR PLAN
        ADMINISTRATOR OR YOUR EMPLOYER, AS APPLICABLE.
      </p>
      <p>
        <span className="font-bold">21. GOVERNING LAW.</span> THESE TERMS SHALL BE GOVERNED BY AND
        CONSTRUED ACCORDING TO THE LAWS OF THE STATE OF NEW JERSEY, EXCLUDING ITS CONFLICTS OF LAWS
        RULES, REGARDLESS OF WHERE ANY ACTION MAY BE BROUGHT. YOU AGREE TO SUBMIT TO THE EXCLUSIVE
        JURISDICTION OF THE STATE AND FEDERAL COURTS OF THE STATE OF NEW JERSEY. NOTWITHSTANDING
        ANYTHING TO THE CONTRARY ABOVE, IF YOU AND YOUR EMPLOYER ARE LOCATED IN CANADA, THESE TERMS
        SHALL BE GOVERNED BY AND CONSTRUED IN ACCORDANCE WITH THE LAWS OF THE PROVINCE OF ONTARIO,
        AND THE FEDERAL LAWS OF CANADA, APPLICABLE THEREIN, EXCLUDING ANY CONFLICTS OF LAWS RULES,
        REGARDLESS OF WHERE ANY ACTION MAY BE BROUGHT. YOU AGREE TO SUBMIT TO THE EXCLUSIVE
        JURISDICTION OF THE COURTS OF THE PROVINCE OF ONTARIO.
      </p>
      <p>
        <span className="font-bold">22. THIRD PARTY BENEFICIARIES.</span> THESE TERMS ADP&apos;s
        licensors and vendors shall be considered third party beneficiaries of these terms for
        purposes of Sections 13 and 14.
      </p>
    </section>
  );
}

export { LegalDocument as default };
