/*eslint-disable class-methods-use-this*/
import AuthenticationMethodListing from './AuthenticationMethodListing';
import { noop } from '../../../../../utils/constants';
import Translator from '../../../../../utils/translation/Translator';
import { SecondaryButton } from '@idm/ui-components';
import PropTypes from 'prop-types';
import React from 'react';

import './index.scss';

const contactInfoDictionary = new Translator('ACCOUNT_SETTINGS.CONTACT_INFO');

class ContactInfoView extends React.Component {
  static propTypes = {
    i18nContext: PropTypes.shape({ getTextForKey: PropTypes.func.isRequired }).isRequired,
    dictionary: PropTypes.shape().isRequired,
    authenticationMethods: PropTypes.arrayOf(PropTypes.shape),
    className: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    hideActionButtons: PropTypes.bool,
    onDelete: PropTypes.func,
    onAddContact: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
    authenticationMethods: null,
    hideActionButtons: false,
    onDelete: noop,
  };

  getNoAuthenticationMethodsView() {
    const { i18nContext } = this.props;
    const message = i18nContext.getTextForKey('EMPTY_CONTACT_INFO_MESSAGE');

    return <div data-meta-id="empty-methods">{message}</div>;
  }

  getLoadingView() {
    return <div data-meta-id="methods-loading">Loading contact methods...</div>;
  }

  getErrorView() {
    const { i18nContext } = this.props;
    const errorMessage = i18nContext.getTextForKey('ERROR_LOADING_CONTACT_INFO');

    return <div data-meta-id="methods-load-error">{errorMessage}</div>;
  }

  renderActions() {
    const { onAddContact, dictionary, hideActionButtons } = this.props;

    if (hideActionButtons) {
      return null;
    }

    return (
      <div className="actions-bar">
        <SecondaryButton
          data-meta-id="add-contact-button"
          onClick={onAddContact}
          className="add-contact-button"
        >
          {contactInfoDictionary.get('EDIT_CONTACT', dictionary)}
        </SecondaryButton>
      </div>
    );
  }

  renderListOfMethods(authenticationMethods) {
    const { onDelete, i18nContext } = this.props;

    if (!authenticationMethods.length) {
      return this.getNoAuthenticationMethodsView();
    }

    return authenticationMethods.map((method) => (
      <AuthenticationMethodListing
        className="method-listing"
        key={method.id}
        i18nContext={i18nContext}
        onDeleteClick={onDelete}
        authenticationMethod={method}
      />
    ));
  }

  renderFromMethods(authenticationMethods) {
    return [this.renderListOfMethods(authenticationMethods), this.renderActions()];
  }

  renderAuthenticationMethods(authenticationMethods) {
    return !authenticationMethods
      ? this.getErrorView()
      : this.renderFromMethods(authenticationMethods);
  }

  render() {
    const { authenticationMethods, isLoading, className } = this.props;

    return (
      <section className={`contact-info-view ${className}`}>
        {isLoading
          ? this.getLoadingView()
          : this.renderAuthenticationMethods(authenticationMethods)}
      </section>
    );
  }
}

export default ContactInfoView;
