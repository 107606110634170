import messagesActionCreator from '../../actioncreators/messages';
import obligationsActionCreator from '../../actioncreators/obligations';
import UtilityHelper from '../../utils/helpers';
import { staticConstants } from '../../utils/constants';
import { AuthenticationFlow } from '@idm/authentication-flows';
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const DEFAULT_AUTH_LEVEL = 3;

const { LEVEL_OF_ASSURANCE_MFA, toastMessage } = staticConstants;

function mapStateToProps(state, ownProps) {
  return {
    isAuthenticatedByNexo: state.me.main.isAuthenticatedByNexo,
    language: state.accountSettingsLocalization.main.language,
    levelOfAssurance: state.me.main.levelOfAssurance,
    requiredLevel: UtilityHelper.parseQueryString(ownProps.location.search).requiredLevel,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    dispatchCodeSentToastMessage() {
      const toastMessageAction = messagesActionCreator.addToastMessage({
        message: 'CODE_SENT',
        type: toastMessage.SUCCESS_TOAST_TYPE,
        id: UtilityHelper.generateUniqueId(),
        translationNamespace: toastMessage.TOAST_I18N_NAMESPACE,
      });

      dispatch(toastMessageAction);
    },

    dispatchErrorToastMessage() {
      const toastMessageAction = messagesActionCreator.addToastMessage({
        message: 'STEP_UP_ERROR',
        type: toastMessage.FAILURE_TOAST_TYPE,
        id: UtilityHelper.generateUniqueId(),
        translationNamespace: toastMessage.TOAST_I18N_NAMESPACE,
      });

      dispatch(toastMessageAction);
    },

    async dispatchStepUpComplete() {
      const queryString = ownProps.location.search;
      const { redirectUrl } = UtilityHelper.parseQueryString(queryString);

      obligationsActionCreator.clearObligations();
      const defaultSuccessUrl = staticConstants.DEFAULT_LOGIN_LANDING_PAGE;
      const successLocation = redirectUrl || defaultSuccessUrl;

      obligationsActionCreator.changeUrl(successLocation);
    },

    async dispatchCancel() {
      const queryString = ownProps.location.search;
      const { cancelUrl } = UtilityHelper.parseQueryString(queryString);
      const defaultCancelUrl = '/auth/logout';
      const cancelLocation = cancelUrl || defaultCancelUrl;

      await obligationsActionCreator.clearObligations();
      obligationsActionCreator.changeUrl(cancelLocation);
    },
  };
}

const MfaStepUpContainer = (props) => {
  const {
    dispatchErrorToastMessage,
    dispatchCodeSentToastMessage,
    dispatchCancel,
    requiredLevel,
    dispatchStepUpComplete,
    language,
    isAuthenticatedByNexo,
    levelOfAssurance,
  } = props;

  if (!isAuthenticatedByNexo && levelOfAssurance === LEVEL_OF_ASSURANCE_MFA) {
    dispatchStepUpComplete();
    return null;
  }

  return (
    <div className="pre-login-mfa">
      <AuthenticationFlow
        onError={dispatchErrorToastMessage}
        onCodeSent={dispatchCodeSentToastMessage}
        onCancel={dispatchCancel}
        requiredAuthLevel={requiredLevel}
        onSuccess={dispatchStepUpComplete}
        language={language}
      />
    </div>
  );
};

MfaStepUpContainer.defaultProps = {
  requiredLevel: DEFAULT_AUTH_LEVEL,
  language: null,
};

MfaStepUpContainer.propTypes = {
  requiredLevel: PropTypes.number,
  levelOfAssurance: PropTypes.number.isRequired,
  isAuthenticatedByNexo: PropTypes.bool.isRequired,
  dispatchCodeSentToastMessage: PropTypes.func.isRequired,
  dispatchErrorToastMessage: PropTypes.func.isRequired,
  dispatchStepUpComplete: PropTypes.func.isRequired,
  dispatchCancel: PropTypes.func.isRequired,
  language: null,
};

const MfaStepUpPage = connect(mapStateToProps, mapDispatchToProps)(MfaStepUpContainer);

export default MfaStepUpPage;
