import React from 'react';
import PropTypes from 'prop-types';
import { FormTextInput as TextInput } from '@idm/ui-components';

class EmailFormInput extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: null,
  };

  render() {
    const { className, ...otherProps } = this.props;

    return (
      <div className={className}>
        <TextInput
          type="text"
          className="input-large"
          autoFocus={true}
          validateOnChange={true}
          data-rule-required={true}
          data-rule-email={true}
          {...otherProps}
        />
      </div>
    );
  }
}

export default EmailFormInput;
