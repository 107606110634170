import datasource from './datasource';
import messagesActionCreator from '../../messages';
import applicationActionCreator from '../../application';
import getConstants, { staticConstants, errorTypes } from '../../../utils/constants';
import buildFormActionCreator from '../../builders/form';
import buildLoadDataActionCreator from '../../builders/loadData';
import actionTypes, { actionTypeSections } from '../../../actiontypes';
import UtilityHelper from '../../../utils/helpers';
import http from '../../../utils/http';
import urls from '../../../utils/urls';

const { requestResult } = getConstants();
const { tokenKinds } = staticConstants;

const personalInfoActionCreator = {
  ...buildFormActionCreator(actionTypeSections.CREATE_ACCOUNT_PERSONAL_INFO),

  ...buildLoadDataActionCreator(actionTypeSections.CREATE_ACCOUNT_PERSONAL_INFO),

  resetMainState() {
    return {
      type: actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.RESET_STATE,
    };
  },

  resetState() {
    return (dispatch) => {
      dispatch(this.resetMainState());

      dispatch(this.resetFormState());

      dispatch(this.resetLoadDataState());
    };
  },

  setClientId(payload) {
    return {
      type: actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.SET_CLIENT_ID,
      payload,
    };
  },
  setCompanyName(payload) {
    return {
      type: actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.SET_COMPANY_NAME,
      payload,
    };
  },

  setAssociateFirstName(payload) {
    return {
      type: actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.SET_ASSOCIATE_FIRST_NAME,
      payload,
    };
  },

  setAssociateLastName(payload) {
    return {
      type: actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.SET_ASSOCIATE_LAST_NAME,
      payload,
    };
  },

  createVerifyingTokenAction(token) {
    return {
      type: actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.VERIFY_TOKEN_REQUEST,
      payload: token,
    };
  },

  createTokenSuccessfullyVerifiedAction(token) {
    return {
      type: actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.VERIFY_TOKEN_SUCCESS,
      payload: token,
    };
  },

  createTokenVerificationFailureAction(token, error) {
    return {
      type: actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.VERIFY_TOKEN_FAILURE,
      payload: { token, error },
    };
  },

  checkTokenCode(code, token) {
    return async (dispatch) => {
      try {
        dispatch(this.createVerifyingTokenAction(token));

        await datasource.validateToken(tokenKinds.REGISTRATION_EMAIL_VERIFICATION, token, { code });

        dispatch(this.createTokenSuccessfullyVerifiedAction(token));
      } catch (e) {
        dispatch(this.createTokenVerificationFailureAction(token, e));

        throw e;
      }
    };
  },

  loadAndVerifyToken(token, isSSO) {
    return async (dispatch) => {
      try {
        dispatch(this.loadDataRequest());

        const tokenKind = isSSO ? tokenKinds.ASSOCIATE_SSO : tokenKinds.ASSOCIATE;
        const res = await http.loadAndVerify(tokenKind, token, true);

        if (res.data.extra_information) {
          const {
            client_name: companyName,
            first_name: associateFirstName,
            last_name: associateLastName,
          } = res.data.extra_information;
          const { clientId } = res.data.token_blob;

          if (clientId) {
            dispatch(this.setClientId(clientId));
          }
          if (companyName) {
            dispatch(this.setCompanyName(companyName));
          }
          if (associateFirstName) {
            dispatch(this.setAssociateFirstName(associateFirstName));
          }
          if (associateLastName) {
            dispatch(this.setAssociateLastName(associateLastName));
          }
        }
        dispatch(this.loadDataSuccess());
      } catch (e) {
        dispatch(this.loadDataFailure());

        if (e.code === http.supportedErrorCodes.TOKEN_LOAD_VERIFY_FAILURE) {
          applicationActionCreator.changeUrl(urls.ERROR, errorTypes.BAD_REGISTRATION_TOKEN);
          return;
        }

        throw e;
      }
    };
  },

  checkAuthenticationRequest() {
    return {
      type: actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.CHECK_AUTH_REQUEST,
    };
  },

  checkAuthenticationSuccess() {
    return {
      type: actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.CHECK_AUTH_SUCCESS,
    };
  },

  checkAuthenticationFailure() {
    return {
      type: actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.CHECK_AUTH_FAILURE,
    };
  },

  checkAuthentication(token, isSSO) {
    return async (dispatch) => {
      dispatch(this.checkAuthenticationRequest());

      try {
        await http.checkAuthentication();

        if (!isSSO) {
          applicationActionCreator.changeUrl(`/auth/user-confirmation?token=${token}`);
        }

        dispatch(this.checkAuthenticationSuccess());
      } catch (e) {
        dispatch(this.checkAuthenticationFailure());

        if (
          e.code === http.supportedErrorCodes.INSUFFICIENT_PERMISSIONS ||
          e.code === http.supportedErrorCodes.MISSING_SESSION_COOKIE
        ) {
          return;
        }

        throw e;
      }
    };
  },

  checkAuthenticationAndVerifyToken(token, isSSO) {
    return async (dispatch) => {
      try {
        await dispatch(this.loadAndVerifyToken(token, isSSO));
        await dispatch(this.checkAuthentication(token, isSSO));
      } catch (e) {
        applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);
      }
    };
  },

  setCodeEmailVerification(payload) {
    return {
      type: actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.SET_CODE_EMAIL_VERIFICATION_TOKEN,
      payload,
    };
  },

  prepareCodeEmailVerificationRequest() {
    return {
      type: actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.PREPARE_CODE_EMAIL_VERIFICATION_REQUEST,
    };
  },

  prepareCodeEmailVerificationSuccess() {
    return {
      type: actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.PREPARE_CODE_EMAIL_VERIFICATION_SUCCESS,
    };
  },

  prepareCodeEmailVerificationFailure() {
    return {
      type: actionTypes.CREATE_ACCOUNT_PERSONAL_INFO.PREPARE_CODE_EMAIL_VERIFICATION_FAILURE,
    };
  },

  prepareCodeEmailVerification(email, associateToken) {
    return async (dispatch) => {
      try {
        dispatch(this.prepareCodeEmailVerificationRequest());

        //TODO:move to datasource
        const httpRequest = { emailAddress: email, associateToken };
        const { data } = await http.prepareCodeEmailVerification(httpRequest);

        const emailVerificationToken = data.token;
        //end move

        dispatch(this.setCodeEmailVerification(emailVerificationToken));

        dispatch(this.prepareCodeEmailVerificationSuccess());
      } catch (e) {
        applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);
        throw e;
      }
    };
  },

  sendResetPasswordEmail(identifier) {
    return async (dispatch) => {
      try {
        await http.requestPasswordReset(identifier);

        dispatch(
          messagesActionCreator.addToastMessage({
            message: 'EMAIL_SENT',
            type: 'success',
            id: UtilityHelper.generateUniqueId(),
            translationNamespace: 'TOAST_MESSAGE',
          })
        );
      } catch (err) {
        dispatch(
          messagesActionCreator.addToastMessage({
            message: 'EMAIL_SENT_ERROR',
            type: 'error',
            id: UtilityHelper.generateUniqueId(),
            translationNamespace: 'TOAST_MESSAGE',
          })
        );
      }
    };
  },
};

export { personalInfoActionCreator as default };
