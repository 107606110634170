import datasource from './datasource';
import actionTypes from '../../../actiontypes/accountSettings/contactInfo';
import messagesActionCreator from '../../messages';
import http from '../../../utils/http';
import { staticConstants } from '../../../utils/constants';
import utils from '../../../utils/helpers';

const toastMessageConstants = staticConstants.toastMessage;

const i18nKeys = {
  DELETE_FAILURE: 'FAILED_TO_DELETE_CONTACT_METHOD',
  DELETE_SUCCESS: 'DELETED_CONTACT_METHOD',
  GET_FEATURE_FLAG_FAILURE: 'FAILED_TO_GET_FEATURE_FLAG',
};

const getActionForTypeAndData = (type, payload) => ({ type, payload });

const createToastMessage = (type, messageKey) => ({
  message: messageKey,
  type,
  id: utils.generateUniqueId(),
  translationNamespace: toastMessageConstants.TOAST_I18N_NAMESPACE,
});

const actionBuilder = {
  loadSuccessAction: (data) => getActionForTypeAndData(actionTypes.LOAD.SUCCESS, data),
  loadRequestAction: () => getActionForTypeAndData(actionTypes.LOAD.REQUEST),
  loadFailureAction: (error) => getActionForTypeAndData(actionTypes.LOAD.FAILURE, error),
  deleteRequestAction: () => getActionForTypeAndData(actionTypes.DELETE.REQUEST),
  deleteSuccessAction: () => getActionForTypeAndData(actionTypes.DELETE.SUCCESS),
  deleteFailureAction: (error) => getActionForTypeAndData(actionTypes.DELETE.FAILURE, error),
  featureEnabledSuccessAction: (data) =>
    getActionForTypeAndData(actionTypes.GET_ADP_EMAIL_REQUIRED_FLAG_STATUS.SUCCESS, data),
  featureEnabledFailureAction: (error) =>
    getActionForTypeAndData(actionTypes.GET_ADP_EMAIL_REQUIRED_FLAG_STATUS.FAILURE, error),
  requireAdpEmailEnableSuccessAction: (data) =>
    getActionForTypeAndData(actionTypes.GET_ONLY_ALLOW_ADP_EMAIL_FLAG_STATUS.SUCCESS, data),
  requireAdpEmailEnableFailureAction: (error) =>
    getActionForTypeAndData(actionTypes.GET_ONLY_ALLOW_ADP_EMAIL_FLAG_STATUS.FAILURE, error),
  createSuccessToastMessage: (messageKey) =>
    createToastMessage(toastMessageConstants.SUCCESS_TOAST_TYPE, messageKey),
  createFailureToastMessage: (messageKey) =>
    createToastMessage(toastMessageConstants.FAILURE_TOAST_TYPE, messageKey),
};

const deleteContactMethodByType = async (method) => {
  if (method.type === 'PHONE') {
    await datasource.deletePhoneContactMethod(method.phoneNumber);
  } else {
    await datasource.deleteEmailContactMethod(method.emailAddress);
  }
};

const contactInfoActionCreator = {
  loadContactInfoMethods() {
    return async (dispatch) => {
      try {
        const contactInfoLoadingAction = actionBuilder.loadRequestAction();

        dispatch(contactInfoLoadingAction);

        const actionData = await datasource.loadContactInfo();

        dispatch(actionBuilder.loadSuccessAction(actionData));
      } catch (error) {
        const errorAction = actionBuilder.loadFailureAction(error);

        dispatch(errorAction);
      }
    };
  },

  getRequireAdpEmailFlagStatus() {
    return async (dispatch) => {
      try {
        const featureEnabledStatus = await datasource.getFeatureEnabledStatus(
          staticConstants.featureFlags.REQUIRE_ADP_EMAIL_FOR_ICC_ACCESS
        );

        dispatch(actionBuilder.featureEnabledSuccessAction(featureEnabledStatus));
      } catch (error) {
        const errorAction = actionBuilder.featureEnabledFailureAction(error);
        const toastActionData = actionBuilder.createFailureToastMessage(
          i18nKeys.GET_FEATURE_FLAG_FAILURE
        );
        const toastAction = messagesActionCreator.addToastMessage(toastActionData);

        dispatch(toastAction);
        dispatch(errorAction);
      }
    };
  },

  getOnlyAllowAdpEmails() {
    return async (dispatch) => {
      try {
        const featureEnabledStatus = await datasource.getFeatureEnabledStatus(
          staticConstants.featureFlags.REQUIRE_ONLY_ALLOW_ADP_EMAIL
        );
        const { data: myClientInfoResData } = await http.getMyClientInfo();
        const { clientId } = myClientInfoResData;

        dispatch(
          actionBuilder.requireAdpEmailEnableSuccessAction({ featureEnabledStatus, clientId })
        );
      } catch (error) {
        const errorAction = actionBuilder.requireAdpEmailEnableFailureAction(error);
        const toastActionData = actionBuilder.createFailureToastMessage(
          i18nKeys.GET_FEATURE_FLAG_FAILURE
        );
        const toastAction = messagesActionCreator.addToastMessage(toastActionData);

        dispatch(toastAction);
        dispatch(errorAction);
      }
    };
  },

  deleteContactMethod(contactMethod) {
    return async (dispatch) => {
      try {
        dispatch(actionBuilder.deleteRequestAction());
        await deleteContactMethodByType(contactMethod);
        const toastActionData = actionBuilder.createSuccessToastMessage(i18nKeys.DELETE_SUCCESS);
        const toastAction = messagesActionCreator.addToastMessage(toastActionData);

        dispatch(toastAction);
        dispatch(actionBuilder.deleteSuccessAction());
      } catch (error) {
        const errorAction = actionBuilder.deleteFailureAction(error);
        const toastActionData = actionBuilder.createFailureToastMessage(i18nKeys.DELETE_FAILURE);
        const toastAction = messagesActionCreator.addToastMessage(toastActionData);

        dispatch(toastAction);
        dispatch(errorAction);
      }
    };
  },
};

export default contactInfoActionCreator;
