import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReactLink } from 'react-router-dom';

const Link = (props) => {
  const { to, onChangeUrl, children } = props;

  const changeURL = () => {
    onChangeUrl(to);
  };

  return (
    <ReactLink to={to} onClick={changeURL} {...props}>
      {children}
    </ReactLink>
  );
};

Link.propTypes = {
  onChangeUrl: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
  children: PropTypes.object,
};

Link.defaultProps = { children: null };

export default Link;
