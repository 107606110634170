import domainUtilities from '../utils/domainUtilities';
import helpers from '../utils/helpers';
import { noop } from '../utils/constants';
import meActionCreator from '../actioncreators/me';
import React from 'react';
import PropTypes from 'prop-types';

export default class Root extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      checkFinished: false,
    };
  }

  async componentDidMount() {
    //if it is flowbrite check for user login. if error, then user is not logged in, take user to adp.com
    if (domainUtilities.isFlowbriteDomain(helpers.getWindowLocation())) {
      try {
        await meActionCreator.assertAuthenticated(noop); // dont dispatch events
      } catch (e) {
        const url = domainUtilities.getCorrespondingAdpDomainFromFlowbrite(
          helpers.getWindowLocation()
        );

        helpers.redirectTo(url);
      }
    }
    this.setState({ checkFinished: true });
  }

  render() {
    const { children } = this.props;
    const { checkFinished } = this.state;

    if (checkFinished) {
      return children;
    }
    return null;
  }
}
