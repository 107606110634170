import styles from './style.module.scss';
import { noop } from '../../utils/constants';
import { LiteralText, LinkButton } from '../../components';
import TranslatedText from '../../components/elements/textDisplays/TranslatedText';
import { LeftCaret } from '../../components/elements/icons';
import Avatar from '../../components/Avatar';
import AdpLogo from '../../components/elements/icons/svg/AdpLogo';
import React from 'react';
import PropTypes from 'prop-types';
import combineClasses from 'classnames';
import { connect } from 'react-redux';

const Header = (props) => {
  const {
    className,
    showBackButton,
    onClickBackButton,
    clientName,
    firstName,
    lastName,
    onClickLogoutButton,
  } = props;
  const headerContentClassName = combineClasses(styles.headerContent, className);

  return (
    <div className={styles.headerContainer}>
      <header className={headerContentClassName} data-meta-id="page-header">
        <div className={styles.lifionLogoContainer}>
          <AdpLogo className={styles.lifionLogo} />
        </div>
        <div className={styles.rightSection}>
          {showBackButton && (
            <LinkButton
              className={styles.backButton}
              data-meta-id="return-button"
              onClick={onClickBackButton}
            >
              <LeftCaret className={styles.backArrow} />
              <TranslatedText i18nKey="HEADER.BACK_TO_CLIENT" params={{ clientName }} />
            </LinkButton>
          )}
          <div className={styles.userInfo}>
            <Avatar className={styles.avatar}>
              <LiteralText>
                {firstName.charAt(0)}
                {lastName.charAt(0)}
              </LiteralText>
            </Avatar>
            <div className={styles.details}>
              <LiteralText className={styles.name}>{firstName}</LiteralText>

              <LinkButton
                className={styles.logoutButton}
                data-meta-id="logout-button"
                onClick={onClickLogoutButton}
              >
                <TranslatedText i18nKey="HEADER.LOGOUT_BUTTON" />
              </LinkButton>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  showBackButton: PropTypes.bool,
  onClickBackButton: PropTypes.func,
  onClickLogoutButton: PropTypes.func,

  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
};

Header.defaultProps = {
  className: '',
  showBackButton: false,
  onClickBackButton: noop,
  onClickLogoutButton: noop,
};

function mapStateToProps(state) {
  return {
    firstName: state.me.main.firstName,
    lastName: state.me.main.lastName,
    email: state.me.main.email,
    clientName: state.me.main.clientName,
  };
}

export { Header as UnwrappedHeader };
export default connect(mapStateToProps)(Header);
