import actionTypes from '../actiontypes';

const mainInitialState = {
  url: window.location.href,
  obligationData: null,
};

function applicationReducer(state = mainInitialState, action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.APPLICATION.URL_CHANGE:
      return {
        ...state,
        url: payload.url,
        state: payload.state,
      };
    case actionTypes.APPLICATION.URL_STATE_CHANGE:
      return {
        ...state,
        url: window.location.href,
        state: payload,
      };
    case actionTypes.APPLICATION.HANDLE_OBLIGATIONS:
      return {
        ...state,
        obligationData: payload,
      };
    case actionTypes.APPLICATION.CLEAR_OBLIGATIONS:
      return {
        ...state,
        obligationData: null,
      };
    default:
      return state;
  }
}

export { applicationReducer as default };
