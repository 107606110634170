import styles from './index.module.scss';
import { noop } from '../../../../../../../utils/constants';
import { DeleteIcon } from '../../../../../../../components/elements/icons';
import React from 'react';
import PropTypes from 'prop-types';
import combineClasses from 'classnames';

const authenticationMethodShape = {
  type: PropTypes.oneOf(['PHONE', 'EMAIL', 'VOICE', 'TEXT']).isRequired,
  isOnlyOneOfType: PropTypes.bool.isRequired,
};

class AuthenticationMethodActions extends React.Component {
  static propTypes = {
    authenticationMethod: PropTypes.shape(authenticationMethodShape).isRequired,
    className: PropTypes.string,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    onDelete: noop,
  };

  renderDeleteIcon(method, onDelete) {
    return <DeleteIcon className={styles.deleteIcon} onClick={() => onDelete(method)} />;
  }

  render() {
    const { authenticationMethod, className, onDelete } = this.props;
    const { isOnlyOneOfType, type } = authenticationMethod;

    if (!type || isOnlyOneOfType) {
      return null;
    }

    return (
      <div className={combineClasses(styles.actionContainer, className)}>
        {this.renderDeleteIcon(authenticationMethod, onDelete)}
      </div>
    );
  }
}

export default AuthenticationMethodActions;
