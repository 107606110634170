import AuthenticationMethodActions from './AuthenticationMethodActions';
import AuthenticationMethodDisplay from './AuthenticationMethodDisplay';
import { noop } from '../../../../../../utils/constants';
import BorderedCard from 'components/elements/containers/BorderedCard';
import PropTypes from 'prop-types';
import React from 'react';

import './index.scss';

const AuthenticationMethodListing = (props) => {
  const { authenticationMethod, className, onDeleteClick, i18nContext } = props;

  return (
    <div className={`authentication-method-listing ${className}`}>
      <BorderedCard className="card">
        <AuthenticationMethodDisplay
          i18nContext={i18nContext}
          authenticationMethod={authenticationMethod}
        />
        <AuthenticationMethodActions
          authenticationMethod={authenticationMethod}
          onDelete={onDeleteClick}
        />
      </BorderedCard>
    </div>
  );
};

AuthenticationMethodListing.propTypes = {
  i18nContext: PropTypes.shape({ getTextForKey: PropTypes.func.isRequired }).isRequired,
  authenticationMethod: PropTypes.shape().isRequired,
  className: PropTypes.string,
  onDeleteClick: PropTypes.func,
};

AuthenticationMethodListing.defaultProps = {
  className: '',
  onDeleteClick: noop,
};

export default AuthenticationMethodListing;
