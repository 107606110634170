import AssociateConfirmation from './AssociateConfirmation';
import ssoMeActionCreator from 'actioncreators/ssoMe';
import UtilityHelper from 'utils/helpers/';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SsoServiceProvider extends Component {
  static propTypes = {
    isInitializing: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,

    dispatchResetState: PropTypes.func.isRequired,
    dispatchInitialize: PropTypes.func.isRequired,

    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  constructor(props) {
    super(props);

    const queryParamMap = UtilityHelper.parseQueryString(props.location.search);

    this.token = queryParamMap.token;
  }

  componentWillMount() {
    const { dispatchInitialize, history } = this.props;

    dispatchInitialize(this.token, history);
  }

  componentWillUnmount() {
    const { dispatchResetState } = this.props;

    dispatchResetState();
  }

  renderContent() {
    const { isAuthenticated, match, location } = this.props;

    if (isAuthenticated) {
      return (
        <Switch>
          <Route
            exact={true}
            path={`${match.path}/associate-confirmation`}
            component={AssociateConfirmation}
          />
          <Redirect
            exact={true}
            from={`${match.path}/personal-info`}
            to={{
              pathname: `/auth/create-account/${this.token}/sso`,
              search: location.search,
            }}
          />

          <Redirect
            from={`${match.path}`}
            to={{
              pathname: `${match.path}/associate-confirmation`,
              search: `?token=${this.token}`,
            }}
          />
        </Switch>
      );
    }
    return null;
  }

  render() {
    const { isInitializing } = this.props;

    if (isInitializing) {
      return null;
    }

    return this.renderContent();
  }
}

function mapStateToProps(state) {
  return {
    isInitializing: state.me.main.isCheckingAuth || state.ssoMe.loadData.isLoadingData,
    isAuthenticated: state.me.main.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchResetState() {
      dispatch(ssoMeActionCreator.resetState());
    },

    dispatchInitialize(token, history) {
      dispatch(ssoMeActionCreator.initialize(token, history));
    },
  };
}

export { SsoServiceProvider as UnwrappedSsoServiceProvider };
export default connect(mapStateToProps, mapDispatchToProps)(SsoServiceProvider);
