const loginActionTypes = {
  RESET_STATE: 'RESET_STATE',
  BASIC_LOGIN_REQUEST: 'BASIC_LOGIN_REQUEST',
  BASIC_LOGIN_SUCCESS: 'BASIC_LOGIN_SUCCESS',
  BASIC_LOGIN_FAILURE: 'BASIC_LOGIN_FAILURE',
  CHECK_AUTH_REQUEST: 'CHECK_AUTH_REQUEST',
  CHECK_AUTH_SUCCESS: 'CHECK_AUTH_SUCCESS',
  CHECK_AUTH_FAILURE: 'CHECK_AUTH_FAILURE',
  CHECK_USERNAME_REQUEST: 'CHECK_USERNAME_REQUEST',
  CHECK_USERNAME_SUCCESS: 'CHECK_USERNAME_SUCCESS',
  CHECK_USERNAME_FAILURE: 'CHECK_USERNAME_FAILURE',
  HIDE_PASSWORD_INPUT_DISPLAY: 'HIDE_PASSWORD_INPUT_DISPLAY',
};

export { loginActionTypes as default };
