import applicationActionCreator from './application';
import actionTypes from '../actiontypes';
import http from '../utils/http';
import getConstants from '../utils/constants';

const { requestResult, authenticationMethod } = getConstants();

const sessionExpireModalActionCreator = {
  resetState() {
    return {
      type: actionTypes.SESSION_EXPIRE_MODAL.RESET_STATE,
    };
  },

  logoutRequest() {
    return {
      type: actionTypes.SESSION_EXPIRE_MODAL.LOGOUT_REQUEST,
    };
  },

  logoutFailure() {
    return {
      type: actionTypes.SESSION_EXPIRE_MODAL.LOGOUT_FAILURE,
    };
  },

  logoutSuccess() {
    return {
      type: actionTypes.SESSION_EXPIRE_MODAL.LOGOUT_SUCCESS,
    };
  },

  forceLogout() {
    return async (dispatch) => {
      try {
        dispatch(this.logoutRequest());

        await http.logout();

        const { data } = await http.checkAuthentication();
        const url =
          data.authentication_method === authenticationMethod.NEXO
            ? window.__nexo_logout_url__
            : '/auth/login?redirectUrl=';

        if (!url) {
          applicationActionCreator.changeState(requestResult.FAILURE_UNDETERMINED_NEXO_SESSION);
          dispatch(this.logoutFailure());

          return;
        }

        dispatch(this.logoutSuccess());
        dispatch(this.hideModal());

        const redirectUrl = encodeURIComponent(
          `${window.location.pathname}${window.location.search}`
        );

        applicationActionCreator.changeUrl(`${url}${redirectUrl}`);
      } catch (e) {
        dispatch(this.logoutFailure());
      }
    };
  },

  showModal(timeLeftForDisplay) {
    return {
      type: actionTypes.SESSION_EXPIRE_MODAL.SHOW_MODAL,
      payload: timeLeftForDisplay,
    };
  },

  hideModal() {
    return {
      type: actionTypes.SESSION_EXPIRE_MODAL.HIDE_MODAL,
    };
  },

  prolongSessionFailure() {
    return {
      type: actionTypes.SESSION_EXPIRE_MODAL.PROLONG_SESSION_FAILURE,
    };
  },

  prolongSessionSuccess() {
    return {
      type: actionTypes.SESSION_EXPIRE_MODAL.PROLONG_SESSION_SUCCESS,
    };
  },

  prolongSessionRequest() {
    return {
      type: actionTypes.SESSION_EXPIRE_MODAL.PROLONG_SESSION_REQUEST,
    };
  },

  prolongSession(headers) {
    return async (dispatch) => {
      dispatch(this.prolongSessionRequest());

      try {
        await http.prolongSession(headers);

        dispatch(this.prolongSessionSuccess());
      } catch (e) {
        dispatch(this.prolongSessionFailure());

        await dispatch(this.forceLogout());
      }
    };
  },
};

export { sessionExpireModalActionCreator as default };
