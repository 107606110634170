import buildFormReducer from 'reducers/builders/form';
import actionTypes, { actionTypeSections } from 'actiontypes/';
import { combineReducers } from 'redux';

const mainInitialState = {
  isUpdatingData: false,
};

const formInitialState = {
  email: {
    value: '',
    isValid: false,
    isValidating: false,
  },
};

function mainReducer(state = mainInitialState, action) {
  const actionType = action.type;

  switch (actionType) {
    case actionTypes.EMAIL_VERIFY_NOTIFICATION.RESET_STATE:
      return { ...mainInitialState };
    case actionTypes.EMAIL_VERIFY_NOTIFICATION.UPDATE_DATA_REQUEST:
      return {
        ...state,
        isUpdatingData: true,
      };
    case actionTypes.EMAIL_VERIFY_NOTIFICATION.UPDATE_DATA_SUCCESS:
    case actionTypes.EMAIL_VERIFY_NOTIFICATION.UPDATE_DATA_FAILURE:
      return {
        ...state,
        isUpdatingData: false,
      };
    default:
      return state;
  }
}

const emailVerifyNotificationReducer = combineReducers({
  main: mainReducer,
  form: buildFormReducer(formInitialState, actionTypeSections.EMAIL_VERIFY_NOTIFICATION),
});

export { emailVerifyNotificationReducer as default };
