import createStore from './store';
import BaseActionCreator from './actioncreators/BaseActionCreator';
import MfaStepUp from './containers/MfaStepUp';
import UnauthenticatedProxyLayout from './containers/layouts/UnauthenticatedProxyLayout';
import UnauthenticatedLayout from './containers/layouts/UnauthenticatedLayout';
import AuthenticatedLayout from './containers/layouts/AuthenticatedLayout';
import AuthenticatedPage from './containers/AuthenticatedPage';
import OutagePage from './containers/OutagePage';
import ArchivedPage from './containers/ArchivedPage';
import UnauthorizedErrorPage from './containers/UnauthorizedErrorPage';
import RouteManager from './containers/RouteManager';
import ObligationHandler from './containers/ObligationHandler';
import Messages from './containers/Messages';
import buildGetUserConfirmation from './utils/routes';
import App from './ReduxApp';
import { registerRules as registerCustomValidationRules } from './components/elements/formInputs/rules';
import ResourceNotFoundErrorPage from './containers/ResourceNotFoundErrorPage';
import AccountNoAccessErrorPage from './containers/AccountNoAccessErrorPage';
import Root from './containers/Root';
import urls from './utils/urls';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import React from 'react';

const initialState = window.INITIAL_STATE;
const store = createStore(initialState);

registerCustomValidationRules();

BaseActionCreator.setDefaultDispatcher(store);
const getUserConfirmation = buildGetUserConfirmation(store);

const AuthenticatedRoutes = (props) => (
  <AuthenticatedPage {...props}>
    <Switch>
      <Route
        exact={true}
        path={urls.CHANGE_PASSWORD_OBLIGATION}
        component={UnauthenticatedLayout}
      />
      <Route
        exact={true}
        path={urls.CHANGE_USERNAME_OBLIGATION}
        component={UnauthenticatedLayout}
      />
      <Route
        exact={true}
        path={urls.REVIEW_CONTACT_INFO_OBLIGATION}
        component={UnauthenticatedLayout}
      />
      <Route path={urls.LOGIN_STEP_UP} component={UnauthenticatedLayout} />
      <Route path="/auth/step-up" component={MfaStepUp} />
      <Route path={urls.authenticated.FORCE_SAML_LOGIN} component={UnauthenticatedLayout} />
      <Route
        exact={true}
        path={urls.authenticated.CREATE_BASIC_CREDENTIALS}
        component={UnauthenticatedLayout}
      />
      <Route
        exact={true}
        path={urls.authenticated.LEGAL_AGREEMENTS}
        component={UnauthenticatedLayout}
      />
      <Route path={urls.authenticated.OBLIGATIONS} component={UnauthenticatedLayout} />
      <Route path={urls.VERIFY_CONTACT_INFO} component={UnauthenticatedLayout} />
    </Switch>
  </AuthenticatedPage>
);

const routes = (
  <Provider store={store}>
    <App>
      <Root>
        <Messages />
        <BrowserRouter getUserConfirmation={getUserConfirmation}>
          <main className="ui-page-wrapper">
            <Route path="*" component={RouteManager} />
            <Switch>
              <Route path={urls.authenticated.OBLIGATIONS} component={null} />
              <Route path={urls.LOGIN_STEP_UP} component={null} />
              <Route path={urls.CHANGE_USERNAME_OBLIGATION} component={null} />
              <Route path="*" component={ObligationHandler} />
            </Switch>

            <Switch>
              <Route path={urls.AUTHENTICATED_ROOT} render={AuthenticatedRoutes} />

              <Route path={urls.UNAUTHENTICATED_ROOT} component={UnauthenticatedLayout} />
              <Route
                path={urls.LOGIN_STEP_UP}
                render={(props) => <AuthenticatedRoutes {...props} noRedirect={true} />}
              />
              <Route
                exact={true}
                path={urls.CHANGE_PASSWORD_OBLIGATION}
                component={AuthenticatedRoutes}
              />
              <Route
                exact={true}
                path={urls.CHANGE_USERNAME_OBLIGATION}
                component={AuthenticatedRoutes}
              />
              <Route
                exact={true}
                path={urls.REVIEW_CONTACT_INFO_OBLIGATION}
                component={AuthenticatedRoutes}
              />
              <Route exact={true} path={urls.VERIFY_CONTACT_INFO} component={AuthenticatedRoutes} />
              <Route exact={true} path="/auth/step-up" component={AuthenticatedRoutes} />

              <Route exact={true} path="/auth/user-confirmation" component={AuthenticatedLayout} />
              <Route exact={true} path="/auth/account-linking" component={AuthenticatedLayout} />
              <Route
                exact={true}
                path="/auth/decline-confirmation"
                component={AuthenticatedLayout}
              />
              <Route
                exact={true}
                path="/auth/sso-idp-adp/marketplace"
                component={AuthenticatedLayout}
              />
              <Route path="/auth/account-settings" component={AuthenticatedLayout} />
              <Route exact={true} path="/auth/choose-product" component={AuthenticatedLayout} />
              <Route exact={true} path="/auth/impersonation" component={AuthenticatedLayout} />

              <Route exact={true} path="/auth/archived" component={ArchivedPage} />
              <Route exact={true} path="/auth/outage" component={OutagePage} />
              <Route exact={true} path="/auth/saml-error" component={OutagePage} />
              <Route
                exact={true}
                path="/auth/unauthorized-error"
                component={UnauthorizedErrorPage}
              />
              <Route path="/auth/resource-not-found" component={ResourceNotFoundErrorPage} />
              <Route path="/auth/account-no-access" component={AccountNoAccessErrorPage} />

              <Route exact={true} path="/auth/login" component={UnauthenticatedProxyLayout} />
              <Route exact={true} path="/auth/logout" component={UnauthenticatedProxyLayout} />
              <Route path="/auth" component={UnauthenticatedLayout} />
            </Switch>
          </main>
        </BrowserRouter>
      </Root>
    </App>
  </Provider>
);

export { routes as default };
