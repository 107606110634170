import BaseActionCreator from '../BaseActionCreator';
import actionTypes from '../../actiontypes';
import messagesActionCreator from '../messages';
import http from '../../utils/http';
import UtilityHelper from '../../utils/helpers';
import { staticConstants } from '../../utils/constants';

const {
  UPDATE_PASSWORD_INCORRECT_PASSWORD_OR_INVALID_PASSWORD_FAILURE,
  UPDATE_DATA_REQUEST,
  UPDATE_DATA_SUCCESS,
  UPDATE_DATA_FAILURE,
  UPDATE_PASSWORD_FAIL_TOO_MANY_ATTEMPTS,
  RESET_STATE,
} = actionTypes.ACCOUNT_SETTINGS_CHANGE_PASSWORD;

const isChangePasswordModalDisplaying =
  staticConstants.localStorage.IS_CHANGE_PASSWORD_MODAL_DISPLAYING;

class ChangePasswordActionCreator extends BaseActionCreator {
  resetState() {
    this.dispatch(RESET_STATE);
  }

  invalidPasswordFailure(errorCode) {
    return {
      type: UPDATE_PASSWORD_INCORRECT_PASSWORD_OR_INVALID_PASSWORD_FAILURE,
      payload: {
        errorCode,
      },
    };
  }

  async updatePassword(payload) {
    try {
      this.dispatch(UPDATE_DATA_REQUEST);

      await http.changeMyPassword(payload);

      this.dispatch(
        messagesActionCreator.addToastMessage({
          message: 'CHANGE_PW_SUCCESS_MESSAGE',
          type: 'success',
          id: UtilityHelper.generateUniqueId(),
          translationNamespace: 'TOAST_MESSAGE',
        })
      );

      localStorage.setItem(isChangePasswordModalDisplaying, 'true');

      this.dispatch(UPDATE_DATA_SUCCESS);
    } catch (error) {
      if (
        Array.isArray(error.errors) &&
        error.errors[0].code === http.supportedErrorCodes.TOO_MANY_ATTEMPTS
      ) {
        localStorage.setItem(isChangePasswordModalDisplaying, 'true');

        this.dispatch(UPDATE_PASSWORD_FAIL_TOO_MANY_ATTEMPTS);
      } else {
        const previousErrorCode =
          Array.isArray(error.errors) && error.errors[1] && error.errors[1].code;
        const isBadPassword = Boolean(http.supportedErrorCodes[previousErrorCode]);
        let thrownError = error;

        if (isBadPassword) {
          this.dispatch(this.invalidPasswordFailure(previousErrorCode));
          thrownError = Object.assign(new Error(), { code: previousErrorCode });
        }

        this.dispatch(UPDATE_DATA_FAILURE);
        throw thrownError;
      }
    }
  }
}

export default new ChangePasswordActionCreator();
