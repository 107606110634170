import Obligations from './Obligations';
import styles from './obligationHandler.module.scss';
import { staticConstants } from '../../utils/constants';
import urls from '../../utils/urls';
import utils from '../../utils/helpers';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { MediumPageModal } from '@idm/ui-components';

const { obligationActionTypes } = staticConstants;

class ObligationHandler extends Component {
  static propTypes = {
    obligationData: PropTypes.shape({
      kind: PropTypes.string.isRequired,
      command: PropTypes.string,
      commandArgs: PropTypes.shape({}),
      isLoginObligation: PropTypes.bool,
      url: PropTypes.string,
    }),
    onAfterObligation: PropTypes.func.isRequired,
    onAlternativeAction: PropTypes.func,
  };

  static defaultProps = {
    obligationData: null,
    onAlternativeAction: null,
  };

  renderRedirect(to) {
    if (utils.isAbsoluteUrl(to.pathname)) {
      const target = new URL(to.pathname);

      const currentPath = utils.getCurrentPath();

      if (target.pathname !== currentPath) {
        target.searchParams.set('redirectUrl', currentPath);
      }
      utils.redirectTo(target.toString());
      return null;
    }
    const location = utils.getWindowLocation();
    const { pathname } = to;
    const shouldRedirect = location.pathname !== pathname;

    return shouldRedirect ? <Redirect to={to} /> : null;
  }

  renderObligations() {
    const { obligationData, onAfterObligation, onAlternativeAction } = this.props;

    return (
      <Obligations
        obligationData={obligationData}
        onAfterObligation={onAfterObligation}
        onAlternativeAction={onAlternativeAction}
      />
    );
  }

  renderCommandObligation() {
    const { obligationData } = this.props;

    if (obligationData.isLoginObligation) {
      const targetPath = urls.getForObligation(obligationData.command);

      return this.renderRedirect({
        pathname: targetPath,
        search: utils.getWindowLocation().search,
        state: obligationData,
      });
    }

    return <MediumPageModal className={styles.modal}>{this.renderObligations()}</MediumPageModal>;
  }

  render() {
    const { obligationData } = this.props;

    if (obligationData && obligationData.kind) {
      const { kind, url, isLoginObligation } = obligationData;

      switch (kind) {
        case obligationActionTypes.COMMAND:
          return this.renderCommandObligation();
        case obligationActionTypes.REDIRECT:
          return this.renderRedirect({
            pathname: url,
            search: isLoginObligation
              ? utils.getWindowLocation().search
              : `?redirectUrl=${utils.getCurrentPath()}`,
          });
        default:
          break;
      }
    }
    return null;
  }
}

export default ObligationHandler;
