import meActionCreator from '../../../actioncreators/me';
import UnauthenticatedLayout from '../UnauthenticatedLayout';
import Login from '../../Login';
import Logout from '../../Logout';

import urls from '../../../utils/urls';

import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class UnwrappedUnauthenticatedProxyLayout extends Component {
  static propTypes = {
    isAuthenticatedByNexo: PropTypes.bool.isRequired,
    isCheckingAuth: PropTypes.bool.isRequired,

    dispatchOnMount: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { dispatchOnMount } = this.props;

    dispatchOnMount();
  }

  render() {
    const { isCheckingAuth, isAuthenticatedByNexo } = this.props;

    if (isCheckingAuth) return null;

    return isAuthenticatedByNexo ? (
      <Switch>
        <Route exact={true} path={urls.LOGIN} component={Login} />
        <Route exact={true} path={urls.LOGOUT} component={Logout} />
        <Redirect from="*" to={urls.LOGIN} />
      </Switch>
    ) : (
      <UnauthenticatedLayout {...this.props} />
    );
  }
}

function mapStateToProps(state) {
  return {
    isCheckingAuth: state.me.main.isCheckingAuth,
    isAuthenticatedByNexo: state.me.main.isAuthenticatedByNexo,
    isRebrandEnabled: state.me.main.isRebrandEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchOnMount() {
      dispatch(meActionCreator.getRebrandFeatureFlagStatus());
      return dispatch(meActionCreator.assertAuthenticated());
    },
  };
}

const UnauthenticatedProxyLayout = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnwrappedUnauthenticatedProxyLayout);

export { UnwrappedUnauthenticatedProxyLayout, UnauthenticatedProxyLayout as default };
