import buildFormActionCreator from '../builders/form';
import applicationActionCreator from '../application';
import actionTypes, { actionTypeSections } from '../../actiontypes';
import http from '../../utils/http';
import getConstants from '../../utils/constants';

const { requestResult } = getConstants();

const requestActionCreator = {
  ...buildFormActionCreator(actionTypeSections.FORGOT_USERNAME_REQUEST),

  resetMainState() {
    return {
      type: actionTypes.FORGOT_USERNAME_REQUEST.RESET_STATE,
    };
  },

  resetState() {
    return (dispatch) => {
      dispatch(this.resetMainState());

      dispatch(this.resetFormState());
    };
  },

  requestForgotUsernameRequest() {
    return {
      type: actionTypes.FORGOT_USERNAME_REQUEST.REQUEST_USERNAME_REQUEST,
    };
  },

  requestForgotUsernameSuccess() {
    return {
      type: actionTypes.FORGOT_USERNAME_REQUEST.REQUEST_USERNAME_SUCCESS,
    };
  },

  requestForgotUsernameFailure() {
    return {
      type: actionTypes.FORGOT_USERNAME_REQUEST.REQUEST_USERNAME_FAILURE,
    };
  },

  requestForgotUsername(email) {
    return async (dispatch) => {
      try {
        dispatch(this.requestForgotUsernameRequest());

        await http.requestForgotUsername(email);

        applicationActionCreator.changeState(requestResult.SUCCESS_FORGOT_USERNAME_REQUEST);

        dispatch(this.requestForgotUsernameSuccess());
      } catch (error) {
        const isBadAttempt = error.code === http.supportedErrorCodes.TOO_MANY_ATTEMPTS;

        if (isBadAttempt) {
          applicationActionCreator.changeState(requestResult.FAILURE_RECOVER_ATTEMPT);
        } else {
          applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);
        }

        dispatch(this.requestForgotUsernameFailure());
      }
    };
  },
};

export { requestActionCreator as default };
