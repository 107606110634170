import getConstants from '../../utils/constants';
import buildFormActionCreator from '../builders/form';
import buildLoadDataActionCreator from '../builders/loadData';
import applicationActionCreator from '../application';
import actionTypes, { actionTypeSections } from '../../actiontypes';
import http from '../../utils/http';

const { requestResult } = getConstants();

const resetActionCreator = {
  ...buildFormActionCreator(actionTypeSections.FORGOT_PASSWORD_RESET),

  ...buildLoadDataActionCreator(actionTypeSections.FORGOT_PASSWORD_RESET),

  resetMainState() {
    return {
      type: actionTypes.FORGOT_PASSWORD_RESET.RESET_STATE,
    };
  },

  resetState() {
    return (dispatch) => {
      dispatch(this.resetMainState());

      dispatch(this.resetFormState());

      dispatch(this.resetLoadDataState());
    };
  },

  loadAndVerifyToken(token) {
    return async (dispatch) => {
      dispatch(this.loadDataRequest());

      try {
        await http.loadAndVerify('resetPassword', token);

        dispatch(this.loadDataSuccess());
      } catch (e) {
        dispatch(this.loadDataFailure());

        if (e.code === http.supportedErrorCodes.TOKEN_LOAD_VERIFY_FAILURE) {
          applicationActionCreator.changeState(requestResult.FAILURE_LINK_RESET_PASS);
        } else {
          applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);
        }
      }
    };
  },

  invalidPasswordFailure(errorCode) {
    return {
      type: actionTypes.FORGOT_PASSWORD_RESET.RESET_PASSWORD_INVALID_PASSWORD_FAILURE,
      payload: {
        errorCode,
      },
    };
  },

  resetPasswordRequest() {
    return {
      type: actionTypes.FORGOT_PASSWORD_RESET.RESET_PASSWORD_REQUEST,
    };
  },

  resetPasswordSuccess() {
    return {
      type: actionTypes.FORGOT_PASSWORD_RESET.RESET_PASSWORD_SUCCESS,
    };
  },

  resetPasswordFailure() {
    return {
      type: actionTypes.FORGOT_PASSWORD_RESET.RESET_PASSWORD_FAILURE,
    };
  },

  resetPassword(token, newPassword) {
    return (dispatch) => {
      dispatch(this.resetPasswordRequest());

      return http
        .execute('resetPassword', token, { newPassword })
        .then(() => {
          dispatch(this.resetPasswordSuccess());

          applicationActionCreator.changeState(requestResult.SUCCESS_FORGOT_PASS_RESET);
        })
        .catch((error) => {
          dispatch(this.resetPasswordFailure());

          const isBadLink =
            error.code === http.supportedErrorCodes.TOKEN_LOAD_VERIFY_FAILURE ||
            error.code === http.supportedErrorCodes.TOKEN_EXECUTE_FAILURE;
          const isBadPassword = Boolean(http.supportedErrorCodes[error.code]);

          if (isBadLink) {
            applicationActionCreator.changeState(requestResult.FAILURE_LINK_RESET_PASS);
          } else if (isBadPassword) {
            dispatch(this.invalidPasswordFailure(error.code));
          } else {
            applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);
          }
        });
    };
  },
};

export { resetActionCreator as default };
