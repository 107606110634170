import HttpClient from './HttpClient';
import getConstants from '../constants';

const constants = getConstants(process.env.BASE_URL || window.location.origin);
const identityOrchestratorUrl = constants.serviceBaseUrls.identityOrchestrator;
const httpClient = HttpClient.createInstance({ baseURL: identityOrchestratorUrl });

export default function getSettingsByGroupName(settingsGroupName) {
  return httpClient.get(`/settings/${settingsGroupName}`);
}
