import Rule from './Rule';
import ValidationError from '../../exceptions/ValidationError';
import datasource from '../../../actioncreators/accountSettings/contactInfo/datasource';
import { staticConstants } from '../../constants';
import WindowUtils from '../../helpers/window';

export default class NoNonAdpEmailRule extends Rule {
  constructor(messages, clientId) {
    super(messages);
    this.message = messages;
    this.clientId = clientId;
  }

  async apply(value) {
    const ADP_DOMAIN_EMAIL_REGEX = /(@adp\.com)$/gi;

    const { host } = WindowUtils.getWindowLocation();

    try {
      const isRequireOnlyAllowAdpEmail = await datasource.getFeatureEnabledStatus(
        staticConstants.featureFlags.REQUIRE_ONLY_ALLOW_ADP_EMAIL
      );

      const isExternalDomain = host.includes(staticConstants.domains.EXTERNAL_DOMAIN);

      if (isRequireOnlyAllowAdpEmail && (this.clientId === '002' || !isExternalDomain)) {
        const isEmailAdp = value.match(ADP_DOMAIN_EMAIL_REGEX);

        if (!isEmailAdp) {
          return Promise.reject(new ValidationError(this.message));
        }
      }
      return Promise.resolve(this.message);
    } catch (err) {
      return Promise.reject(new ValidationError(this.message));
    }
  }
}
