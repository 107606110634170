import { RegexRule } from '@tbiegner99/react-forms';

const DIGIT_REGEX = /[0-9]/;

class DigitRule extends RegexRule {
  constructor() {
    super(DIGIT_REGEX);
  }

  static get ruleName() {
    return 'digit';
  }

  getDefaultMessage() {
    return 'Must contain a digit';
  }
}

export default DigitRule;
