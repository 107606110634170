import serializer from './serializer';
import HttpClient from '../../utils/http/HttpClient';
import getConstants from '../../utils/constants';

const constants = getConstants(process.env.BASE_URL);
const identityOrchestratorUrl = constants.serviceBaseUrls.identityOrchestrator;

class EmailVerificationDatasource {
  constructor() {
    this.httpClient = HttpClient.createInstance({
      baseURL: identityOrchestratorUrl,
    });
  }

  async resendEmailVerification(emailAddress, emailAddressToUpdate) {
    const url = '/tokens/emailVerification';
    const requestBody = serializer.sendVerification.toRequest(emailAddress, emailAddressToUpdate);

    const response = await this.httpClient.post(url, requestBody);

    return serializer.sendVerification.fromResponse(response);
  }

  async verifyEmail(token, code) {
    const url = '/tokens/execute';
    const requestBody = serializer.verifyEmail.toRequest(token, code);

    await this.httpClient.post(url, requestBody);
  }
}

export default new EmailVerificationDatasource();
