import UtilityHelper from '../../../utils/helpers';
import { CheckIcon } from '../../elements/icons';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

class CheckboxInput extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
    className: '',
    id: UtilityHelper.generateUniqueId(),
  };

  _toggleIsChecked = () => {
    this.props.onChange(!this.props.value);
  };

  render() {
    const { value, children, label, className, id } = this.props;

    return (
      <div className={`input checkbox-input ${className}`}>
        <input type="checkbox" checked={value} id={id} />
        <label onClick={this._toggleIsChecked} role="button" htmlFor={id} data-meta-id={id}>
          <CheckIcon width={14} height={14} />
        </label>
        <span className="checkbox-label">{label || children}</span>
      </div>
    );
  }
}

export { CheckboxInput as default };
