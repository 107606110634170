import datasource from './datasource';
import getConstants from '../../../utils/constants';
import actionTypes from '../../../actiontypes';
import messagesActionCreator from '../../messages';
import UtilityHelper from '../../../utils/helpers';
import BaseActionCreator from '../../BaseActionCreator';

const { requestResult } = getConstants();

const { CREATE_CREDENTIALS_REQUEST, CREATE_CREDENTIALS_SUCCESS, CREATE_CREDENTIALS_FAILURE } =
  actionTypes.USER_BASIC_CREDENTIALS;

class BasicCredentialsActionCreator extends BaseActionCreator {
  async createBasicCredentials(payload) {
    this.dispatch(CREATE_CREDENTIALS_REQUEST);

    try {
      await datasource.createBasicCredentials(payload);

      this.dispatch(
        messagesActionCreator.addToastMessage({
          message: 'CREATED_BASIC_CREDENTIALS',
          type: 'success',
          id: UtilityHelper.generateUniqueId(),
          translationNamespace: 'TOAST_MESSAGE',
        })
      );
      this.dispatch(CREATE_CREDENTIALS_SUCCESS, payload);
    } catch (err) {
      this.dispatch(CREATE_CREDENTIALS_FAILURE);
      this.changeState(requestResult.FAILURE_UNSUPPORTED);
      throw err;
    }
  }
}

export default new BasicCredentialsActionCreator();
