import actionTypes from '../../actiontypes';

const { CREATE_CREDENTIAL_ACCOUNT_INFO } = actionTypes;

const mainInitialState = {
  usernameError: {
    isVisible: false,
    code: undefined,
  },
  passwordError: {
    isVisible: false,
    code: undefined,
  },
  isCreatingAccount: false,
  isLoadingData: false,
};

const accountInfoReducer = (state = mainInitialState, action) => {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case CREATE_CREDENTIAL_ACCOUNT_INFO.VERIFYING_TOKEN:
      return {
        ...state,
        isLoadingData: true,
      };

    case CREATE_CREDENTIAL_ACCOUNT_INFO.TOKEN_VERIFIED:
      return {
        ...state,
        isLoadingData: false,
      };
    case CREATE_CREDENTIAL_ACCOUNT_INFO.CREATE_CREDENTIAL_REQUEST:
      return {
        ...state,
        isCreatingAccount: true,
        usernameError: {
          isVisible: false,
          code: undefined,
        },
        passwordError: {
          isVisible: false,
          code: undefined,
        },
      };
    case CREATE_CREDENTIAL_ACCOUNT_INFO.CREATE_CREDENTIAL_PASSWORD_VALIDATION_FAILURE:
      return {
        ...state,
        passwordError: {
          isVisible: true,
          code: actionPayload.errorCode,
        },
      };
    case CREATE_CREDENTIAL_ACCOUNT_INFO.CREATE_CREDENTIAL_USERNAME_OR_EMAIL_VALIDATION_FAILURE:
      return {
        ...state,
        usernameError: {
          isVisible: true,
          code: actionPayload.errorCode,
        },
      };
    case CREATE_CREDENTIAL_ACCOUNT_INFO.CREATE_CREDENTIAL_SUCCESS:
    case CREATE_CREDENTIAL_ACCOUNT_INFO.CREATE_CREDENTIAL_FAILURE:
      return { ...state, isCreatingAccount: false };
    case CREATE_CREDENTIAL_ACCOUNT_INFO.RESET_STATE:
      return { ...mainInitialState };
    default:
      return state;
  }
};

export default accountInfoReducer;
