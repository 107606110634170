import actionTypes from '../actiontypes';

const messagesActionCreator = {
  resetState() {
    return {
      type: actionTypes.MESSAGES.RESET_STATE,
    };
  },

  addToastMessage(payload) {
    return {
      type: actionTypes.MESSAGES.ADD_TOAST_MESSAGE,
      payload,
    };
  },

  removeToastMessage(payload) {
    return {
      type: actionTypes.MESSAGES.REMOVE_TOAST_MESSAGE,
      payload,
    };
  },
};

export default messagesActionCreator;
