import meActionCreator from '../../../actioncreators/me';
import applicationActionCreator from '../../../actioncreators/application';
import namespaceTranslatedText from '../../TranslatedText';
import { ButtonContainer, PrimaryButton, SecondaryButton } from '../../../components';
import Translator from '../../../utils/translation/Translator';
import getConstants from '../../../utils/constants';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const { requestResult } = getConstants();

const TranslatedText = namespaceTranslatedText('SSO_SERVICE_PROVIDER.ASSOCIATE_CONFIRMATION');
const associateConfirmationDictionary = new Translator(
  'SSO_SERVICE_PROVIDER.ASSOCIATE_CONFIRMATION'
);

import './style.scss';

class AssociateConfirmation extends Component {
  static propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    dictionary: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    onChangeUrl: PropTypes.func.isRequired,
    dispatchLogout: PropTypes.func.isRequired,
    isRebrandEnabled: PropTypes.bool,

    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    isRebrandEnabled: false,
  };

  onIsMeButtonClick = () => {
    const { onChangeUrl, location } = this.props;

    onChangeUrl(`/auth/sso-service-provider/personal-info${location.search}`);
  };

  onNotYouButtonClick = () => {
    const { location, dispatchLogout } = this.props;

    dispatchLogout(`${location.pathname}${location.search}`, {
      REDIRECT_RESULT: requestResult.FAILURE_UNAUTHORIZED,
    });
  };

  render() {
    const { firstName, lastName, email, dictionary, isRebrandEnabled } = this.props;

    return (
      <section className="associate-confirmation">
        <h1>
          <TranslatedText textKey="WELCOME_MESSAGE" isRebrandEnabled={isRebrandEnabled} />
        </h1>
        <div className="identity">
          <div className="avatar initials">
            {firstName.charAt(0)}
            {lastName.charAt(0)}
          </div>
          <div className="details">
            <div className="primary-text">
              <TranslatedText
                textKey="ARE_YOU"
                containHtml={true}
                params={{ firstName, lastName }}
              />
            </div>
            <div className="secondary-text">
              <TranslatedText textKey="CONTINUE_AS" params={{ email }} />
            </div>
          </div>
        </div>
        <ButtonContainer>
          <PrimaryButton onClick={this.onIsMeButtonClick} size="large" data-meta-id="is-you-button">
            {associateConfirmationDictionary.get('YES', dictionary)}
          </PrimaryButton>
          <SecondaryButton
            onClick={this.onNotYouButtonClick}
            size="large"
            data-meta-id="not-you-button"
          >
            {associateConfirmationDictionary.get('NO', dictionary)}
          </SecondaryButton>
        </ButtonContainer>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    firstName: state.ssoMe.main.firstName,
    lastName: state.ssoMe.main.lastName,
    email: state.ssoMe.main.email,
    dictionary: state.translation.main.dictionary,
    isRebrandEnabled: state.me.main.isRebrandEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChangeUrl(url) {
      applicationActionCreator.changeUrl(url);
    },
    dispatchLogout(redirectLocation) {
      dispatch(meActionCreator.logout(redirectLocation));
    },
  };
}

export { AssociateConfirmation as UnwrappedAssociateConfirmation };
export default connect(mapStateToProps, mapDispatchToProps)(AssociateConfirmation);
