import helpers from '../helpers';
import getConstants from '../constants';
import Cookies from 'universal-cookie';
import { convertLifionLanguageCodeToStandardADPCode } from 'lifion-to-adp-language-mapper';

const {
  defaultRegionalSettings,
  ONE_YEAR,
  ADP_COOKIE_DOMAIN,
  ONEADP_COOKIE_DOMAIN,
  ADP_LANGUAGE_COOKIE,
  regionalSettingsKeys,
  regionalSettingsLyricMap,
} = getConstants();
const cookies = new Cookies();

const adpDomainCookieOption = {
  domain: ADP_COOKIE_DOMAIN,
};
const oneadpCookieOption = {
  domain: ONEADP_COOKIE_DOMAIN,
};

export default {
  setCookie(key, value, options = {}) {
    const sevenDaysLater = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);

    cookies.set(key, value, { path: '/', expires: sevenDaysLater, ...options });
  },

  getCookie(key) {
    return cookies.get(key);
  },

  removeCookieByName(name) {
    document.cookie = `${encodeURIComponent(
      name
    )}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
  },

  setRegionalSettingsCookies(regionalSettings) {
    const adpLanguageCode = convertLifionLanguageCodeToStandardADPCode(regionalSettings.language);

    const settings = {
      ...defaultRegionalSettings,
      ...regionalSettings,
    };

    const oneYearLater = new Date(new Date().getTime() + ONE_YEAR);
    const expiresCookieOptions = { expires: oneYearLater };
    const hostname = helpers.getHostnameForCookies();
    const domainDerivedFromHostname = hostname ? `.${hostname}` : null;
    const isHostOneAdp = hostname && hostname.includes(ONEADP_COOKIE_DOMAIN);

    Object.keys(settings).forEach((key) => {
      if (regionalSettingsKeys.includes(key)) {
        if (!window.__add_cookies_nodot_hostname__) {
          this.removeCookieByName(key);
        }

        if (!window.__do_not_add_lyric_personal_settings_cookies__) {
          const lyricKey = regionalSettingsLyricMap[key];
          const cookieOption = isHostOneAdp
            ? { ...oneadpCookieOption, ...expiresCookieOptions }
            : { ...adpDomainCookieOption, ...expiresCookieOptions };

          this.setCookie(lyricKey, settings[key], cookieOption);
        }
      }

      let cookieOptions = {};

      if (!window.__do_not_add_personal_settings_cookies_adp_domain__) {
        cookieOptions = isHostOneAdp
          ? { ...oneadpCookieOption, ...expiresCookieOptions }
          : { ...adpDomainCookieOption, ...expiresCookieOptions };
      } else {
        cookieOptions = { domain: domainDerivedFromHostname, ...expiresCookieOptions };
      }

      this.setCookie(key, settings[key], cookieOptions);
    });

    this.setCookie(ADP_LANGUAGE_COOKIE, adpLanguageCode, {
      ...adpDomainCookieOption,
      ...expiresCookieOptions,
    });
  },
};
