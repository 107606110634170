const mapSingleRegion = (region) => ({
  name: region.name,
  code: region.code,
});

const mapRegions = (regions) => regions.map(mapSingleRegion);

const mapSingleTimezone = (timezone) => ({
  name: timezone.caption,
  code: timezone.code,
});

const mapTimezones = (timezones) => timezones.map(mapSingleTimezone);

const mapSingleLanguage = (language) => ({
  name: language.name,
  code: language.code,
});

const mapLanguages = (languages) => languages.map(mapSingleLanguage);

const toUpdateSettingsRequest = (data) => ({
  timezone: data.timezone,
  region: data.region,
  preferredLanguage: data.language,
});
const fromGetSettingsResponse = (data) => ({
  timezone: data.timezone,
  region: data.region,
  language: data.preferredLanguage,
});

export default {
  getLanguages: {
    fromResponse: mapLanguages,
  },
  getRegions: {
    fromResponse: mapRegions,
  },
  getTimezones: {
    fromResponse: mapTimezones,
  },

  settings: {
    toUpdateSettingsRequest,
    fromGetSettingsResponse,
  },
};
