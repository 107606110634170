import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

class Tab extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    path: PropTypes.string.isRequired,
    active: PropTypes.bool,
    classes: PropTypes.arrayOf(PropTypes.string.isRequired),
  };

  static defaultProps = {
    active: false,
    classes: [],
  };

  render() {
    const { children, classes, path, active } = this.props;
    const className = cx('tab', { active }, ...classes);

    return (
      <li className={className}>
        <NavLink to={path} activeClassName="active">
          {children}
        </NavLink>
      </li>
    );
  }
}

export { Tab as default };
