import buildLoadDataReducer from 'reducers/builders/loadData';
import actionTypes, { actionTypeSections } from 'actiontypes/';
import { combineReducers } from 'redux';

const mainInitialState = {
  associates: [],
  isSettingActiveAssociate: false,
};

const loadDataInitialState = {
  isLoadingData: false,
};

function mainReducer(state = mainInitialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.ACCOUNT_SETTINGS_SWITCH_CLIENTS.SET_ASSOCIATES:
      return {
        ...state,
        associates: actionPayload,
      };
    case actionTypes.ACCOUNT_SETTINGS_SWITCH_CLIENTS.SET_ACTIVE_ASSOCIATE_SUCCESS:
    case actionTypes.ACCOUNT_SETTINGS_SWITCH_CLIENTS.SET_ACTIVE_ASSOCIATE_FAILURE:
      return { ...state, isSettingActiveAssociate: false };
    case actionTypes.ACCOUNT_SETTINGS_SWITCH_CLIENTS.SET_ACTIVE_ASSOCIATE_REQUEST:
      return { ...state, isSettingActiveAssociate: true };
    case actionTypes.ACCOUNT_SETTINGS_SWITCH_CLIENTS.RESET_STATE:
      return { ...mainInitialState };
    default:
      return state;
  }
}

const switchClientsReducer = combineReducers({
  main: mainReducer,
  loadData: buildLoadDataReducer(
    loadDataInitialState,
    actionTypeSections.ACCOUNT_SETTINGS_SWITCH_CLIENTS
  ),
});

export { switchClientsReducer as default };
