import datasource from '../../../../actioncreators/token/datasource';

import { Rule } from '@tbiegner99/react-forms';

export default class NoDuplicateUsernameRule extends Rule {
  constructor(...args) {
    super();
    const [token, kind] = args;

    this.token = token;
    this.kind = kind;
  }

  static get ruleName() {
    return 'duplicate-username';
  }

  get priority() {
    return Number.MAX_SAFE_INTEGER; //make ssure its last in validation for short circuiting
  }

  async validate(username) {
    try {
      await datasource.verifyValidUsername(this.token, username, this.kind);
    } catch (err) {
      throw new Error(err.code);
    }
  }
}
