import datasource from './datasource';
import actionTypes from '../../actiontypes';

const landingProductsActionCreator = {
  async getLandingProducts(dispatch) {
    try {
      dispatch({
        type: actionTypes.LANDING_PRODUCTS.REQUEST,
      });

      const loadedProducts = await datasource.getLandingProducts();

      dispatch({
        type: actionTypes.LANDING_PRODUCTS.SUCCESS,
        payload: loadedProducts,
      });
    } catch (err) {
      dispatch({
        type: actionTypes.LANDING_PRODUCTS.FAILURE,
        payload: err,
      });
      throw err;
    }
  },
};

export { landingProductsActionCreator as default };
