import styles from './index.module.scss';
import UnscopedTranslatedText, { withNamespace } from '../../textDisplays/TranslatedText';
import { noop } from '../../../../utils/constants';
import DSIcon from '../../icons/ds';
import { PasswordInput, SmallParagraph, StandardParagraph } from '@idm/ui-components';

import React from 'react';
import PropTypes from 'prop-types';

const TranslatedText = withNamespace('PW_INPUT');

class PasswordCreationInput extends React.Component {
  static propTypes = {
    onValidate: PropTypes.func,
    showRequirements: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    onValidate: noop,
    showRequirements: false,
    className: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      validationResult: [],
    };
  }

  getAddPasswordRules() {
    const { showRequirements } = this.props;

    if (!showRequirements) {
      return {};
    }
    return {
      'data-rule-upper-case-required': true,
      'data-rule-lower-case-required': true,
      'data-rule-special-char-required': true,
      'data-rule-digit': true,
      'data-rule-min-length': 8,
      'data-rule-max-length': 72,
      'data-msg-upper-case-required': () => <TranslatedText i18nKey="UPPER_CASE_RULE" />,
      'data-msg-lower-case-required': () => <TranslatedText i18nKey="LOWER_CASE_RULE" />,
      'data-msg-special-char-required': () => <TranslatedText i18nKey="SPECIAL_CHAR_RULE" />,
      'data-msg-digit': () => <TranslatedText i18nKey="DIGIT_RULE" />,
      'data-msg-min-length': () => <TranslatedText i18nKey="MIN_LENGTH_RULE" />,
      'data-msg-max-length': () => <TranslatedText i18nKey="MAX_LENGTH_RULE" />,
    };
  }

  getRuleTitles() {
    return {
      'upper-case-required': <TranslatedText i18nKey="UPPER_CASE_RULE" />,
      'lower-case-required': <TranslatedText i18nKey="LOWER_CASE_RULE" />,
      'special-char-required': <TranslatedText i18nKey="SPECIAL_CHAR_RULE" />,
      digit: <TranslatedText i18nKey="DIGIT_RULE" />,
      'min-length': <TranslatedText i18nKey="MIN_LENGTH_RULE" />,
      'max-length': <TranslatedText i18nKey="DOES_NOT_EXCEED_MAX_LENGTH" />,
    };
  }

  updateRulesMetStatus = (value) => {
    this.setState({ validationResult: value.results });
    const { onValidate } = this.props;
    let rulesMet = true;

    value.results.forEach((rule) => {
      rulesMet = rulesMet && rule.valid;
    });
    onValidate(rulesMet);
  };

  getRulesMetStatus() {
    const { validationResult } = this.state;
    const resultDictionary = {};

    validationResult.forEach((ruleResult) => {
      resultDictionary[ruleResult.ruleName] = ruleResult.valid;
    });
    return resultDictionary;
  }

  renderRulesMet() {
    const resultDictionary = this.getRulesMetStatus();
    const titles = this.getRuleTitles();

    return Object.keys(titles).map((rule, index) =>
      this.renderRequirement(index, rule, titles[rule], resultDictionary[rule])
    );
  }

  renderRequirement = (index, rule, title, isValid) => {
    const pwReqCheckCircleSize = 24;
    const pwReqCheckCircleColor = '#544E4A';

    return (
      <div key={index} data-meta-id={rule} className={styles.rule}>
        <SmallParagraph className={styles.icon}>
          {isValid ? (
            <span data-rule-success={true} className={styles.iconSpanWrapper}>
              <DSIcon
                iconId="icon-check-circle"
                width={pwReqCheckCircleSize}
                height={pwReqCheckCircleSize}
                color={pwReqCheckCircleColor}
              />
            </span>
          ) : (
            <span data-rule-success={false} className={styles.iconSpanWrapper}>
              <DSIcon
                iconId="icon-circle-outline"
                width={pwReqCheckCircleSize}
                height={pwReqCheckCircleSize}
                color={pwReqCheckCircleColor}
              />
            </span>
          )}
        </SmallParagraph>
        <SmallParagraph className={styles.ruleName}>{title}</SmallParagraph>
      </div>
    );
  };

  renderRequirements() {
    const { showRequirements } = this.props;

    if (!showRequirements) {
      return null;
    }

    return (
      <div className={styles.passwordContainer}>
        <div className={styles.validationBox} id="change-password-requirements">
          <SmallParagraph>
            <div className={styles.validationBoxHeaderWrapper}>
              <UnscopedTranslatedText
                i18nKey="ACCOUNT_SETTINGS.CHANGE_PW.NEW_PW_REQUIREMENT"
                className={styles.validationBoxHeader}
              />
            </div>
          </SmallParagraph>
          <div className={styles.rulesMet}>{this.renderRulesMet()}</div>
          <SmallParagraph>
            <TranslatedText i18nKey="ADDITIONAL_VALIDATION" />
          </SmallParagraph>
        </div>
      </div>
    );
  }

  renderShowLink(show) {
    const toggleShow = () => this.setState({ show: !show });

    return (
      <StandardParagraph onClick={toggleShow} data-meta-id="show-link" className={styles.showLink}>
        {show ? (
          <UnscopedTranslatedText i18nKey="NOT_VALIDATED_PW_INPUT.HIDE" />
        ) : (
          <UnscopedTranslatedText i18nKey="NOT_VALIDATED_PW_INPUT.SHOW" />
        )}
      </StandardParagraph>
    );
  }

  render() {
    const { show } = this.state;
    const { className, ...otherProps } = this.props;

    return (
      <div className={className}>
        <div className={styles.inputContainer}>
          <PasswordInput
            className={styles.passwordInput}
            onValidationFinished={this.updateRulesMetStatus}
            show={show}
            {...this.getAddPasswordRules()}
            {...otherProps}
          >
            {this.renderShowLink(show)}
          </PasswordInput>
        </div>
        {this.renderRequirements()}
      </div>
    );
  }
}

export default PasswordCreationInput;
