import HttpClient from '../../utils/http/HttpClient';
import getConstants from '../../utils/constants';
import TranslationsUtilities from '../../utils/translation/utility';

const constants = getConstants(process.env.BASE_URL);
const identityOrchestratorUrl = constants.serviceBaseUrls.identityOrchestratorV1;

class TranslationsDatasource {
  constructor() {
    this.httpClient = HttpClient.createInstance({
      baseURL: identityOrchestratorUrl,
    });
  }

  async loadKeys(language) {
    const url = '/resources/identity-ui-client';

    const response = await this.httpClient.get(url, {
      headers: {
        'x-ohcm-user-language': language,
      },
    });

    return TranslationsUtilities.generateDictionaryFromResourcePackge(response.data);
  }

  loadResourcePackage(language) {
    return this.loadKeys(language);
  }
}

export default new TranslationsDatasource();
