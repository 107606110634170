import actionTypes from '../../actiontypes';

const initialState = {
  devices: [],
  revokedDeviceIds: [],
  isLoadingData: false,
  isUpdatingData: false,
};

function devicesReducer(state = initialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.ACCOUNT_SETTINGS_DEVICES.SET_DEVICES:
      return {
        ...state,
        devices: actionPayload,
      };
    case actionTypes.ACCOUNT_SETTINGS_DEVICES.ADD_REVOKED_DEVICE_ID:
      return {
        ...state,
        revokedDeviceIds: [...state.revokedDeviceIds, actionPayload],
      };
    case actionTypes.ACCOUNT_SETTINGS_DEVICES.LOAD_DATA_REQUEST:
      return {
        ...state,
        isLoadingData: true,
      };
    case actionTypes.ACCOUNT_SETTINGS_DEVICES.LOAD_DATA_SUCCESS:
    case actionTypes.ACCOUNT_SETTINGS_DEVICES.LOAD_DATA_FAILURE:
      return {
        ...state,
        isLoadingData: false,
      };
    case actionTypes.ACCOUNT_SETTINGS_DEVICES.UPDATE_DATA_REQUEST:
      return {
        ...state,
        isUpdatingData: true,
      };
    case actionTypes.ACCOUNT_SETTINGS_DEVICES.UPDATE_DATA_SUCCESS:
    case actionTypes.ACCOUNT_SETTINGS_DEVICES.UPDATE_DATA_FAILURE:
      return {
        ...state,
        isUpdatingData: false,
      };
    case actionTypes.ACCOUNT_SETTINGS_DEVICES.RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
}

export { devicesReducer as default };
