import DigitRule from './DigitRule';
import UpperCaseRule from './UpperCaseRule';
import LowerCaseRule from './LowerCaseRule';
import SpecialCharRule from './SpecialCharRule';
import UsernameRule from './UsernameRule';
import NoDuplicateUserRule from './NoDuplicateUserRule';

import { ValidationRuleManager } from '@tbiegner99/react-forms';

const registerRuleIfExists = (rule) => {
  if (!ValidationRuleManager.doesRuleExst(rule.ruleName)) {
    ValidationRuleManager.registerRule(rule);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const registerRules = () => {
  registerRuleIfExists(DigitRule);
  registerRuleIfExists(UpperCaseRule);
  registerRuleIfExists(LowerCaseRule);
  registerRuleIfExists(SpecialCharRule);
  registerRuleIfExists(UsernameRule);
  registerRuleIfExists(NoDuplicateUserRule);
};
