import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

class Footer extends Component {
  static propTypes = {
    links: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.node.isRequired,
        label: PropTypes.node.isRequired,
      }).isRequired
    ),
    className: PropTypes.string,
  };

  static defaultProps = {
    links: [
      {
        label: 'Privacy',
        url: 'https://privacy.adp.com/privacy.html',
      },
      {
        label: 'Legal',
        url: '/auth/legal',
      },
    ],
    className: '',
  };

  getLinks = () =>
    this.props.links.map((link) =>
      /^https?:\/\//.test(link.url) ? (
        <a
          key={link.url}
          className="footer-link"
          rel="noopener noreferrer"
          target="_blank"
          href={link.url}
        >
          {link.label}
        </a>
      ) : (
        <Link key={link.url} className="footer-link" to={link.url}>
          {link.label}
        </Link>
      )
    );

  render() {
    const { className } = this.props;

    return <div className={`footer ${className}`}>{this.getLinks()}</div>;
  }
}

export { Footer as default };
