import BaseActionCreator from './BaseActionCreator';
import actionTypes from '../actiontypes';
import http from '../utils/http';

const { LEGAL_AGREEMENTS } = actionTypes;

class LegalAgreementsActionCreator extends BaseActionCreator {
  async loadData(keys) {
    try {
      this.dispatch(actionTypes.LEGAL_AGREEMENTS.LOAD_DATA_REQUEST);

      const { data: agreementsResData } = await http.getLegalAgreementsByKeys(keys);

      const payload = {
        agreements: agreementsResData.data,
      };

      this.dispatch(LEGAL_AGREEMENTS.SET_DATA, payload);

      this.dispatch(actionTypes.LEGAL_AGREEMENTS.LOAD_DATA_SUCCESS);
    } catch (e) {
      this.dispatch(actionTypes.LEGAL_AGREEMENTS.LOAD_DATA_FAILURE);
    }
  }

  async agreeToLegalAgreements(agreementsData) {
    try {
      this.dispatch(actionTypes.LEGAL_AGREEMENTS.UPDATE_DATA_REQUEST);

      await http.agreeToLegalAgreements(agreementsData);

      this.dispatch(actionTypes.LEGAL_AGREEMENTS.UPDATE_DATA_SUCCESS);
    } catch (e) {
      this.dispatch(actionTypes.LEGAL_AGREEMENTS.UPDATE_DATA_FAILURE);
    }
  }
}

export default new LegalAgreementsActionCreator();
