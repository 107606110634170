import UtilityHelper from '../../../utils/helpers';
import { noop } from '../../../utils/constants';
import { NotValidatedInput } from '@idm/ui-components';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

class NotValidatedPasswordInput extends Component {
  static propTypes = {
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    dataMetaId: PropTypes.string,
    hidePasswordToggle: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    value: PropTypes.string,
    showPasswordText: PropTypes.string,
    hidePasswordText: PropTypes.string,
  };

  static defaultProps = {
    autoComplete: '',
    autoFocus: false,
    dataMetaId: '',
    hidePasswordToggle: false,
    id: UtilityHelper.generateUniqueId(),
    label: '',
    onBlur: noop,
    onFocus: noop,
    onChange: noop,
    onKeyPress: noop,
    value: '',
    showPasswordText: 'Show',
    hidePasswordText: 'Hide',
  };

  constructor(props) {
    super(props);

    this.state = { showPassword: false };
  }

  toggleVisibility = (event) => {
    const { showPassword } = this.state;

    event.stopPropagation();

    this.setState({
      showPassword: !showPassword,
    });
  };

  renderPasswordToggle(type) {
    const { hidePasswordToggle, showPasswordText, hidePasswordText } = this.props;
    const { showPassword } = this.state;

    if (hidePasswordToggle) {
      return null;
    }

    return (
      <span
        onClick={this.toggleVisibility}
        className={`password-visibility-toggle ${type}`}
        role="button"
      >
        {!showPassword ? showPasswordText : hidePasswordText}
      </span>
    );
  }

  render() {
    const {
      autoComplete,
      autoFocus,
      dataMetaId,
      id,
      label,
      onBlur,
      onFocus,
      onChange,
      onKeyPress,
      value,
    } = this.props;
    const { showPassword } = this.state;
    const type = showPassword ? 'text' : 'password';
    const passwordToggle = this.renderPasswordToggle(type);

    return (
      <div className="password-input-container">
        <NotValidatedInput
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          dataMetaId={dataMetaId}
          id={id}
          label={label}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          onKeyPress={onKeyPress}
          type={type}
          value={value}
        />
        {passwordToggle}
      </div>
    );
  }
}

export { NotValidatedPasswordInput as default };
