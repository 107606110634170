import styles from './index.module.scss';
import utils from '../../../../utils/helpers';
import { withNamespace } from '../../../../components/elements/textDisplays/TranslatedText';
import urls from '../../../../utils/urls';
import SuccessNotificationPage from '../../../../components/SuccessNotification';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PageHeader } from '@idm/ui-components';

const TranslatedText = withNamespace('OBLIGATION.SAML_REQUIRED');

class SamlRedirectObligation extends Component {
  static propTypes = {
    args: PropTypes.shape({
      clientId: PropTypes.string,
    }).isRequired,
  };

  redirectToSaml(clientId) {
    utils.redirectTo(urls.getSamlPageForClient(clientId));
  }

  render() {
    const {
      args: { clientId },
    } = this.props;

    return (
      <section>
        <PageHeader className={styles.header}>
          <TranslatedText i18nKey="TITLE" />
        </PageHeader>
        <div className={styles.spacer} />
        <SuccessNotificationPage onContinueAction={() => this.redirectToSaml(clientId)}>
          <TranslatedText i18nKey="MESSAGE" />
        </SuccessNotificationPage>
      </section>
    );
  }
}

export default SamlRedirectObligation;
