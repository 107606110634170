import actionCreator from '../../actioncreators/emailVerifyNotification';
import applicationActionCreator from '../../actioncreators/application';
import meActionCreator from '../../actioncreators/me';
import { NoDuplicateRule } from '../../utils/validator/rules';
import { getI18nContext } from '../TranslatedText';
import {
  Notification,
  ButtonContainer,
  SecondaryButton,
  PrimaryButton,
  LinkButton,
} from '../../components';
import EnterCodeView from '../EnterCode';
import urls from '../../utils/urls';
import utils from '../../utils/helpers';
import {
  StandardParagraph,
  Header3,
  TextInput,
  InlineMessage,
  ValidatedInput,
  MessageTypes,
} from '@idm/ui-components';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const FlowStates = {
  SEND_CODE: 'SEND_CODE',
  ENTER_CODE: 'ENTER_CODE',
};

class EmailVerifyNotification extends Component {
  static propTypes = {
    formEmail: PropTypes.string.isRequired,
    isValidating: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    isInitializing: PropTypes.bool.isRequired,
    i18nContext: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    dispatchResetState: PropTypes.func.isRequired,
    dispatchSetFormField: PropTypes.func.isRequired,
    dispatchSetFormFieldValidationResult: PropTypes.func.isRequired,
    dispatchSendVerificationCode: PropTypes.func.isRequired,
    dispatchInitialize: PropTypes.func.isRequired,
    dispatchVerifyEmail: PropTypes.func.isRequired,
    dispatchVerificationComplete: PropTypes.func.isRequired,

    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  constructor(props) {
    super(props);
    this.state = {
      flowState: FlowStates.SEND_CODE,
      showEmailChangeForm: false,
      isMaximumAttemptsExceeded: false,
    };
  }

  componentDidMount() {
    const { location, dispatchInitialize } = this.props;
    const redirectUrl = encodeURIComponent(`${location.pathname}`);

    dispatchInitialize(`/auth/login?redirectUrl=${redirectUrl}`);
  }

  componentWillUnmount() {
    const { dispatchResetState } = this.props;

    dispatchResetState();
  }

  getValidationRules = (isSubmitting) => {
    const { i18nContext } = this.props;
    const rules = [
      new ValidatedInput.RequiredRule(i18nContext.getRawTextForKey('FIELD_REQUIRED')),
      new ValidatedInput.EmailRule(i18nContext.getRawTextForKey('EMAIL_REQUIRED')),
    ];

    if (isSubmitting) {
      rules.push(
        new NoDuplicateRule({
          failMessage: i18nContext.getRawTextForKey('DUP_EMAIL_RULE'),
          tooManyAttemptsMessage: this.tooManyAttempts,
          hitSnagMessage: i18nContext.getRawTextForKey('HIT_A_SNAG'),
        })
      );
    }
    return rules;
  };

  handleValidationChange(field, validationResult) {
    const { dispatchSetFormFieldValidationResult } = this.props;

    dispatchSetFormFieldValidationResult(field, validationResult);
  }

  handleChange(field, event) {
    const { dispatchSetFormField } = this.props;

    dispatchSetFormField(field, event.target.value);
  }

  submitChangeEmailForm = async (evt) => {
    evt.preventDefault();
    try {
      const submissionRules = this.getValidationRules(true);

      await this.updateEmailRef.assertValid(submissionRules);
      await this.submitForm();
    } catch (err) {
      //not valid
    }
  };

  handleEmailChange = this.handleChange.bind(this, 'email');

  clearEmailForm = () => {
    const { dispatchSetFormField } = this.props;

    dispatchSetFormField('email', '');
  };

  handleEmailValidationChange = this.handleValidationChange.bind(this, 'email');

  sendVerificationCode = async (email, emailToUpdate) => {
    const { dispatchSendVerificationCode } = this.props;

    try {
      const token = await dispatchSendVerificationCode(email, emailToUpdate);

      this.setState({ flowState: FlowStates.ENTER_CODE, token });
    } catch (e) {
      //TODO: handle this.
    }
  };

  tooManyAttempts = () => {
    const { i18nContext } = this.props;

    this.setState({ isMaximumAttemptsExceeded: true });
    return i18nContext.getRawTextForKey('TOO_MANY_ATTEMPTS');
  };

  cancelChangeForm = () => {
    this.setState({ showEmailChangeForm: false });
  };

  checkCode = (code) => {
    const { dispatchVerifyEmail } = this.props;
    const { token } = this.state;

    return dispatchVerifyEmail(token, code);
  };

  async submitForm() {
    const { email, formEmail } = this.props;

    await this.sendVerificationCode(formEmail, email);
    this.clearEmailForm();
    this.setState({
      currentEmail: formEmail,
      emailToUpdate: email,
      showEmailChangeForm: false,
      flowState: FlowStates.ENTER_CODE,
    });
  }

  renderChangeEmailInput() {
    const { i18nContext, isValidating, formEmail } = this.props;
    const rules = this.getValidationRules(false);
    const setRef = (obj) => {
      this.updateEmailRef = obj;
    };

    return (
      <div data-meta-id="change-email-form">
        <StandardParagraph>{i18nContext.getTextForKey('CHANGE_EMAIL_BODY')}</StandardParagraph>
        <ValidatedInput
          forceValidationCheck={isValidating}
          handleValidationChange={this.handleEmailValidationChange}
          onChange={this.handleEmailChange}
          ref={setRef}
          rules={rules}
          value={formEmail}
          label={i18nContext.getTextForKey('EMAIL_LABEL')}
          InputComponent={TextInput}
          id="email-input"
          dataMetaId="email-input"
        />
        <div className="button-row">
          <ButtonContainer>
            <SecondaryButton onClick={this.cancelChangeForm} data-meta-id="cancel-button">
              {i18nContext.getTextForKey('CANCEL_BUTTON')}
            </SecondaryButton>
            <PrimaryButton type="submit" data-meta-id="submit-button">
              {i18nContext.getTextForKey('UPDATE_BUTTON')}
            </PrimaryButton>
          </ButtonContainer>
        </div>
      </div>
    );
  }

  renderChangeEmailLink() {
    const { i18nContext } = this.props;

    return (
      <div>
        <StandardParagraph className="paragraph_center">
          <div>{i18nContext.getTextForKey('CHANGE_EMAIL_TEXT')}</div>
          <LinkButton
            onClick={() => this.setState({ showEmailChangeForm: true })}
            data-meta-id="change-email-link"
          >
            {i18nContext.getTextForKey('CHANGE_EMAIL_BUTTON')}
          </LinkButton>
        </StandardParagraph>
      </div>
    );
  }

  renderAttemptsError = () => {
    const { i18nContext } = this.props;

    return (
      <div>
        <InlineMessage type={MessageTypes.ERROR}>
          {i18nContext.getTextForKey('TOO_MANY_ATTEMPTS')}
        </InlineMessage>
        <StandardParagraph>
          {i18nContext.getTextForKey('TOO_MANY_ATTEMPTS_EXPLANATION')}
        </StandardParagraph>
      </div>
    );
  };

  renderChangeEmailForm() {
    const { i18nContext } = this.props;
    const { isMaximumAttemptsExceeded, showEmailChangeForm } = this.state;

    if (!showEmailChangeForm) {
      return this.renderChangeEmailLink();
    }
    return (
      <form onSubmit={this.submitChangeEmailForm} className="update-email-container">
        <Header3>{i18nContext.getTextForKey('CHANGE_EMAIL_HEADER')}</Header3>

        {isMaximumAttemptsExceeded ? this.renderAttemptsError() : this.renderChangeEmailInput()}
      </form>
    );
  }

  renderNotificationContent() {
    const { i18nContext, email } = this.props;

    return (
      <div>
        <StandardParagraph>
          {i18nContext.getTextForKey('EMAIL_VERIFY_OBLIGATION_MESSAGE', {
            email: <b>{email}</b>,
          })}
        </StandardParagraph>
        <SecondaryButton
          onClick={() => this.sendVerificationCode(email)}
          data-meta-id="resend-verification-link"
        >
          {i18nContext.getTextForKey('RESEND_EMAIL_BUTTON')}
        </SecondaryButton>
      </div>
    );
  }

  renderSendCodeView() {
    const { i18nContext } = this.props;
    const notificationTitle = i18nContext.getTextForKey('EMAIL_VERIFY_OBLIGATION_TITLE');

    return (
      <section className="email-verify-notification">
        <StandardParagraph className="resend-email-notification-container">
          <Notification status="info" title={notificationTitle}>
            {this.renderNotificationContent()}
          </Notification>
          <div className="divider" />
        </StandardParagraph>
        {this.renderChangeEmailForm()}
      </section>
    );
  }

  renderEnterCodeView() {
    const { email, dispatchVerificationComplete } = this.props;
    const { currentEmail, emailToUpdate } = this.state;

    const goBack = () =>
      this.setState({ currentEmail: null, emailToUpdate: null, flowState: FlowStates.SEND_CODE });
    const emailToSend = currentEmail || email;
    const authMethod = {
      displayValue: emailToSend,
    };

    return (
      <div>
        <EnterCodeView
          selectedAuthMethod={authMethod}
          onCheckCode={this.checkCode}
          onCancel={goBack}
          onResendCode={() => this.sendVerificationCode(emailToSend, emailToUpdate)}
          onVerificationComplete={dispatchVerificationComplete}
        />
      </div>
    );
  }

  render() {
    const { isInitializing } = this.props;
    const { flowState } = this.state;

    if (isInitializing) {
      return null;
    }

    switch (flowState) {
      case FlowStates.SEND_CODE:
        return this.renderSendCodeView();
      case FlowStates.ENTER_CODE:
      default:
        return this.renderEnterCodeView();
    }
  }
}

function mapStateToProps(state) {
  return {
    formEmail: state.emailVerifyNotification.form.email.value,
    isAllValid: state.emailVerifyNotification.form.email.isValid,
    isValidating: state.emailVerifyNotification.form.email.isValidating,
    email: state.me.main.email,
    isEmailVerified: state.me.main.isEmailVerified,
    isInitializing: state.me.main.isCheckingAuth || state.me.loadData.isLoadingData,
    i18nContext: getI18nContext('VERIFY_EMAIL_NOTIFICATION', state.translation.main.dictionary),
    isMaximumAttemptsExceeded: false,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    dispatchResetState() {
      dispatch(actionCreator.resetState());
    },

    dispatchSetFormField(field, value) {
      dispatch(actionCreator.setFormField(field, value));
    },

    dispatchSetFormIsValidating() {
      dispatch(actionCreator.setFormIsValidating());
    },

    dispatchSetFormFieldValidationResult(field, validationResult) {
      dispatch(actionCreator.setFormFieldValidationResult(field, validationResult));
    },

    dispatchSendVerificationCode(email, emailToUpdate, history) {
      return dispatch(actionCreator.resendVerificationEmail(email, emailToUpdate, history));
    },

    dispatchVerifyEmail(token, code) {
      return dispatch(actionCreator.verifyEmailWithCode(token, code));
    },

    async dispatchVerificationComplete() {
      const { location } = ownProps;
      const { redirectUrl } = utils.parseQueryString(location.search);

      await applicationActionCreator.clearObligations();
      applicationActionCreator.changeUrl(redirectUrl || urls.LIFION_HOME);
    },

    dispatchInitialize(redirectLocation) {
      dispatch(meActionCreator.initialize(redirectLocation));
    },
  };
}

export { EmailVerifyNotification as UnwrappedEmailVerifyNotification };
export default connect(mapStateToProps, mapDispatchToProps)(EmailVerifyNotification);
