import buildFormActionCreator from './builders/form';
import obligationsActionCreator from './obligations';
import applicationActionCreator from './application';
import actionTypes, { actionTypeSections } from '../actiontypes';
import http from '../utils/http';
import cookieUtils from '../utils/cookie';
import getConstants from '../utils/constants';
import UtilityHelper from '../utils/helpers';
import urls from '../utils/urls';
import WindowUtils from '../utils/helpers/window';

const { authenticationMethod } = getConstants();

const loginActionCreator = {
  ...buildFormActionCreator(actionTypeSections.LOGIN),

  resetMainState() {
    return {
      type: actionTypes.LOGIN.RESET_STATE,
    };
  },

  resetState() {
    return (dispatch) => {
      dispatch(this.resetMainState());

      dispatch(this.resetFormState());
    };
  },

  loginRequest() {
    return {
      type: actionTypes.LOGIN.BASIC_LOGIN_REQUEST,
    };
  },

  loginSuccess() {
    return {
      type: actionTypes.LOGIN.BASIC_LOGIN_SUCCESS,
    };
  },

  loginFailure(error) {
    return {
      type: actionTypes.LOGIN.BASIC_LOGIN_FAILURE,
      payload: { error },
    };
  },

  usernameValidationRequest() {
    return {
      type: actionTypes.LOGIN.CHECK_USERNAME_REQUEST,
      payload: { isCheckingUsername: true },
    };
  },

  usernameValidationSuccess() {
    return {
      type: actionTypes.LOGIN.CHECK_USERNAME_SUCCESS,
      payload: { isCheckingUsername: false, showPasswordField: true },
    };
  },

  usernameValidationFailure(error) {
    return {
      type: actionTypes.LOGIN.CHECK_USERNAME_FAILURE,
      payload: { isCheckingUsername: false, showPasswordField: true, error },
    };
  },

  checkUsername(username) {
    return async (dispatch) => {
      try {
        dispatch(this.usernameValidationRequest(true));

        const {
          data: { isLifionLogin, isNexoClientRebrandEnabled },
        } = await http.checkAuthPath(username);

        if (isLifionLogin) {
          dispatch(this.usernameValidationSuccess());
        } else {
          const redirectUrl = UtilityHelper.getRedirectUrl(
            WindowUtils.getNexoBaseUrl(isNexoClientRebrandEnabled)
          );

          applicationActionCreator.changeUrl(redirectUrl);
        }
      } catch (err) {
        dispatch(this.usernameValidationFailure(err.errors[0]));
      }
    };
  },

  login(username, password, recaptchaToken, redirectUrl) {
    return async (dispatch) => {
      try {
        dispatch(this.loginRequest());
        const res = await http.login(username, password, recaptchaToken);

        const personalSettings = res.data.personalSettings
          ? {
              language: res.data.personalSettings.preferredLanguage,
              region: res.data.personalSettings.region,
              timezone: res.data.personalSettings.timezone,
            }
          : {};

        cookieUtils.setRegionalSettingsCookies(personalSettings);

        cookieUtils.removeCookieByName('lifion_user_id');

        try {
          const FOR_LOGIN = true;

          await obligationsActionCreator.checkObligation(redirectUrl, FOR_LOGIN);
        } catch (err) {
          return;
        }

        dispatch(this.loginSuccess());

        const serverRedirectUrl = res.data.redirectUrl;

        const url =
          serverRedirectUrl && redirectUrl && redirectUrl.startsWith('/')
            ? `${serverRedirectUrl}${redirectUrl}`
            : redirectUrl;

        applicationActionCreator.changeUrl(url);
      } catch (error) {
        dispatch(this.loginFailure(error.errors[0]));
      }
    };
  },

  hidePasswordInputDisplay(hidePassword) {
    return async (dispatch) => {
      dispatch({
        type: actionTypes.LOGIN.HIDE_PASSWORD_INPUT_DISPLAY,
        payload: { showPasswordField: !hidePassword },
      });
    };
  },

  redirectToExternalBaseUrl(externalBaseUrl) {
    return async () => {
      const modifiedExternalBaseUrl = externalBaseUrl?.replace('oneadp', 'adp');

      applicationActionCreator.changeUrl(`${modifiedExternalBaseUrl}${urls.LOGIN}`);
    };
  },

  checkAuthRequest() {
    return {
      type: actionTypes.LOGIN.CHECK_AUTH_REQUEST,
    };
  },

  checkAuthSuccess({ authenticationMode, levelOfAssurance }) {
    return {
      type: actionTypes.LOGIN.CHECK_AUTH_SUCCESS,
      payload: {
        isAuthenticatedByNexo: authenticationMode === authenticationMethod.NEXO,
        levelOfAssurance,
      },
    };
  },

  checkAuthFailure() {
    return {
      type: actionTypes.LOGIN.CHECK_AUTH_FAILURE,
    };
  },

  redirectIfLoggedIn(redirectUrl) {
    return async (dispatch) => {
      dispatch(this.checkAuthRequest());
      try {
        const {
          data: { authentication_method: authenticationMode, levelOfAssurance },
        } = await http.checkAuthentication();

        dispatch(this.checkAuthSuccess({ authenticationMode, levelOfAssurance }));

        applicationActionCreator.changeUrl(redirectUrl);
      } catch (err) {
        dispatch(this.checkAuthFailure());
        if (cookieUtils.getCookie('lifionSsoSession')) {
          applicationActionCreator.changeUrl(`/auth/logout?redirectUrl=${redirectUrl}`);
        }
      }
    };
  },
};

export { loginActionCreator as default };
