import Urls from '../../utils/urls';
import React from 'react';
import PropTypes from 'prop-types';

class AuthenticatedPage extends React.Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    location: PropTypes.shape().isRequired,
    noRedirect: PropTypes.bool,

    children: PropTypes.node,
    onCheckAuthenticated: PropTypes.func.isRequired,
    onChangeUrl: PropTypes.func.isRequired,
  };

  static defaultProps = {
    noRedirect: false,
    isAuthenticated: null,
    children: null,
  };

  componentDidMount() {
    const { onCheckAuthenticated } = this.props;

    onCheckAuthenticated();
  }

  componentDidUpdate(prevProps) {
    const { location, isAuthenticated, onChangeUrl, noRedirect } = this.props;

    if (prevProps.isAuthenticated !== isAuthenticated && isAuthenticated === false) {
      const redirectParam = noRedirect ? '' : `?redirectUrl=${location.pathname}`;

      onChangeUrl(`${Urls.LOGIN}${redirectParam}`);
    }
  }

  render() {
    const { isAuthenticated, children } = this.props;

    if (isAuthenticated) {
      return children;
    }
    return null;
  }
}

export default AuthenticatedPage;
