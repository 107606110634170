import actionTypes from '../actiontypes';

const initialState = {
  toastMessages: [],
};

function messagesReducer(state = initialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.MESSAGES.ADD_TOAST_MESSAGE:
      return {
        ...state,
        toastMessages: [actionPayload, ...state.toastMessages],
      };
    case actionTypes.MESSAGES.REMOVE_TOAST_MESSAGE:
      return {
        ...state,
        toastMessages: state.toastMessages.filter(
          (toastMessage) => toastMessage.id !== actionPayload.id
        ),
      };
    case actionTypes.MESSAGES.RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
}

export { messagesReducer as default };
