import PersonalInfo from './PersonalInfo';
import AccountInfo from './AccountInfo';
import TranslationsContext from '../../context/TranslationsContext';
import EnterCode from '../EnterCode';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const RegistrationSteps = {
  PERSONAL_INFO: 'personal-info',
  VERIFY_EMAIL: 'verify-email',
  ACCOUNT_INFO: 'account-info',
};

class CreateAccount extends Component {
  static contextType = TranslationsContext;

  static propTypes = {
    onSendEmailCode: PropTypes.func.isRequired,
    onCheckCode: PropTypes.func.isRequired,
    onVerifyToken: PropTypes.func.isRequired,
    onPersonalInfoSubmissionFailure: PropTypes.func.isRequired,
    onCreateAccount: PropTypes.func.isRequired,

    isSSO: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    associateToken: PropTypes.string.isRequired,
    emailVerificationToken: PropTypes.string,
  };

  static defaultProps = {
    emailVerificationToken: null,
    isSSO: false,
    firstName: '',
    lastName: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      flowStep: RegistrationSteps.PERSONAL_INFO,
      email: null,
      code: null,
    };
  }

  componentDidMount() {
    const { onVerifyToken, associateToken, isSSO } = this.props;

    onVerifyToken(associateToken, isSSO);
  }

  onPersonalInfoSubmitted = async (formData) => {
    const { associateToken, onSendEmailCode, onPersonalInfoSubmissionFailure } = this.props;
    const { email } = formData;

    try {
      await onSendEmailCode(email, associateToken);
      this.setState({ flowStep: RegistrationSteps.VERIFY_EMAIL, email });
    } catch (err) {
      onPersonalInfoSubmissionFailure(err);
    }
  };

  onSendEmailCodeVerified = () => {
    const { isSSO, emailVerificationToken, onCreateAccount, firstName, lastName } = this.props;
    const { email, code } = this.state;

    if (isSSO) {
      const data = {
        email,
        firstName,
        lastName,
        code,
      };

      onCreateAccount(emailVerificationToken, isSSO, data);
    } else {
      this.changeFlowStep(RegistrationSteps.ACCOUNT_INFO);
    }
  };

  checkCode = (code) => {
    const { emailVerificationToken, onCheckCode } = this.props;

    this.setState({ code });
    return onCheckCode(code, emailVerificationToken);
  };

  changeFlowStep(newStep) {
    this.setState({ flowStep: newStep });
  }

  renderAccountInfo() {
    const { emailVerificationToken, associateToken } = this.props;
    const { code } = this.state;

    return (
      <AccountInfo
        code={code}
        emailVerificationToken={emailVerificationToken}
        associateToken={associateToken}
        onCancel={() => this.changeFlowStep(RegistrationSteps.PERSONAL_INFO)}
      />
    );
  }

  renderPersonalInfo() {
    const { associateToken, isSSO, firstName, lastName } = this.props;

    return (
      <PersonalInfo
        token={associateToken}
        isSSO={isSSO}
        firstName={firstName}
        lastName={lastName}
        dispatchSubmitForm={this.onPersonalInfoSubmitted}
      />
    );
  }

  renderVerifyEmail() {
    const { associateToken, onSendEmailCode } = this.props;
    const { email } = this.state;
    const { language } = this.context;

    const selectedAuthMethod = {
      displayValue: email,
    };

    return (
      <EnterCode
        selectedAuthMethod={selectedAuthMethod}
        language={language}
        onCancel={() => this.changeFlowStep(RegistrationSteps.PERSONAL_INFO)}
        onVerificationComplete={this.onSendEmailCodeVerified}
        onResendCode={() => onSendEmailCode(email, associateToken)}
        onCheckCode={this.checkCode}
      />
    );
  }

  render() {
    const { flowStep } = this.state;

    switch (flowStep) {
      case RegistrationSteps.PERSONAL_INFO:
        return this.renderPersonalInfo();
      case RegistrationSteps.VERIFY_EMAIL:
        return this.renderVerifyEmail();
      case RegistrationSteps.ACCOUNT_INFO:
      default:
        return this.renderAccountInfo();
    }
  }
}

export default CreateAccount;
export { RegistrationSteps };
