const getPersonalSettings = (data) =>
  data.personalSettings
    ? {
        language: data.personalSettings.preferredLanguage,
        region: data.personalSettings.region,
        timezone: data.personalSettings.timezone,
      }
    : {};

const fromLoginResponse = (data) => {
  return {
    personalSettings: getPersonalSettings(data),
    redirectUrl: data.redirectUrl,
  };
};

const toLoginRequest = (data) => {
  return {
    username: data.username,
    password: data.password,
  };
};

export default {
  fromLoginResponse,
  toLoginRequest,
};
