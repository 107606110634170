import styles from './index.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import combineClasses from 'classnames';

const ContactInfoValue = (props) => {
  const { className, children, ...otherProps } = props;
  const cssClasses = combineClasses(styles.contactInfoValue, className);

  return (
    <span {...otherProps} className={cssClasses}>
      {children}
    </span>
  );
};

ContactInfoValue.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

ContactInfoValue.defaultProps = {
  children: null,
  className: '',
};

export default ContactInfoValue;
