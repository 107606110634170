import Link from './Link';
import applicationActionCreator from '../../../../actioncreators/application';
import { connect } from 'react-redux';

function mapDispatchToProps() {
  return {
    onChangeUrl(url) {
      applicationActionCreator.changeUrl(url);
    },
  };
}

export default connect(mapDispatchToProps)(Link);
