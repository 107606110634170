import ContactInfoPage from './ContactInfoPage';
import actionCreator from '../../../actioncreators/accountSettings/contactInfo';
import { getI18nContext } from '../../TranslatedText';
import messagesActionCreator from 'actioncreators/messages';
import UtilityHelper from 'utils/helpers';
import { staticConstants } from 'utils/constants';
import { connect } from 'react-redux';

const { toastMessage } = staticConstants;

function mapStateToProps(state) {
  const { accountSettingsContactInfo, accountSettingsLocalization, translation } = state;
  const { dictionary } = translation.main;
  const i18nContext = getI18nContext('ACCOUNT_SETTINGS.CONTACT_INFO', dictionary);
  const language = accountSettingsLocalization.main.getCurrentLanguage();
  const { isRequireAdpEmailFlagEnabled, isOnlyAllowAdpEmailFlagEnabled, clientId } =
    accountSettingsContactInfo;

  return {
    i18nContext,
    isAuthenticationMethodsLoading: accountSettingsContactInfo.isLoadingData,
    authenticationMethods: accountSettingsContactInfo.contactInfo,
    language,
    isRequireAdpEmailFlagEnabled,
    isOnlyAllowAdpEmailFlagEnabled,
    clientId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadAuthenticationMethods() {
      return dispatch(actionCreator.loadContactInfoMethods());
    },

    onGetRequireAdpEmailFlagStatus() {
      return dispatch(actionCreator.getRequireAdpEmailFlagStatus());
    },

    onGetOnlyAllowAdpEmails() {
      return dispatch(actionCreator.getOnlyAllowAdpEmails());
    },

    onSuccessNotification(translatedMessage) {
      const toastMessageAction = messagesActionCreator.addToastMessage({
        message: translatedMessage.message,
        type: toastMessage.SUCCESS_TOAST_TYPE,
        id: UtilityHelper.generateUniqueId(),
      });

      dispatch(toastMessageAction);
    },

    onErrorNotification(translatedMessage) {
      const toastMessageAction = messagesActionCreator.addToastMessage({
        message: translatedMessage.message,
        type: toastMessage.FAILURE_TOAST_TYPE,
        id: UtilityHelper.generateUniqueId(),
      });

      dispatch(toastMessageAction);
    },

    async onDelete(authenticationMethod) {
      await dispatch(actionCreator.deleteContactMethod(authenticationMethod));
      return dispatch(actionCreator.loadContactInfoMethods());
    },
  };
}

const ReduxConnectedContactInfoPage = connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true,
})(ContactInfoPage);

export { mapDispatchToProps, mapStateToProps };
export default ReduxConnectedContactInfoPage;
