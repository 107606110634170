import BaseActionCreator from './BaseActionCreator';
import actionTypes from '../actiontypes';
import http from '../utils/http';
import domainUtilities from '../utils/domainUtilities';
import helpers from '../utils/helpers';
import getConstants from '../utils/constants';

const { authenticationMethod } = getConstants();

class ApplicationActionCreator extends BaseActionCreator {
  async assertAuthenticated() {
    try {
      this.dispatch(actionTypes.ME.CHECK_AUTH_REQUEST);

      const {
        data: { levelOfAssurance, authentication_method: authenticationMode },
      } = await http.checkAuthentication();

      this.dispatch(actionTypes.ME.CHECK_AUTH_SUCCESS, {
        levelOfAssurance,
        isAuthenticatedByNexo: authenticationMode === authenticationMethod.NEXO,
      });
    } catch (e) {
      this.dispatch(actionTypes.ME.CHECK_AUTH_FAILURE);

      throw e;
    }
  }

  async checkAuthentication(redirectLocation = '/auth/login', state) {
    try {
      await this.assertAuthenticated();
    } catch (e) {
      if (domainUtilities.isFlowbriteDomain(helpers.getWindowLocation())) {
        const url = domainUtilities.getCorrespondingAdpDomainFromFlowbrite(
          helpers.getWindowLocation()
        );

        this.changeUrl(url);
        return;
      }
      this.changeUrl(redirectLocation, state);
    }
  }
}

export default new ApplicationActionCreator();
