import cookieUtils from 'utils/cookie';
import getConstants from 'utils/constants';

const PARAM_NAME_REGEX = /\{(.*?)\}/;
const PARAM_REGEX = /(\{.*?\})/;
const { defaultLanguage, lyricRegionalSettingsCookies } = getConstants();
const { LYRIC_LANGUAGE } = lyricRegionalSettingsCookies;
const getParameterName = (paramString) => PARAM_NAME_REGEX.exec(paramString)[1];
const getParameterForItem = (item, params) => params[getParameterName(item)];
const itemIsParameter = (item) => PARAM_REGEX.test(item);

const interpolateWithReactObjects = (translatedText, params) => {
  const chunks = translatedText.split(PARAM_REGEX);

  const items = chunks.map((item) => {
    if (itemIsParameter(item)) {
      return getParameterForItem(item, params);
    }
    return item;
  });

  return items;
};

const translationUtils = {
  getCurrentLanguage() {
    const accountLanguage =
      cookieUtils.getCookie(LYRIC_LANGUAGE) || cookieUtils.getCookie('language');
    const browserDefaultLanguage = navigator.languages
      ? navigator.languages[0]
      : navigator.language || navigator.userLanguage;

    return accountLanguage || browserDefaultLanguage || defaultLanguage;
  },

  isSupportedPolicyLanguage(language) {
    const {
      supportedLanguages: { legalPolicy },
    } = getConstants();
    const isSupported = legalPolicy.indexOf(language) >= 0;

    return isSupported;
  },

  getLegalTerms() {
    const currentLanguage = this.getCurrentLanguage();
    const supported = this.isSupportedPolicyLanguage(currentLanguage);
    const baseURL = 'https://netsecure.adp.com/public/legal/TermsCondition';
    const defaultTermsUrl = 'https://netsecure.adp.com/public/legal/TermsCondition.html';

    if (supported) {
      const params = currentLanguage.split('-').join('_');

      return `${baseURL}_${params}.html`;
    }

    return defaultTermsUrl;
  },

  getPrivacyPolicy() {
    const currentLanguage = this.getCurrentLanguage();
    const supported = this.isSupportedPolicyLanguage(currentLanguage);
    const basePrivacyUrl = 'https://privacy.adp.com/privacy.html';

    if (supported) {
      return `${basePrivacyUrl}?locale=${currentLanguage}`;
    }

    return basePrivacyUrl;
  },

  getBrowserRequirements() {
    const browserRequirementsUrl =
      'https://support.adp.com/netsecure/pages/pub/netsrc_login/pilot/common/en_us/empl/netscr/base/logon/netsrc_req.htm';

    return browserRequirementsUrl;
  },

  getLifionByAdp() {
    const lifionByAdpUrl = 'https://www.adp.com';

    return lifionByAdpUrl;
  },

  generateDictionaryFromResourcePackge(resourcePackage) {
    const dictionary = {};

    for (const textKey of Object.keys(resourcePackage.resources)) {
      dictionary[textKey] = resourcePackage.resources[textKey].data;
    }

    return dictionary;
  },

  interpolateWithReactObjects,

  interpolate(translatedText, params) {
    const toString = (item) => String(item);

    return interpolateWithReactObjects(translatedText, params).map(toString).join('');
  },

  getLocale() {
    const currentLanguage = this.getCurrentLanguage();
    const defaultLocale = 'en';

    return currentLanguage.split('-')[0] || defaultLocale;
  },
};

export { translationUtils as default };
