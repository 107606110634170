import actionCreator from '../../actioncreators/saml';
import UtilityHelper from '../../utils/helpers';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Saml extends React.Component {
  static propTypes = {
    dispatchLoadData: PropTypes.func.isRequired,

    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  componentDidMount() {
    const { location, dispatchLoadData, history } = this.props;
    const { marketplaceApplicationGroupName: settingsGroupName } = UtilityHelper.parseQueryString(
      location.search
    );

    dispatchLoadData(settingsGroupName, history);
  }

  render() {
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchLoadData(settingsGroupName, history) {
      dispatch(actionCreator.loadData(settingsGroupName, history));
    },
  };
}

export { Saml as UnwrappedSaml };
export default connect(null, mapDispatchToProps)(Saml);
