import styles from './index.module.scss';
import { Button as FormButton } from '@tbiegner99/react-forms';
import React from 'react';
import PropTypes from 'prop-types';
import combineClasses from 'classnames';

const InputSizes = {
  LARGE: 'large',
};

const Button = (props) => {
  const { className, size, ...otherProps } = props;

  return (
    <FormButton
      {...otherProps}
      className={combineClasses(styles.button, styles[size], className)}
    />
  );
};

Button.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(InputSizes)),
};

Button.defaultProps = {
  className: null,
  size: null,
};

const createButtonType = (type) => (props) =>
  (
    // eslint-disable-next-line react/prop-types, react/destructuring-assignment
    <Button {...props} className={combineClasses(styles[type], props.className)} />
  );

export default Button;
export { InputSizes };
export const PrimaryButton = createButtonType('primary');
export const SecondaryButton = createButtonType('secondary');
export const TertiaryButton = createButtonType('tertiary');
export const LinkButton = createButtonType('link');
export const DestructiveButton = createButtonType('destructive');
export const VeryDestructiveButton = createButtonType('veryDestructive');
