import actionTypes from 'actiontypes/';

const modalActionCreator = {
  resetState() {
    return {
      type: actionTypes.MODAL.RESET_STATE,
    };
  },

  show(stringifiedModalProps, okCallback, cancelCallback) {
    let modalProps;

    try {
      modalProps = JSON.parse(stringifiedModalProps);
    } catch (e) {
      modalProps = {};
    }

    return {
      type: actionTypes.MODAL.SHOW,
      payload: {
        title: modalProps.title,
        body: modalProps.body,
        okButtonText: modalProps.okButtonText,
        cancelButtonText: modalProps.cancelButtonText,
        okCallback,
        cancelCallback,
      },
    };
  },

  hide() {
    return {
      type: actionTypes.MODAL.HIDE,
    };
  },
};

export default modalActionCreator;
