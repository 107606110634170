import buildLoadDataReducer from 'reducers/builders/loadData';
import actionTypes, { actionTypeSections } from 'actiontypes/';
import { combineReducers } from 'redux';

const mainInitialState = {};

const loadDataInitialState = {
  isLoadingData: false,
};

function mainReducer(state = mainInitialState, action) {
  const actionType = action.type;

  switch (actionType) {
    case actionTypes.USER_CONFIRMATION.RESET_STATE:
      return { ...mainInitialState };
    default:
      return state;
  }
}

const userConfirmationReducer = combineReducers({
  main: mainReducer,
  loadData: buildLoadDataReducer(loadDataInitialState, actionTypeSections.USER_CONFIRMATION),
});

export { userConfirmationReducer as default };
