import getTimeLeft from '../../utils/session';
import actionCreator from '../../actioncreators/sessionExpireModal';
import Translator from '../../utils/translation/Translator';
import { ButtonContainer, PrimaryButton, SecondaryButton } from '../../components';
import namespaceTranslatedText from '../TranslatedText';
import {
  ONE_MIN as SESSION_WARNING_TIME_INTERVAL,
  ONE_SEC as CHECK_SESSION_WARNING_TIME_INTERVAL,
  THREE_SEC,
  staticConstants,
} from '../../utils/constants';
import datasource from '../../actioncreators/accountSettings/contactInfo/datasource';
import { Modal } from '@idm/ui-components';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const TranslatedText = namespaceTranslatedText('SESSION_EXP_MODAL');
const sessionExpModalDictionary = new Translator('SESSION_EXP_MODAL');
const UPDATE_TTL_TIME_INTERVAL = SESSION_WARNING_TIME_INTERVAL + THREE_SEC;
const syncSessionTTLHeaders = {
  'x-ohcm-prolong-session': false,
  'x-ohcm-sync-session-ttl': true,
};

class SessionExpireModal extends Component {
  static propTypes = {
    isModalVisible: PropTypes.bool.isRequired,
    timeLeftForDisplay: PropTypes.number.isRequired,
    dictionary: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

    dispatchResetState: PropTypes.func.isRequired,
    dispatchForceLogout: PropTypes.func.isRequired,
    dispatchShowModal: PropTypes.func.isRequired,
    dispatchHideModal: PropTypes.func.isRequired,
    dispatchProlongSession: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.updateTimeLeftIntervalId = setInterval(
      this.checkSessionWarning,
      CHECK_SESSION_WARNING_TIME_INTERVAL
    );
    this.didUpdateTtl = false;
    this.syncSessionTtlFeatureEnabled = false;
  }

  async componentDidMount() {
    this.syncSessionTtlFeatureEnabled = await datasource.getFeatureEnabledStatus(
      staticConstants.featureFlags.SYNC_SESSION_TTL_FEATURE
    );
  }

  componentWillUnmount() {
    const { dispatchResetState } = this.props;

    dispatchResetState();

    clearInterval(this.updateTimeLeftIntervalId);
  }

  handleLogOutClick = () => {
    const { dispatchForceLogout } = this.props;

    dispatchForceLogout();
  };

  handleProlongSession = () => {
    const { dispatchProlongSession } = this.props;

    dispatchProlongSession();
  };

  syncSessionTTLBeforeSessionWarning = () => {
    const { dispatchProlongSession } = this.props;

    if (this.syncSessionTtlFeatureEnabled) {
      dispatchProlongSession(syncSessionTTLHeaders);
    }
  };

  checkSessionWarning = () => {
    const { dispatchForceLogout, dispatchShowModal, dispatchHideModal, isModalVisible } =
      this.props;
    const timeLeftMs = getTimeLeft();

    const isSessionExpired = timeLeftMs < CHECK_SESSION_WARNING_TIME_INTERVAL;
    const shouldShowSessionWarning = timeLeftMs < SESSION_WARNING_TIME_INTERVAL;
    const shouldUpdateTtl = timeLeftMs < UPDATE_TTL_TIME_INTERVAL;

    if (isSessionExpired) {
      dispatchForceLogout();
    } else if (shouldShowSessionWarning) {
      const timeLeftForDisplay = Math.floor(timeLeftMs / 1000);

      dispatchShowModal(timeLeftForDisplay);
    } else if (shouldUpdateTtl) {
      this.syncSessionTTLBeforeSessionWarning();
    } else if (isModalVisible) {
      dispatchHideModal();
    }
  };

  render() {
    const { isModalVisible, dictionary, timeLeftForDisplay } = this.props;

    return (
      <Modal
        isVisible={isModalVisible}
        title={sessionExpModalDictionary.get('TITLE', dictionary)}
        onOk={this.handleProlongSession}
        onCancel={this.handleProlongSession}
        footer={[
          <ButtonContainer key="buttons">
            <PrimaryButton onClick={this.handleProlongSession}>
              {sessionExpModalDictionary.get('STAY_BUTTON', dictionary)}
            </PrimaryButton>
            <SecondaryButton onClick={this.handleLogOutClick}>
              {sessionExpModalDictionary.get('LOG_OUT_BUTTON', dictionary)}
            </SecondaryButton>
          </ButtonContainer>,
        ]}
      >
        <TranslatedText textKey="COUNTDOWN_MESSAGE" params={{ timeLeft: timeLeftForDisplay }} />
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    isModalVisible: state.sessionExpireModal.isModalVisible,
    timeLeftForDisplay: state.sessionExpireModal.timeLeftForDisplay,
    dictionary: state.translation.main.dictionary,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchResetState() {
      dispatch(actionCreator.resetState());
    },

    dispatchForceLogout() {
      dispatch(actionCreator.forceLogout());
    },

    dispatchProlongSession(headers = {}) {
      dispatch(actionCreator.prolongSession(headers));
    },

    dispatchShowModal(timeLeftForDisplay) {
      dispatch(actionCreator.showModal(timeLeftForDisplay));
    },

    dispatchHideModal() {
      dispatch(actionCreator.hideModal());
    },
  };
}

export { SessionExpireModal as UnwrappedSessionExpireModal };
export default connect(mapStateToProps, mapDispatchToProps)(SessionExpireModal);
