const fromSyncContactResponse = (syncContactResponse) => {
  return {
    contactInfoUpdatedAt: syncContactResponse.contactInfoUpdatedAt,
  };
};

export default {
  syncContactInfo: {
    fromResponse: fromSyncContactResponse,
  },
};
