import DesktopRegistrationComplete from './DesktopRegistrationComplete';
import MobileRegistrationComplete from './MobileRegistrationComplete';
import DeviceContext from '../../../context/Device';
import React from 'react';

class RegistrationComplete extends React.Component {
  static contextType = DeviceContext;

  render() {
    const {
      device: { isMobile, isIOS },
    } = this.context;

    if (isMobile) {
      return <MobileRegistrationComplete isIOS={isIOS} {...this.props} />;
    }
    return <DesktopRegistrationComplete {...this.props} />;
  }
}

export default RegistrationComplete;
