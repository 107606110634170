import serializer from './serializer';
import HttpClient from '../../../utils/http/HttpClient';
import getConstants, { staticConstants } from '../../../utils/constants';

const constants = getConstants(process.env.BASE_URL);
const identityOrchestratorUrl = constants.serviceBaseUrls.identityOrchestrator;

class ContactInfoDatasource {
  constructor() {
    this.httpClient = HttpClient.createInstance({
      baseURL: identityOrchestratorUrl,
    });
  }

  async loadContactInfo() {
    const { data: response } = await this.httpClient.get('/persons/me/contact-info');

    return serializer.getContactInfo.fromResponse(response);
  }

  async deletePhoneContactMethod(phoneNumber) {
    const url = `/persons/me/phone-numbers?phoneNumber=${encodeURIComponent(phoneNumber)}`;

    await this.httpClient.delete(url);
  }

  async deleteEmailContactMethod(emailAddress) {
    const url = `/persons/me/email-addresses?emailAddress=${encodeURIComponent(emailAddress)}`;

    await this.httpClient.delete(url);
  }

  async getFeatureEnabledStatus(featureKey, workstream = staticConstants.workstreams.IDM) {
    const url = `/feature-settings/${workstream}/features/${featureKey}`;
    const response = await this.httpClient.post(url);

    return response.data.isEnabled;
  }
}

export default new ContactInfoDatasource();
