import { staticConstants } from '../../utils/constants';
import UtilityHelper from '../../utils/helpers';
import actionCreator from '../../actioncreators/startImpersonation';
import { Modal, Button, Loader, ValidatedInput, Tooltip, TextArea } from '@idm/ui-components';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const { lifionHomePage } = staticConstants;

const validatedField = 'reason';

import './style.scss';

const REQUIRED_ERROR_MESSAGE = 'Required Field';

const requiredFieldRule = new ValidatedInput.RequiredRule(REQUIRED_ERROR_MESSAGE);

class ImpersonationModal extends React.PureComponent {
  static propTypes = {
    associateId: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    associateDisplayName: PropTypes.string.isRequired,
    clientName: PropTypes.string.isRequired,
    isLoadingData: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,

    dispatchStartImpersonation: PropTypes.func.isRequired,

    dispatchSetFormField: PropTypes.func.isRequired,
    dispatchSetFormFieldValidationResult: PropTypes.func.isRequired,
    dispatchSetFormIsValidating: PropTypes.func.isRequired,
    isValidating: PropTypes.bool.isRequired,
    isFormValid: PropTypes.bool.isRequired,
    dispatchResetState: PropTypes.func.isRequired,
    onLoadData: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  constructor(props) {
    super(props);
    this.state = { ready: false, isImpersonating: false };

    const queryParams = UtilityHelper.parseQueryString(props.location.search);

    const { associateId, returnUrl } = queryParams;

    if (!associateId) {
      UtilityHelper.redirectTo(lifionHomePage);
    }
    this.associateId = associateId;
    this.returnUrl = UtilityHelper.convertToRelativePath(returnUrl || lifionHomePage);
  }

  componentDidUpdate() {
    const { isLoadingData } = this.props;

    if (!isLoadingData) {
      this.setState({ ready: true });
    }
  }

  componentDidMount() {
    const { onLoadData } = this.props;

    onLoadData(this.associateId);
  }

  onStartImpersonateButtonClick = async () => {
    const { isFormValid, dispatchSetFormIsValidating } = this.props;

    if (isFormValid) {
      await this.startImpersonation();
    } else {
      await dispatchSetFormIsValidating();
    }
  };

  handleChange(field, event) {
    const inputValue = event.target.value;
    const { dispatchSetFormField } = this.props;

    this.setState({ [field]: inputValue });

    dispatchSetFormField(field, inputValue);
  }

  handleReasonChange = this.handleChange.bind(this, 'reason');

  startImpersonation = async () => {
    try {
      this.setState({ isImpersonating: true });

      const { reason } = this.state;
      const kind = 'associate';
      const { associateId: impersonatedActorId, clientId: impersonatedClientId } = this.props;
      const impersonationData = { kind, impersonatedActorId, impersonatedClientId, reason };

      await this.props.dispatchStartImpersonation(impersonationData);
      UtilityHelper.redirectTo(lifionHomePage);
    } finally {
      this.setState({ isImpersonating: false });
      this.props.dispatchResetState();
    }
  };

  onCancel = () => {
    const { dispatchResetState } = this.props;

    dispatchResetState();
    UtilityHelper.redirectTo(this.returnUrl);
  };

  handleValidationChangeForFormField = (field) => (validationResult) =>
    this.props.dispatchSetFormFieldValidationResult(field, validationResult);

  renderReasonFormField = () => {
    const { reason } = this.state;

    return (
      <div className="impersonate-form-fields" key="reason">
        <div className="form-field-label">
          <span className="form-field-label-text">Start Impersonation Reason *</span>
          <Tooltip message="Reason for impersonation" trigger="click" />
        </div>
        <ValidatedInput
          value={reason}
          dataMetaId="reason"
          onChange={this.handleReasonChange}
          handleValidationChange={this.handleValidationChangeForFormField('reason')}
          validateOnChange={true}
          forceValidationCheck={this.props.isValidating}
          placeholder="Describe..."
          InputComponent={TextArea}
          rules={[requiredFieldRule]}
        />
      </div>
    );
  };

  renderFooterButtons = () => [
    <Button
      buttonType="cancel"
      onClick={this.onCancel}
      key="cancel"
      value="Cancel"
      data-meta-id="cancel-button"
    />,
    <Button
      buttonType="primary"
      onClick={this.onStartImpersonateButtonClick}
      key="impersonate"
      value="Start Impersonation"
      data-meta-id="impersonate-button"
    />,
  ];

  renderLoader = () => {
    const { isImpersonating } = this.state;

    return isImpersonating ? <Loader className="data-loader" /> : null;
  };

  renderImpersonationInfo = () => {
    const { associateDisplayName, clientName } = this.props;
    const headerText = "You've selected:";

    return (
      <div className="info-container modal-body-container-margin">
        <p className="info-header-text">{headerText}</p>
        <div className="info-container-body">
          <div className="section">
            <p>Associate</p>
            <span className="user-name-text">{associateDisplayName}</span>
          </div>
          <div className="section">
            <p>Client Instance</p>
            <span className="client-name-text">{clientName}</span>
          </div>
        </div>
      </div>
    );
  };

  renderImpersonateForm = () => (
    <div className="modal-body-container-margin">
      <p className="confirmation-header-text">Why do you need to impersonate this user?</p>
      <div className="impersonate-form">{this.renderReasonFormField()}</div>
    </div>
  );

  renderModalBody = () => (
    <div className="impersonation-modal-body">
      {this.renderLoader()}
      {this.renderImpersonationInfo()}
      {this.renderImpersonateForm()}
    </div>
  );

  render() {
    const { ready } = this.state;
    const { isError } = this.props;

    if (!ready || isError) {
      return null;
    }

    const modalProps = {
      footer: this.renderFooterButtons(),
      isVisible: true,
      title: 'Impersonate',
      width: 450,
    };

    return <Modal {...modalProps}>{this.renderModalBody()}</Modal>;
  }
}

function mapStateToProps(state) {
  const { startImpersonation } = state;
  const { clientId, associateId, associateDisplayName, clientName, personName, isError } =
    startImpersonation.main;

  return {
    clientId,
    associateId,
    clientName,
    personName,
    associateDisplayName,
    isError,
    isFormValid: startImpersonation.form[validatedField].isValid,
    isValidating: startImpersonation.form[validatedField].isValidating,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadData(associateId) {
      return dispatch(actionCreator.loadData(associateId));
    },

    dispatchStartImpersonation(imperonsationData) {
      return dispatch(actionCreator.startImpersonation(imperonsationData));
    },

    dispatchSetFormIsValidating() {
      dispatch(actionCreator.setFormIsValidating());
    },

    dispatchSetFormFieldValidationResult(field, validationResult) {
      dispatch(actionCreator.setFormFieldValidationResult(field, validationResult));
    },

    dispatchSetFormField(field, value) {
      dispatch(actionCreator.setFormField(field, value));
    },

    dispatchResetState() {
      dispatch(actionCreator.resetState());
    },
  };
}

export { ImpersonationModal as UnwrappedImpersonationModal };
export { mapDispatchToProps };
export default connect(mapStateToProps, mapDispatchToProps)(ImpersonationModal);
