import CreateAccountFlow from './CreateAccountFlow';
import actionCreator from '../../actioncreators/createAccount/personalInfo';
import accountInfoActionCreator from '../../actioncreators/createAccount/accountInfo';
import { connect } from 'react-redux';

function mapStateToProps(state, ownProps) {
  const { token, accountType } = ownProps.match.params;
  const { createAccountPersonalInfo } = state;

  return {
    isSSO: accountType === 'sso',
    associateToken: token,
    emailVerificationToken: createAccountPersonalInfo.main.codeEmailVerificationToken,
    firstName: createAccountPersonalInfo.main.associateFirstName,
    lastName: createAccountPersonalInfo.main.associateLastName,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onPersonalInfoSubmissionFailure: () => {},

    onVerifyToken: (token, isSSO) => {
      dispatch(actionCreator.checkAuthenticationAndVerifyToken(token, isSSO, ownProps.history));
    },

    onCheckCode: (code, verificationToken) =>
      dispatch(actionCreator.checkTokenCode(code, verificationToken)),

    onSendEmailCode: async (email, associateToken) => {
      const { history } = ownProps;

      await dispatch(actionCreator.prepareCodeEmailVerification(email, associateToken, history));
    },

    onCreateAccount: (token, isSSO, data) => {
      dispatch(accountInfoActionCreator.createAccount(token, data, isSSO, ownProps.history));
    },
  };
}

const ReduxCreateAccount = connect(mapStateToProps, mapDispatchToProps)(CreateAccountFlow);

export default ReduxCreateAccount;
