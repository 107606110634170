import serializer from './serializer';
import HttpClient from '../../utils/http/HttpClient';
import getConstants from '../../utils/constants';

const constants = getConstants(process.env.BASE_URL);
const identityOrchestratorUrl = constants.serviceBaseUrls.identityOrchestrator;

class ReviewContactInfoDatasource {
  constructor() {
    this.httpClient = HttpClient.createInstance({
      baseURL: identityOrchestratorUrl,
    });
  }

  async confirmContactInfo() {
    const url = '/persons/me/contact-info';
    const { data: response } = await this.httpClient.patch(url);

    return serializer.syncContactInfo.fromResponse(response);
  }
}

export default new ReviewContactInfoDatasource();
