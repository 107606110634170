const accountLinkingActionTypes = {
  RESET_STATE: 'RESET_STATE',
  SET_COMPANY_NAME: 'SET_COMPANY_NAME',
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
  CONNECT_REQUEST: 'CONNECT_REQUEST',
  CONNECT_SUCCESS: 'CONNECT_SUCCESS',
  CONNECT_FAILURE: 'CONNECT_FAILURE',
  DECLINE_INVITATION_REQUEST: 'DECLINE_INVITATION_REQUEST',
  DECLINE_INVITATION_SUCCESS: 'DECLINE_INVITATION_SUCCESS',
  DECLINE_INVITATION_FAILURE: 'DECLINE_INVITATION_FAILURE',
};

export { accountLinkingActionTypes as default };
