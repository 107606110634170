import datasource from './datasource';
import BaseActionCreator from '../BaseActionCreator';
import translationUtils from '../../utils/translation/utility';
import defaultResourcePackage from '../../utils/translation/defaultResourcePackage.json';
import actionTypes from '../../actiontypes';

const translationsActions = actionTypes.TRANSLATION;

class TranslationsActionCreator extends BaseActionCreator {
  setData(payload) {
    return {
      type: actionTypes.TRANSLATION.SET_DATA,
      payload,
    };
  }

  async getResourcePackageTranslations(currentLanguage) {
    try {
      const resource = await datasource.loadResourcePackage(currentLanguage);

      return resource;
    } catch (error) {
      const languagePieces = currentLanguage.split('-');
      const languageCode = languagePieces[0];

      if (languagePieces.length > 1) {
        try {
          return await this.getResourcePackageTranslations(languageCode);
        } catch (err) {
          //continue to throw error
        }
      }
      throw new Error('error fetching translation resource package');
    }
  }

  async loadPackageResource() {
    try {
      this.dispatch(translationsActions.LOAD_RESOURCES);

      const currentLanguage = translationUtils.getCurrentLanguage(defaultResourcePackage.version);

      // Initialize the dictionary with default values
      let dictionary =
        translationUtils.generateDictionaryFromResourcePackge(defaultResourcePackage);

      // If language is specified and not english, then replace all dictionary values with their translations
      if (currentLanguage && currentLanguage !== 'en' && currentLanguage !== 'en-US') {
        const resourcePackageTranslations = await this.getResourcePackageTranslations(
          currentLanguage,
          defaultResourcePackage.version
        );

        dictionary = { ...dictionary, ...resourcePackageTranslations };
      }

      this.dispatch(this.setData({ dictionary }));

      this.dispatch(translationsActions.RESOURCES_LOADED);
    } catch (e) {
      this.dispatch(translationsActions.RESOURCE_LOAD_FAIILED);
      const dictionary =
        translationUtils.generateDictionaryFromResourcePackge(defaultResourcePackage);

      this.dispatch(this.setData({ dictionary }));
    }
  }
}

export default new TranslationsActionCreator();
