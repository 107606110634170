import Rule from './Rule';
import ValidationError from '../../exceptions/ValidationError';

export default class LengthRule extends Rule {
  constructor(failMessage, min, max) {
    super(failMessage);

    this.min = min;
    this.max = max;
  }

  apply(value) {
    const { min, max } = this;

    if (value.length < min) {
      return Promise.reject(new ValidationError(this.message));
    }

    if (value.length > max) {
      return Promise.reject(new ValidationError(this.message));
    }

    return Promise.resolve(this.message);
  }
}
