import LocalizationSettingsForm from './SettingsForm';
import Translator from '../../../utils/translation/Translator';
import TranslatedText from '../../TranslatedText/Translation';
import { Header2 } from '@idm/ui-components';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const objectArray = PropTypes.arrayOf(PropTypes.object.isRequired);

class LocalizationPage extends Component {
  static propTypes = {
    languages: objectArray.isRequired,
    regions: objectArray.isRequired,
    timezones: objectArray.isRequired,
    isLoadingData: PropTypes.bool.isRequired,

    dictionary: PropTypes.object.isRequired,

    onLoadData: PropTypes.func.isRequired,
    onUpdateRegionalSettings: PropTypes.func.isRequired,

    defaultRegion: PropTypes.string,
    defaultLanguage: PropTypes.string,
    defaultTimezone: PropTypes.string,
  };

  static defaultProps = {
    defaultRegion: null,
    defaultLanguage: null,
    defaultTimezone: null,
  };

  constructor(props) {
    super(props);
    this.state = { ready: false };
  }

  componentDidUpdate(prevProps) {
    if (this.hasFinishedLoading(prevProps)) {
      this.setState({ ready: true });
    }
  }

  hasFinishedLoading(prevProps) {
    const { isLoadingData } = this.props;

    return !isLoadingData && prevProps.isLoadingData;
  }

  componentDidMount() {
    const { onLoadData } = this.props;

    onLoadData();
  }

  renderSettingsForm(translator) {
    const {
      onUpdateRegionalSettings,
      languages,
      regions,
      timezones,
      defaultLanguage,
      defaultRegion,
      defaultTimezone,
    } = this.props;

    return (
      <LocalizationSettingsForm
        onSubmit={onUpdateRegionalSettings}
        translator={translator}
        languages={languages}
        regions={regions}
        timezones={timezones}
        defaultLanguage={defaultLanguage}
        defaultRegion={defaultRegion}
        defaultTimezone={defaultTimezone}
      />
    );
  }

  render() {
    const { ready } = this.state;

    if (!ready) {
      return null;
    }
    const { dictionary } = this.props;
    const translator = new Translator('', dictionary);

    return (
      <div className="regional-settings-tab">
        <Header2>
          <TranslatedText
            namespace=""
            dictionary={dictionary}
            textKey="ACCOUNT_SETTINGS.LOCALIZATION.TITLE"
          />
        </Header2>
        {this.renderSettingsForm(translator)}
      </div>
    );
  }
}

export default LocalizationPage;
