import buildFormReducer from 'reducers/builders/form';
import actionTypes, { actionTypeSections } from 'actiontypes/';
import { combineReducers } from 'redux';

const mainInitialState = {
  error: {
    isVisible: false,
    code: undefined,
  },
  isUpdatingData: false,
  modal: {
    isVisible: false,
    isPasswordChanged: false,
  },
};

const formInitialState = {
  currentPassword: {
    value: '',
    isValid: false,
    isValidating: false,
  },
  newPassword: {
    value: '',
    isValid: false,
    isValidating: false,
  },
};

function mainReducer(state = mainInitialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_PASSWORD.UPDATE_DATA_REQUEST:
      return {
        ...state,
        isUpdatingData: true,
        error: {
          isVisible: false,
          code: undefined,
        },
      };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_PASSWORD.UPDATE_DATA_SUCCESS:
      return {
        ...state,
        modal: {
          isVisible: true,
          isPasswordChanged: true,
        },
      };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_PASSWORD.UPDATE_DATA_FAILURE:
      return {
        ...state,
        isUpdatingData: false,
      };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_PASSWORD
      .UPDATE_PASSWORD_INCORRECT_PASSWORD_OR_INVALID_PASSWORD_FAILURE:
      return {
        ...state,
        error: {
          isVisible: true,
          code: actionPayload.errorCode,
        },
      };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_PASSWORD.RESET_STATE:
      return { ...mainInitialState };
    case actionTypes.ACCOUNT_SETTINGS_CHANGE_PASSWORD.UPDATE_PASSWORD_FAIL_TOO_MANY_ATTEMPTS:
      return {
        ...state,
        modal: {
          isVisible: true,
          isPasswordChanged: false,
        },
      };
    default:
      return state;
  }
}

const changePasswordReducer = combineReducers({
  main: mainReducer,
  form: buildFormReducer(formInitialState, actionTypeSections.ACCOUNT_SETTINGS_CHANGE_PASSWORD),
});

export { changePasswordReducer as default };
