import styles from './errors.module.scss';

import TitledIcon from '../../../components/TitledIcon';
import { Header2, StandardParagraph } from '@idm/ui-components';
import React from 'react';
import PropTypes from 'prop-types';

const BaseError = (props) => {
  const { title, children } = props;

  return (
    <section>
      <TitledIcon type="error">
        <Header2>{title}</Header2>
      </TitledIcon>

      <div className={styles.errorMessage}>
        <StandardParagraph>{children}</StandardParagraph>
      </div>
    </section>
  );
};

BaseError.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
};

BaseError.defaultProps = {
  title: null,
  children: null,
};

export default BaseError;
