import buildLoadDataReducer from 'reducers/builders/loadData';
import actionTypes, { actionTypeSections } from 'actiontypes/';
import { combineReducers } from 'redux';

const mainInitialState = {
  companyName: '',
  isModalShown: false,
};

const loadDataInitialState = {
  isLoadingData: false,
};

function mainReducer(state = mainInitialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.ACCOUNT_LINKING.RESET_STATE:
      return { ...mainInitialState };
    case actionTypes.ACCOUNT_LINKING.SET_COMPANY_NAME:
      return { ...state, companyName: actionPayload };
    case actionTypes.ACCOUNT_LINKING.SHOW_MODAL:
      return { ...state, isModalShown: true };
    case actionTypes.ACCOUNT_LINKING.HIDE_MODAL:
      return { ...state, isModalShown: false };
    default:
      return state;
  }
}

const accountLinkgReducer = combineReducers({
  main: mainReducer,
  loadData: buildLoadDataReducer(loadDataInitialState, actionTypeSections.ACCOUNT_LINKING),
});

export { accountLinkgReducer as default };
