import ObligationHandler from './ObligationHandler';
import actionCreator from '../../actioncreators/application';
import utilHelper from '../../utils/helpers/window';
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
    obligationData: state.application.obligationData,
  };
}

function mapDispatchToProps() {
  return {
    async onAfterObligation() {
      await actionCreator.clearObligations();
      utilHelper.refresh();
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ObligationHandler);
