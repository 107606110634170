import { RegexRule } from '@tbiegner99/react-forms';

const REGEX = /[A-Z]/;

class UpperCaseRule extends RegexRule {
  constructor() {
    super(REGEX);
  }

  static get ruleName() {
    return 'upper-case-required';
  }

  getDefaultMessage() {
    return 'Must contain an upper case character';
  }
}

export default UpperCaseRule;
