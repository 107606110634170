import actionTypes from 'actiontypes/';

const initialState = {
  isModalVisible: false,
  timeLeftForDisplay: 60,
  isLoggingOut: false,
  isProlongingSession: false,
};

function sessionExpireModalReducer(state = initialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.SESSION_EXPIRE_MODAL.LOGOUT_FAILURE:
    case actionTypes.SESSION_EXPIRE_MODAL.LOGOUT_SUCCESS:
      return { ...state, isLoggingOut: false };
    case actionTypes.SESSION_EXPIRE_MODAL.LOGOUT_REQUEST:
      return { ...state, isLoggingOut: true };
    case actionTypes.SESSION_EXPIRE_MODAL.PROLONG_SESSION_FAILURE:
    case actionTypes.SESSION_EXPIRE_MODAL.PROLONG_SESSION_SUCCESS:
      return { ...state, isProlongingSession: false };
    case actionTypes.SESSION_EXPIRE_MODAL.PROLONG_SESSION_REQUEST:
      return { ...state, isProlongingSession: true };
    case actionTypes.SESSION_EXPIRE_MODAL.SHOW_MODAL:
      return {
        ...state,
        isModalVisible: true,
        timeLeftForDisplay: actionPayload,
      };
    case actionTypes.SESSION_EXPIRE_MODAL.HIDE_MODAL:
      return { ...state, isModalVisible: false, timeLeftForDisplay: 60 };
    case actionTypes.SESSION_EXPIRE_MODAL.RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
}

export { sessionExpireModalReducer as default };
