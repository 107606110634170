import { LengthRule } from '../../../utils/validator/rules';
import UtilityHelper from '../../../utils/helpers';
import { noop } from '../../../utils/constants';
import { ValidatedInput, NotValidatedInput } from '@idm/ui-components';
import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/destructuring-assignment */
function TextInput(props) {
  const rules = [new LengthRule(props.errorMessage, props.minLength, props.maxLength)];

  return (
    <ValidatedInput
      autoFocus={props.autoFocus}
      autoComplete={props.autoComplete}
      className={props.className}
      dataMetaId={props.dataMetaId}
      forceValidationCheck={props.forceValidationCheck}
      handleValidationChange={props.handleValidationChange}
      id={props.id}
      label={props.label}
      InputComponent={NotValidatedInput}
      neverShowError={props.neverShowError}
      onChange={props.onChange}
      onKeyPress={props.onKeyPress}
      rules={[...rules, ...props.additionalRules]}
      type="text"
      validateOnChange={props.validateOnChange}
      value={props.value}
      ref={props.setRef}
    />
  );
}

TextInput.propTypes = {
  additionalRules: PropTypes.arrayOf(PropTypes.object.isRequired),
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  dataMetaId: PropTypes.string,
  errorMessage: PropTypes.string,
  forceValidationCheck: PropTypes.bool,
  handleValidationChange: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string,
  neverShowError: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  validateOnChange: PropTypes.bool,
  value: PropTypes.string,
  setRef: PropTypes.func,
};

TextInput.defaultProps = {
  additionalRules: [],
  autoComplete: '',
  autoFocus: false,
  className: '',
  dataMetaId: '',
  errorMessage: 'This field is required',
  forceValidationCheck: false,
  handleValidationChange: noop,
  id: UtilityHelper.generateUniqueId(),
  label: '',
  neverShowError: false,
  minLength: 2,
  maxLength: 40,
  onChange: noop,
  onKeyPress: noop,
  validateOnChange: false,
  value: '',
  setRef: noop,
};

export { TextInput as default };
