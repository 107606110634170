import styles from './style.module.scss';
import AdpLogo from '../elements/icons/svg/AdpLogo';
import TranslatedText from '../elements/textDisplays/TranslatedText';
import { XLPageHeader, Header2 } from '@idm/ui-components';
import React from 'react';
import PropTypes from 'prop-types';
import combineClasses from 'classnames';

const LeftPane = (props) => {
  const { className } = props;
  const clazz = combineClasses(className, styles.leftPane);

  return (
    <div className={clazz}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <AdpLogo />
        </div>
        <div className={styles.description}>
          <XLPageHeader className={styles.mainTitle}>
            <TranslatedText namespace="LEFT_PANE" i18nKey="TAG_LINE_PRIMARY" />
          </XLPageHeader>
          <div className={styles.divider} />
          <Header2 className={styles.subtitle}>
            <TranslatedText namespace="LEFT_PANE" i18nKey="TAG_LINE_SECONDARY" />{' '}
          </Header2>
        </div>
      </div>
    </div>
  );
};

LeftPane.propTypes = {
  className: PropTypes.string,
};
LeftPane.defaultProps = {
  className: '',
};

export default LeftPane;
