const flowbritePrefixMap = {
  pilot: 'lifion',
  stag: 'stag.lifion',
  uat: 'appstaging.lifion',
  int: 'int.lifion',
  appbuild: 'appbuild.lifion',
  local: 'local', // for localdev add entry in /etc/hosts
  prep: 'prep.lifion',
};

const FLOWBRITE_REGEX = /^(([^.]+)\.)*?flowbrite\.com$/;

const isFlowbriteDomain = (url) => {
  return FLOWBRITE_REGEX.test(url.hostname);
};

const getFlowbritePrefix = (url) => {
  if (!isFlowbriteDomain(url)) {
    return null;
  }
  const matches = url.hostname.match(FLOWBRITE_REGEX);

  return matches[matches.length - 1];
};

const getCorrespondingAdpDomainFromFlowbrite = (url) => {
  const retUrl = new URL(url.toString());
  const prefix = getFlowbritePrefix(url);

  if (!prefix) {
    return url.toString();
  }
  const adpPrefix = flowbritePrefixMap[prefix];

  if (adpPrefix) {
    const newHost = url.hostname.replace(`${prefix}.flowbrite.com`, `${adpPrefix}.adp.com`);

    retUrl.hostname = newHost;
  }
  return retUrl.toString();
};

export default {
  isFlowbriteDomain,
  getCorrespondingAdpDomainFromFlowbrite,
};
