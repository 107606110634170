import Translator from '../../utils/translation/Translator';
import React from 'react';
import PropTypes from 'prop-types';

const Translation = (props) => {
  const {
    dictionary,
    namespace,
    translator,
    textKey,
    containHtml,
    params,
    style,
    isRebrandEnabled,
  } = props;
  const namespaceDictionary = translator || new Translator(namespace, dictionary);
  const translatedText = containHtml
    ? namespaceDictionary.getHtml(textKey, null, params, isRebrandEnabled)
    : namespaceDictionary.getReactRenderedString(textKey, null, params, isRebrandEnabled);

  return (
    <span data-text-key={textKey} style={style}>
      {translatedText}
    </span>
  );
};

Translation.propTypes = {
  translator: PropTypes.shape(),
  namespace: PropTypes.string.isRequired,
  textKey: PropTypes.string.isRequired,
  dictionary: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  containHtml: PropTypes.bool,
  isRebrandEnabled: PropTypes.bool,
  params: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

Translation.defaultProps = {
  containHtml: false,
  params: null,
  translator: null,
  style: {},
  isRebrandEnabled: false,
};
export default Translation;
