import Label from '../../../../../../../components/elements/textDisplays/Label';
import {
  EmailIcon,
  PhoneIcon,
  MobileIcon,
  IconSizes,
} from '../../../../../../../components/elements/icons';
import ContactInfoValue from '../../../../../../../components/elements/textDisplays/ContactInfoValue';
import phoneUtils from '../../../../../../../utils/phoneNumber';
import PropTypes from 'prop-types';
import React from 'react';
import combineClasses from 'classnames';

import './index.scss';

const getDisplayInfo = (authenticationMethod) => {
  const { type } = authenticationMethod;

  switch (type.toLowerCase()) {
    case 'phone':
    case 'text':
    case 'voice':
      return phoneUtils.getDisplayFormat(authenticationMethod.phoneNumber);
    default:
      return authenticationMethod.emailAddress;
  }
};
const getIconForType = (type) => {
  switch (type.toLowerCase()) {
    case 'text':
      return <MobileIcon size={IconSizes.LARGE} />;
    case 'phone':
    case 'voice':
      return <PhoneIcon size={IconSizes.LARGE} />;
    default:
      return <EmailIcon size={IconSizes.LARGE} />;
  }
};

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const AuthenticationMethodDisplay = (props) => {
  const { authenticationMethod, className, i18nContext } = props;
  const cssClasses = combineClasses('authentication-method-display', className);

  const contactPurposeLabel = authenticationMethod.isPersonal
    ? i18nContext.getTextForKey('IS_PERSONAL')
    : i18nContext.getTextForKey('IS_BUSINESS');

  const contactTypeLabel = capitalizeFirstLetter(authenticationMethod.type);

  return (
    <div className={cssClasses}>
      <div className="icon">{getIconForType(authenticationMethod.type)}</div>
      <div>
        <Label className="type-label">
          <span>
            {contactPurposeLabel} {contactTypeLabel}
          </span>
        </Label>
        <ContactInfoValue>{getDisplayInfo(authenticationMethod)}</ContactInfoValue>
      </div>
    </div>
  );
};

const authenticationMethodShape = {
  type: PropTypes.oneOf(['PHONE', 'EMAIL', 'TEXT', 'VOICE']).isRequired,
  isPersonal: PropTypes.bool.isRequired,
};

AuthenticationMethodDisplay.propTypes = {
  authenticationMethod: PropTypes.shape(authenticationMethodShape).isRequired,
  className: PropTypes.string,
  i18nContext: PropTypes.shape({ getTextForKey: PropTypes.func.isRequired }).isRequired,
};

AuthenticationMethodDisplay.defaultProps = {
  className: '',
};

export default AuthenticationMethodDisplay;
