import styles from './style.module.scss';
import { withNamespace } from '../../../components/elements/textDisplays/TranslatedText';
import actionCreator from '../../../actioncreators/forgotPassword/reset';
import UtilityHelper from '../../../utils/helpers';
import { ButtonContainer, PrimaryButton } from '../../../components';
import ServerError from '../../../components/ServerError';
import PasswordCreationInput from '../../../components/elements/formInputs/PasswordCreationInput';
import { Form } from '@tbiegner99/react-forms';
import { PageHeader, StandardParagraph } from '@idm/ui-components';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const TranslatedText = withNamespace('FORGOT_PASSWORD.RESET');

class Reset extends Component {
  static propTypes = {
    isErrorVisible: PropTypes.bool.isRequired,
    errorCode: PropTypes.string,
    isLoadingData: PropTypes.bool.isRequired,

    dispatchResetPassword: PropTypes.func.isRequired,
    dispatchLoadAndVerifyToken: PropTypes.func.isRequired,

    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    errorCode: undefined,
  };

  componentDidMount() {
    const { location, history, dispatchLoadAndVerifyToken } = this.props;
    const { token } = UtilityHelper.parseQueryString(location.search);

    dispatchLoadAndVerifyToken(token, history);
  }

  submit(formData) {
    const { dispatchResetPassword, location, history } = this.props;
    const { newPassword } = formData;
    const { token } = UtilityHelper.parseQueryString(location.search);

    dispatchResetPassword(token, newPassword, history);
  }

  render() {
    const { isErrorVisible, isLoadingData, errorCode } = this.props;

    if (isLoadingData) {
      return null;
    }

    return (
      <Form onSubmit={(data) => this.submit(data)}>
        <PageHeader className={styles.header2}>
          <TranslatedText i18nKey="RESET_PW_HEADER" />
        </PageHeader>
        <StandardParagraph className={styles.resetMessage}>
          <TranslatedText i18nKey="RESET_PW_SUB_HEADER" />
        </StandardParagraph>
        <PasswordCreationInput
          name="newPassword"
          label={<TranslatedText i18nKey="NEW_PW_LABEL" />}
          className={styles.passwordInput}
          data-meta-id="new-password-input"
          showRequirements={true}
        />
        {isErrorVisible && <ServerError code={errorCode} />}
        <ButtonContainer>
          <PrimaryButton submittable={true} size="large" data-meta-id="reset-password-button">
            <TranslatedText i18nKey="RESET_PW_BUTTON" />
          </PrimaryButton>
        </ButtonContainer>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    isErrorVisible: state.forgotPasswordReset.main.error.isVisible,
    errorCode: state.forgotPasswordReset.main.error.code,
    isLoadingData: state.forgotPasswordReset.loadData.isLoadingData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchResetPassword(token, newPassword, history) {
      dispatch(actionCreator.resetPassword(token, newPassword, history));
    },

    dispatchLoadAndVerifyToken(token, history) {
      dispatch(actionCreator.loadAndVerifyToken(token, history));
    },
  };
}

export { Reset as UnwrappedResetPassword };
export default connect(mapStateToProps, mapDispatchToProps)(Reset);
