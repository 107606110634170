import ChangePassword from '../../ChangePasswordPage';
import namespaceTranslatedText from 'containers/TranslatedText/';
import React from 'react';

const TranslatedText = namespaceTranslatedText('ACCOUNT_SETTINGS.CHANGE_PW');

const AccountSettingsChangePassword = (props) => {
  const title = <TranslatedText textKey="TITLE" />;

  return <ChangePassword title={title} {...props} />;
};

export default AccountSettingsChangePassword;
