import TranslationsContext from '../../context/TranslationsContext';
import TranslatedText from '../../components/elements/textDisplays/TranslatedText';
import { EnterCodeView } from '@idm/authentication-flows';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

/* eslint-disable react/destructuring-assignment */
const EnterCodeViewContainer = (props) => {
  const { language } = useContext(TranslationsContext);
  const title = <TranslatedText i18nKey="ENTER_CODE.VERIFY_EMAIL" />;

  return (
    <div className="enter-code">
      <EnterCodeView
        selectedAuthMethod={props.selectedAuthMethod}
        title={title}
        onCheckCode={props.onCheckCode}
        onCancel={props.onCancel}
        onResendCode={props.onResendCode}
        onCodeAccepted={props.onVerificationComplete}
        language={language}
      />
    </div>
  );
};

EnterCodeViewContainer.propTypes = {
  selectedAuthMethod: PropTypes.shape.isRequired,
  onCheckCode: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onResendCode: PropTypes.func.isRequired,
  onVerificationComplete: PropTypes.func.isRequired,
};

export default EnterCodeViewContainer;
