import UtilityHelper from 'utils/helpers/';
import namespaceTranslatedText from 'containers/TranslatedText/';
import { ErrorPage } from '@idm/ui-components';
import React from 'react';
import PropTypes from 'prop-types';

const TranslatedText = namespaceTranslatedText('RESOURCE_NOT_FOUND_ERROR');

const errorMessage = (traceId) => (
  <div className="idm-error-message forbidden-error">
    <div className="idm-error-header-text">
      <TranslatedText textKey="DONT_HAVE_PERMISSION" />
    </div>
    <p className="idm-error-desc-text">
      <TranslatedText textKey="NOT_INTENDED_FOR_YOU" />
    </p>
    <p className="idm-error-desc-text">
      <TranslatedText textKey="IMMEDIATE_ASSISTANCE" containHtml={false} params={{ traceId }} />
    </p>
    <a href="/auth/logout">
      <TranslatedText textKey="LOGOUT" containHtml={true} />
    </a>
  </div>
);

function ResourceNotFoundErrorPage(props) {
  const { location } = props;

  const queryParams = UtilityHelper.parseQueryString(location.search);
  const traceId = queryParams.traceId || '';

  return <ErrorPage code="404" message={errorMessage(traceId)} />;
}

ResourceNotFoundErrorPage.propTypes = {
  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export { ResourceNotFoundErrorPage as default };
