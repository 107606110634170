import buildFormReducer from 'reducers/builders/form';
import buildLoadDataReducer from 'reducers/builders/loadData';
import actionTypes, { actionTypeSections } from 'actiontypes/';
import { combineReducers } from 'redux';

const mainInitialState = {
  usernameError: {
    isVisible: false,
    code: undefined,
  },
  passwordError: {
    isVisible: false,
    code: undefined,
  },
  isCreatingAccount: false,
};

const formInitialState = {
  username: {
    value: '',
    isValid: false,
    isValidating: false,
  },
  password: {
    value: '',
    isValid: false,
    isValidating: false,
  },
  termsAccepted: {
    value: false,
  },
};

const loadDataInitialState = {
  isLoadingData: false,
};

function mainReducer(state = mainInitialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.CREATE_ACCOUNT_ACCOUNT_INFO.CREATE_ACCOUNT_REQUEST:
      return {
        ...state,
        isCreatingAccount: true,
        usernameError: {
          isVisible: false,
          code: undefined,
        },
        passwordError: {
          isVisible: false,
          code: undefined,
        },
      };
    case actionTypes.CREATE_ACCOUNT_ACCOUNT_INFO.CREATE_ACCOUNT_PASSWORD_VALIDATION_FAILURE:
      return {
        ...state,
        passwordError: {
          isVisible: true,
          code: actionPayload.errorCode,
        },
      };
    case actionTypes.CREATE_ACCOUNT_ACCOUNT_INFO
      .CREATE_ACCOUNT_USERNAME_OR_EMAIL_VALIDATION_FAILURE:
      return {
        ...state,
        usernameError: {
          isVisible: true,
          code: actionPayload.errorCode,
        },
      };
    case actionTypes.CREATE_ACCOUNT_ACCOUNT_INFO.CREATE_ACCOUNT_SUCCESS:
    case actionTypes.CREATE_ACCOUNT_ACCOUNT_INFO.CREATE_ACCOUNT_FAILURE:
      return { ...state, isCreatingAccount: false };
    case actionTypes.CREATE_ACCOUNT_ACCOUNT_INFO.RESET_STATE:
      return { ...mainInitialState };
    default:
      return state;
  }
}

const accountInfoReducer = combineReducers({
  main: mainReducer,
  form: buildFormReducer(formInitialState, actionTypeSections.CREATE_ACCOUNT_ACCOUNT_INFO),
  loadData: buildLoadDataReducer(
    loadDataInitialState,
    actionTypeSections.CREATE_ACCOUNT_ACCOUNT_INFO
  ),
});

export { accountInfoReducer as default };
