import styles from './index.module.scss';
import PlayStoreIcon from '../images/play_store.svg';
import IOSStoreIcon from '../images/ios_app_store.svg';
import AppIcon from '../images/app_icon.svg';
import LogoImage from '../../../../../static/assets/images/Logo.png';
import { Notification, LinkButton } from '../../../../components';
import TranslationText from '../../../../components/elements/textDisplays/TranslatedText';
import Utilities from '../../../../utils/helpers';
import { StandardParagraph } from '@idm/ui-components';
import combineClasses from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

class MobileRegistrationComplete extends React.Component {
  static propTypes = {
    isIOS: PropTypes.bool,
  };

  static defaultProps = {
    isIOS: false,
  };

  renderStoreLink() {
    const { isIOS } = this.props;

    if (!isIOS) {
      return (
        <img
          className={combineClasses(styles.linkImage, styles.appStoreImage)}
          src={PlayStoreIcon}
          data-meta-id="play-store-link"
          alt="Play Store"
        />
      );
    }

    return (
      <img
        className={combineClasses(styles.linkImage, styles.appStoreImage)}
        data-meta-id="app-store-link"
        src={IOSStoreIcon}
        alt="App Store"
      />
    );
  }

  renderContent() {
    return (
      <div className={styles.images}>
        <LinkButton data-meta-id="app-link" onClick={Utilities.openAppWithFallback}>
          <img
            className={combineClasses(styles.linkImage, styles.appImage)}
            src={AppIcon}
            alt="ADP App"
          />
        </LinkButton>
        <LinkButton data-meta-id="store-link" onClick={Utilities.openAppWithFallback}>
          {this.renderStoreLink()}
        </LinkButton>
      </div>
    );
  }

  render() {
    const status = 'success';
    const title = <TranslationText i18nKey="REGISTRATION.SUCCESS.TITLE" />;
    const message = (
      <StandardParagraph>
        <TranslationText i18nKey="REGISTRATION.SUCCESS.MOBILE_MESSAGE" />
      </StandardParagraph>
    );

    return (
      <section className={styles.mobileDisplay}>
        <div className={styles.logo}>
          <img className={styles.logoImage} src={LogoImage} alt="Lifion logo" />
        </div>
        <div data-meta-id={`${status}-page`}>
          <Notification title={title} message={message} status={status}>
            {this.renderContent()}
          </Notification>
        </div>
        <div className={styles.desktopLink}>
          <LinkButton
            data-meta-id="continue-desktop"
            onClick={() => Utilities.navigateToLifionHome()}
          >
            <TranslationText i18nKey="REGISTRATION.SUCCESS.MOBILE_LINK" />
          </LinkButton>
        </div>
      </section>
    );
  }
}

export default MobileRegistrationComplete;
