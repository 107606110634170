import AuthenticatedPage from './AuthenticatedPage';
import applicationActionCreator from '../../actioncreators/application';
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
    isAuthenticated: state.me.main.isAuthenticated,
    isCheckingAuth: state.me.main.isCheckingAuth,
  };
}

function mapDispatchToProps() {
  return {
    onChangeUrl(url) {
      applicationActionCreator.changeUrl(url);
    },

    async onCheckAuthenticated() {
      try {
        await applicationActionCreator.assertAuthenticated();
      } catch (err) {
        //do nothing actions will dispatch
      }
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedPage);
