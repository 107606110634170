import Rule from './Rule';
import http from '../../http';
import ValidationError from '../../exceptions/ValidationError';

const handleMessage = (message, ...params) => {
  let thrownMessage = message;

  if (typeof message === 'function') {
    thrownMessage = message(...params);
  }
  if (thrownMessage) {
    throw new ValidationError(thrownMessage);
  }
};

export default class NoDuplicateRule extends Rule {
  constructor(failMessages, tokenObject = {}, fieldToValidate, customErrorMessageComponent) {
    super(failMessages.failMessage || failMessages, customErrorMessageComponent);

    this.token = tokenObject.token;
    this.tokenKind = tokenObject.kind;

    this.fieldToValidate = fieldToValidate;

    if (failMessages.tooManyAttemptsMessage) {
      this.tooManyAttemptsFailMessage = failMessages.tooManyAttemptsMessage;
    }

    if (failMessages.hitSnagMessage) {
      this.hitSnagFailMessage = failMessages.hitSnagMessage;
    }
  }

  async apply(value) {
    if (this.token) {
      try {
        const res = await http.validate(this.tokenKind, this.token, {
          [this.fieldToValidate]: value,
        });

        if (res.data[this.fieldToValidate] && res.data[this.fieldToValidate].exists) {
          throw new Error();
        }
        return this.message;
      } catch (err) {
        throw new ValidationError(this.message);
      }
    }

    try {
      await http.checkEmailUniqueness(value);
      return this.message;
    } catch (err) {
      let errMessage;

      if (err.code === http.supportedErrorCodes.EMAIL_ADDRESS_ALREADY_EXISTS) {
        errMessage = this.message;
      } else if (err.code === http.supportedErrorCodes.TOO_MANY_ATTEMPTS) {
        errMessage = this.tooManyAttemptsFailMessage;
      } else {
        errMessage = this.hitSnagFailMessage;
      }
      handleMessage(errMessage);
      return this.message;
    }
  }
}
