const sessionExpireModalActionTypes = {
  RESET_STATE: 'RESET_STATE',
  PROLONG_SESSION_REQUEST: 'PROLONG_SESSION_REQUEST',
  PROLONG_SESSION_FAILURE: 'PROLONG_SESSION_FAILURE',
  PROLONG_SESSION_SUCCESS: 'PROLONG_SESSION_SUCCESS',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
  SET_TIME_TO_LIVE_TIMEOUT: 'SET_TIME_TO_LIVE_TIMEOUT',
  CLEAR_TIME_TO_LIVE_TIMEOUT: 'CLEAR_TIME_TO_LIVE_TIMEOUT',
};

export { sessionExpireModalActionTypes as default };
