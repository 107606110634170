import translationUtils from '../../utils/translation/utility';
import actionTypes from '../../actiontypes';
import localizationActions from '../../actiontypes/accountSettings/localization';
import { combineReducers } from 'redux';

const mainInitialState = {
  languages: [],
  regions: [],
  timezones: [],
  currentLanguage: null,
  currentTimezone: null,
  currentRegion: null,
  isLoadingData: false,
  isUpdatingData: false,
  getCurrentLanguage: () => translationUtils.getCurrentLanguage(),
  get language() {
    return translationUtils.getCurrentLanguage();
  },
};

function mainReducer(state = mainInitialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.ACCOUNT_SETTINGS_LOCALIZATION.LOAD_DATA_REQUEST:
      return {
        ...state,
        isLoadingData: true,
      };
    case localizationActions.UPDATE_DATA_SUCCESS:
    case actionTypes.ACCOUNT_SETTINGS_LOCALIZATION.LOAD_DATA_SUCCESS:
      return {
        ...state,
        isLoadingData: false,
        currentLanguage: actionPayload.language,
        currentTimezone: actionPayload.timezone,
        currentRegion: actionPayload.region,
      };
    case actionTypes.ACCOUNT_SETTINGS_LOCALIZATION.LOAD_DATA_FAILURE:
      return {
        ...state,
        isLoadingData: false,
      };
    case actionTypes.ACCOUNT_SETTINGS_LOCALIZATION.SET_LANGUAGES:
      return {
        ...state,
        languages: actionPayload,
      };
    case actionTypes.ACCOUNT_SETTINGS_LOCALIZATION.SET_REGIONS:
      return {
        ...state,
        regions: actionPayload,
      };
    case actionTypes.ACCOUNT_SETTINGS_LOCALIZATION.SET_TIMEZONES:
      return {
        ...state,
        timezones: actionPayload,
      };
    case actionTypes.ACCOUNT_SETTINGS_LOCALIZATION.UPDATE_DATA_REQUEST:
      return {
        ...state,
        isUpdatingData: true,
      };
    case actionTypes.ACCOUNT_SETTINGS_LOCALIZATION.UPDATE_DATA_FAILURE:
      return {
        ...state,
        isUpdatingData: false,
      };
    case actionTypes.ACCOUNT_SETTINGS_LOCALIZATION.RESET_STATE:
      return {
        ...mainInitialState,
        languages: state.languages,
        regions: state.regions,
        timezones: state.timezones,
      };
    default:
      return state;
  }
}

const localizationReducer = combineReducers({
  main: mainReducer,
});

export { localizationReducer as default };
