import styles from './icon.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import combineClasses from 'classnames';
import { FaPhoneAlt, FaMobileAlt, FaRegEnvelope, FaTrashAlt } from 'react-icons/fa';

const IconTypes = {
  DELETE: 'delete',
  EMAIL: 'email',
  MOBILE: 'mobile',
  PHONE: 'phone',
};

const IconSizes = {
  CUSTOM: 'custom',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'x-large',
  XXLARGE: '2x-large',
};

const mapSizeToClass = (size) => {
  switch (size) {
    case IconSizes.MEDIUM:
      return styles.mediumIcon;
    case IconSizes.LARGE:
      return styles.largeIcon;
    case IconSizes.XLARGE:
      return styles.xLargeIcon;
    case IconSizes.XXLARGE:
      return styles.xxLargeIcon;
    case IconSizes.CUSTOM:
      return '';
    case IconSizes.SMALL:
    default:
      return styles.smallIcon;
  }
};

const mapTypeToIcon = (size, props) => {
  switch (size) {
    case IconTypes.PHONE:
      return <FaPhoneAlt data-meta-icon="phone" {...props} />;
    case IconTypes.MOBILE:
      return <FaMobileAlt data-meta-icon="mobile" {...props} />;
    case IconTypes.EMAIL:
      return <FaRegEnvelope data-meta-icon="email" {...props} />;
    case IconTypes.DELETE:
    default:
      return <FaTrashAlt data-meta-icon="delete" {...props} />;
  }
};

const Icon = (props) => {
  const { size, type, className, ...otherProps } = props;
  const sizeClass = mapSizeToClass(size);

  const iconClassName = combineClasses(sizeClass, className);
  const iconProps = { className: iconClassName, ...otherProps };

  return mapTypeToIcon(type, iconProps);
};

Icon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(IconSizes)),
  type: PropTypes.oneOf(Object.values(IconTypes)).isRequired,
};

Icon.defaultProps = {
  className: '',
  size: IconSizes.SMALL,
};

export { IconSizes, IconTypes };
export default Icon;
