import styles from './index.module.scss';
import { noop } from '../../../utils/constants';
import TranslatedText from '../../../components/elements/textDisplays/TranslatedText';
import PasswordCreationInput from '../../../components/elements/formInputs/PasswordCreationInput';

import { PrimaryButton, SecondaryButton, LinkButton } from '../../../components';
import ServerError from '../../../components/ServerError';
import {
  Checkbox,
  FormTextInput as TextInput,
  PageHeader,
  StandardParagraph,
  FormErrorLabel,
} from '@idm/ui-components';

import { Form } from '@tbiegner99/react-forms';
import React from 'react';
import PropTypes from 'prop-types';

const translateKey = (key, props) => <TranslatedText i18nKey={key} {...props} />;

class CredentialForm extends React.Component {
  static propTypes = {
    submitTitle: PropTypes.node,
    requireTermsAcceptance: PropTypes.bool,
    title: PropTypes.node,
    subtitle: PropTypes.node,
    token: PropTypes.string,
    tokenKind: PropTypes.string,
    isLogin: PropTypes.bool,

    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    onForgotPassword: PropTypes.func,
  };

  static defaultProps = {
    token: null,
    tokenKind: null,
    submitTitle: null,
    isLogin: false,
    requireTermsAcceptance: false,
    title: null,
    subtitle: null,
    onCancel: null,
    onSubmit: noop,
    onForgotPassword: noop,
  };

  constructor(props) {
    super(props);
    this.state = {
      isErrorVisible: false,
    };
  }

  async submitForm(data) {
    const { onSubmit } = this.props;

    try {
      await onSubmit(data);
      this.setState({ errorCode: null, isErrorVisible: false });
    } catch (err) {
      this.setState({ errorCode: err.code, isErrorVisible: true });
    }
  }

  getUsernameCreateRules() {
    const { isLogin, token, tokenKind } = this.props;

    if (isLogin) return {};
    return {
      'data-rule-duplicate-username': token ? [token, tokenKind] : false,
      'data-msg-duplicate-username': () =>
        translateKey('CREATE_ACCOUNT.ACCOUNT_INFO.DUP_USERNAME_RULE'),
      'data-rule-length-range': [5, 30],
      'data-msg-length-range': () => translateKey('CREATE_ACCOUNT.ACCOUNT_INFO.USERNAME_ERROR'),

      'data-rule-username': true,
    };
  }

  renderButtonsRow() {
    const { submitTitle, onCancel, isLogin, onForgotPassword } = this.props;

    return (
      <div className={styles.buttonRow}>
        {isLogin && (
          <LinkButton onClick={onForgotPassword}>
            <TranslatedText i18nKey="LOGIN.FORGOT_USER_ID" />
          </LinkButton>
        )}
        {typeof onCancel === 'function' && (
          <div className={styles.buttonContainer}>
            <SecondaryButton onClick={onCancel} data-meta-id="back-button" size="large">
              <TranslatedText i18nKey="FORGOT_PASSWORD.REQUEST.CANCEL_BUTTON" />
            </SecondaryButton>
          </div>
        )}
        <div className={styles.buttonContainer}>
          <PrimaryButton submittable={true} data-meta-id="create-account-button" size="large">
            {submitTitle}
          </PrimaryButton>
        </div>
      </div>
    );
  }

  renderFormErrors() {
    const { isErrorVisible, errorCode } = this.state;

    if (isErrorVisible) {
      return <ServerError code={errorCode} />;
    }
    return null;
  }

  renderTerms() {
    const { requireTermsAcceptance, isLogin } = this.props;

    if (isLogin || !requireTermsAcceptance) {
      return null;
    }

    return (
      <div data-role="agreement">
        <Checkbox
          falseValue={null}
          validateOnChange={true}
          data-rule-required={true}
          data-msg-required={translateKey('CREATE_BASIC_CREDENTIALS.ACCEPT_TERMS_RULE_MESSAGE')}
          name="acceptTerms"
        >
          <StandardParagraph data-meta-id="agreement-checkbox">
            <TranslatedText
              dangerouslySetInnerHtml={true}
              i18nKey="CREATE_ACCOUNT.ACCOUNT_INFO.ACKNOWLEDGEMENT"
            />
          </StandardParagraph>
        </Checkbox>
        <FormErrorLabel data-meta-id="accept-terms" for="acceptTerms" />
      </div>
    );
  }

  render() {
    const { title, subtitle, isLogin } = this.props;

    return (
      <section>
        {title ? <PageHeader className={styles.title}>{title}</PageHeader> : null}
        {subtitle ? (
          <StandardParagraph className={styles.title}>{subtitle}</StandardParagraph>
        ) : null}
        <Form onSubmit={(data) => this.submitForm(data)} className={styles.form}>
          <div data-role="username">
            <TextInput
              data-meta-id="username"
              data-rule-required={true}
              data-msg-required={() => translateKey('TEXT_INPUT.ERROR_MESSAGE')}
              {...this.getUsernameCreateRules()}
              label={translateKey('CREATE_ACCOUNT.ACCOUNT_INFO.USERNAME_LABEL')}
              name="username"
              validateOnBlur={true}
            />
          </div>
          <div data-role="password">
            <PasswordCreationInput
              data-meta-id="password"
              data-msg-required={() => translateKey('TEXT_INPUT.ERROR_MESSAGE')}
              showRequirements={!isLogin}
              label={translateKey('CREATE_ACCOUNT.ACCOUNT_INFO.PASSWORD_LABEL')}
              name="password"
            />
          </div>

          {this.renderTerms()}
          {this.renderFormErrors()}
          {this.renderButtonsRow()}
        </Form>
      </section>
    );
  }
}
export default CredentialForm;
