import { withNamespace } from '../../../components/elements/textDisplays/TranslatedText';
import CreateCredentialForm from '../../CreateBasicCredentialsPage/CreateCredentialForm';
import actionCreator from '../../../actioncreators/createAccount/accountInfo';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const TranslatedText = withNamespace('CREATE_ACCOUNT.ACCOUNT_INFO');
const translateKey = (key, props) => <TranslatedText i18nKey={key} {...props} />;

class AccountInfo extends Component {
  static propTypes = {
    associateToken: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    emailVerificationToken: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    onCreateAccount: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  submitForm(formData) {
    const {
      firstName,
      lastName,
      email: address,
      emailVerificationToken,
      code,
      onCreateAccount,
    } = this.props;

    const { username, password, acceptTerms } = formData;

    const data = {
      username,
      password,
      firstName,
      lastName,
      email: [{ address }],
      phoneNumber: null,
      termsAndConditionsChecked: acceptTerms,
      code,
    };

    return onCreateAccount(emailVerificationToken, data);
  }

  render() {
    const { associateToken, onCancel } = this.props;

    return (
      <CreateCredentialForm
        title={translateKey('TITLE')}
        subtitle={null}
        submitTitle={translateKey('CREATE_ACCOUNT_BUTTON')}
        requireTermsAcceptance={true}
        token={associateToken}
        onSubmit={(data) => this.submitForm(data)}
        onCancel={onCancel}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    firstName: state.createAccountPersonalInfo.form.firstName.value,
    lastName: state.createAccountPersonalInfo.form.lastName.value,
    email: state.createAccountPersonalInfo.form.email.value,
  };
}

function mapDispatchToProps() {
  return {
    onCreateAccount(token, data) {
      const isSSO = false;

      return actionCreator.createAccount(token, data, isSSO);
    },
  };
}

export { AccountInfo as UnwrappedAccountInfo };
export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo);
