import serializer from './serializer';
import HttpClient from '../../utils/http/HttpClient';
import getConstants from '../../utils/constants';

const constants = getConstants(process.env.BASE_URL);
const baseURL = constants.serviceBaseUrls.authenticationService;

class LoginDatasource {
  constructor() {
    this.httpClient = HttpClient.createInstance({
      baseURL,
    });
  }

  async login(username, password) {
    const payload = serializer.toLoginRequest({
      username,
      password,
    });

    const response = await this.httpClient.post('/login', payload);

    return serializer.fromLoginResponse(response.data);
  }
}

export default new LoginDatasource();
