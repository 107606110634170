import translation from './translation';
import login from './login';
import forgotPasswordRequest from './forgotPassword/request';
import forgotUsernameRequest from './forgotUsername/request';
import selfRegistrationRequest from './selfRegistration/request';
import ssoLoginRequest from './ssoLogin/request';
import forgotPasswordReset from './forgotPassword/reset';
import accountRegistration from './accountRegistration';
import createAccountPersonalInfo from './createAccount/personalInfo';
import createAccountAccountInfo from './createAccount/accountInfo';
import createCredentialAccountInfo from './createCredential/accountInfo';
import accountSettingsChangePassword from './accountSettings/changePassword';
import accountSettingsChangeUsername from './accountSettings/changeUsername';
import accountSettingsContactInfo from './accountSettings/contactInfo';
import accountSettingsDevices from './accountSettings/devices';
import accountSettingsLocalization from './accountSettings/localization';
import accountSettingsSwitchClients from './accountSettings/switchClients';
import switchClientsRedirectPage from './accountSettings/switchClientsRedirectPage';
import knowledgeCheck from './knowledgeCheck';
import messages from './messages';
import me from './me';
import saml from './saml';
import sessionExpireModal from './sessionExpireModal';
import userConfirmation from './userConfirmation';
import accountLinking from './accountLinking';
import modal from './modal';
import ssoMe from './ssoMe';
import ssoServiceProviderPersonalInfo from './ssoServiceProvider/personalInfo';
import emailVerifyNotification from './emailVerifyNotification';
import legalAgreements from './legalAgreements';
import mfa from './mfa';
import landingProducts from './landingProducts';
import application from './application';
import verifyContactInfo from './verifyContactInfo';
import startImpersonation from './startImpersonation';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  translation,
  knowledgeCheck,
  login,
  forgotPasswordRequest,
  forgotUsernameRequest,
  selfRegistrationRequest,
  ssoLoginRequest,
  forgotPasswordReset,
  accountRegistration,
  createAccountPersonalInfo,
  createAccountAccountInfo,
  createCredentialAccountInfo,
  accountSettingsChangePassword,
  accountSettingsChangeUsername,
  accountSettingsContactInfo,
  accountSettingsDevices,
  accountSettingsLocalization,
  accountSettingsSwitchClients,
  switchClientsRedirectPage,
  messages,
  me,
  saml,
  sessionExpireModal,
  userConfirmation,
  accountLinking,
  modal,
  ssoMe,
  ssoServiceProviderPersonalInfo,
  emailVerifyNotification,
  legalAgreements,
  mfa,
  landingProducts,
  application,
  verifyContactInfo,
  startImpersonation,
});

export { rootReducer as default };
