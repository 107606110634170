const emailVerificationTokenKind = 'emailVerification';

const toSendVerificationRequest = (emailAddress, emailAddressToUpdate) => ({
  emailAddress,
  oldEmail: emailAddressToUpdate,
});

const fromSendVerificationResponse = (response) => ({
  token: response.data.token,
});

const toVerifyEmailRequest = (token, code) => ({
  kind: emailVerificationTokenKind,
  token,
  data: {
    code,
  },
});

export default {
  sendVerification: {
    toRequest: toSendVerificationRequest,
    fromResponse: fromSendVerificationResponse,
  },
  verifyEmail: {
    toRequest: toVerifyEmailRequest,
  },
};
