import serializer from './serializer';
import HttpClient from '../../utils/http/HttpClient';
import getConstants from '../../utils/constants';

const OBLIGATION_STATUS = 412;
const constants = getConstants(process.env.BASE_URL);
const authenticationServiceUrl = constants.serviceBaseUrls.authenticationService;

class ObligationsDatasource {
  constructor() {
    this.httpClient = HttpClient.createInstance({
      baseURL: authenticationServiceUrl,
      skipErrorHandling: true,
    });
  }

  async checkObligation() {
    try {
      await this.httpClient.get('/check-obligations');
      return { obligationRequired: false };
    } catch (error) {
      const { response } = error;

      if (response.status === OBLIGATION_STATUS) {
        const action = serializer.checkObligations.fromResponse(response.data);

        return {
          obligationRequired: true,
          action,
        };
      }
      throw error;
    }
  }
}

export default new ObligationsDatasource();
