import actionTypes from '../actiontypes/verifyContactInfo';

const initialState = {
  personId: null,
  idmContactInfo: null,
  sorContactInfo: null,
  isLoadingData: false,
  loadError: undefined,
};

function verifyContactInfoReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.LOAD.SUCCESS:
      return {
        ...state,
        personId: payload.person.personId,
        idmContactInfo: payload.idmContactInfo,
        sorContactInfo: payload.sorContactInfo,
        isLoadingData: false,
        loadError: null,
      };
    case actionTypes.LOAD.FAILURE:
      return {
        ...state,
        personId: null,
        idmContactInfo: null,
        isLoadingData: false,
        loadError: payload,
      };
    case actionTypes.LOAD.REQUEST:
      return {
        ...state,
        isLoadingData: true,
      };
    default:
      return state;
  }
}

export default verifyContactInfoReducer;
