import contactInfoSerializer from './accountSettings/contactInfo/serializer';
import actionTypes from '../actiontypes/verifyContactInfo';
import http from '../utils/http';

const getActionForTypeAndData = (type, payload) => ({ type, payload });

const actionBuilder = {
  loadRequestAction: () => getActionForTypeAndData(actionTypes.LOAD.REQUEST),
  loadSuccessAction: (data) => getActionForTypeAndData(actionTypes.LOAD.SUCCESS, data),
  loadFailureAction: (error) => getActionForTypeAndData(actionTypes.LOAD.FAILURE, error),
};

const verifyContactInfoActionCreator = {
  loadData() {
    return async (dispatch) => {
      const { loadRequestAction, loadSuccessAction, loadFailureAction } = actionBuilder;

      dispatch(loadRequestAction());

      try {
        const [personData, contactInfoData, sorContactInfoData] = await Promise.all([
          http.getMyPersonalInfo(),
          http.getMyContactInfo(),
          http.getMySorContactInfo(),
        ]);

        const { data: person } = personData;
        const idmContactInfo = contactInfoSerializer.getContactInfo.fromResponse(
          contactInfoData.data
        );
        const sorContactInfo = contactInfoSerializer.getContactInfo.fromResponse(
          sorContactInfoData.data
        );

        dispatch(loadSuccessAction({ person, idmContactInfo, sorContactInfo }));
      } catch (err) {
        dispatch(loadFailureAction(err));
      }
    };
  },
};

export default verifyContactInfoActionCreator;
