import BaseActionCreator from '../../BaseActionCreator';
import http from '../../../utils/http';
import actionTypes from '../../../actiontypes';
import getConstants, { tokenKinds } from '../../../utils/constants';

const { CREATE_CREDENTIAL_ACCOUNT_INFO } = actionTypes;
const { requestResult } = getConstants();

class CreateCredentialAccountActionCreator extends BaseActionCreator {
  async loadAndVerifyToken(token) {
    try {
      this.dispatch(CREATE_CREDENTIAL_ACCOUNT_INFO.VERIFYING_TOKEN);

      await http.loadAndVerify(tokenKinds.CREATE_CREDENTIAL, token);

      this.dispatch(CREATE_CREDENTIAL_ACCOUNT_INFO.TOKEN_VERIFIED);
    } catch (e) {
      if (e.code === http.supportedErrorCodes.TOKEN_LOAD_VERIFY_FAILURE) {
        this.changeState(requestResult.FAILURE_LINK);
      } else {
        this.changeState(requestResult.FAILURE_UNSUPPORTED);
      }
    }
  }

  invalidPasswordFailure(errorCode) {
    return {
      type: CREATE_CREDENTIAL_ACCOUNT_INFO.CREATE_CREDENTIAL_PASSWORD_VALIDATION_FAILURE,
      payload: {
        errorCode,
      },
    };
  }

  invalidUsernameFailure(errorCode) {
    return {
      type: CREATE_CREDENTIAL_ACCOUNT_INFO.CREATE_CREDENTIAL_USERNAME_OR_EMAIL_VALIDATION_FAILURE,
      payload: {
        errorCode,
      },
    };
  }

  createCredentialRequest() {
    return {
      type: CREATE_CREDENTIAL_ACCOUNT_INFO.CREATE_CREDENTIAL_REQUEST,
    };
  }

  createCredentialSuccess() {
    return {
      type: CREATE_CREDENTIAL_ACCOUNT_INFO.CREATE_CREDENTIAL_SUCCESS,
    };
  }

  createCredentialFailure() {
    return {
      type: CREATE_CREDENTIAL_ACCOUNT_INFO.CREATE_CREDENTIAL_FAILURE,
    };
  }

  async createCredential(token, data) {
    try {
      this.dispatch(this.createCredentialRequest());

      const requestData = {
        username: data.username,
        password: data.password,
        termsAndConditionsChecked: data.acceptTerms,
      };

      await http.execute('credentialCreation', token, requestData);

      this.dispatch(this.createCredentialSuccess());

      this.changeUrl(`/auth/create-credential/success-notification${window.location.search}`);
    } catch (error) {
      this.dispatch(this.createCredentialFailure());

      const isBadLink =
        error.code === http.supportedErrorCodes.TOKEN_LOAD_VERIFY_FAILURE ||
        error.code === http.supportedErrorCodes.TOKEN_EXECUTE_FAILURE ||
        error.code === http.supportedErrorCodes.INVALID_TOKEN;

      const isBadUsername =
        error.code === http.supportedErrorCodes.DUPLICATE_USERNAME ||
        error.code === http.supportedErrorCodes.DUPLICATE_EMAIL;

      const isBadPassword = Boolean(http.supportedErrorCodes[error.code]);

      if (isBadLink) {
        this.changeState(requestResult.FAILURE_LINK);
      } else if (isBadUsername) {
        this.dispatch(this.invalidUsernameFailure(error.code));
        throw error;
      } else if (isBadPassword) {
        this.dispatch(this.invalidPasswordFailure(error.code));
        throw error;
      } else {
        this.changeState(requestResult.FAILURE_UNSUPPORTED);
      }
    }
  }
}

export default new CreateCredentialAccountActionCreator();
