const accountInfoActionTypes = {
  RESET_STATE: 'RESET_STATE',
  VERIFYING_TOKEN: 'VERIFYING_TOKEN',
  TOKEN_VERIFIED: 'TOKEN_VERIFIED',
  CREATE_CREDENTIAL_REQUEST: 'CREATE_CREDENTIAL_REQUEST',
  CREATE_CREDENTIAL_SUCCESS: 'CREATE_CREDENTIAL_SUCCESS',
  CREATE_CREDENTIAL_FAILURE: 'CREATE_CREDENTIAL_FAILURE',
  CREATE_CREDENTIAL_PASSWORD_VALIDATION_FAILURE: 'CREATE_CREDENTIAL_PASSWORD_VALIDATION_FAILURE',
  CREATE_CREDENTIAL_USERNAME_OR_EMAIL_VALIDATION_FAILURE:
    'CREATE_CREDENTIAL_USERNAME_OR_EMAIL_VALIDATION_FAILURE',
};

export { accountInfoActionTypes as default };
