import styles from './errors.module.scss';
import BaseError from './BaseError';
import { withNamespace } from '../../../components/elements/textDisplays/TranslatedText';

import Device from '../../../context/Device';
import Utilities from '../../../utils/helpers';
import { LinkButton } from '../../../components';
import React from 'react';

const TranslatedText = withNamespace('ERRORS');

class BadTokenError extends React.Component {
  static contextType = Device;

  renderMessage() {
    return (
      <div>
        <TranslatedText i18nKey="BAD_TOKEN.MESSAGE" />
        <ul className={styles.badLinkReasons}>
          <li>
            <div>
              <TranslatedText i18nKey="BAD_TOKEN.ALREADY_REGISTERED" />
            </div>
            <LinkButton
              className={styles.accessLink}
              onClick={() => Utilities.navigateToLifionHome()}
            >
              <TranslatedText i18nKey="BAD_TOKEN.ACCESS_LINK" />
            </LinkButton>
          </li>
          <li>
            <TranslatedText i18nKey="BAD_TOKEN.EXPIRED_LINK" />
          </li>
        </ul>
      </div>
    );
  }

  render() {
    const title = <TranslatedText i18nKey="BAD_TOKEN.TITLE" />;

    return <BaseError title={title}>{this.renderMessage()}</BaseError>;
  }
}

export default BadTokenError;
