import ContactInfoView from './ContactInfoView';
import { noop, staticConstants } from '../../../../utils/constants';
import { ContactInfoVerificationFlow, ErrorCodes } from '@idm/authentication-flows';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './style.scss';

const { errorCodes, errorNotificationEvents } = staticConstants;
const authFlowsErrorCodes = ErrorCodes;

class ContactInfoPage extends React.Component {
  static propTypes = {
    i18nContext: PropTypes.shape().isRequired,
    dictionary: PropTypes.shape().isRequired,
    authenticationMethods: PropTypes.arrayOf(PropTypes.shape),
    isAuthenticationMethodsLoading: PropTypes.bool.isRequired,
    onLoadAuthenticationMethods: PropTypes.func,
    onDelete: PropTypes.func,
    onSuccessNotification: PropTypes.func,
    onErrorNotification: PropTypes.func,
    language: PropTypes.string,
    hideActionButtons: PropTypes.bool,
    onGetRequireAdpEmailFlagStatus: PropTypes.func,
    isRequireAdpEmailFlagEnabled: PropTypes.bool,
    onGetOnlyAllowAdpEmails: PropTypes.func,
    isOnlyAllowAdpEmailFlagEnabled: PropTypes.bool,
    clientId: PropTypes.string,
  };

  static defaultProps = {
    authenticationMethods: null,
    onLoadAuthenticationMethods: noop,
    onDelete: noop,
    onSuccessNotification: noop,
    onErrorNotification: noop,
    language: null,
    hideActionButtons: false,
    onGetRequireAdpEmailFlagStatus: noop,
    isRequireAdpEmailFlagEnabled: true,
    onGetOnlyAllowAdpEmails: noop,
    isOnlyAllowAdpEmailFlagEnabled: false,
    clientId: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      showAddModal: false,
    };
  }

  componentDidMount() {
    const {
      authenticationMethods,
      onLoadAuthenticationMethods,
      isAuthenticationMethodsLoading,
      onGetRequireAdpEmailFlagStatus,
      onGetOnlyAllowAdpEmails,
    } = this.props;
    const authenticationMethodsAreNotProvidedAndNotLoading =
      !authenticationMethods && !isAuthenticationMethodsLoading;

    if (authenticationMethodsAreNotProvidedAndNotLoading) {
      onLoadAuthenticationMethods();
      onGetRequireAdpEmailFlagStatus();
      onGetOnlyAllowAdpEmails();
    }
  }

  onAddCancel = () => {
    this.setState({ showAddModal: false });
  };

  onAddContact = () => {
    this.setState({ showAddModal: true });
  };

  onAddContactInfoSuccess = () => {
    const { onLoadAuthenticationMethods } = this.props;

    this.dispatchSuccessNotificationEvent('ADD_SUCCESS');
    this.setState({ showAddModal: false });
    onLoadAuthenticationMethods();
  };

  onVerificationCodeSent = () => {
    this.dispatchSuccessNotificationEvent('VERIFICATION_CODE_SENT');
  };

  onAddError = (code, error) => {
    let errorNotificationEvent = errorNotificationEvents.ADD_ERROR;

    if (error.code === errorCodes.PHONE_NUMBER_ALREADY_EXISTS) {
      errorNotificationEvent = errorNotificationEvents.DUPE_PHONE_NUMBER_ERROR;
    } else if (code === authFlowsErrorCodes.INVALID_PHONE_NUMBER) {
      errorNotificationEvent = errorNotificationEvents.INVALID_PHONE_NUMBER;
    } else if (code === authFlowsErrorCodes.INVALID_EMAIL) {
      errorNotificationEvent = errorNotificationEvents.INVALID_EMAIL;
    }

    this.dispatchErrorNotificationEvent(errorNotificationEvent);
  };

  onInvalidAddForm = () => {
    this.dispatchErrorNotificationEvent('FORM_ERROR');
  };

  dispatchErrorNotificationEvent = (messageKey) => {
    const { i18nContext, onErrorNotification } = this.props;
    const message = i18nContext.getTextForKey(messageKey);

    onErrorNotification({
      message,
    });
  };

  dispatchSuccessNotificationEvent = (messageKey) => {
    const { i18nContext, onSuccessNotification } = this.props;
    const message = i18nContext.getTextForKey(messageKey);

    onSuccessNotification({
      message,
    });
  };

  renderAddContactModal() {
    const {
      language,
      authenticationMethods,
      isRequireAdpEmailFlagEnabled,
      isOnlyAllowAdpEmailFlagEnabled,
      clientId,
    } = this.props;

    return (
      <div className="idm-modal-container">
        <ContactInfoVerificationFlow
          onError={this.onAddError}
          onInvalidFormSubmission={this.onInvalidAddForm}
          isRequireAdpEmailFlagEnabled={isRequireAdpEmailFlagEnabled}
          isOnlyAllowAdpEmailFlagEnabled={isOnlyAllowAdpEmailFlagEnabled}
          clientId={clientId}
          onCodeSent={this.onVerificationCodeSent}
          onCancel={this.onAddCancel}
          onSuccess={this.onAddContactInfoSuccess}
          language={language}
          userInfo={authenticationMethods}
        />
      </div>
    );
  }

  render() {
    const {
      authenticationMethods,
      isAuthenticationMethodsLoading,
      i18nContext,
      dictionary,
      hideActionButtons,
    } = this.props;
    const { onDelete } = this.props;
    const { showAddModal } = this.state;

    return (
      <section className="idm-contact-info-page">
        <ContactInfoView
          i18nContext={i18nContext}
          onDelete={onDelete}
          onAddContact={this.onAddContact}
          authenticationMethods={authenticationMethods}
          isLoading={isAuthenticationMethodsLoading}
          dictionary={dictionary}
          hideActionButtons={hideActionButtons}
        />
        {showAddModal && this.renderAddContactModal()}
      </section>
    );
  }
}

function mapStateToProps(state) {
  const { accountSettingsContactInfo, translation } = state;

  return {
    contactInfo: accountSettingsContactInfo.contactInfo,
    dictionary: translation.main.dictionary,
  };
}

export default connect(mapStateToProps, null, null, { withRef: true })(ContactInfoPage);
