const mapSingleEmail = (emailRepsonse, index, arr) => ({
  type: 'EMAIL',
  id: `${emailRepsonse.personId}_${emailRepsonse.emailAddress}`,
  personId: emailRepsonse.personId,
  emailAddress: emailRepsonse.emailAddress,
  isVerified: emailRepsonse.isVerified,
  isOnlyOneOfType: arr.length === 1,
  isPersonal: emailRepsonse.isPersonal,
  createdAt: emailRepsonse.createdAt,
  lastModifiedAt: emailRepsonse.lastModifiedAt,
});

const mapEmails = (emails) => emails.map(mapSingleEmail);

const mapSinglePhone = (phone, index, arr) => ({
  type: 'PHONE',
  id: `${phone.personId}_${phone.phoneNumber}`,
  personId: phone.personId,
  phoneNumber: phone.phoneNumber,
  isVerified: phone.isVerified,
  isOnlyOneOfType: arr.length === 1,
  isPersonal: phone.isPersonal,
  createdAt: phone.createdAt,
  lastModifiedAt: phone.lastModifiedAt,
});

const mapPhones = (phones) => phones.map(mapSinglePhone);

const fromGetResponse = (response) => [
  ...mapEmails(response.emails),
  ...mapPhones(response.phones),
];

export default {
  getContactInfo: {
    fromResponse: fromGetResponse,
  },
};
