import HttpClient from './HttpClient';
import getConstants from '../constants';

const constants = getConstants(process.env.BASE_URL || window.location.origin);
const identityOrchestratorUrl = constants.serviceBaseUrls.identityOrchestrator;
const httpClient = HttpClient.createInstance({ baseURL: identityOrchestratorUrl });

export function getLegalAgreementsByKeys(keys) {
  return httpClient.get(`/legal-agreements?keys=${keys}`);
}

export function agreeToLegalAgreements(agreementsData) {
  return httpClient.post('/persons/me/legal-agreements', agreementsData);
}
