const fromLoginResponse = (data) => {
  const { identityProviders } = data;
  const ssoDiscoveryEnabledProvider = identityProviders.find(
    (provider) => provider.isDiscoveryEnabled
  );

  return {
    url: ssoDiscoveryEnabledProvider
      ? ssoDiscoveryEnabledProvider.initiateRequestUrl
      : identityProviders[0].initiateRequestUrl,
  };
};

const toLoginRequest = (data) => {
  return {
    email: data.email,
  };
};

export default {
  fromLoginResponse,
  toLoginRequest,
};
