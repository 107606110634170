import Rule from './Rule';
import ValidationError from '../../exceptions/ValidationError';

export default class LowerCaseRule extends Rule {
  apply(value) {
    if (/[a-z]/.test(value)) {
      return Promise.resolve(this.message);
    }

    return Promise.reject(new ValidationError(this.message));
  }
}
