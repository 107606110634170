import serializer from './serializer';
import HttpClient from '../../utils/http/HttpClient';
import getConstants from '../../utils/constants';

const constants = getConstants(process.env.BASE_URL);
const authenticationServiceUrl = constants.serviceBaseUrls.authenticationService;

class LandingProductsDatasource {
  constructor() {
    this.httpClient = HttpClient.createInstance({
      baseURL: authenticationServiceUrl,
    });
  }

  async getLandingProducts() {
    const response = await this.httpClient.get('/landing-products');

    return serializer.fromLoadLandingProductsResponse(response.data);
  }
}

export default new LandingProductsDatasource();
