import actionTypes from 'actiontypes/';

const initialState = {
  dob: '',
  errorCode: null,
  isValidDob: false,
  isValidatingDob: false,
  showDobRequired: false,
  locale: '',
};

function knowledgeCheckReducer(state = initialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.ACCOUNT_REGISTRATION.CHECK_ASSOCIATE_DOB_SUCCESS:
      return { ...state, isValidDob: true, isValidatingDob: false };
    case actionTypes.ACCOUNT_REGISTRATION.CHECK_ASSOCIATE_DOB_FAILURE:
      return { ...state, isValidatingDob: false, errorCode: actionPayload };
    case actionTypes.ACCOUNT_REGISTRATION.CHECK_ASSOCIATE_DOB_REQUEST:
      return { ...state, isValidatingDob: true, errorCode: null };
    case actionTypes.ACCOUNT_REGISTRATION.SET_ASSOCIATE_DOB:
      return { ...state, dob: actionPayload, errorCode: null };
    case actionTypes.ACCOUNT_REGISTRATION.TOGGLE_SHOW_DOB_OBLIGATION:
      return { ...state, showDobRequired: !state.showDobRequired };
    case actionTypes.ACCOUNT_REGISTRATION.SET_DOB_LOCALE:
      return { ...state, locale: actionPayload };
    case actionTypes.ACCOUNT_REGISTRATION.RESET_DOB_CHECK:
      return { ...initialState };
    default:
      return state;
  }
}

export { knowledgeCheckReducer as default };
