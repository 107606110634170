import serializer from './serializer';
import HttpClient from '../../../utils/http/HttpClient';
import getConstants from '../../../utils/constants';

const constants = getConstants(process.env.BASE_URL);
const identityOrchestratorUrl = constants.serviceBaseUrls.identityOrchestrator;

class RegistrationPersonalInfoDatasource {
  constructor() {
    this.httpClient = HttpClient.createInstance({
      baseURL: identityOrchestratorUrl,
    });
  }

  async validateToken(tokenKind, token, validationData) {
    const validationDataRequest = serializer.toValidateTokenRequest(
      tokenKind,
      token,
      validationData
    );
    const url = '/tokens/validate';

    await this.httpClient.post(url, validationDataRequest);
  }
}

export default new RegistrationPersonalInfoDatasource();
