import buildFormReducer from 'reducers/builders/form';
import actionTypes, { actionTypeSections } from 'actiontypes/';
import { combineReducers } from 'redux';

const mainInitialState = {
  isCheckingAuth: false,
  isCheckingUsername: false,
  showPasswordField: false,
  isLoggingIn: false,
  error: {
    isVisible: false,
    code: undefined,
  },
  captchaRequired: false,
  recaptchaSiteKey: undefined,
};

const formInitialState = {
  username: {
    value: '',
    isValid: false,
    isValidating: false,
  },
  password: {
    value: '',
    isValid: false,
    isValidating: false,
  },
  recaptchaToken: {
    value: undefined,
    isValid: true,
    isValidating: false,
  },
};

function mainReducer(state = mainInitialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.LOGIN.BASIC_LOGIN_REQUEST:
      return {
        ...state,
        error: {
          isVisible: false,
          code: undefined,
        },
        isLoggingIn: true,
      };
    case actionTypes.LOGIN.RESET_STATE:
    case actionTypes.LOGIN.BASIC_LOGIN_SUCCESS:
      return { ...mainInitialState };
    case actionTypes.LOGIN.BASIC_LOGIN_FAILURE:
      return {
        ...state,
        error: {
          isVisible: true,
          code: actionPayload.error.code,
        },
        captchaRequired:
          !!actionPayload.error.details && !!actionPayload.error.details.captchaRequired,
        recaptchaSiteKey: actionPayload.error.details?.recaptchaSiteKey,
        isLoggingIn: false,
      };
    case actionTypes.LOGIN.CHECK_AUTH_REQUEST:
      return {
        ...state,
        isCheckingAuth: true,
      };
    case actionTypes.LOGIN.CHECK_USERNAME_REQUEST:
      return {
        ...state,
        isCheckingUsername: actionPayload.isCheckingUsername,
      };
    case actionTypes.LOGIN.CHECK_USERNAME_SUCCESS:
      return {
        ...state,
        isCheckingUsername: actionPayload.isCheckingUsername,
        showPasswordField: actionPayload.showPasswordField,
      };
    case actionTypes.LOGIN.CHECK_USERNAME_FAILURE:
      return {
        ...state,
        isCheckingUsername: actionPayload.isCheckingUsername,
        showPasswordField: actionPayload.showPasswordField,
        error: {
          isVisible: true,
          code: actionPayload.error.code,
        },
      };
    case actionTypes.LOGIN.HIDE_PASSWORD_INPUT_DISPLAY:
      return {
        ...state,
        showPasswordField: actionPayload.showPasswordField,
      };
    case actionTypes.LOGIN.CHECK_AUTH_SUCCESS:
      return {
        ...state,
        isCheckingAuth: false,
        isAuthenticatedByNexo: actionPayload?.isAuthenticatedByNexo || false,
        levelOfAssurance: actionPayload?.levelOfAssurance,
      };
    case actionTypes.LOGIN.CHECK_AUTH_FAILURE:
      return {
        ...state,
        isCheckingAuth: false,
      };
    default:
      return state;
  }
}

const loginReducer = combineReducers({
  main: mainReducer,
  form: buildFormReducer(formInitialState, actionTypeSections.LOGIN),
});

export { loginReducer as default };
