import CreateCredentialForm from './CreateCredentialForm';

import { withNamespace } from '../../components/elements/textDisplays/TranslatedText';
import React from 'react';
import PropTypes from 'prop-types';

const TranslatedText = withNamespace('CREATE_BASIC_CREDENTIALS');
const translateKey = (key, props) => <TranslatedText i18nKey={key} {...props} />;

const CreateBasicCredentialsContainer = (props) => {
  return (
    <div>
      <CreateCredentialForm
        title={translateKey('TITLE')}
        subtitle={translateKey('SUBTITLE')}
        submitTitle={translateKey('SUBMIT')}
        requireTermsAcceptance={true}
        {...props}
      />
    </div>
  );
};

CreateBasicCredentialsContainer.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CreateBasicCredentialsContainer;
