import UtilityHelper from '../../utils/helpers';
import getConstants, { staticConstants, noop } from '../../utils/constants';
import http from '../../utils/http';
import phoneUtils from '../../utils/phoneNumber';
import actionCreator from '../../actioncreators/verifyContactInfo';
import applicationActionCreator from '../../actioncreators/application';
import { ContactInfoVerificationFlow, ErrorCodes } from '@idm/authentication-flows';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';

const { requestResult } = getConstants();
const { contactInfoVerification, mobileEventKeys, logLevels } = staticConstants;
const { logger } = http;
const authFlowsErrorCodes = ErrorCodes;
const errorCodes = {
  TOO_MANY_ATTEMPTS: 'TOO_MANY_ATTEMPTS',
  EMAIL_ADDRESS_ALREADY_EXISTS: 'EMAIL_ADDRESS_ALREADY_EXISTS',
  CONTACT_INFO_ALREADY_VERIFIED: 'CONTACT_INFO_ALREADY_VERIFIED',
  INVALID_CONTACT_INFO_FORMAT: 'INVALID_CONTACT_INFO_FORMAT',
  MISSING_REQUIRED_QUERY_PARAMS: 'MISSING_REQUIRED_QUERY_PARAMS',
};
const errorDetails = {
  MISSING_REQUIRED_QUERY_PARAMS: {
    code: errorCodes.MISSING_REQUIRED_QUERY_PARAMS,
    message: 'There are some required query params that are missing',
  },
  INVALID_CONTACT_INFO_FORMAT: {
    code: errorCodes.INVALID_CONTACT_INFO_FORMAT,
    message: 'The format of the contact info value is invalid',
  },
};

const areQueryParamsValid = (type, purpose) => {
  if (
    (type !== contactInfoVerification.types.EMAIL &&
      type !== contactInfoVerification.types.PHONE) ||
    (purpose !== contactInfoVerification.purposes.PERSONAL &&
      purpose !== contactInfoVerification.purposes.BUSINESS)
  ) {
    return false;
  }

  return true;
};

const getTypeFromUserContact = (userContact) => {
  if (userContact.emailAddress) {
    return contactInfoVerification.types.EMAIL;
  }

  return contactInfoVerification.types.PHONE;
};

const getPurposeFromUserContact = (userContact) => {
  if (userContact.isPersonal) {
    return contactInfoVerification.purposes.PERSONAL;
  }

  return contactInfoVerification.purposes.BUSINESS;
};

class VerifyContactInfo extends React.Component {
  static propTypes = {
    language: PropTypes.string,
    personId: PropTypes.string,
    location: PropTypes.object.isRequired,
    idmContactInfo: PropTypes.arrayOf(PropTypes.shape),
    sorContactInfo: PropTypes.arrayOf(PropTypes.shape),
    isLoadingData: PropTypes.bool,
    dataLoadError: PropTypes.object,
    onLoadData: PropTypes.func,
  };

  static defaultProps = {
    language: null,
    personId: null,
    idmContactInfo: [],
    sorContactInfo: [],
    isLoadingData: false,
    dataLoadError: null,
    onLoadData: noop,
  };

  constructor(props) {
    super(props);

    const queryParams = UtilityHelper.parseQueryString(props.location.search);
    const { type: contactType, purpose: contactPurpose, returnUrl, platformType } = queryParams;

    if (!areQueryParamsValid(contactType, contactPurpose)) {
      this.handleError(Object.assign(new Error(), errorDetails.MISSING_REQUIRED_QUERY_PARAMS));
    }

    this.platformType = platformType;
    this.contactType = contactType;
    this.contactPurpose = contactPurpose;
    this.returnUrl = UtilityHelper.convertToRelativePath(returnUrl || '');
    this.contactInfo = {
      isPersonal: contactPurpose === contactInfoVerification.purposes.PERSONAL,
    };

    this.state = {
      showVerificationFlow: false,
    };

    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleError = this.handleError.bind(this);
    this.onContactInfoVerifyFlowError = this.onContactInfoVerifyFlowError.bind(this);
    this.onContactInfoVerifyFlowSuccess = this.onContactInfoVerifyFlowSuccess.bind(this);
  }

  componentDidMount() {
    const { onLoadData } = this.props;

    if (areQueryParamsValid(this.contactType, this.contactPurpose)) {
      onLoadData();
    }
  }

  componentDidUpdate() {
    const { sorContactInfo, isLoadingData, dataLoadError } = this.props;
    const { showVerificationFlow } = this.state;

    if (
      !showVerificationFlow &&
      !isLoadingData &&
      areQueryParamsValid(this.contactType, this.contactPurpose)
    ) {
      logger.log({
        message: 'Initiated contact info verification',
        level: logLevels.INFO,
        meta: this.createBaseLogMeta(),
      });

      if (!dataLoadError && sorContactInfo && sorContactInfo.length > 0) {
        const sorContactToVerify = sorContactInfo.find(
          (sorContact) =>
            getTypeFromUserContact(sorContact) === this.contactType &&
            getPurposeFromUserContact(sorContact) === this.contactPurpose
        );

        if (this.isContactTypeEmail()) {
          this.contactInfo.emailAddress = sorContactToVerify.emailAddress;
        } else {
          try {
            this.contactInfo.phoneNumber = phoneUtils.formatPhoneNumberForIDM(
              sorContactToVerify.phoneNumber
            );
          } catch (err) {
            this.handleError(Object.assign(new Error(), errorDetails.INVALID_CONTACT_INFO_FORMAT));
          }
        }
      } else {
        this.handleError(dataLoadError);
      }

      if (this.isContactInfoAlreadyVerified()) {
        this.handleSuccess(true);
      }

      this.setState({
        showVerificationFlow: true,
      });
    }
  }

  isContactTypeEmail = () => this.contactType === contactInfoVerification.types.EMAIL;

  isContactInfoAlreadyVerified() {
    const { idmContactInfo } = this.props;

    const contactValue = this.contactInfo.emailAddress ?? this.contactInfo.phoneNumber;

    if (!idmContactInfo || !contactValue) {
      return false;
    }

    let isAlreadyVerified = false;

    if (this.isContactTypeEmail()) {
      isAlreadyVerified = idmContactInfo.some(
        (userContact) =>
          userContact.emailAddress === contactValue &&
          getPurposeFromUserContact(userContact) === this.contactPurpose
      );
    } else {
      isAlreadyVerified = idmContactInfo.some(
        (userContact) =>
          userContact.phoneNumber === contactValue &&
          getPurposeFromUserContact(userContact) === this.contactPurpose
      );
    }

    return isAlreadyVerified;
  }

  createBaseLogMeta() {
    const { personId } = this.props;

    return {
      personId,
      contactDetails: {
        type: this.contactType,
        purpose: this.contactPurpose,
      },
    };
  }

  handleError(error) {
    const validError = error ?? {};

    logger.log({
      message: 'Encountered error during contact info verification',
      level: logLevels.ERROR,
      meta: {
        ...this.createBaseLogMeta(),
        error: {
          code: validError.code,
          message: validError.message,
        },
      },
    });

    if (validError.code) {
      switch (validError.code) {
        case errorCodes.EMAIL_ADDRESS_ALREADY_EXISTS:
          applicationActionCreator.changeState(
            requestResult.FAILURE_VERIFY_CONTACT_INFO_DUPLICATE_EMAIL
          );
          return;
        case errorCodes.TOO_MANY_ATTEMPTS:
          applicationActionCreator.changeState(
            requestResult.FAILURE_VERIFY_CONTACT_INFO_TOO_MANY_ATTEMPTS
          );
          return;
        case errorCodes.INVALID_CONTACT_INFO_FORMAT:
          applicationActionCreator.changeState(
            requestResult.FAILURE_VERIFY_CONTACT_INFO_INVALID_FORMAT
          );
          return;
        default:
          break;
      }
    }

    applicationActionCreator.changeState(requestResult.FAILURE_VERIFY_CONTACT_INFO_GENERIC_ERROR);
  }

  handleCancel() {
    logger.logWithoutBatching({
      message: 'User canceled contact info verification',
      level: logLevels.INFO,
      meta: this.createBaseLogMeta(),
    });

    UtilityHelper.triggerMobileEvent(mobileEventKeys.IDM_CONTACT_VERIFY_CANCEL);

    if (this.platformType === 'adp-mobile') {
      window.history.back();
      return;
    }

    window.location.href = UtilityHelper.convertToRelativePath(this.returnUrl);
  }

  handleSuccess(isAlreadyVerified) {
    logger.log({
      message: 'Successfully completed contact info verification',
      level: logLevels.INFO,
      meta: {
        ...this.createBaseLogMeta(),
        isAlreadyVerified,
      },
    });

    if (isAlreadyVerified) {
      applicationActionCreator.changeState(
        requestResult.SUCCESS_VERIFY_CONTACT_INFO_ALREADY_VERIFIED
      );
      return;
    }

    if (this.isContactTypeEmail()) {
      applicationActionCreator.changeState(requestResult.SUCCESS_VERIFY_CONTACT_INFO_EMAIL);
    } else {
      applicationActionCreator.changeState(requestResult.SUCCESS_VERIFY_CONTACT_INFO_PHONE);
    }
  }

  onContactInfoVerifyFlowSuccess() {
    this.handleSuccess(false);
  }

  onContactInfoVerifyFlowError(code, error) {
    let formattedError = error;

    if (code === authFlowsErrorCodes.INVALID_PHONE_NUMBER) {
      formattedError = Object.assign(new Error(), errorDetails.INVALID_CONTACT_INFO_FORMAT);
    }

    this.handleError(formattedError);
  }

  render() {
    const { language, idmContactInfo } = this.props;
    const { showVerificationFlow } = this.state;

    return (
      <div className="verify-contact-info">
        {showVerificationFlow && (
          <ContactInfoVerificationFlow
            userInfo={idmContactInfo || []}
            contactInfoOverride={this.contactInfo}
            onSuccess={this.onContactInfoVerifyFlowSuccess}
            onCancel={this.handleCancel}
            onError={this.onContactInfoVerifyFlowError}
            language={language}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { accountSettingsLocalization, verifyContactInfo } = state;
  const {
    main: { language },
  } = accountSettingsLocalization;
  const {
    personId,
    idmContactInfo,
    sorContactInfo,
    isLoadingData,
    loadError: dataLoadError,
  } = verifyContactInfo;

  return {
    language,
    personId,
    idmContactInfo,
    sorContactInfo,
    isLoadingData,
    dataLoadError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadData() {
      return dispatch(actionCreator.loadData());
    },
  };
}

const VerifyContactInfoPage = connect(mapStateToProps, mapDispatchToProps)(VerifyContactInfo);

export { mapDispatchToProps };
export default VerifyContactInfoPage;
