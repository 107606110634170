import { RegexRule } from '@tbiegner99/react-forms';

const REGEX = /[a-z]/;

class LowerCaseRule extends RegexRule {
  constructor() {
    super(REGEX);
  }

  static get ruleName() {
    return 'lower-case-required';
  }

  getDefaultMessage() {
    return 'Must contain a lower case character';
  }
}

export default LowerCaseRule;
