const accountInfoActionTypes = {
  RESET_STATE: 'RESET_STATE',
  CREATE_ACCOUNT_REQUEST: 'CREATE_ACCOUNT_REQUEST',
  CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_FAILURE: 'CREATE_ACCOUNT_FAILURE',
  CREATE_ACCOUNT_PASSWORD_VALIDATION_FAILURE: 'CREATE_ACCOUNT_PASSWORD_VALIDATION_FAILURE',
  CREATE_ACCOUNT_USERNAME_OR_EMAIL_VALIDATION_FAILURE:
    'CREATE_ACCOUNT_USERNAME_OR_EMAIL_VALIDATION_FAILURE',
};

export { accountInfoActionTypes as default };
