import './style.scss';
import TranslatedText from '../../components/elements/textDisplays/TranslatedText';
import { getI18nContext } from '../TranslatedText';
import actionCreator from '../../actioncreators/accountSettings/switchClientsRedirectPage';
import { ButtonContainer, PrimaryButton } from '../../components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PageHeader, StandardParagraph } from '@idm/ui-components';
import React from 'react';

const I18nKeys = {
  headerTitle: 'ACCOUNT_SETTINGS.SWITCH_CLIENT.EDUCATIONAL_POPUP_HEADER',
  educationalPopupMessageOne: 'ACCOUNT_SETTINGS.SWITCH_CLIENT.EDUCATIONAL_POPUP_MESSAGE_ONE',
  educationalPopupMessageTwo: 'ACCOUNT_SETTINGS.SWITCH_CLIENT.EDUCATIONAL_POPUP_MESSAGE_TWO',
  continueButton: 'ACCOUNT_SETTINGS.SWITCH_CLIENT.CONTINUE_BUTTON',
  currentUsername: 'ACCOUNT_SETTINGS.SWITCH_CLIENT.CURRENT_USER_ID',
};

class SwitchClientRedirectPage extends React.Component {
  static propTypes = {
    i18nContext: PropTypes.shape({ getRawTextForKey: PropTypes.func.isRequired }).isRequired,
    username: PropTypes.string.isRequired,
    isNexoClient: PropTypes.bool.isRequired,
    redirectLoginUrl: PropTypes.string.isRequired,
    dispatchHandleClientSwitchRedirect: PropTypes.func.isRequired,
  };

  onContinue = () => {
    const { dispatchHandleClientSwitchRedirect, isNexoClient, redirectLoginUrl } = this.props;

    dispatchHandleClientSwitchRedirect(isNexoClient || false, redirectLoginUrl);
  };

  render() {
    const { username } = this.props;

    return (
      <div className="switch-client">
        <header>
          <PageHeader>
            <TranslatedText i18nKey={I18nKeys.headerTitle} />
          </PageHeader>
        </header>
        <main>
          <div className="educational-message-text">
            <StandardParagraph>
              <TranslatedText i18nKey={I18nKeys.educationalPopupMessageOne} />
            </StandardParagraph>
            <StandardParagraph>
              <TranslatedText i18nKey={I18nKeys.educationalPopupMessageTwo} />
            </StandardParagraph>
            {username && (
              <StandardParagraph>
                <TranslatedText i18nKey={I18nKeys.currentUsername} />
                <div>{username}</div>
              </StandardParagraph>
            )}
          </div>
        </main>
        <footer>
          <ButtonContainer>
            <PrimaryButton data-meta-id="continue" onClick={this.onContinue}>
              <TranslatedText i18nKey={I18nKeys.continueButton} />
            </PrimaryButton>
          </ButtonContainer>
        </footer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { translation, switchClientsRedirectPage } = state;
  const { dictionary } = translation.main;
  const i18nContext = getI18nContext('OBLIGATION.CHANGE_USERNAME', dictionary);
  const { username, isNexoClient, redirectLoginUrl } = switchClientsRedirectPage.main;

  return {
    i18nContext,
    username,
    isNexoClient,
    redirectLoginUrl,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchHandleClientSwitchRedirect(isNexoClient, redirectLoginUrl) {
      dispatch(actionCreator.handleClientSwitchRedirect(isNexoClient, redirectLoginUrl));
    },
  };
}

export { SwitchClientRedirectPage as UnwrappedSwitchClientRedirectPage };
export default connect(mapStateToProps, mapDispatchToProps)(SwitchClientRedirectPage);
