import buildFormReducer from 'reducers/builders/form';
import actionTypes, { actionTypeSections } from 'actiontypes/';
import { combineReducers } from 'redux';

const mainInitialState = {
  emailError: {
    isVisible: false,
    code: undefined,
  },
  isCreatingAccount: false,
  codeEmailVerificationToken: undefined,
  isPreparingToken: false,
};

const formInitialState = {
  email: {
    value: '',
    isValid: false,
    isValidating: false,
  },
  confirmEmail: {
    value: '',
    isValid: false,
    isValidating: false,
  },
  phoneCountryCode: {
    value: undefined,
  },
  phoneNumber: {
    value: '',
    isValid: true,
    isValidating: false,
  },
  termsAccepted: {
    value: false,
  },
};

function mainReducer(state = mainInitialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.SSO_SERVICE_PROVIDER_PERSONAL_INFO.CREATE_ACCOUNT_REQUEST:
      return {
        ...state,
        isCreatingAccount: true,
        emailError: {
          isVisible: false,
          code: undefined,
        },
      };
    case actionTypes.SSO_SERVICE_PROVIDER_PERSONAL_INFO.CREATE_ACCOUNT_EMAIL_VALIDATION_FAILURE:
      return {
        ...state,
        emailError: {
          isVisible: true,
          code: actionPayload.errorCode,
        },
      };
    case actionTypes.SSO_SERVICE_PROVIDER_PERSONAL_INFO.CREATE_ACCOUNT_SUCCESS:
    case actionTypes.SSO_SERVICE_PROVIDER_PERSONAL_INFO.CREATE_ACCOUNT_FAILURE:
      return { ...state, isCreatingAccount: false };
    case actionTypes.SSO_SERVICE_PROVIDER_PERSONAL_INFO.RESET_STATE:
      return { ...mainInitialState };
    case actionTypes.SSO_SERVICE_PROVIDER_PERSONAL_INFO.SET_CODE_EMAIL_VERIFICATION_TOKEN:
      return { ...state, codeEmailVerificationToken: actionPayload };
    case actionTypes.SSO_SERVICE_PROVIDER_PERSONAL_INFO.PREPARE_CODE_EMAIL_VERIFICATION_REQUEST:
      return { ...state, isPreparingToken: true };
    case actionTypes.SSO_SERVICE_PROVIDER_PERSONAL_INFO.PREPARE_CODE_EMAIL_VERIFICATION_SUCCESS:
    case actionTypes.SSO_SERVICE_PROVIDER_PERSONAL_INFO.PREPARE_CODE_EMAIL_VERIFICATION_FAILURE:
      return { ...state, isPreparingToken: false };
    default:
      return state;
  }
}

const personalInfoReducer = combineReducers({
  main: mainReducer,
  form: buildFormReducer(formInitialState, actionTypeSections.SSO_SERVICE_PROVIDER_PERSONAL_INFO),
});

export { personalInfoReducer as default };
