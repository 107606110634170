const requestActionTypes = {
  RESET_STATE: 'RESET_STATE',
  UPDATE_DATA_REQUEST: 'UPDATE_DATA_REQUEST',
  UPDATE_DATA_SUCCESS: 'UPDATE_DATA_SUCCESS',
  UPDATE_DATA_FAILURE: 'UPDATE_DATA_FAILURE',
  VERIFICATION_SUCCESS: 'VERIFICATION_SUCCESS',
  VERIFICATION_FAILURE: 'VERIFICATION_FAILURE',
};

export { requestActionTypes as default };
