import datasource from './datasource';
import actionTypes from '../../actiontypes';
import helpers from '../../utils/helpers';
import BaseActionCreator from '../BaseActionCreator';

class SSOLoginActionCreator extends BaseActionCreator {
  requestSSOLoginFailure() {
    return {
      type: actionTypes.SSO_LOGIN_REQUEST.REQUEST_SSO_LOGIN_FAILURE,
    };
  }

  async ssoLogin(email) {
    try {
      const { url } = await datasource.ssoLogin(email);

      helpers.redirectTo(url);
    } catch (error) {
      this.dispatch(this.requestSSOLoginFailure());
    }
  }
}

export default new SSOLoginActionCreator();
