import { LinkButton } from '../elements/buttons/Button';
import UtilityHelper from 'utils/helpers';
import { noop } from 'utils/constants';
import { colorizeByWord } from 'ohcm-media-colorizer';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './style.scss';

function Card({
  className,
  avatarText,
  active,
  showAvatar,
  mainText,
  subText,
  actionText,
  onActionClick,
  onWholeCardClick,
  associateId,
  clientId,
  clientTypeText,
  displayclientTypeText,
}) {
  const containerClassName = cx('idm-card', { active }, className, onWholeCardClick && 'clickable');
  const cardPropsForClickEvent = {};
  const backgroundColor = colorizeByWord(mainText);

  if (onWholeCardClick) {
    Object.assign(
      cardPropsForClickEvent,
      UtilityHelper.getAccessibilityPropsForClickableElement(onWholeCardClick)
    );
  }

  return (
    <div className={containerClassName} {...cardPropsForClickEvent}>
      <div className="left">
        {showAvatar && (
          <div className="icon initials avatar rectangular" style={{ backgroundColor }}>
            {avatarText || mainText[0]}
          </div>
        )}
        <div className="text">
          <div className="main">{mainText}</div>
          <div className="sub">{subText}</div>
        </div>
      </div>
      {displayclientTypeText && <div className="clienttypepill"> {clientTypeText} </div>}
      <div className="right">
        <LinkButton className="action" onClick={onActionClick}>
          <span
            data-meta-associate-id={associateId}
            data-meta-client-id={clientId}
            data-meta-active={active ? 'active' : 'inactive'}
          >
            {actionText}
          </span>
        </LinkButton>
      </div>
    </div>
  );
}

Card.propTypes = {
  associateId: PropTypes.string,
  clientId: PropTypes.string,
  avatarText: PropTypes.node,
  mainText: PropTypes.node,
  clientTypeText: PropTypes.node,
  displayclientTypeText: PropTypes.bool,
  subText: PropTypes.string,
  actionText: PropTypes.node,
  onActionClick: PropTypes.func,
  onWholeCardClick: PropTypes.func,
  showAvatar: PropTypes.bool,
  active: PropTypes.bool,
  className: PropTypes.string,
};

Card.defaultProps = {
  associateId: '',
  clientId: '',
  avatarText: '',
  mainText: '',
  clientTypeText: '',
  displayclientTypeText: false,
  subText: '',
  actionText: '',
  onActionClick: noop,
  onWholeCardClick: undefined,
  showAvatar: true,
  active: false,
  className: '',
};

export default Card;
