import TRANSLATION from './translation';
import LOGIN from './login';
import FORGOT_PASSWORD_REQUEST from './forgotPassword/request';
import FORGOT_USERNAME_REQUEST from './forgotUsername/request';
import FORGOT_PASSWORD_RESET from './forgotPassword/reset';
import SELF_REGISTRATION from './selfRegistration/request';
import SSO_LOGIN_REQUEST from './ssoLogin/request';
import ACCOUNT_REGISTRATION from './accountRegistration';
import CREATE_ACCOUNT_PERSONAL_INFO from './createAccount/personalInfo';
import CREATE_ACCOUNT_ACCOUNT_INFO from './createAccount/accountInfo';
import CREATE_CREDENTIAL_ACCOUNT_INFO from './createCredential/accountInfo';
import VERIFY_EMAIL from './verifyEmail';
import ACCOUNT_SETTINGS_LOCALIZATION from './accountSettings/localization';
import ACCOUNT_SETTINGS_CHANGE_PASSWORD from './accountSettings/changePassword';
import ACCOUNT_SETTINGS_CHANGE_USERNAME from './accountSettings/changeUsername';
import ACCOUNT_SETTINGS_DEVICES from './accountSettings/devices';
import ACCOUNT_SETTINGS_SWITCH_CLIENTS from './accountSettings/switchClients';
import ACCOUNT_SETTINGS_SWITCH_CLIENTS_REDIRECT from './accountSettings/switchClientsRedirectPage';
import START_IMPERSONATION_REQUEST from './startImpersonation';
import MESSAGES from './messages';
import ME from './me';
import FORM from './form';
import LOAD_DATA from './loadData';
import SAML from './saml';
import SESSION_EXPIRE_MODAL from './sessionExpireModal';
import USER_CONFIRMATION from './userConfirmation';
import ACCOUNT_LINKING from './accountLinking';
import MODAL from './modal';
import SSO_ME from './ssoMe';
import SSO_SERVICE_PROVIDER_PERSONAL_INFO from './ssoServiceProvider/personalInfo';
import EMAIL_VERIFY_NOTIFICATION from './emailVerifyNotification';
import LEGAL_AGREEMENTS from './legalAgreements';
import MFA from './mfa';
import APPLICATION from './application';
import LANDING_PRODUCTS from './landingProducts';
import USER_BASIC_CREDENTIALS from './userBasicCredentials';
import REVIEW_CONTACT_INFO from './reviewContactInfo';

const allActionTypesPerSection = {
  TRANSLATION,
  LOGIN,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_USERNAME_REQUEST,
  FORGOT_PASSWORD_RESET,
  SELF_REGISTRATION,
  SSO_LOGIN_REQUEST,
  ACCOUNT_REGISTRATION,
  CREATE_ACCOUNT_PERSONAL_INFO,
  CREATE_ACCOUNT_ACCOUNT_INFO,
  CREATE_CREDENTIAL_ACCOUNT_INFO,
  VERIFY_EMAIL,
  ACCOUNT_SETTINGS_CHANGE_PASSWORD,
  ACCOUNT_SETTINGS_CHANGE_USERNAME,
  ACCOUNT_SETTINGS_DEVICES,
  ACCOUNT_SETTINGS_LOCALIZATION,
  ACCOUNT_SETTINGS_SWITCH_CLIENTS,
  ACCOUNT_SETTINGS_SWITCH_CLIENTS_REDIRECT,
  START_IMPERSONATION_REQUEST,
  MESSAGES,
  ME,
  FORM,
  LOAD_DATA,
  SAML,
  SESSION_EXPIRE_MODAL,
  USER_CONFIRMATION,
  ACCOUNT_LINKING,
  MODAL,
  SSO_ME,
  SSO_SERVICE_PROVIDER_PERSONAL_INFO,
  EMAIL_VERIFY_NOTIFICATION,
  LEGAL_AGREEMENTS,
  MFA,
  LANDING_PRODUCTS,
  APPLICATION,
  USER_BASIC_CREDENTIALS,
  REVIEW_CONTACT_INFO,
};

namespaceActionTypesPerSection(allActionTypesPerSection);

function namespaceActionTypesPerSection(actionTypesPerSection) {
  for (const section in actionTypesPerSection) {
    if (Object.hasOwnProperty.call(actionTypesPerSection, section)) {
      const actionTypes = actionTypesPerSection[section];

      for (const type in actionTypes) {
        if (Object.hasOwnProperty.call(actionTypes, type)) {
          actionTypes[`_${type}`] = `${actionTypes[type]}`;
          actionTypes[type] = `${section}.${actionTypes[type]}`;
        }
      }
    }
  }
}

const actionTypeSections = Object.keys(allActionTypesPerSection).reduce(
  (acc, curr) => ({ ...acc, [curr]: curr }),
  {}
);

export { allActionTypesPerSection as default, actionTypeSections };
