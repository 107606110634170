import buildLoadDataActionCreator from './builders/loadData';
import applicationActionCreator from './application';
import datasource from './accountSettings/contactInfo/datasource';
import actionTypes, { actionTypeSections } from '../actiontypes';
import http from '../utils/http';
import phoneNumberUtils from '../utils/phoneNumber';
import getConstants, { staticConstants } from '../utils/constants';
import domainUtilities from '../utils/domainUtilities';
import helpers from '../utils/helpers';
import WindowUtils from '../utils/helpers/window';

const { requestResult, authenticationMethod } = getConstants();

const checkAuthenticationRequest = () => ({
  type: actionTypes.ME.CHECK_AUTH_REQUEST,
});

const checkAuthenticationSuccess = ({ authenticationMode, levelOfAssurance }) => ({
  type: actionTypes.ME.CHECK_AUTH_SUCCESS,
  payload: {
    isAuthenticatedByNexo: authenticationMode === authenticationMethod.NEXO,
    levelOfAssurance,
  },
});

const checkAuthenticationFailure = () => ({
  type: actionTypes.ME.CHECK_AUTH_FAILURE,
});

const clientRebrandFeatureRequest = () => ({
  type: actionTypes.ME.CHECK_CLIENT_REBRAND_REQUEST,
});

const clientRebrandFeatureSuccess = (clientRebrandEnabledStatus) => ({
  type: actionTypes.ME.CHECK_CLIENT_REBRAND_SUCCESS,
  payload: { clientRebrandEnabledStatus },
});

const clientRebrandFeatureFailure = () => ({
  type: actionTypes.ME.CHECK_CLIENT_REBRAND_FAILURE,
});

const meActionCreator = {
  ...buildLoadDataActionCreator(actionTypeSections.ME),

  checkAuthenticationRequest,
  checkAuthenticationSuccess,
  checkAuthenticationFailure,

  resetMainState() {
    return {
      type: actionTypes.ME.RESET_STATE,
    };
  },

  resetState() {
    return (dispatch) => {
      dispatch(this.resetMainState());

      dispatch(this.resetLoadDataState());
    };
  },

  setData(payload) {
    return {
      type: actionTypes.ME.SET_DATA,
      payload,
    };
  },

  loadData() {
    return async (dispatch) => {
      try {
        dispatch(this.loadDataRequest());

        const { data: myPersonalInfoResData } = await http.getMyPersonalInfo();
        const { data: myClientInfoResData } = await http.getMyClientInfo();

        const payload = {};

        if (myPersonalInfoResData.personId) {
          payload.personId = myPersonalInfoResData.personId;
        }

        if (myPersonalInfoResData.firstName) {
          payload.firstName = myPersonalInfoResData.firstName;
        }

        if (myPersonalInfoResData.lastName) {
          payload.lastName = myPersonalInfoResData.lastName;
        }

        const { address: email, isVerified: isEmailVerified } =
          (myPersonalInfoResData.email && myPersonalInfoResData.email[0]) || {};

        if (email) {
          payload.email = email;
          payload.isEmailVerified = isEmailVerified;
        }

        const phoneNumber =
          myPersonalInfoResData.phoneNumber &&
          myPersonalInfoResData.phoneNumber[0] &&
          myPersonalInfoResData.phoneNumber[0].number;

        const parsedPhoneNumber = phoneNumberUtils.getCountryCodeAndPhoneNumber(phoneNumber);

        if (parsedPhoneNumber.countryCode && parsedPhoneNumber.number) {
          payload.phone = {
            number: parsedPhoneNumber.number,
            countryCode: parsedPhoneNumber.countryCode,
          };
        }

        if (myClientInfoResData.clientName) {
          payload.clientName = myClientInfoResData.clientName;
        }

        if (myClientInfoResData.clientId) {
          payload.clientId = myClientInfoResData.clientId;
        }

        if (myClientInfoResData.isClientRebrandEnabled) {
          payload.isClientRebrandEnabled = myClientInfoResData.isClientRebrandEnabled;
        }

        dispatch(this.setData(payload));

        dispatch(this.loadDataSuccess());
      } catch (e) {
        applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);

        dispatch(this.loadDataFailure());

        throw e;
      }
    };
  },

  assertAuthenticated() {
    return async (dispatch) => {
      try {
        dispatch(checkAuthenticationRequest());

        const {
          data: { authentication_method: authenticationMode, levelOfAssurance },
        } = await http.checkAuthentication();

        dispatch(checkAuthenticationSuccess({ authenticationMode, levelOfAssurance }));
      } catch (e) {
        dispatch(checkAuthenticationFailure());

        throw e;
      }
    };
  },

  getRebrandFeatureFlagStatus() {
    return async (dispatch) => {
      try {
        dispatch(clientRebrandFeatureRequest());

        const featureEnabledStatus = await datasource.getFeatureEnabledStatus(
          staticConstants.featureFlags.ENABLE_CLIENT_REBRAND
        );

        dispatch(clientRebrandFeatureSuccess(featureEnabledStatus));
      } catch (e) {
        dispatch(clientRebrandFeatureFailure());

        throw e;
      }
    };
  },

  checkAuthentication(redirectLocation = '/auth/login', state) {
    return async (dispatch) => {
      try {
        await this.assertAuthenticated()(dispatch);
      } catch (e) {
        if (domainUtilities.isFlowbriteDomain(helpers.getWindowLocation())) {
          const url = domainUtilities.getCorrespondingAdpDomainFromFlowbrite(
            helpers.getWindowLocation()
          );

          applicationActionCreator.changeUrl(url);
          return;
        }
        applicationActionCreator.changeUrl(redirectLocation, state);

        throw e;
      }
    };
  },

  initialize(redirectLocation, redirectImmediately) {
    return async (dispatch) => {
      try {
        if (!redirectImmediately) {
          dispatch(checkAuthenticationRequest());
          dispatch(this.loadDataRequest());

          await this.checkAuthentication(redirectLocation)(dispatch);
          await dispatch(this.loadData());

          return;
        }

        applicationActionCreator.changeUrl(redirectLocation);
      } catch (e) {
        /*
         * No need to handle here. Let each async catch block do its own job.
         * This catch block is for blocking the subsequent request(s) to fire
         * if any request fails beforehand.
         */
      }
    };
  },

  logoutRequest() {
    return {
      type: actionTypes.ME.LOGOUT_REQUEST,
    };
  },

  logoutSuccess() {
    return {
      type: actionTypes.ME.LOGOUT_SUCCESS,
    };
  },

  logoutFailure() {
    return {
      type: actionTypes.ME.LOGOUT_FAILURE,
    };
  },

  logoutAllSessionsRequest() {
    return {
      type: actionTypes.ME.LOGOUT_ALL_SESSIONS_REQUEST,
    };
  },

  logoutAllSessionsSuccess() {
    return {
      type: actionTypes.ME.LOGOUT_ALL_SESSIONS_SUCCESS,
    };
  },

  logoutAllSessionsFailure() {
    return {
      type: actionTypes.ME.LOGOUT_ALL_SESSIONS_FAILURE,
    };
  },

  handleLogout(
    dispatch,
    userAuthenticationMethod,
    historyState,
    redirectLocation,
    shouldLogoutAllSessions
  ) {
    if (userAuthenticationMethod === authenticationMethod.NEXO) {
      return this.handleNexoLogout(dispatch, redirectLocation, shouldLogoutAllSessions);
    }
    if (domainUtilities.isFlowbriteDomain(helpers.getWindowLocation())) {
      return this.handleFlowbriteLogout(dispatch, shouldLogoutAllSessions);
    }
    return this.handleDefaultLogout(
      dispatch,
      redirectLocation,
      historyState,
      shouldLogoutAllSessions
    );
  },

  dispatchLogout(shouldLogoutAllSessions) {
    return shouldLogoutAllSessions
      ? meActionCreator.logoutAllSessionsSuccess()
      : meActionCreator.logoutSuccess();
  },

  handleNexoLogout(dispatch, redirectLocation, shouldLogoutAllSessions) {
    const nexoBaseUrl = WindowUtils.getNexoBaseUrlRebrand();

    const nexoLogoutUrl = window.__nexo_logout_url__;

    if (!nexoBaseUrl || !nexoLogoutUrl) {
      applicationActionCreator.changeState(requestResult.FAILURE_UNDETERMINED_NEXO_SESSION);
      dispatch(this.logoutFailure());
      return;
    }
    if (redirectLocation === '/auth/switch-clients-message') {
      applicationActionCreator.changeUrl(`${nexoBaseUrl}${redirectLocation}`);
    } else {
      applicationActionCreator.changeUrl(`${nexoLogoutUrl}${nexoBaseUrl}${redirectLocation}`);
    }

    dispatch(this.dispatchLogout(shouldLogoutAllSessions));
  },

  handleFlowbriteLogout(dispatch, shouldLogoutAllSessions) {
    const url = domainUtilities.getCorrespondingAdpDomainFromFlowbrite(helpers.getWindowLocation());

    dispatch(this.dispatchLogout(shouldLogoutAllSessions));
    applicationActionCreator.changeUrl(url);
  },

  handleDefaultLogout(dispatch, redirectLocation, historyState, shouldLogoutAllSessions) {
    applicationActionCreator.changeUrl(redirectLocation, historyState);
    dispatch(this.dispatchLogout(shouldLogoutAllSessions));
  },

  logout(redirectLocation = '/auth/login', historyState) {
    return async (dispatch) => {
      try {
        dispatch(this.logoutRequest());
        const [userAuthenticationMethod] = await Promise.all([
          http.getAuthenticationMethod(),
          http.logout(),
        ]);

        await applicationActionCreator.clearObligations();
        this.handleLogout(dispatch, userAuthenticationMethod, historyState, redirectLocation);
      } catch (e) {
        applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);
        dispatch(this.logoutFailure());
      }
    };
  },

  logoutAllSessions(redirectLocation = '/auth/login', historyState) {
    return async (dispatch) => {
      try {
        dispatch(this.logoutAllSessionsRequest());
        const [userAuthenticationMethod] = await Promise.all([
          http.getAuthenticationMethod(),
          http.logoutAllSessions(),
        ]);

        applicationActionCreator.clearObligations();
        this.handleLogout(dispatch, userAuthenticationMethod, historyState, redirectLocation, true);
      } catch (e) {
        applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);
        dispatch(this.logoutAllSessionsFailure());
      }
    };
  },
};

export default meActionCreator;
