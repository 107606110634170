import UnwrappedSSOLoginRequest from './UnwrappedSSOLoginRequest';
import applicationActionCreator from '../../../actioncreators/application';
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
    dictionary: state.translation.main.dictionary,
    showError: state.ssoLoginRequest.main.showError,
  };
}

function mapDispatchToProps() {
  return {
    onChangeUrl(url) {
      applicationActionCreator.changeUrl(url);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UnwrappedSSOLoginRequest);
