import { ErrorIcon, CheckIcon, InfoIcon } from '../elements/icons';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './style.scss';

const ICON_DIMENSION = 14;
const TOTAL_ANIMATIONS = 2;

class ToastMessage extends Component {
  static propTypes = {
    message: PropTypes.node.isRequired,
    removeSelf: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['error', 'info', 'success']),
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
  };

  static defaultProps = {
    type: undefined,
    className: '',
  };

  static getIcon(type) {
    const iconProps = { width: ICON_DIMENSION, height: ICON_DIMENSION };

    if (type === 'success') {
      return <CheckIcon {...iconProps} />;
    }
    if (type === 'info') {
      return <InfoIcon {...iconProps} />;
    }

    return <ErrorIcon {...iconProps} />;
  }

  constructor(props) {
    super(props);

    this.numCompletedAnimations = 0;
  }

  onAnimationEnd = () => {
    const { id, removeSelf } = this.props;

    this.numCompletedAnimations += 1;

    if (this.numCompletedAnimations >= TOTAL_ANIMATIONS) {
      removeSelf(id);
    }
  };

  render() {
    const { className, type, message } = this.props;
    const classes = cx('toast-message', type, className);

    return (
      <div
        className={classes}
        onAnimationEnd={this.onAnimationEnd}
        data-meta-id={`toast-message-${type}`}
      >
        <div className="toast-icon-holder">{type && ToastMessage.getIcon(type)}</div>
        <div className="toast-message-holder">{message}</div>
      </div>
    );
  }
}

export { ToastMessage as default };
