const accountRegistrationActionTypes = {
  RESET_STATE: 'RESET_STATE',
  SET_COMPANY_NAME: 'SET_COMPANY_NAME',
  SET_ASSOCIATE_FIRST_NAME: 'SET_ASSOCIATE_FIRST_NAME',
  SET_ASSOCIATE_IDENTIFIER: 'SET_ASSOCIATE_IDENTIFIER',
  SET_CLIENT_IDENTIFIER: 'SET_CLIENT_IDENTIFIER',
  SET_ASSOCIATE_DOB: 'SET_ASSOCIATE_DOB',
  CHECK_ASSOCIATE_DOB_REQUEST: 'CHECK_ASSOCIATE_DOB_REQUEST',
  CHECK_ASSOCIATE_DOB_SUCCESS: 'CHECK_ASSOCIATE_DOB_SUCCESS',
  CHECK_ASSOCIATE_DOB_FAILURE: 'CHECK_ASSOCIATE_DOB_FAILURE',
  TOGGLE_SHOW_DOB_OBLIGATION: 'TOGGLE_SHOW_DOB_OBLIGATION',
  SKIP_TO_CREATE_ACCOUNT: 'SKIP_TO_CREATE_ACCOUNT',
  SET_DOB_LOCALE: 'SET_DOB_LOCALE',
  RESET_DOB_CHECK: 'RESET_DOB_CHECK',
  FAILURE_REDIRECT_TO_AIM: 'FAILURE_REDIRECT_TO_AIM',
};

export { accountRegistrationActionTypes as default };
