class DeviceUtilities {
  static SCREEN_SIZES = ['small', 'medium', 'large'];

  static isAndroid() {
    return /(android)/i.test(DeviceUtilities.getUserAgent());
  }

  static isIOS() {
    return /(iPhone|iPad|iPod)/i.test(DeviceUtilities.getUserAgent());
  }

  static isMobile() {
    return DeviceUtilities.isAndroid() || DeviceUtilities.isIOS();
  }

  static getUserAgent() {
    return window.navigator.userAgent;
  }

  static getWindowSize() {
    return [window.innerWidth, window.innerHeight];
  }

  static isScreenAtLeastSize(size) {
    const { sizeCategory } = DeviceUtilities.getScreenInfo();

    const sizeIndex = DeviceUtilities.SCREEN_SIZES.indexOf(size.toLowerCase());

    return sizeCategory >= sizeIndex;
  }

  static getScreenInfo() {
    const [windowWidth, windowHeight] = DeviceUtilities.getWindowSize();

    const BREAKPOINTS = [560, 1024];
    let sizeCategory = 0;

    while (sizeCategory < BREAKPOINTS.length) {
      if (windowWidth <= BREAKPOINTS[sizeCategory]) {
        break;
      }
      sizeCategory += 1;
    }
    return {
      sizeCategory,
      windowWidth,
      windowHeight,
      screenSize: DeviceUtilities.SCREEN_SIZES[sizeCategory],
    };
  }

  static getDeviceInfo() {
    const isAndroid = DeviceUtilities.isAndroid();
    const isIOS = DeviceUtilities.isIOS();

    return {
      isMobile: DeviceUtilities.isMobile(),
      isAndroid,
      isIOS,
      screenInfo: DeviceUtilities.getScreenInfo(),
      windowSize: DeviceUtilities.getWindowSize(),
      userAgent: DeviceUtilities.getUserAgent(),
    };
  }
}

export default DeviceUtilities;
