import actionCreator from '../../../actioncreators/accountRegistration';
import { supportedErrorCodes } from '../../../utils/http/HttpClient';
import namespaceTranslatedText from '../../TranslatedText';
import { ButtonContainer, PrimaryButton, InlineMessage } from '../../../components';
import { Calendar } from '@idm/ui-components';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../style.scss';

const TranslatedText = namespaceTranslatedText('ACCOUNT_REGISTRATION');

class KnowledgeCheck extends Component {
  static propTypes = {
    isLoadingData: PropTypes.bool.isRequired,
    dispatchResetState: PropTypes.func.isRequired,
    dispatchSetDob: PropTypes.func.isRequired,
    dispatchCheckDob: PropTypes.func.isRequired,
    dispatchResetDobCheckState: PropTypes.func.isRequired,
    dispatchToggleShowDobObligation: PropTypes.func.isRequired,
    dob: PropTypes.string.isRequired,
    isValidDob: PropTypes.bool.isRequired,
    associateId: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    errorCode: PropTypes.any,
  };

  static defaultProps = {
    errorCode: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasSetDob: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { isValidDob, dispatchToggleShowDobObligation } = this.props;

    if (isValidDob !== prevProps.isValidDob) {
      dispatchToggleShowDobObligation();
    }
  }

  componentWillUnmount() {
    const { dispatchResetState, dispatchResetDobCheckState } = this.props;

    dispatchResetState();
    dispatchResetDobCheckState();
  }

  getErrorMessage = () => {
    const { errorCode } = this.props;

    switch (errorCode) {
      case supportedErrorCodes.ERROR_VERIFYING_ASSOCIATE:
        return <TranslatedText textKey="ERROR_VERIFYING_ASSOCIATE" />;
      case supportedErrorCodes.TOO_MANY_ATTEMPTS:
        return <TranslatedText textKey="TOO_MANY_ATTEMPTS" />;
      default:
        return <TranslatedText textKey="SNAG_TRY_AGAIN" containHtml={true} />;
    }
  };

  handleDobCheck = () => {
    const { clientId, associateId, dob, dispatchCheckDob } = this.props;

    dispatchCheckDob(clientId, associateId, dob);
  };

  handleDateChange = (values) => {
    const { dispatchSetDob } = this.props;

    this.setState({ hasSetDob: true });
    dispatchSetDob(values);
  };

  render() {
    const { isLoadingData, errorCode, locale } = this.props;
    const { hasSetDob } = this.state;

    if (isLoadingData) {
      return null;
    }

    return (
      <section className="account-registration">
        <h2>
          <TranslatedText textKey="CHECK_DOB_TITLE" />
        </h2>
        <p>
          <TranslatedText textKey="CHECK_DOB_MESSAGE" />
        </p>

        <div className="datepicker-wrapper">
          <Calendar
            locale={locale}
            placeholder="Select Date"
            onChange={this.handleDateChange}
            data-meta-id="calendar"
            disabled={true}
            autocomplete="off"
          />
        </div>
        {errorCode && <InlineMessage type="error">{this.getErrorMessage()}</InlineMessage>}
        <ButtonContainer>
          <a to="/auth/login" className="login-link" data-meta-id="cancel-check-dob-cancel">
            <TranslatedText textKey="CHECK_DOB_CANCEL" />
          </a>
          <PrimaryButton
            onClick={this.handleDobCheck}
            size="large"
            data-meta-id="check-dob-button"
            disabled={!hasSetDob}
          >
            <TranslatedText textKey="CHECK_DOB_BUTTON_SUBMIT" />
          </PrimaryButton>
        </ButtonContainer>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    dob: state.knowledgeCheck.dob,
    isValidDob: state.knowledgeCheck.isValidDob,
    isValidatingDob: state.knowledgeCheck.isValidatingDob,
    associateId: state.accountRegistration.main.associateId,
    clientId: state.accountRegistration.main.clientId,
    isLoadingData: state.accountRegistration.loadData.isLoadingData,
    errorCode: state.knowledgeCheck.errorCode,
    locale: state.knowledgeCheck.locale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchResetState() {
      dispatch(actionCreator.resetState());
    },

    dispatchResetDobCheckState() {
      dispatch(actionCreator.resetDobCheck());
    },

    dispatchSetDob(dob) {
      dispatch(actionCreator.setAssociateDob(dob));
    },

    dispatchCheckDob(clientId, associateId, dob) {
      dispatch(actionCreator.checkAssociateDob(clientId, associateId, dob));
    },

    dispatchToggleShowDobObligation() {
      dispatch(actionCreator.toggleShowDobObligation());
    },
  };
}

export { KnowledgeCheck as UnwrappedKnowledgeCheck };
export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeCheck);
