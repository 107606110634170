import buildLoadDataReducer from 'reducers/builders/loadData';
import actionTypes, { actionTypeSections } from 'actiontypes/';
import { combineReducers } from 'redux';

const mainInitialState = {
  firstName: '',
  lastName: '',
  email: '',
  clientName: '',
  clientId: '',
};

const loadDataInitialState = {
  isLoadingData: false,
};

function mainReducer(state = mainInitialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.SSO_ME.RESET_STATE:
      return { ...mainInitialState };
    case actionTypes.SSO_ME.SET_DATA:
      return {
        ...state,
        ...actionPayload,
      };
    default:
      return state;
  }
}

const ssoMeReducer = combineReducers({
  main: mainReducer,
  loadData: buildLoadDataReducer(loadDataInitialState, actionTypeSections.SSO_ME),
});

export { ssoMeReducer as default };
