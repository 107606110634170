import buildFormReducer from 'reducers/builders/form';
import buildLoadDataReducer from 'reducers/builders/loadData';
import actionTypes, { actionTypeSections } from 'actiontypes/';
import { combineReducers } from 'redux';

const mainInitialState = {
  isResetting: false,
  error: {
    isVisible: false,
    code: undefined,
  },
};

const formInitialState = {
  newPassword: {
    value: '',
    isValid: false,
    isValidating: false,
  },
};

const loadDataInitialState = {
  isLoadingData: false,
};

function mainReducer(state = mainInitialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.FORGOT_PASSWORD_RESET.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isResetting: true,
        error: {
          isVisible: false,
          code: undefined,
        },
      };
    case actionTypes.FORGOT_PASSWORD_RESET.RESET_PASSWORD_FAILURE:
    case actionTypes.FORGOT_PASSWORD_RESET.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetting: false,
      };
    case actionTypes.FORGOT_PASSWORD_RESET.RESET_PASSWORD_INVALID_PASSWORD_FAILURE:
      return {
        ...state,
        error: {
          isVisible: true,
          code: actionPayload.errorCode,
        },
      };

    case actionTypes.FORGOT_PASSWORD_RESET.RESET_STATE:
      return { ...mainInitialState };
    default:
      return state;
  }
}

const resetReducer = combineReducers({
  main: mainReducer,
  form: buildFormReducer(formInitialState, actionTypeSections.FORGOT_PASSWORD_RESET),
  loadData: buildLoadDataReducer(loadDataInitialState, actionTypeSections.FORGOT_PASSWORD_RESET),
});

export { resetReducer as default };
