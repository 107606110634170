import ProductSelection from './ProductSelection';
import actionCreator from 'actioncreators/products';
import applicationActionCreator from 'actioncreators/application';
import React from 'react';

import { connect } from 'react-redux';

import './index.scss';

function mapStateToProps(state) {
  const { isLoading, landingProducts } = state.landingProducts;

  return {
    isLoading,
    products: landingProducts,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadProducts: () => actionCreator.getLandingProducts(dispatch),
    onProductSelected: (product) => applicationActionCreator.changeUrl(product.targetUrl),
  };
}

/*Why is this not a component, we do this everywhere in authenticatedLayout*/
const ChooseProductsPage = (props) => (
  <section className="ui-choose-products-page">
    <div className="content">
      <ProductSelection {...props} />
    </div>
  </section>
);

const ReduxCreateAccount = connect(mapStateToProps, mapDispatchToProps)(ChooseProductsPage);

export default ReduxCreateAccount;
