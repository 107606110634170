import CredentialForm from '../../CreateBasicCredentialsPage/CreateCredentialForm';
import actionCreator from '../../../actioncreators/login/index';
import utils from '../../../utils/helpers';
import urls from '../../../utils/urls';
import React from 'react';
import PropTypes from 'prop-types';

const LoginForm = (props) => {
  const onSubmit = async (data) => {
    const { redirectUrl, onAfterLogin } = props;
    const { username, password } = data;

    await actionCreator.login(username, password, redirectUrl);

    utils.invoke(onAfterLogin);
  };

  const onForgotPassword = () => {
    actionCreator.changeUrl(urls.FORGOT_PASSWORD);

    actionCreator.clearObligations();
  };

  return (
    <CredentialForm
      {...props}
      onForgotPassword={onForgotPassword}
      isLogin={true}
      onSubmit={onSubmit}
    />
  );
};

LoginForm.propTypes = {
  redirectUrl: PropTypes.string,
  onAfterLogin: PropTypes.func,
  onForgotPassword: PropTypes.func,
};

LoginForm.defaultProps = {
  redirectUrl: null,
  onAfterLogin: null,
  onForgotPassword: null,
};

export default LoginForm;
