const meActionTypes = {
  RESET_STATE: 'RESET_STATE',
  SET_DATA: 'SET_DATA',
  CHECK_AUTH_REQUEST: 'CHECK_AUTH_REQUEST',
  CHECK_AUTH_SUCCESS: 'CHECK_AUTH_SUCCESS',
  CHECK_AUTH_FAILURE: 'CHECK_AUTH_FAILURE',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ALL_SESSIONS_REQUEST: 'LOGOUT_ALL_SESSIONS_REQUEST',
  LOGOUT_ALL_SESSIONS_FAILURE: 'LOGOUT_ALL_SESSIONS_FAILURE',
  LOGOUT_ALL_SESSIONS_SUCCESS: 'LOGOUT_ALL_SESSIONS_SUCCESS',
  CHECK_CLIENT_REBRAND_REQUEST: 'CHECK_CLIENT_REBRAND_REQUEST',
  CHECK_CLIENT_REBRAND_SUCCESS: 'CHECK_CLIENT_REBRAND_SUCCESS',
  CHECK_CLIENT_REBRAND_FAILURE: 'CHECK_CLIENT_REBRAND_FAILURE',
};

export { meActionTypes as default };
