import { staticConstants } from 'utils/constants';

const { tokenKinds } = staticConstants;

const getValidationRequestDataForKind = (tokenKind, data) => {
  switch (tokenKind) {
    case tokenKinds.REGISTRATION_EMAIL_VERIFICATION:
      return {
        code: data.code,
      };
    default:
      return {};
  }
};

const toValidateTokenRequest = (kind, token, validationData) => ({
  token,
  kind,
  data: getValidationRequestDataForKind(kind, validationData),
});

export default {
  toValidateTokenRequest,
};
