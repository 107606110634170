import ChangePasswordPage from '../ChangePasswordPage';
import namespaceTranslatedText from 'containers/TranslatedText/';
import { connect } from 'react-redux';
import React from 'react';

const TranslatedText = namespaceTranslatedText('OBLIGATION_CHANGE_PW');

function mapStateToProps() {
  return {
    title: <TranslatedText textKey="TITLE" />,
    subtitle: <TranslatedText textKey="SUBTITLE" />,
  };
}

export default connect(mapStateToProps)(ChangePasswordPage);
