const personalInfoActionTypes = {
  RESET_STATE: 'RESET_STATE',
  CREATE_ACCOUNT_REQUEST: 'CREATE_ACCOUNT_REQUEST',
  CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_FAILURE: 'CREATE_ACCOUNT_FAILURE',
  CREATE_ACCOUNT_EMAIL_VALIDATION_FAILURE: 'CREATE_ACCOUNT_EMAIL_VALIDATION_FAILURE',
  SET_CODE_EMAIL_VERIFICATION_TOKEN: 'SET_CODE_EMAIL_VERIFICATION_TOKEN',
  PREPARE_CODE_EMAIL_VERIFICATION_REQUEST: 'PREPARE_CODE_EMAIL_VERIFICATION_REQUEST',
  PREPARE_CODE_EMAIL_VERIFICATION_SUCCESS: 'PREPARE_CODE_EMAIL_VERIFICATION_SUCCESS',
  PREPARE_CODE_EMAIL_VERIFICATION_FAILURE: 'PREPARE_CODE_EMAIL_VERIFICATION_FAILURE',
};

export { personalInfoActionTypes as default };
