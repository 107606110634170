import Obligations from '../Obligations';
import utilHelper from '../../../utils/helpers';
import actionCreator from '../../../actioncreators/obligations';
import urls from '../../../utils/urls';
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

class ObligationsPage extends React.Component {
  static propTypes = {
    onCheckObligations: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { onCheckObligations } = this.props;

    onCheckObligations();
  }

  render() {
    const { onCheckObligations, ...otherProps } = this.props;

    return <Obligations {...otherProps} />;
  }
}

function mapStateToProps(state) {
  return {
    obligationData: state.application.obligationData,
  };
}

function mapDispatchToProps() {
  return {
    async onAfterObligation() {
      await actionCreator.clearObligations();
      const { redirectUrl = urls.LIFION_HOME } = utilHelper.getWindwQueryParams();

      actionCreator.changeUrl(redirectUrl, null);
    },

    async onCheckObligations() {
      try {
        await actionCreator.checkObligation();
      } catch (err) {
        //iignore
      }
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ObligationsPage);
