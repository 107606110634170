import TranslatedText from '../../components/elements/textDisplays/TranslatedText';
import utilHelper from '../../utils/helpers';
import { staticConstants } from '../../utils/constants';
import contactInfoActionCreator from '../../actioncreators/accountSettings/contactInfo';
import actionCreator from '../../actioncreators/reviewContactInfo';
import { getI18nContext } from '../TranslatedText';
import ContactInfo from '../AccountSettings/ContactInfo';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ButtonContainer,
  PrimaryButton,
  SecondaryButton,
  PageHeader,
  StandardParagraph,
} from '@idm/ui-components';
import React from 'react';

import './style.scss';

const I18nKeys = {
  title: 'OBLIGATION.REVIEW_CONTACT.TITLE',
  subtitle: 'OBLIGATION.REVIEW_CONTACT.SUBTITLE',
  editContactButton: 'OBLIGATION.REVIEW_CONTACT.EDIT_CONTACT_BUTTON',
  confirmButton: 'OBLIGATION.REVIEW_CONTACT.CONFIRM_BUTTON',
};

class ReviewContactInfoObligationPage extends React.Component {
  static propTypes = {
    i18nContext: PropTypes.shape({ getTextForKey: PropTypes.func.isRequired }).isRequired,
    onConfirm: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.contactRef = null;

    this.setContactRef = (element) => {
      this.contactRef = element?.getWrappedInstance()?.getWrappedInstance();
    };

    this.addContact = () => {
      this.contactRef.onAddContact();
    };
  }

  render() {
    const { onConfirm } = this.props;

    return (
      <div className="review-contact-info">
        <header>
          <PageHeader>
            <TranslatedText i18nKey={I18nKeys.title} />
          </PageHeader>
          <StandardParagraph>
            <TranslatedText i18nKey={I18nKeys.subtitle} />
          </StandardParagraph>
        </header>
        <main>
          <ContactInfo ref={this.setContactRef} hideActionButtons={true} />
        </main>
        <footer>
          <ButtonContainer>
            <SecondaryButton data-meta-id="add-contact" onClick={this.addContact}>
              <TranslatedText i18nKey={I18nKeys.editContactButton} />
            </SecondaryButton>
            <PrimaryButton data-meta-id="confirm" onClick={onConfirm}>
              <TranslatedText i18nKey={I18nKeys.confirmButton} />
            </PrimaryButton>
          </ButtonContainer>
        </footer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { accountSettingsContactInfo, translation } = state;
  const { dictionary } = translation.main;
  const i18nContext = getI18nContext('ACCOUNT_SETTINGS.CONTACT_INFO', dictionary);

  return {
    i18nContext,
    isAuthenticationMethodsLoading: accountSettingsContactInfo.isLoadingData,
    authenticationMethods: accountSettingsContactInfo.contactInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    async onDelete(authenticationMethod) {
      await dispatch(contactInfoActionCreator.deleteContactMethod(authenticationMethod));
      return dispatch(contactInfoActionCreator.loadContactInfoMethods());
    },

    async onConfirm() {
      const { redirectUrl } = utilHelper.getWindwQueryParams();

      await actionCreator.confirmContactInfo();
      await actionCreator.clearObligations();

      actionCreator.changeUrl(redirectUrl || staticConstants.DEFAULT_LOGIN_LANDING_PAGE);
    },
  };
}
export { mapDispatchToProps };
export default connect(mapStateToProps, mapDispatchToProps)(ReviewContactInfoObligationPage);
