import actionTypes from '../actiontypes';

class BaseActionCreator {
  static setDefaultDispatcher(dispatcher) {
    BaseActionCreator.dispatcher = dispatcher;
  }

  static getDispatcher() {
    return BaseActionCreator.dispatcher;
  }

  get dispatcher() {
    return BaseActionCreator.getDispatcher();
  }

  dispatch(type, payload) {
    if (typeof type === 'object' || typeof type === 'function') {
      return this.dispatcher.dispatch(type);
    }

    return this.dispatcher.dispatch({
      type,
      payload,
    });
  }

  changeUrl(url, state) {
    this.dispatch(actionTypes.APPLICATION.URL_CHANGE, { url, state });
  }

  changeState(redirectResult) {
    this.dispatch(actionTypes.APPLICATION.URL_STATE_CHANGE, { REDIRECT_RESULT: redirectResult });
  }

  handleObligations(obligationData) {
    this.dispatch({
      type: actionTypes.APPLICATION.HANDLE_OBLIGATIONS,
      payload: obligationData,
    });
  }

  clearObligations() {
    this.dispatch({
      type: actionTypes.APPLICATION.CLEAR_OBLIGATIONS,
    });
  }
}
export default BaseActionCreator;
