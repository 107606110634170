import actionTypes from '../../actiontypes/accountSettings/contactInfo';

const initialState = {
  isLoadingData: false,
  loadError: undefined,
  contactInfo: null,
  isRequireAdpEmailFlagEnabled: true,
  isOnlyAllowAdpEmailFlagEnabled: false,
};

function contactInfoReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.LOAD.SUCCESS:
      return {
        ...state,
        contactInfo: payload,
        isLoadingData: false,
        loadError: null,
      };
    case actionTypes.LOAD.FAILURE:
      return {
        ...state,
        contactInfo: null,
        isLoadingData: false,
        loadError: payload,
      };
    case actionTypes.LOAD.REQUEST:
      return {
        ...state,
        isLoadingData: true,
      };
    case actionTypes.GET_ADP_EMAIL_REQUIRED_FLAG_STATUS.SUCCESS:
      return {
        ...state,
        isRequireAdpEmailFlagEnabled: payload,
      };
    case actionTypes.GET_ADP_EMAIL_REQUIRED_FLAG_STATUS.FAILURE:
      return {
        ...state,
        isRequireAdpEmailFlagEnabled: true,
      };
    case actionTypes.GET_ONLY_ALLOW_ADP_EMAIL_FLAG_STATUS.SUCCESS:
      return {
        ...state,
        isOnlyAllowAdpEmailFlagEnabled: payload.featureEnabledStatus,
        clientId: payload.clientId,
      };
    case actionTypes.GET_ONLY_ALLOW_ADP_EMAIL_FLAG_STATUS.FAILURE:
      return {
        ...state,
        isOnlyAllowAdpEmailFlagEnabled: false,
        clientId: '',
      };
    default:
      return state;
  }
}

export default contactInfoReducer;
