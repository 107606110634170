import cookieUtils from 'utils/cookie/';
import getConstants from 'utils/constants';

const { gracePeriodInMS } = getConstants();

const getTimeLeft = () => {
  const now = Date.now();
  let sessionTtlCookie = cookieUtils.getCookie('LIFSESSTTL');

  if (!sessionTtlCookie) {
    sessionTtlCookie = cookieUtils.getCookie('sessionTtl');
  }
  const expirationTimestamp = (sessionTtlCookie && Date.parse(sessionTtlCookie)) || now;
  const timeLeft = expirationTimestamp - now;

  return timeLeft - gracePeriodInMS;
};

export { getTimeLeft as default };
