import buildNamedWrapperReducer from 'reducers/builders/namedWrapper';
import actionTypes from 'actiontypes/';

const { FORM } = actionTypes;

export default (initialState, name) => {
  const formReducer = (state = initialState, action) => {
    const actionType = action.type;
    const actionPayload = action.payload;

    switch (actionType) {
      case FORM.RESET_STATE:
        return { ...initialState };
      case FORM.SET_FIELD:
        return {
          ...state,
          [actionPayload.field]: {
            ...state[actionPayload.field],
            value: actionPayload.value,
            dirty: true,
          },
        };
      case FORM.SET_FIELD_CLEAN:
        return {
          ...state,
          [actionPayload.field]: {
            ...state[actionPayload.field],
            value: actionPayload.value,
            dirty: false,
          },
        };
      case FORM.SET_VALIDATION_RESULT:
        return {
          ...state,
          [actionPayload.field]: {
            ...state[actionPayload.field],
            isValid: actionPayload.validationResult,
            isValidating: false,
          },
        };
      case FORM.SET_IS_VALIDATING:
        return {
          ...state,
          ...Object.keys(state)
            .map((field) => ({ [field]: { ...state[field], isValidating: true } }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
        };
      case FORM.MARK_ALL_CLEAN:
        return {
          ...state,
          ...Object.keys(state)
            .map((field) => ({ [field]: { ...state[field], dirty: false } }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
        };
      default:
        return state;
    }
  };

  return buildNamedWrapperReducer(formReducer, name);
};
