import React from 'react';
import PropTypes from 'prop-types';
import combineClasses from 'classnames';

const createContainer = (baseClass) => {
  const Container = (props) => {
    const { className, children, ...otherProps } = props;
    const cssClass = combineClasses(baseClass, className);

    return (
      <div className={cssClass} {...otherProps}>
        {children}
      </div>
    );
  };

  Container.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
  };

  Container.defaultProps = {
    children: null,
    className: '',
  };

  return Container;
};

export default createContainer;
