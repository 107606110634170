import Translator from '../../../../utils/translation/Translator';
import TranslationContext from '../../../../context/TranslationsContext';

import React from 'react';
import PropTypes from 'prop-types';

class TranslatedText extends React.Component {
  static contextType = TranslationContext;

  static propTypes = {
    namespace: PropTypes.string,
    i18nKey: PropTypes.string.isRequired,
    params: PropTypes.arrayOf(PropTypes.node),
    dangerouslySetInnerHtml: PropTypes.bool,
  };

  static defaultProps = {
    namespace: null,
    params: [],
    dangerouslySetInnerHtml: false,
  };

  render() {
    const { namespace, i18nKey, params, dangerouslySetInnerHtml, ...otherProps } = this.props;
    const { dictionary } = this.context;

    const translator = new Translator(namespace, dictionary);
    const text = translator.getReactRenderedString(i18nKey, null, params);
    const fullKey = translator.getFullKey(i18nKey);

    if (dangerouslySetInnerHtml) {
      return (
        <span data-text-key={fullKey} dangerouslySetInnerHTML={{ __html: text }} {...otherProps} />
      );
    }
    return (
      <span data-text-key={fullKey} {...otherProps}>
        {text}
        {/* This eensures tests fail for missed keys. for undefiined text will return striing 'undefined' instead of empty*/}
      </span>
    );
  }
}
const withNamespace = (namespace) => (props) => <TranslatedText namespace={namespace} {...props} />;

export { withNamespace };

export default TranslatedText;
