const localizationActionTypes = {
  RESET_STATE: 'RESET_STATE',
  LOAD_DATA_REQUEST: 'LOAD_DATA_REQUEST',
  LOAD_DATA_SUCCESS: 'LOAD_DATA_SUCCESS',
  SET_LANGUAGES: 'SET_LANGUAGES',
  SET_REGIONS: 'SET_REGIONS',
  SET_TIMEZONES: 'SET_TIMEZONES',
  LOAD_DATA_FAILURE: 'LOAD_DATA_FAILURE',
  UPDATE_DATA_REQUEST: 'UPDATE_DATA_REQUEST',
  UPDATE_DATA_SUCCESS: 'UPDATE_DATA_SUCCESS',
  UPDATE_DATA_FAILURE: 'UPDATE_DATA_FAILURE',
  ERROR_LOADING_OPTIONS: 'ERROR_LOADING_OPTIONS',
};

export { localizationActionTypes as default };
