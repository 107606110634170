import getConstants, { staticConstants } from 'utils/constants';
import messagesActionCreator from 'actioncreators/messages';
import UtilityHelper from 'utils/helpers';
import { StepUpFlow, AuthenticationFlow } from '@idm/authentication-flows';
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const { toastMessage, mobileEventKeys } = staticConstants;
const { authenticationMethod: AUTHENTICATION_METHOD } = getConstants();

function mapStateToProps(state) {
  return {
    requiredLevel: state.mfa.requiredLevel,
    language: state.accountSettingsLocalization.main.language,
    isAuthenticatedByNexo: state.me.main.isAuthenticatedByNexo || false,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    dispatchCodeSentToastMessage() {
      const toastMessageAction = messagesActionCreator.addToastMessage({
        message: 'CODE_SENT',
        type: toastMessage.SUCCESS_TOAST_TYPE,
        id: UtilityHelper.generateUniqueId(),
        translationNamespace: toastMessage.TOAST_I18N_NAMESPACE,
      });

      dispatch(toastMessageAction);
    },

    dispatchErrorToastMessage() {
      const toastMessageAction = messagesActionCreator.addToastMessage({
        message: 'STEP_UP_ERROR',
        type: toastMessage.FAILURE_TOAST_TYPE,
        id: UtilityHelper.generateUniqueId(),
        translationNamespace: toastMessage.TOAST_I18N_NAMESPACE,
      });

      UtilityHelper.triggerMobileEvent(mobileEventKeys.STEP_UP_ERROR);
      dispatch(toastMessageAction);
    },

    dispatchStepUpComplete() {
      UtilityHelper.triggerMobileEvent(mobileEventKeys.STEP_UP_SUCCESS);
      ownProps.history.goBack();
    },

    dispatchCancel() {
      UtilityHelper.triggerMobileEvent(mobileEventKeys.STEP_UP_CANCEL);
      ownProps.history.goBack();
    },
  };
}

const MfaStepUpContainer = (props) => {
  const {
    dispatchErrorToastMessage,
    dispatchCodeSentToastMessage,
    dispatchCancel,
    requiredLevel,
    dispatchStepUpComplete,
    language,
    isAuthenticatedByNexo,
  } = props;

  const { transactionType, transactionCategory } = UtilityHelper.getWindwQueryParams();

  if (isAuthenticatedByNexo) {
    return (
      <div className="idm-modal-container">
        <StepUpFlow
          onError={dispatchErrorToastMessage}
          onCodeSent={dispatchCodeSentToastMessage}
          onCancel={dispatchCancel}
          requiredAuthLevel={requiredLevel}
          onSuccess={dispatchStepUpComplete}
          language={language}
          sessionType={AUTHENTICATION_METHOD.NEXO}
          transactionType={transactionType}
          transactionCategory={transactionCategory}
        />
      </div>
    );
  }
  return (
    <div className="idm-modal-container">
      <AuthenticationFlow
        onError={dispatchErrorToastMessage}
        onCodeSent={dispatchCodeSentToastMessage}
        onCancel={dispatchCancel}
        requiredAuthLevel={requiredLevel}
        onSuccess={dispatchStepUpComplete}
        language={language}
      />
    </div>
  );
};

MfaStepUpContainer.propTypes = {
  requiredLevel: PropTypes.number.isRequired,
  dispatchCodeSentToastMessage: PropTypes.func.isRequired,
  dispatchErrorToastMessage: PropTypes.func.isRequired,
  dispatchStepUpComplete: PropTypes.func.isRequired,
  dispatchCancel: PropTypes.func.isRequired,
  language: PropTypes.string,
  isAuthenticatedByNexo: PropTypes.bool.isRequired,
};

MfaStepUpContainer.defaultProps = {
  language: null,
};

const MfaStepUpPage = connect(mapStateToProps, mapDispatchToProps)(MfaStepUpContainer);

export default MfaStepUpPage;
