/* eslint-disable jsx-a11y/label-has-for*/
import styles from './index.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import combineClasses from 'classnames';

const Label = (props) => {
  const { className, children, ...otherProps } = props;
  const cssClasses = combineClasses(styles.idmLabel, className);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label {...otherProps} className={cssClasses}>
      {children}
    </label>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Label.defaultProps = {
  children: null,
  className: '',
};

export default Label;
