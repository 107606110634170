import buildLoadDataActionCreator from './builders/loadData';
import applicationActionCreator from './application';
import actionTypes, { actionTypeSections } from '../actiontypes';
import http from '../utils/http';
import getConstants from '../utils/constants';

const { requestResult } = getConstants();

const userConfirmationActionCreator = {
  ...buildLoadDataActionCreator(actionTypeSections.USER_CONFIRMATION),

  resetMainState() {
    return {
      type: actionTypes.USER_CONFIRMATION.RESET_STATE,
    };
  },

  resetState() {
    return (dispatch) => {
      dispatch(this.resetMainState());

      dispatch(this.resetLoadDataState());
    };
  },

  loadAndVerifyToken(token) {
    return async (dispatch) => {
      try {
        dispatch(this.loadDataRequest());

        await http.loadAndVerify('associate', token);

        dispatch(this.loadDataSuccess());
      } catch (e) {
        dispatch(this.loadDataFailure());

        if (e.code === http.supportedErrorCodes.TOKEN_LOAD_VERIFY_FAILURE) {
          applicationActionCreator.changeState(requestResult.FAILURE_LINK);

          return;
        }

        applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);
      }
    };
  },
};

export { userConfirmationActionCreator as default };
