import InlineMessage from '../InlineMessage';
import UnscopedTranslatedText, { withNamespace } from '../elements/textDisplays/TranslatedText';
import { supportedErrorCodes } from '../../utils/http/HttpClient';
import React from 'react';
import PropTypes from 'prop-types';

const TranslatedText = withNamespace('CREATE_ACCOUNT.ACCOUNT_INFO');

const getErrorMessage = (errorCode) => {
  switch (errorCode) {
    case supportedErrorCodes.PASSWORD_BLACKLISTED:
      return <TranslatedText i18nKey="PASSWORD_BLACKLISTED" />;
    case supportedErrorCodes.PASSWORD_3_SEQUENTIAL_CHARS:
      return <TranslatedText i18nKey="PASSWORD_3_SEQUENTIAL_CHARS" />;
    case supportedErrorCodes.PASSWORD_3_REPEATED_CHARS:
      return <TranslatedText i18nKey="PASSWORD_3_REPEATED_CHARS" />;
    case supportedErrorCodes.DUPLICATE_EMAIL:
      return <TranslatedText i18nKey="DUPLICATE_EMAIL" />;
    case supportedErrorCodes.DUPLICATE_USERNAME:
      return <TranslatedText i18nKey="DUPLICATE_USERNAME" />;
    case supportedErrorCodes.INCORRECT_PASSWORD:
      return (
        <UnscopedTranslatedText i18nKey="ACCOUNT_SETTINGS.CHANGE_PW.FAILED_TO_UPDATE_PERSONS_PASSWORD" />
      );
    case supportedErrorCodes.TOO_WEAK_PASSWORD:
      return (
        <span data-meta-id="too-weak-password-msg">
          You&apos;ve used this password recently. Please choose another password
        </span>
      );
    default:
      return <TranslatedText i18nKey="SNAG_TRY_AGAIN" dangerouslySetInnerHtml={true} />;
  }
};

const ServerError = (props) => {
  const { code } = props;

  return (
    <InlineMessage type="error">
      <ul>
        <li>{getErrorMessage(code)}</li>
      </ul>
    </InlineMessage>
  );
};

ServerError.propTypes = {
  code: PropTypes.string.isRequired,
};

export default ServerError;
