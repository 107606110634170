import buildLoadDataReducer from 'reducers/builders/loadData';
import actionTypes, { actionTypeSections } from 'actiontypes/';
import { combineReducers } from 'redux';

const mainInitialState = {
  dictionary: null,
};

const loadDataInitialState = {
  isLoadingData: true,
};

function mainReducer(state = mainInitialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.TRANSLATION.SET_DATA:
      return {
        ...state,
        ...actionPayload,
      };
    case actionTypes.TRANSLATION.RESET_STATE:
      return { ...mainInitialState };
    default:
      return state;
  }
}

const translationReducer = combineReducers({
  main: mainReducer,
  loadData: buildLoadDataReducer(loadDataInitialState, actionTypeSections.TRANSLATION),
});

export { translationReducer as default };
