import buildLoadDataActionCreator from './builders/loadData';
import applicationActionCreator from './application';
import actionTypes, { actionTypeSections } from '../actiontypes';
import getConstants from '../utils/constants';
import http from '../utils/http';

const { requestResult } = getConstants();

const samlActionCreator = {
  ...buildLoadDataActionCreator(actionTypeSections.SAML),

  resetMainState() {
    return {
      type: actionTypes.SAML.RESET_STATE,
    };
  },

  resetState() {
    return (dispatch) => {
      dispatch(this.resetMainState());

      dispatch(this.resetLoadDataState());
    };
  },

  loadData(settingsGroupName) {
    return async (dispatch) => {
      dispatch(this.loadDataRequest());
      try {
        const response = await http.getSettingsByGroupName(settingsGroupName);
        const { url } = response.data;

        if (url) {
          dispatch(this.loadDataSuccess());

          applicationActionCreator.changeUrl(url);
        } else {
          throw new Error();
        }
      } catch (e) {
        dispatch(this.loadDataFailure());

        if (e.code === http.supportedErrorCodes.FAILED_TO_GET_SETTINGS) {
          applicationActionCreator.changeState(requestResult.FAILURE_SAML_INCORRECT_APP_ID);
        } else {
          applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);
        }
      }
    };
  },
};

export { samlActionCreator as default };
