import serializer from './serializer';
import HttpClient from '../../../utils/http/HttpClient';
import getConstants from '../../../utils/constants';

const constants = getConstants(process.env.BASE_URL);
const identityOrchestratorUrl = constants.serviceBaseUrls.identityOrchestrator;

class BasicCredentialsDatasource {
  constructor() {
    this.httpClient = HttpClient.createInstance({
      baseURL: identityOrchestratorUrl,
    });
  }

  async createBasicCredentials(data) {
    const url = '/persons/me/basic-credentials';
    const requestBody = serializer.createCredentials.toRequest(data);

    await this.httpClient.post(url, requestBody);
  }
}

export default new BasicCredentialsDatasource();
