import Rule from './Rule';
import ValidationError from '../../exceptions/ValidationError';

export default class DigitRule extends Rule {
  apply(value) {
    if (/[0-9]/.test(value)) {
      return Promise.resolve(this.message);
    }

    return Promise.reject(new ValidationError(this.message));
  }
}
