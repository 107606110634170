import UtilityHelper from '../../../utils/helpers';
import actionCreator from '../../../actioncreators/createCredential/accountInfo';
import TranslatedText from '../../../components/elements/textDisplays/TranslatedText';
import CreateCredentialForm from '../../CreateBasicCredentialsPage/CreateCredentialForm';
import { tokenKinds } from '../../../utils/constants';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const translateKey = (key, isClientRebrandEnabled) => (
  <TranslatedText i18nKey={key} isRebrandEnabled={isClientRebrandEnabled} />
);

class AccountInfo extends Component {
  static propTypes = {
    isLoadingData: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    onCreateCredential: PropTypes.func.isRequired,
    onVerifyToken: PropTypes.func.isRequired,
    isRebrandEnabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = { mounted: false };
  }

  static defaultProps = {
    isRebrandEnabled: false,
  };

  componentDidMount() {
    const { token, onVerifyToken } = this.props;

    onVerifyToken(token);
    this.setState({ mounted: true });
  }

  submitForm = (data) => {
    const { token, onCreateCredential } = this.props;

    return onCreateCredential(token, data);
  };

  render() {
    const { token, isLoadingData, isRebrandEnabled } = this.props;
    const { mounted } = this.state;

    if (!mounted || isLoadingData) {
      return null;
    }

    return (
      <CreateCredentialForm
        title={translateKey('CREATE_CREDENTIAL.ACCOUNT_INFO.TITLE', isRebrandEnabled)}
        subtitle={null}
        submitTitle={translateKey('CREATE_ACCOUNT.ACCOUNT_INFO.CREATE_ACCOUNT_BUTTON')}
        requireTermsAcceptance={true}
        token={token}
        tokenKind={tokenKinds.CREATE_CREDENTIAL}
        onSubmit={(data) => this.submitForm(data)}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { token } = UtilityHelper.parseQueryString(ownProps.location.search);

  return {
    token,
    isLoadingData: state.createCredentialAccountInfo.isLoadingData,
    isRebrandEnabled: state.me.main.isRebrandEnabled,
  };
}

function mapDispatchToProps() {
  return {
    onVerifyToken(token) {
      return actionCreator.loadAndVerifyToken(token);
    },

    onCreateCredential(token, data) {
      return actionCreator.createCredential(token, data);
    },
  };
}

export { AccountInfo as UnwrappedAccountInfo };
export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo);
