import AccountInfo from './AccountInfo';
import SuccessNotification from './SuccessNotification';
import UtilityHelper from 'utils/helpers/';
import { Route, Redirect, Switch } from 'react-router-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CreateCredential extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  constructor(props) {
    super(props);

    const queryParamMap = UtilityHelper.parseQueryString(props.location.search);

    this.token = queryParamMap.token;
  }

  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route exact={true} path={`${match.path}/account-info`} component={AccountInfo} />
        <Route
          exact={true}
          path={`${match.path}/success-notification`}
          component={SuccessNotification}
        />

        <Redirect
          from={`${match.path}`}
          to={{
            pathname: `${match.path}/account-info`,
            search: `?token=${this.token}`,
          }}
        />
      </Switch>
    );
  }
}

export { CreateCredential as default };
