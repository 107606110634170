import { Notification } from '../../components';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class NotificationPage extends Component {
  static propTypes = {
    status: PropTypes.oneOf(['success', 'error', 'info']),
    title: PropTypes.string,
    message: PropTypes.string,
    bottomLink: PropTypes.shape({
      toPath: PropTypes.string,
      text: PropTypes.string,
    }),

    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    status: 'error',
    title: '404: Page Not Found',
    message: 'Sorry, the requested page could not be found. It may have been deleted or moved.',
    bottomLink: {
      toPath: undefined,
      text: undefined,
    },
  };

  renderLink() {
    const { bottomLink: propBottomLink, location } = this.props;
    const bottomLink = (location.state && location.state.bottomLink) || propBottomLink;
    const linkTo = bottomLink.toPath;
    const linkText = bottomLink.text || linkTo;

    if (!bottomLink.toPath) {
      return null;
    }

    return <Link to={linkTo}>{linkText}</Link>;
  }

  render() {
    const { status: propStatus, title: propTitle, message: propMessage, location } = this.props;
    const locationState = location.state;
    const status = (locationState && locationState.status) || propStatus;
    const title = (locationState && locationState.title) || propTitle;
    const message = (locationState && locationState.message) || propMessage;

    return (
      <section>
        <div data-meta-id={`${status}-page`}>
          <Notification title={title} message={message} status={status}>
            {this.renderLink()}
          </Notification>
        </div>
      </section>
    );
  }
}

export { NotificationPage as default };
