import buildFormReducer from 'reducers/builders/form';
import actionTypes, { actionTypeSections } from 'actiontypes/';
import { combineReducers } from 'redux';

const mainInitialState = {
  isRequesting: false,
};

const formInitialState = {
  email: {
    value: '',
    isValid: false,
    isValidating: false,
  },
};

function mainReducer(state = mainInitialState, action) {
  const actionType = action.type;

  switch (actionType) {
    case actionTypes.SELF_REGISTRATION.RESET_STATE:
      return { ...mainInitialState };
    case actionTypes.SELF_REGISTRATION.SELF_REGISTRATION_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case actionTypes.SELF_REGISTRATION.REQUEST_STATUS_SUCCESS:
    case actionTypes.SELF_REGISTRATION.REQUEST_STATUS_FAILURE:
      return {
        ...state,
        isRequesting: false,
      };
    default:
      return state;
  }
}

const requestReducer = combineReducers({
  main: mainReducer,
  form: buildFormReducer(formInitialState, actionTypeSections.SELF_REGISTRATION),
});

export { requestReducer as default };
