const startImpersonationActionTypes = {
  RESET_STATE: 'RESET_STATE',
  IMPERSONATION_LOAD_REQUEST: 'IMPERSONATION_LOAD_REQUEST',
  IMPERSONATION_LOAD_SUCCESS: 'IMPERSONATION_LOAD_SUCCESS',
  IMPERSONATION_LOAD_FAILURE: 'IMPERSONATION_LOAD_FAILURE',
  IMPERSONATION_START_REQUEST: 'IMPERSONATION_START_REQUEST',
  IMPERSONATION_START_SUCCESS: 'IMPERSONATION_START_SUCCESS',
  IMPERSONATION_START_FAILURE: 'IMPERSONATION_START_FAILURE',
};

export default startImpersonationActionTypes;
