const obligationKinds = {
  REDIRECT: 'redirect',
  COMMAND: 'command',
};
const fromActionRequiredResponse = (actionRequiredData) => {
  switch (actionRequiredData.kind) {
    case obligationKinds.REDIRECT:
      return {
        kind: actionRequiredData.kind,
        url: actionRequiredData.url,
      };
    case obligationKinds.COMMAND:
      return {
        kind: actionRequiredData.kind,
        command: actionRequiredData.commandKind,
        args: actionRequiredData.commandArgs,
      };
    default:
      throw new Error(`Unknown command kind: ${actionRequiredData.kind}`);
  }
};

const fromObligationErrorResponse = (responseData) =>
  fromActionRequiredResponse(responseData.obligation.requiredActions[0]);

export default {
  checkObligations: {
    fromResponse: fromObligationErrorResponse,
  },
  fromActionRequiredResponse,
};
