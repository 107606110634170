import styles from './index.module.scss';
import React from 'react';
import combineClasses from 'classnames';
import PropTypes from 'prop-types';

const Avatar = (props) => {
  const { children, className, isRectangular, isUnknown, isCompany } = props;

  const classes = combineClasses(className, styles.avatar, styles.initials, {
    [styles.company]: isCompany,
    [styles.rectangular]: isRectangular,
    [styles.unknown]: isUnknown,
  });

  return <div className={classes}>{children}</div>;
};

Avatar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isRectangular: PropTypes.bool,
  isUnknown: PropTypes.bool,
  isCompany: PropTypes.bool,
};

Avatar.defaultProps = {
  children: null,
  className: null,
  isRectangular: false,
  isUnknown: false,
  isCompany: false,
};
export default Avatar;
