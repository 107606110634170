import datasource from './datasource';
import buildFormActionCreator from '../../builders/form';
import messagesActionCreator from '../../messages';
import UtilityHelper from '../../../utils/helpers';
import URLs from '../../../utils/urls';
import obligationsActionCreator from '../../obligations';
import actionTypes, { actionTypeSections } from '../../../actiontypes';
import http from '../../../utils/http';
import getConstants, { staticConstants } from '../../../utils/constants';

const getActionForTypeAndData = (type, payload) => ({ type, payload });

const actionBuilder = {
  loadUsernameRequestAction: () =>
    getActionForTypeAndData(actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.LOAD_REQUEST),
  loadUsernameSuccessAction: (data) =>
    getActionForTypeAndData(actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.LOAD_SUCCESS, data),
  loadUsernameFailureAction: (error) =>
    getActionForTypeAndData(actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.LOAD_FAILURE, error),

  getConflictionUsernamesRequestAction: () =>
    getActionForTypeAndData(actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.GET_USERNAME_REQUEST),
  getConflictionUsernamesSuccessAction: (data) =>
    getActionForTypeAndData(
      actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.GET_USERNAME_SUCCESS,
      data
    ),
  getConflictionUsernamesFailureAction: (error) =>
    getActionForTypeAndData(actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.GET_USERNAME_ERROR, error),

  checkUsernameUniqueRequestAction: () =>
    getActionForTypeAndData(
      actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.CHECK_USERNAME_UNIQUE_REQUEST
    ),
  checkUsernameUniqueSuccessAction: (data) =>
    getActionForTypeAndData(
      actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.CHECK_USERNAME_UNIQUE_SUCCESS,
      data
    ),
  checkUsernameUniqueFailureAction: (data) =>
    getActionForTypeAndData(
      actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.CHECK_USERNAME_UNIQUE_ERROR,
      data
    ),

  updateUsernamesRequestAction: () =>
    getActionForTypeAndData(actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.CHANGE_USERNAME_REQUEST),
  updateUsernamesSuccessAction: (data) =>
    getActionForTypeAndData(
      actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.CHANGE_USERNAME_SUCCESS,
      data
    ),
  updateUsernamesFailureAction: (error) =>
    getActionForTypeAndData(
      actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.CHANGE_USERNAME_ERROR,
      error
    ),
};

const ChangeUsernameActionCreator = {
  ...buildFormActionCreator(actionTypeSections.ACCOUNT_SETTINGS_CHANGE_USERNAME),
  resetMainState() {
    return {
      type: actionTypes.ACCOUNT_SETTINGS_CHANGE_USERNAME.RESET_STATE,
    };
  },

  loadData() {
    return async (dispatch) => {
      const { loadUsernameRequestAction, loadUsernameSuccessAction, loadUsernameFailureAction } =
        actionBuilder;

      dispatch(loadUsernameRequestAction());
      try {
        const personData = await http.getMyPersonalInfo();
        const { data: person } = personData;

        dispatch(loadUsernameSuccessAction(person));
      } catch (err) {
        dispatch(loadUsernameFailureAction(err.errors[0]));
      }
    };
  },

  getConflictingUsernames() {
    return async (dispatch) => {
      const {
        getConflictionUsernamesRequestAction,
        getConflictionUsernamesSuccessAction,
        getConflictionUsernamesFailureAction,
      } = actionBuilder;

      dispatch(getConflictionUsernamesRequestAction());
      try {
        const payload = await datasource.getConflictingUsernames();

        if (payload && Array.isArray(payload.associates) && payload.associates.length > 0) {
          payload.associates.forEach(({ clientId, associateId }) => {
            dispatch(this.setFormField(`${clientId}:${associateId}`, ''));
            dispatch(this.setFormField(`${clientId}:${associateId}:password`, ''));
          });
        }
        dispatch(getConflictionUsernamesSuccessAction({ associateUserNameDetails: payload }));
      } catch (err) {
        dispatch(getConflictionUsernamesFailureAction(err.errors[0]));
      }
    };
  },

  checkUsernameUnique(username) {
    return async (dispatch) => {
      const {
        checkUsernameUniqueRequestAction,
        checkUsernameUniqueSuccessAction,
        checkUsernameUniqueFailureAction,
      } = actionBuilder;

      dispatch(checkUsernameUniqueRequestAction());
      try {
        const data = await datasource.checkUsernameUnique(username);
        const { isAvailable } = data;

        dispatch(checkUsernameUniqueSuccessAction(isAvailable));
      } catch (err) {
        dispatch(checkUsernameUniqueFailureAction(true));
      }
    };
  },

  updateUsername(payload) {
    return async (dispatch) => {
      const {
        updateUsernamesFailureAction,
        updateUsernamesRequestAction,
        updateUsernamesSuccessAction,
      } = actionBuilder;

      dispatch(updateUsernamesRequestAction());
      try {
        await datasource.updateUsername(payload);

        dispatch(
          messagesActionCreator.addToastMessage({
            message: 'CHANGE_USERNAME_SUCCESS_MESSAGE',
            type: 'success',
            id: UtilityHelper.generateUniqueId(),
            translationNamespace: 'TOAST_MESSAGE',
          })
        );
        dispatch(updateUsernamesSuccessAction());

        obligationsActionCreator.clearObligations();

        let redirectLocation;
        const {
          data: { authentication_method: authenticationMethod },
        } = await http.checkAuthentication();
        const isAuthenticatedByNexo =
          authenticationMethod === getConstants().authenticationMethod.NEXO;

        if (isAuthenticatedByNexo) {
          redirectLocation = URLs.LOGOUT.concat(`${window.location.search}`);
        } else {
          const { redirectUrl } = UtilityHelper.getWindwQueryParams();

          redirectLocation = redirectUrl || staticConstants.DEFAULT_LOGIN_LANDING_PAGE;
        }

        obligationsActionCreator.changeUrl(redirectLocation);
      } catch (err) {
        dispatch(updateUsernamesFailureAction(err.errors[0]));
      }
    };
  },
};

export { ChangeUsernameActionCreator as default };
