import Translation from './Translation';
import Translator from 'utils/translation/Translator';
import { connect } from 'react-redux';
import React from 'react';

function mapStateToProps(state) {
  return {
    dictionary: state.translation.main.dictionary,
  };
}

const TranslatedText = connect(mapStateToProps, null)(Translation);

function namespaceTranslatedText(namespace) {
  return (props) => <TranslatedText namespace={namespace} {...props} />;
}

function getI18nContext(namespace, dictionary) {
  const translator = new Translator(namespace, dictionary);

  return {
    getTextForKey: (key, params) => (
      <Translation translator={translator} textKey={key} params={params} />
    ),
    getRawTextForKey: (key, params) => translator.get(key, null, params),
  };
}

export {
  namespaceTranslatedText as default,
  getI18nContext,
  TranslatedText,
  Translation as UnwrappedTranslatedText,
};
