import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

class Logo extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['blue', 'white']),
  };

  static defaultProps = {
    type: 'blue',
  };

  render() {
    const { type } = this.props;

    return <div className={`${type}-logo`} />;
  }
}

export { Logo as default };
