import buildLoadDataReducer from 'reducers/builders/loadData';
import actionTypes, { actionTypeSections } from 'actiontypes/';
import { combineReducers } from 'redux';

const mainInitialState = {
  companyName: 'Your company',
  associateFirstName: '',
  associateId: '',
  clientId: '',
  skipToCreateAccount: false,
  registrationCode: '',
};

const loadDataInitialState = {
  isLoadingData: false,
};

function mainReducer(state = mainInitialState, action) {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case actionTypes.ACCOUNT_REGISTRATION.RESET_STATE:
      return { ...mainInitialState };
    case actionTypes.ACCOUNT_REGISTRATION.SET_COMPANY_NAME:
      return { ...state, companyName: actionPayload };
    case actionTypes.ACCOUNT_REGISTRATION.SET_ASSOCIATE_FIRST_NAME:
      return { ...state, associateFirstName: actionPayload };
    case actionTypes.ACCOUNT_REGISTRATION.SET_ASSOCIATE_IDENTIFIER:
      return { ...state, associateId: actionPayload };
    case actionTypes.ACCOUNT_REGISTRATION.SET_CLIENT_IDENTIFIER:
      return { ...state, clientId: actionPayload };
    case actionTypes.ACCOUNT_REGISTRATION.SKIP_TO_CREATE_ACCOUNT:
      return { ...state, skipToCreateAccount: actionPayload };
    case actionTypes.ACCOUNT_REGISTRATION.FAILURE_REDIRECT_TO_AIM:
      return { ...state, registrationCode: actionPayload };
    default:
      return state;
  }
}

const accountRegistrationReducer = combineReducers({
  main: mainReducer,
  loadData: buildLoadDataReducer(loadDataInitialState, actionTypeSections.ACCOUNT_REGISTRATION),
});

export { accountRegistrationReducer as default };
