import actionTypes from 'actiontypes/';

const mainInitialState = {
  landingProducts: null,
  isLoading: false,
  error: null,
};

function emailVerifyNotificationReducer(state = mainInitialState, action) {
  const actionType = action.type;

  switch (actionType) {
    case actionTypes.LANDING_PRODUCTS.REQUEST:
      return {
        isLoading: true,
        landingProducts: null,
        error: null,
      };
    case actionTypes.LANDING_PRODUCTS.FAILURE:
      return {
        isLoading: false,
        landingProducts: null,
        error: action.payload,
      };
    case actionTypes.LANDING_PRODUCTS.SUCCESS:
      return {
        isLoading: false,
        landingProducts: action.payload,
        error: null,
      };
    default:
      return state;
  }
}

export { emailVerifyNotificationReducer as default };
