import TranslationsActionCreator from './actioncreators/translations';
import Application from './App';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  return {
    dictionary: state.translation.main.dictionary,
    children: ownProps.children,
    language: state.accountSettingsLocalization.main.getCurrentLanguage(),

    onAppLoad: () => TranslationsActionCreator.loadPackageResource(),
  };
};

export default connect(mapStateToProps)(Application);
