import meActionCreator from './me';
import applicationActionCreator from './application';
import buildLoadDataActionCreator from './builders/loadData';
import actionTypes, { actionTypeSections } from '../actiontypes';
import getConstants from '../utils/constants';
import http from '../utils/http';

const { requestResult } = getConstants();

const ssoMeActionCreator = {
  ...buildLoadDataActionCreator(actionTypeSections.SSO_ME),

  resetMainState() {
    return {
      type: actionTypes.SSO_ME.RESET_STATE,
    };
  },

  resetState() {
    return (dispatch) => {
      dispatch(this.resetMainState());

      dispatch(this.resetLoadDataState());
    };
  },

  setData(payload) {
    return {
      type: actionTypes.SSO_ME.SET_DATA,
      payload,
    };
  },

  loadAndVerifyToken(token) {
    return async (dispatch) => {
      try {
        dispatch(this.loadDataRequest());

        const res = await http.loadAndVerify('associateSSO', token, true);
        const payload = {};

        const { extra_information: extraInformation, token_blob: tokenBlob } = res.data;

        if (extraInformation) {
          const {
            first_name: firstName,
            last_name: lastName,
            email,
            client_name: clientName,
          } = extraInformation;

          if (firstName) {
            payload.firstName = firstName;
          }

          if (lastName) {
            payload.lastName = lastName;
          }

          if (email) {
            payload.email = email;
          }

          if (clientName) {
            payload.clientName = clientName;
          }
        }

        if (tokenBlob) {
          const { client_id: clientId } = tokenBlob;

          if (clientId) {
            payload.clientId = clientId;
          }
        }

        dispatch(this.setData(payload));

        dispatch(this.loadDataSuccess());
      } catch (e) {
        if (e.code === http.supportedErrorCodes.TOKEN_LOAD_VERIFY_FAILURE) {
          applicationActionCreator.changeState(requestResult.FAILURE_UNAUTHORIZED);
        } else {
          applicationActionCreator.changeState(requestResult.FAILURE_UNSUPPORTED);
        }

        dispatch(this.loadDataFailure());

        throw e;
      }
    };
  },

  initialize(token, history) {
    return async (dispatch) => {
      try {
        dispatch(meActionCreator.checkAuthenticationRequest());
        dispatch(this.loadDataRequest());

        await dispatch(
          meActionCreator.checkAuthentication(window.location.pathname, {
            REDIRECT_RESULT: requestResult.FAILURE_UNAUTHORIZED,
          })
        );
        await dispatch(this.loadAndVerifyToken(token, history));
      } catch (e) {
        /*
         * No need to handle here. Let each async catch block do its own job.
         * This catch block is for blocking the subsequent request(s) to fire
         * if any request fails beforehand.
         */
      }
    };
  },
};

export { ssoMeActionCreator as default };
