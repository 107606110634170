const personalInfoActionTypes = {
  RESET_STATE: 'RESET_STATE',
  SET_CLIENT_ID: 'SET_CLIENT_ID',
  SET_COMPANY_NAME: 'SET_COMPANY_NAME',
  SET_ASSOCIATE_FIRST_NAME: 'SET_ASSOCIATE_FIRST_NAME',
  SET_ASSOCIATE_LAST_NAME: 'SET_ASSOCIATE_LAST_NAME',
  CHECK_AUTH_REQUEST: 'CHECK_AUTH_REQUEST',
  CHECK_AUTH_SUCCESS: 'CHECK_AUTH_SUCCESS',
  CHECK_AUTH_FAILURE: 'CHECK_AUTH_FAILURE',
  SET_CODE_EMAIL_VERIFICATION_TOKEN: 'SET_CODE_EMAIL_VERIFICATION_TOKEN',
  PREPARE_CODE_EMAIL_VERIFICATION_REQUEST: 'PREPARE_CODE_EMAIL_VERIFICATION_REQUEST',
  PREPARE_CODE_EMAIL_VERIFICATION_SUCCESS: 'PREPARE_CODE_EMAIL_VERIFICATION_SUCCESS',
  PREPARE_CODE_EMAIL_VERIFICATION_FAILURE: 'PREPARE_CODE_EMAIL_VERIFICATION_FAILURE',
  VERIFY_TOKEN_REQUEST: 'VERIFY_TOKEN_REQUEST',
  VERIFY_TOKEN_SUCCESS: 'VERIFY_TOKEN_SUCCESS',
  VERIFY_TOKEN_FAILURE: 'VERIFY_TOKEN_FAILURE',
};

export { personalInfoActionTypes as default };
