import CreateBasicCredentialsContainer from './CreateBasicCredentialsPage';
import basicCredentialsActionCreator from '../../actioncreators/createCredential/basicCredentials';
import urls from '../../utils/urls';
import utils from '../../utils/helpers';
import { connect } from 'react-redux';

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({
  onCancel() {
    basicCredentialsActionCreator.changeUrl(urls.LOGOUT);
  },

  async onSubmit(data) {
    await basicCredentialsActionCreator.createBasicCredentials(data);

    const { redirectUrl } = utils.getWindwQueryParams();

    basicCredentialsActionCreator.changeUrl(redirectUrl || urls.LANDING_PAGE);
  },
});

const CreateBasicCredentialsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateBasicCredentialsContainer);

export default CreateBasicCredentialsPage;
