const getValidationPayloadForType = (kind, data) => {
  return { username: data.username };
};
const toValidateRequest = (kind, token, data) => {
  return {
    kind,
    token,
    data: getValidationPayloadForType(kind, data),
  };
};

const fromUsernameCheckResponse = (response) => {
  const { data } = response;

  if (data.username.exists) {
    const error = new Error();

    error.response = response;
    error.code = 'USERNAME_EXISTS';
    throw error;
  }
  return {};
};

const fromValidateResponse = (kind, response) => {
  return fromUsernameCheckResponse(response);
};

export default { toValidateRequest, fromValidateResponse };
