import ChangePassword from './ChangePassword';
import Localization from './Localization';
import Devices from './Devices';
import ContactInfoView from './ContactInfo';
import SwitchClients from './SwitchClients';
import SessionExpireModal from '../SessionExpireModal';
import { Tab } from '../../components';
import Translator from '../../utils/translation/Translator';
import TranslationContext from '../../context/TranslationsContext';
import { withNamespace } from '../../components/elements/textDisplays/TranslatedText';
import { Route, Redirect, Switch } from 'react-router-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Picklist, PicklistOption as Option } from '@idm/ui-components';

import './style.scss';

const TranslatedText = withNamespace('ACCOUNT_SETTINGS');

const TabNames = {
  SwitchClients: 'switch-clients',
  ContactInfo: 'contact-info',
  ChangePassword: 'change-password',
  Localization: 'localization',
  Devices: 'devices',
};

class AccountSettings extends Component {
  static contextType = TranslationContext;

  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    onChangeUrl: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    isAuthenticatedByNexo: PropTypes.bool.isRequired,
  };

  onChangeDropDownSelect = (relativePath) => {
    const { onChangeUrl } = this.props;

    onChangeUrl(`/auth/account-settings/${relativePath}`);
  };

  renderTabs() {
    const { dictionary } = this.context;
    const { match, location, isAuthenticatedByNexo } = this.props;
    const translator = new Translator('ACCOUNT_SETTINGS', dictionary);

    const defaultTabs = [
      { value: TabNames.SwitchClients, label: translator.get('CLIENT_TAB') },
      { value: TabNames.ContactInfo, label: translator.get('CONTACT_INFO_TAB') },
      { value: TabNames.ChangePassword, label: translator.get('PW_TAB') },
      { value: TabNames.Localization, label: translator.get('LOCALIZATION_TAB') },
      { value: TabNames.Devices, label: translator.get('DEVICES_TAB') },
    ];

    const nexoTabs = [
      { value: TabNames.SwitchClients, label: translator.get('CLIENT_TAB') },
      { value: TabNames.ChangePassword, label: translator.get('PW_TAB') },
      { value: TabNames.Localization, label: translator.get('LOCALIZATION_TAB') },
    ];

    const tabs = isAuthenticatedByNexo ? nexoTabs : defaultTabs;

    const toOption = (item) => <Option value={item.value}>{item.label}</Option>;
    const current = location.pathname.split('/').pop();

    return (
      <div className="tabs">
        <ul className="tabs-horizontal">
          {tabs.map((tab) => (
            <Tab key={tab.value} path={`${match.path}/${tab.value}`}>
              {tab.label}
            </Tab>
          ))}
        </ul>
        <Picklist className="tabs-drop-down" value={current} onChange={this.onChangeDropDownSelect}>
          {tabs.map(toOption)}
        </Picklist>
      </div>
    );
  }

  render() {
    const { match } = this.props;

    return (
      <section className="account-settings">
        <SessionExpireModal />
        <h1>
          <TranslatedText i18nKey="HEADER" />
        </h1>
        <div className="header-description">
          <TranslatedText i18nKey="HEADER_DESC" />
        </div>
        {this.renderTabs()}

        <section className="tab-content">
          <Switch>
            <Route
              exact={true}
              path={`${match.path}/${TabNames.SwitchClients}`}
              component={SwitchClients}
            />
            <Route
              exact={true}
              path={`${match.path}/${TabNames.ContactInfo}`}
              component={ContactInfoView}
            />
            <Route
              exact={true}
              path={`${match.path}/${TabNames.ChangePassword}`}
              component={ChangePassword}
            />
            <Route
              exact={true}
              path={`${match.path}/${TabNames.Localization}`}
              component={Localization}
            />
            <Route exact={true} path={`${match.path}/${TabNames.Devices}`} component={Devices} />

            <Redirect from="*" to={`${match.path}/switch-clients`} />
          </Switch>
        </section>
      </section>
    );
  }
}

export { AccountSettings as default };
